import React from 'react';
import {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {post, get, checkHttpStatus} from '../../utils/RestUtilsWithAxios';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import {
    GET_ADMIN_USERS, DELETE_ADMIN_USERS, GET_USER_INFO, SAVE_ADMIN_USER
} from '../../constants/uri';
import UserTable from '../Admin/partials/UserTable';
import Button from "@material-ui/core/Button";
import {Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import {alertBox} from "../../components/BoxProvider";
import * as Globals from "../../constants/globals";
import TablePagination from "@material-ui/core/TablePagination";
import clsx from "clsx";
import {Form} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width: 200
        },
    },
    whiteText: {
        color: 'white'
    }
}))

function AdminAuthorization(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [fade, setFade] = useState(false);
    const [deleteControl, setDeleteControl] = useState(false);
    const [saveControl, setSaveControl] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [tckn, setTckn] = useState("");
    const [searchData, setSearchData] = useState({});
    const [nameSurname, setNameSurname] = useState("");

    useEffect(() => {
        getUsers();
    }, []);
    useEffect(() => {
        if (tckn == null || tckn == "" || tckn.length != 11) {
            setSearchData({});
            setNameSurname("");
        }
    }, [tckn]);

    function clear() {
        setSaveControl(false);
        setDeleteControl(false);
        setAddUser(false);
        setSelectedUser("");
        setTckn("");
        setSearchData({});
        setNameSurname("");
    }

    function getUsers() {
        get(GET_ADMIN_USERS)
            .then(function (response) {
                setData(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            });
    }

    function deleteUser() {
        post(DELETE_ADMIN_USERS, {tckn: selectedUser})
            .then(function (response) {
                if (response.data.status) {
                    getUsers();
                    clear();
                    alertBox("", props.intl.formatMessage({id: "COMMON.ALERT.MSG_SUCCESS_SAVE"}), Globals.ALERT_SUCCESS, props.intl);
                } else {
                    alertBox("", response.data.exception, Globals.ALERT_ERROR, props.intl);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            });
        setDeleteControl(false);
        setSelectedUser("");
    }

    function charCodeControl(event) {
        var charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode :
            ((event.which) ? event.which : 0));
        var valid = (charCode >= 48 && charCode <= 57);
        if (!valid) {
            event.preventDefault();
        }
    }

    function searchTckn() {
        if (tckn.length != 11) {
            alertBox("", "TCKN bilgisi hatalı",
                Globals.ALERT_WARNING, props.intl);
        } else {
            post(GET_USER_INFO, {tckn: tckn})
                .then(function (response) {
                    console.log(response);
                    setSearchData(response.data);
                    setNameSurname(response.data.name + " " + response.data.surname);
                })
                .catch(function (error) {
                    checkHttpStatus(error, props.intl);
                });
        }
    }


    function addAdminUser() {
        if (tckn == null || tckn == "" || searchData.tckn == null) {
            alertBox("", "TCKN bilgisi ile kullanıcıyı arayınız",
                Globals.ALERT_WARNING, props.intl);
        } else if (searchData.tckn != null && searchData.tckn.length != 11) {
            alertBox("", "TCKN bilgisi hatalı",
                Globals.ALERT_WARNING, props.intl);
        } else {
            post(SAVE_ADMIN_USER, {tckn: searchData.tckn})
                .then(function (response) {
                    if (response.data.status) {
                        getUsers();
                        clear();
                        alertBox("", props.intl.formatMessage({id: "COMMON.ALERT.MSG_SUCCESS_SAVE"}), Globals.ALERT_SUCCESS, props.intl);
                    } else {
                        alertBox("", response.data.exception, Globals.ALERT_ERROR, props.intl);
                    }
                })
                .catch(function (error) {
                    checkHttpStatus(error, props.intl);
                });
        }
    }

    return (
        <div className={classes.root}>
            <div>
                <Dialog
                    open={deleteControl}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    style={{textAlign: "left"}}
                >
                    <DialogTitle
                        id="form-dialog-title">{props.intl.formatMessage({id: "ADMIN_AUTHORIZATION.DIALOG.TITLE"})}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            {props.intl.formatMessage({id: "ADMIN_AUTHORIZATION.DIALOG.WARNING"})}
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button color="primary"
                                onClick={() => {
                                    clear();
                                }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_NO"})}
                        </Button>
                        <Button
                            color="primary" variant="contained"
                            onClick={() => {
                                deleteUser()
                            }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_YES"})}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={addUser}
                    fullWidth
                    maxWidth={'md'}
                    aria-labelledby="form-dialog-title"
                    style={{textAlign: "left"}}
                >
                    <DialogTitle
                        id="form-dialog-title">{props.intl.formatMessage({id: "ADMIN_AUTHORIZATION.DIALOG.TITLE"})}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <TextField
                                value={tckn}
                                label="TCKN"
                                margin="normal"
                                className="kt-width-full"
                                name="tckn"
                                variant="outlined"
                                onKeyPress={event => { charCodeControl(event); }}
                                inputProps={{maxLength: 11}}
                                onChange={(event) => setTckn(event.target.value)}
                                InputProps={{
                                    endAdornment: <Button variant='contained' color='secondary' onClick={() => searchTckn()}>
                                        <FormattedMessage id="ADMIN_AUTHORIZATION.SEARCH_BUTTON"/>
                                    </Button>
                                }}
                            />
                            <TextField
                                id="nameSurname"
                                type="text"
                                label="Adı-Soyadı"
                                margin="normal"
                                className="kt-width-full"
                                name="nameSurname"
                                variant="outlined"
                                disabled="true"
                                value={nameSurname}
                            />

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button color="primary"
                                onClick={() => {
                                    clear();
                                }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_CANCEL"})}
                        </Button>
                        <Button
                            color="primary" variant="contained"
                            onClick={() => {
                                if (tckn == null || tckn == "" || searchData.tckn == null)
                                    alertBox("", "TCKN bilgisi ile kullanıcıyı arayınız",
                                        Globals.ALERT_WARNING, props.intl);
                                else
                                    setSaveControl(true)
                            }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_ADD"})}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={saveControl}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    style={{textAlign: "left"}}
                >
                    <DialogTitle
                        id="form-dialog-title">{props.intl.formatMessage({id: "ADMIN_AUTHORIZATION.DIALOG.TITLE"})}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            {props.intl.formatMessage({id: "ADMIN_AUTHORIZATION.DIALOG.SAVE_WARNING"})}
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button color="primary"
                                onClick={() => {
                                    setSaveControl(false)
                                }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_NO"})}
                        </Button>
                        <Button
                            color="primary" variant="contained"
                            onClick={() => {
                                addAdminUser()
                            }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_YES"})}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Paper className={classes.paper}>
                <Fade in={fade} timeout={100}>
                    {
                        data.length > 0 ?
                            <div>
                                <UserTable users={data} intl={props.intl} setDeleteControl={setDeleteControl}
                                           setSelectedUser={setSelectedUser}/>
                            </div>
                            :
                            <Typography
                                variant="subtitle1">{props.intl.formatMessage({id: "ACCOUNT_INFO.MESSAGE.CANNOT_FIND"})}</Typography>
                    }
                </Fade>
                <div style={{textAlign: 'end', paddingTop: 16}}>
                    <Button classes={{label: classes.whiteText}} type='submit' size='large' variant="contained"
                            color='primary' fullWidth className={[classes.showButton]} onClick={() => setAddUser(true)}>
                        {props.intl.formatMessage({id: "ADMIN_AUTHORIZATION.ADD_BUTTON"})}
                    </Button>
                </div>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(AdminAuthorization)
);