import React from "react";
import { FormattedMessage } from "react-intl";

/**
 * Created by gaslan on 3.4.2020
 */
export default class MenuItemText extends React.Component {
    /**
     *
     * @returns {*}
     */
  render() {
    const { item, parentItem } = this.props;

    return (
      <>
        {parentItem && parentItem.bullet === "dot" && (
          <i className="">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        { <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
            {!item.translate ? (
              item.title
            ) : (
              <FormattedMessage id={item.translate} defaultMessage={item.title} />
            )}
          </span>
        }

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" style={{ color: "white" }} />}

        { item.iconUrl ?
          <div class="container">
            <img alt="logo" style={{ width: "33px", height: "33px" }} src={item.iconUrl} />
          </div>
          :
          item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />
        }

      </>
    );
  }
}