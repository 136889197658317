var timerElem;
var x

export function intervalFunc(minutes,endFunction){
  var countDownDate = (new Date().getTime()) + minutes * 60000;
  timerElem = document.getElementById("timerArea");
  timerElem.innerHTML = "<br/>"
   x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (timerElem == null) {
          console.log("timer is up");
          clearInterval(x);
      } else {
          timerElem.innerHTML = "<b>" +  (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds + "</b>";
          if (distance < 0) {
              clearInterval(x);
              timerElem.innerHTML =" <br><b> 00:00 </b>" ;
              console.log("timer end");
              endFunction();
          }
      }
  }, 1000);
}

export function clearTimer() {
    clearInterval(x);
}