import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function Dropdown(props) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    /**
     *
     * @param event
     */
    function handleChange(event) {
        props.handleContainerChange(event.target.value);
    }

    return (
        <div className={classes.root}>
                <FormControl margin={props.noMargin ? undefined : "normal"} required={props.required} undefined variant="outlined" fullWidth style={props.style}>
                    <InputLabel ref={inputLabel} htmlFor="helper">{props.title}</InputLabel>
                    <Select
                        value={props.containerSelectedItem}
                        onChange={props.handleContainerChange ? (e)=>handleChange(e) : props.onChange}
                        input={<OutlinedInput labelWidth={labelWidth} name={props.name} id={props.id}/>}
                        error={props.error}
                    >
                        {props.items.map((item,i)=><MenuItem selected={i===0} key={i} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                    { props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null }
                </FormControl>
        </div>
    );
}