import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import BalanceAndPortfolio from './partials/BalanceAndPortfolio';
import Pledge from './partials/Pledge';
import Lending from './partials/Lending';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import * as Globals from '../../constants/globals';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    navButton: {
      maxWidth:'100%',
      marginLeft: 5,
      marginRight: 5,
      backgroundColor: Globals.COLOR_TRANSPARENT_RED,
      borderRadius: 4,   
    },
    activeNavButton: {
        maxWidth:'100%',
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Globals.COLOR_FULL_RED,
        borderRadius: 4,  
      },
    nav: {
        backgroundColor: "white",
        marginBottom:16,
        justifyContent: "space-around",
        height: "unset",
        padding: 5,
        borderRadius: 4,
        paddingLeft: 0,
        paddingRight: 0
    },
    navText: {
        color: "white",
        fontSize: 14
    },    
}));


/**
 * BalancePortfolio
 * @param props
 * @return {*}
 * @constructor
 */
function BalancePortfolio (props) {
    const classes = useStyles();
    const [pageValue, setPageValue] = React.useState(0);

    return (
            <div className={classes.root}>
                <BottomNavigation
                    value={pageValue}
                    onChange={(event, newValue) => {
                        setPageValue(newValue);
                    }}
                    showLabels
                    classes={{root: classes.nav}}
                    >
                    <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                    formatMessage({id:"PORTFOLIO.NAV.BALANCE"})}</span>}
                                            classes={{root: pageValue === 0 ?
                                                    classes.activeNavButton :
                                                    classes.navButton}}  />
                    <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                    formatMessage({id:"PORTFOLIO.NAV.PLEDGE"})}</span>}
                                            classes={{root: pageValue === 1 ?
                                                    classes.activeNavButton :
                                                    classes.navButton}} />
                    <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                    formatMessage({id:"PORTFOLIO.NAV.BORROWING"})}</span>}
                                            classes={{root: pageValue === 2 ?
                                                    classes.activeNavButton :
                                                    classes.navButton}} />
                </BottomNavigation>

                {
                    pageValue === 0 ?
                    <BalanceAndPortfolio />
                    :
                    pageValue === 1 ?
                    <Pledge />
                    :
                    <Lending />
                }
            </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl((BalancePortfolio));