import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import {put, takeLatest} from "redux-saga/effects";
import {GET_INVESTOR_BLOCKAGE_DATA} from "../../constants/uri";
import {get} from "../../utils/RestUtilsWithAxios";
import {checkHttpStatus} from "../../utils/RestUtilsWithAxios";

export const actionTypes = {
    InvestorBlockageDataRequested: "[InvestorBlockageDataRequested] Action",
    InvestorBlockageDataLoaded: "[InvestorBlockageDataLoaded] Action",
};

const initialInvestorBlockageState = {
    intl: undefined,
    investorBlockageData: undefined,
};

var intl = undefined;

export const reducer = persistReducer(
    { storage, key: "demo1-investorBlockage"},
    (state = initialInvestorBlockageState, action) => {
        switch (action.type) {

            case actionTypes.InvestorBlockageDataRequested: {
                intl = action.payload;
                return state;
            }

            case actionTypes.InvestorBlockageDataLoaded: {
                return { ...state, investorBlockageData: action.payload};
            }

            default:
                return state;
        }
    }
);

export const actions = {
    requestInvestorBlockageData : intl => ({ type: actionTypes.InvestorBlockageDataRequested,
        payload: intl}),
    loadInvestorBlockageData : blockedData => ({ type: actionTypes.InvestorBlockageDataLoaded,
        payload: { blockedData } })
}


export function* saga() {

    yield takeLatest(actionTypes.InvestorBlockageDataRequested, function* blockageRequested() {
        try {
        const { data: blockedData } =  yield  get(GET_INVESTOR_BLOCKAGE_DATA).catch(function (error) {
                checkHttpStatus(error,intl);
            });
        yield put(actions.loadInvestorBlockageData(blockedData));
        }
        catch (e) {
            console.error(e);
        }
    });

}
