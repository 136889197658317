import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 13,
        fontWeight: 600
    },
    fontBold: {
        fontWeight: 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'companyTitle', numeric: false, disablePadding: false, label: "Şirket" },
        { id: 'gmDate', numeric: false, disablePadding: false, label: "Tarih" },
        { id: 'gmAddress', numeric: false, disablePadding: false, label: "Adres" },
        { id: 'explanation', numeric: false, disablePadding: false, label: "Açıklama" },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {
                                row.secondLabel ?
                                    <div>
                                        {row.label}<br />
                                        <Typography classes={{ subtitle1: headClasses.fontBold }}
                                                    variant="subtitle1">{row.secondLabel}</Typography>
                                    </div>
                                    :
                                    <div>{row.label}</div>
                            }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13: {
        fontSize: 13
    },
    link: {
        color:'#aa3323'
    }
}));
/**
 * GmInfoTable
 * @param props
 * @return {*}
 * @constructor
 */
export default function GmInfoTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('memberTooltip');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.accInfoData;


    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
        <div>
            <div className={classes.tableWrapper} id="accountTable">
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        intl={props.intl}
                    />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={'row' + index}
                                    >
                                        <TableCell align="left" key={"companyTitle_" + index}
                                                   id={"companyTitle_" + index}
                                                   className={classes.fontSize13}>{row.companyTitle}</TableCell>
                                        <TableCell align="left" key={"gmDate_" + index}
                                                   id={"gmDate_" + index}
                                                   className={classes.fontSize13}>{row.gmDate}</TableCell>
                                        <TableCell align="left" key={"gmAddress_" + index}
                                                   id={"gmAddress_" + index}
                                                   className={classes.fontSize13}>{row.gmAddress}</TableCell>
                                        <TableCell align="left" key={"explanation_" + index}
                                                   id={"explanation_" + index}
                                                   className={classes.fontSize13}>{row.explanation}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonText={props.intl.formatMessage({ id: "TABLE.PAGINATION.PREVIOUS" })}
                nextIconButtonText={props.intl.formatMessage({ id: "TABLE.PAGINATION.NEXT" })}
                labelRowsPerPage={props.intl.formatMessage({ id: "TABLE.PAGINATION.PER_PAGE" })}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}