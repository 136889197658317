import React from "react";

/**
 * Created by gaslan on 3.4.2020
 */
export default class MenuItemSeparator extends React.Component {
    /**
     *
     * @returns {*}
     */
  render() {
    return (
      <li className="kt-menu__separator">
        <span />
      </li>
    );
  }
}
