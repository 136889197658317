import React from 'react';
import { intervalFunc } from '../utils/countDown';
import { actions } from "../store/ducks/auth.duck";
import myStore from '../store/store';
import jwtDecode from 'jwt-decode';


export default function LogoutCounter (props) {
    var additionalLimit = props.additionalLimit * 60000;
    
    React.useEffect(() => {
        //var expireTime = parseInt(jwtDecode(props.token).exp)*1000;
        var watcher = setInterval( function (){
            var newToken = localStorage.getItem('token');
            var expireTime = parseInt(jwtDecode(newToken).exp)*1000;
            console.log("token expire time , " + expireTime)
            var now = new Date().getTime();
            var distance = expireTime - now;
            if (distance > 0) {
                console.log("checking for session timeout, " + distance/1000 + " seconds left...")
            }
            else {
                myStore.dispatch(actions.setTimeOut(true));
                if (distance > -1 * additionalLimit) {
                    console.log("waiting for a response to refresh session...")
                }
                else {
                    console.log("Your session has timed out. goodbye!");
                    myStore.dispatch(actions.logout());
                    clearInterval(watcher);
                }
            }
            
        }, 10000);
        return () => {
            clearInterval(watcher);
        }
    }, []);
    
    return <div></div>
}