export const TOKEN = 'token';
export const ZIP_TOKEN = 'zipToken';
export const DEFAULT_LANGUAGE_KEY = 'tr';
export const MAINTENANCE_PAGE = false;

export const KEEP_ALIVE_SESSION_INTERVAL = 60000; // 1 dk
export const OPERATORS = ["TR", "TT", "VD"];

export const TURKEY_COUNTRY_ID = 'F12EDE95F07E23AEE04400306EF4F4B5';
export const TURKEY_COUNTRY_CODE = 'TR';

export const EMPTY_NEW_AGENDA_ITEM = { index: 0, description: '', file: undefined };


export const EYAT_USER = 'EYAT_USER';
export const UNREGISTERED_USER = 'UNREGISTERED_USER';
export const EMAIL = 'EMAIL';
export const MOBILE = 'MOBILE';


export const PRIVILEGE_OPEN = 'OPEN';
export const PRIVILEGE_PARAMETERS = 'PARAMETERS';
export const PRIVILEGE_MEETING = 'NEW_MEETING_OPTION';
export const PRIVILEGE_LIST = 'LIST';
export const PRIVILEGE_VIEW = 'VIEW';
export const PRIVILEGE_EDIT = 'EDIT';
export const PRIVILEGE_DELETE = 'DELETE';
export const PRIVILEGE_ADD = 'ADD';

export const PRIVILEGE_SAVE = 'SAVE';
export const PRIVILEGE_START_SESSION = 'START_SESSION';
export const PRIVILEGE_FINISH_SESSION = 'FINISH_SESSION';
export const PRIVILEGE_POSTPONE = 'POSTPONE_MEETING';
export const PRIVILEGE_START_CONVERSATION = 'START_CONVERSATION';
export const PRIVILEGE_FINISH_CONVERSATION = 'FINISH_CONVERSATION';
export const PRIVILEGE_START_VOTING = 'START_VOTING';
export const PRIVILEGE_FINISH_VOTING = 'FINISH_VOTING';
export const PRIVILEGE_SIGN = 'SIGN';
export const DELETE_FROM_SIGN_LIST = 'DELETE_FROM_SIGN_LIST';
export const PRIVILEGE_UPDATE = 'UPDATE';
export const PRIVILEGE_ADD_LIVE_DOCUMENT = 'ADD_LIVE_DOCUMENT';
export const PRIVILEGE_MANAGE_OTHER_SESSIONS = 'MANAGE_OTHER_SESSIONS';
export const PRIVILEGE_SHARE_DOCUMENT = 'SHARE_DOCUMENT';
export const PRIVILEGE_PREVIEW_DOCUMENT = 'PREVIEW_DOCUMENT';
export const PRIVILEGE_ADD_NEW_AGENDA_ITEM = 'ADD_NEW_AGENDA_ITEM';
export const PRIVILEGE_JOIN_ROOM = 'JOIN_ROOM';
export const PRIVILEGE_LEAVE_ROOM = 'LEAVE_ROOM';
export const LIST_MEETING_MINUTES = 'LIST_MEETING_MINUTES';
export const PRIVILEGE_ADD_NN_MEETING = 'ADD_NOT_NEGOTIATION';

export const MEETING_ROOM = 'Meeting Room';

export const PRIVILEGE_PARAMETER_NAME_ADD = 'PARAMETER_NAME_ADD';
export const PRIVILEGE_PARAMETER_NAME_UPDATE = 'PARAMETER_NAME_UPDATE';
export const PRIVILEGE_PARAMETER_NAME_DELETE = 'PARAMETER_NAME_DELETE';
export const PRIVILEGE_PARAMETER_ITEM_UPDATE = 'PARAMETER_ITEM_UPDATE';
export const PRIVILEGE_PARAMETER_ITEM_DELETE = 'PARAMETER_ITEM_DELETE';
export const PRIVILEGE_PARAMETER_ITEM_SAVE = 'PARAMETER_ITEM_SAVE';
export const PRIVILEGE_PDF_EXPORT = 'PDF_EXPORT';
export const PRIVILEGE_EXCEL_EXPORT = 'EXCEL_EXPORT';
export const PRIVILEGE_AGENDA_DOWNLOAD = 'AGENDA_DOWNLOAD';
export const PRIVILEGE_AGENDA_PREVIEW = 'AGENDA_PREVIEW';
export const PRIVILEGE_MEETING_DOWNLOAD = 'MEETING_DOWNLOAD';
export const PRIVILEGE_MEETING_PREVIEW = 'MEETING_PREVIEW';
export const PRIVILEGE_REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
export const PRIVILEGE_USER_ROLE_SAVE = 'USER_ROLE_SAVE';
export const PRIVILEGE_ROLE_ADD = 'ROLE_ADD';
export const PRIVILEGE_ROLE_DELETE = 'ROLE_DELETE';
export const PRIVILEGE_ROLE_SAVE = 'ROLE_SAVE';
export const PRIVILEGE_ENTRY_RECORD = 'ENTRY_RECORD';
export const PRIVILEGE_MEETING_FILE_DOWNLOAD = 'MEETING_FILE_DOWNLOAD';
export const PRIVILEGE_MEETING_FILE_PREVIEW = 'MEETING_FILE_PREVIEW';
export const PRIVILEGE_AGENDA_FILE_DOWNLOAD = 'AGENDA_FILE_DOWNLOAD';
export const PRIVILEGE_EDIT_DOCUMENT_PRIVILEGE = 'EDIT_DOCUMENT_PRIVILEGE';

export const PRIVILEGE_AGENDA_FILE_PREVIEW = 'AGENDA_FILE_PREVIEW';
export const PRIVILEGE_DOCUMENT_PREVIEW = 'DOCUMENT_PREVIEW';
export const PRIVILEGE_DOCUMENT_DOWNLOAD = 'DOCUMENT_DOWNLOAD';
export const PRIVILEGE_DECISION_DETAILS_OPEN = 'DECISION_DETAILS_OPEN';
export const PRIVILEGE_USER_GUIDE_LIST = 'USER_GUIDE_LIST';
export const PRIVILEGE_EYK14_MEMBER_GUIDE = 'MEMBER_GUIDE';
export const PRIVILEGE_EYK14_SECRETARY_GUIDE = 'SECRETARY_GUIDE';
export const PRIVILEGE_USER_ROLE_MNG_TAB_OPEN = 'USER_ROLE_MNG_TAB_OPEN';

export const PARAMETER_TABLE = 'js-parameter-table';
export const MEETING_TABLE = 'js-meeting-table';
export const FINISHED_MEETING_TABLE = 'js-finishedMeeting-table';
export const ARCHIVED_MEETING_TABLE = 'js-archivedMeeting-table';
export const BOARD_MEMBER_TABLE = 'js-boardMember-table';
export const REPORT_TABLE = 'js-report-table';
export const SECRETARY_TABLE = 'js-secretary-table';
export const COMMITTEE_TABLE = 'js-committee-table';

export const COMMITTEE_MEETING_TABLE = 'js-committeeMeeting-table';
export const FINISHED_COMMITTEE_MEETING_TABLE = 'js-finishedCommitteeMeeting-table';
export const ARCHIVED_COMMITTEE_MEETING_TABLE = 'js-archivedCommitteeMeeting-table';



export const DOC_TYPE_AD = 'AD';
export const DOC_TYPE_DG = 'DG';
export const DOC_TYPE_NK = 'NK';
export const DOC_TYPE_DECISION = 'KR';
export const DOC_TYPE_DECISION_DRAFT = 'KT';
export const DOC_TYPE_MEETING_MINUTE = 'TT';
export const DOC_TYPE_SHAREABLE_DOCUMENT = 'SF';

export const CORPORATE_AGENDA_DOCUMENT_RENDERER_TYPE = 'AD';
export const CORPORATE_AGENDA_ITEMS_RENDERER_TYPE = 'AI';
export const CORPORATE_MEETING_ATTACHMENTS_RENDERER_TYPE = 'MA';

export const MEETING_TYPE_NEGOTIATION = 'N';
export const MEETING_TYPE_NOT_NEGOTIATION = 'NN';
export const BOARD_MEETING = 'BM';
export const COMMITTEE_MEETING = 'CM';
export const BOARD_MEETING_TR = 'YK';
export const MEETING_MINUTE = 'MM';



export const VOTE_ACCEPTANCE = 'A';
export const VOTE_REJECTION = 'R';
export const VOTE_CLEAR = 'C';
export const VOTE_UNSENT = 'U';

export const ELECTRONIC_SIGN = 'E';
export const MOBILE_SIGN = 'M';

export const PHYSICAL_PARTICIPANT = 'P';
export const ELECTRONIC_PARTICIPANT = 'E';

export const COLOR_NEGOTIATION = '#00BCD4';
export const COLOR_NOT_NEGOTIATION = '#FF4081';

export const DECISION_YES = 'Y';
export const DECISION_NO = 'N';

export const ROOM_PREFIX = 'ROOM_';

export const ALLOWED_ATTACHMENT_EXTENSIONS = '.pdf, .docx, .xlsx, .doc, .xls, .pptx ';

export const STAR_ICON = '*';

export const MISSION_YKBV = 'YKBV';

export const ALERT_INFO = 'info';
export const ALERT_ERROR = 'error';
export const ALERT_DANGER = 'danger';
export const ALERT_SUCCESS = 'success';
export const ALERT_WARNING = 'warning';

export const COLOR_FULL_RED = '#ae3b33';
export const COLOR_TRANSPARENT_RED = '#ae3b33b3';
export const COLOR_DARK_GRAY = '#646569';
export const COLOR_LIGHT_GRAY = '#aeafb1';
export const BUTTON_PDF = '#cc3333';
export const BUTTON_EXCEL = '#29ab87';

export const CONTENT_TYPE_ZIP = 'application/zip';
export const CONTENT_TYPE_PDF = 'application/pdf';
export const CONTENT_TYPE_EXCEL = 'application/excel';

export const AGENDA_DOCS_ZIP_NAME = ' - Agenda Documents';
export const MEETING_DOCS_ZIP_NAME = ' - Meeting Documents';
export const AGENDA_AND_MEETING_DOCS_ZIP_NAME = ' - Agenda and Meeting Documents'

export const ZIP_EXTENSION = '.zip';

export const PARTICIPANT_CLASS = 'participant';
export const PARTICIPANT_MAIN_CLASS = 'participant main';
export const PARTICIPANT_MEETING_ROOM = "Meeting Room";
export const PARTICIPANT_MEETING_ROOM_ID = "Meeting_Room";
export const PARTICIPANT_MEETING_ROOM_TR = "Toplantı Odası";

export const AGENDA_TYPE = 'AgendaItem';

export const ENTER_KEY_CODE = 13;

export const LIVE_MEETING_STATE_WAITING = 'MW';
export const LIVE_MEETING_STATE_STARTED = 'MS';
export const LIVE_MEETING_STATE_FINISHED = 'MF';
export const LIVE_MEETING_STATE_POSTPONED = 'MP';
export const LIVE_MEETING_STATE_CANCELED = 'MC';
export const LIVE_MEETING_STATE_NOT_STARTED = 'NS';
export const LIVE_MEETING_STATE_NOT_AVAILABLE = 'NA';

export const AGENDA_ITEM_STATE_CONVERSATION_NOT_STARTED = 'IW';
export const AGENDA_ITEM_STATE_CONVERSATION_STARTED = 'CS';
export const AGENDA_ITEM_STATE_CONVERSATION_FINISHED = 'CF';
export const AGENDA_ITEM_STATE_VOTING_STARTED = 'VS';
export const AGENDA_ITEM_STATE_VOTING_CALCULATING = 'VC';
export const AGENDA_ITEM_STATE_VOTING_FINISHED = 'VF';
export const AGENDA_ITEM_ADDED = 'AA';
export const CHILD_AGENDA_ITEM_ADDED = 'CA';

export const LOGIN_TYPE_MOBILE_SIGN = 'MOBILE_SIGN';
export const LOGIN_TYPE_ELECTRONIC_CARD = 'ELECTRONIC_CARD';
export const LOGIN_TYPE_DEBUG = 'DEBUG';

export const DOC_NAME_MAX_LENGTH_VIEW = 15;

export const ERR_DEVICE_NOT_FOUND = 'DevicesNotFoundError';
export const ERR_REQUESTED_DEVICE_NOT_FOUND = 'Requested device not found';

export const MEETING_STATE_ARCHIVED = 'A';
export const MEETING_STATE_ACTIVE = 'S';

export const COMMITTEE_MEETING_STATE_ARCHIVED = 'CA';
export const COMMITTEE_MEETING_STATE_ACTIVE = 'CS';
export const LIVE_COMMITTEE_MEETING_STATE_FINISHED = 'CMF';


export const ID_TYPE_TCKN = 'TCKN';
export const ID_TYPE_PASSPORT = 'PASSPORT';

export const BOARD_MEMBER_FLAG = 'B';
export const SECRETARY_FLAG = 'S';


export const PRIVILEGE_000 = '000';
export const PRIVILEGE_A00 = 'A00';
export const PRIVILEGE_A01 = 'A01';
export const PRIVILEGE_A02 = 'A02';
export const PRIVILEGE_A03 = 'A03';
export const PRIVILEGE_A04 = 'A04';
export const PRIVILEGE_A05 = 'A05';
export const PRIVILEGE_A06 = 'A06';
export const PRIVILEGE_B00 = 'B00';
export const PRIVILEGE_B01 = 'B01';
export const PRIVILEGE_B02 = 'B02';
export const PRIVILEGE_B03 = 'B03';
export const PRIVILEGE_B04 = 'B04';
export const PRIVILEGE_C00 = 'C00';
export const PRIVILEGE_C01 = 'C01';
export const PRIVILEGE_D00 = 'D00';
export const PRIVILEGE_ED0 = 'ED0';
export const PRIVILEGE_F00 = 'F00';
export const PRIVILEGE_F01 = 'F01';
export const PRIVILEGE_F02 = 'F02';
export const PRIVILEGE_F03 = 'F03';
export const PRIVILEGE_F04 = 'F04';
export const PRIVILEGE_L00 = 'L00';
export const PRIVILEGE_L01 = 'L01';
export const PRIVILEGE_L02 = 'L02';
export const PRIVILEGE_L03 = 'L03';
export const PRIVILEGE_L04 = 'L04';
export const PRIVILEGE_L05 = 'L05';
export const PRIVILEGE_L06 = 'L06';
export const PRIVILEGE_L07 = 'L07';
export const PRIVILEGE_L08 = 'L08';
export const PRIVILEGE_L09 = 'L09';
export const PRIVILEGE_L10 = 'L10';
export const PRIVILEGE_L11 = 'L11';
export const PRIVILEGE_L12 = 'L12';
export const PRIVILEGE_L13 = 'L13';
export const PRIVILEGE_L14 = 'L14';
export const PRIVILEGE_L15 = 'L15';
export const PRIVILEGE_L16 = 'L16';
export const PRIVILEGE_L17 = 'L17';
export const PRIVILEGE_L18 = 'L18';
export const PRIVILEGE_L19 = 'L19';
export const PRIVILEGE_L20 = 'L20';
export const PRIVILEGE_L21 = 'L21';
export const PRIVILEGE_L22 = 'L22';
export const PRIVILEGE_L23 = 'L23';
export const PRIVILEGE_M00 = 'M00';
export const PRIVILEGE_M01 = 'M01';
export const PRIVILEGE_M02 = 'M02';
export const PRIVILEGE_M03 = 'M03';
export const PRIVILEGE_M04 = 'M04';
export const PRIVILEGE_M05 = 'M05';
export const PRIVILEGE_M06 = 'M06';
export const PRIVILEGE_M07 = 'M07';
export const PRIVILEGE_M08 = 'M08';
export const PRIVILEGE_P00 = 'P00';
export const PRIVILEGE_P01 = 'P01';
export const PRIVILEGE_P02 = 'P02';
export const PRIVILEGE_P03 = 'P03';
export const PRIVILEGE_P04 = 'P04';
export const PRIVILEGE_P05 = 'P05';
export const PRIVILEGE_P06 = 'P06';
export const PRIVILEGE_P07 = 'P07';
export const PRIVILEGE_P08 = 'P08';
export const PRIVILEGE_P09 = 'P09';
export const PRIVILEGE_R00 = 'R00';
export const PRIVILEGE_R01 = 'R01';
export const PRIVILEGE_R02 = 'R02';
export const PRIVILEGE_R03 = 'R03';
export const PRIVILEGE_R04 = 'R04';
export const PRIVILEGE_S00 = 'S00';
export const PRIVILEGE_S01 = 'S01';
export const PRIVILEGE_S02 = 'S02';
export const PRIVILEGE_S03 = 'S03';
export const PRIVILEGE_S04 = 'S04';
export const PRIVILEGE_S05 = 'S05';
export const PRIVILEGE_S06 = 'S06';
export const PRIVILEGE_U00 = 'U00';
export const PRIVILEGE_U01 = 'U01';
export const PRIVILEGE_U02 = 'U02';
export const PRIVILEGE_V00 = 'V00';
export const PRIVILEGE_V01 = 'V01';
export const PRIVILEGE_V02 = 'V02';
export const PRIVILEGE_V03 = 'V03';
export const PRIVILEGE_V04 = 'V04';
export const PRIVILEGE_V05 = 'V05';
export const PRIVILEGE_V06 = 'V06';
export const PRIVILEGE_W00 = 'W00';
export const PRIVILEGE_W01 = 'W01';
export const PRIVILEGE_W02 = 'W02';
export const PRIVILEGE_W03 = 'W03';

export const MDS_STATE_U = 'UNSENT';
export const MDS_STATE_S = 'SENT';

export const EYAT_MKS_DOWN_MESSAGE_DATE_INFO_TR = 'EYAT_MKS_DOWN_MESSAGE_DATE_INFO_TR';
export const EYAT_MKS_DOWN_MESSAGE_DATE_INFO_EN = 'EYAT_MKS_DOWN_MESSAGE_DATE_INFO_EN';
export const RELEASE_CONTENT = 'RELEASE_CONTENT';
export const RELEASE_TITLE = 'RELEASE_TITLE';
 export const SHOW_POPUP_AFTER = 'SHOW_POPUP_AFTER';

 
