import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
  SetLanguage: "i18n/SET_LANGUAGE"
};

const initialState = {
  lang: "tr"
};
/**
 *
 * @type {Reducer<any & "redux-persist/es/persistReducer".PersistPartial, Action>}
 */
export const reducer = persistReducer(
  { storage, key: "i18n" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetLanguage:
        return { ...state, lang: action.payload.lang };

      default:
        return state;
    }
  }
);
/**
 *
 * @type {{setLanguage: function(*): {type: string, payload: {lang: *}}}}
 */
export const actions = {
    /**
     *
     * @param lang
     * @return {{type: string, payload: {lang: *}}}
     */
  setLanguage: lang => ({ type: actionTypes.SetLanguage, payload: { lang } })
};
