import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import MaterialButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { alertBox } from '../../../components/BoxProvider';
import * as Regex from "../../../constants/Regexp";
import { post, checkHttpStatus } from '../../../utils/RestUtilsWithAxios';
import { UPDATE_INVESTOR_PASSWORD_FOR_NINETY_DAY_INTERVAL } from '../../../constants/uri';
import * as Globals from '../../../constants/globals';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    formWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    form: {
        width: '100%',
        maxWidth: 450
    },
    showButton: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: 200
        },
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    boldText: {
        fontWeight: 'bold'
    },
}))

/**
 * ChangePassword
 * @param props
 * @return {*}
 * @constructor
 */
function ChangePassword(props) {

    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [logout, setLogout] = useState(false);
    /**
     * charCodeControl
     * @param event
     */
    const charCodeControl = (event) => {
        var charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));
        var valid = (charCode >= 48 && charCode <= 57);
        if (!valid) {
            event.preventDefault();
        }
    };

    if (logout) {
        localStorage.clear();
        return <Redirect to="/" />;
    }
    return (
        <Dialog
            open={props.open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            style={{ textAlign: "left" }}
        >
            <DialogTitle>
                {props.intl.formatMessage({ id: "SECURITY.FORM.PASSWORD_REMINDER_TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Şifrenizin 90 günlük olan kullanım süresi dolmuştur. Şifrenizi güncelledikten sonra sisteme giriş yapabilirsiniz.
                </DialogContentText>
                <Formik
                    enableReinitialize
                    initialValues={{
                        newPassword: password,
                        repeatPassword: repeatPassword
                    }}
                    validate={values => {
                        const errors = {};

                        setPassword(values.newPassword);
                        setRepeatPassword(values.repeatPassword);

                        if (!Regex.PASSWORD.test(values.newPassword)) {
                            errors.newPassword = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.SIX_DIGITS"
                            });
                        }

                        if (!Regex.PASSWORD.test(values.repeatPassword)) {
                            errors.repeatPassword = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.SIX_DIGITS"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        var postData = {};
                        postData['password'] = values.newPassword;
                        postData['verifyPassword'] = values.repeatPassword;
                        postData['currentPassword'] = null;
                        postData['fromExpired'] = true;

                        post(UPDATE_INVESTOR_PASSWORD_FOR_NINETY_DAY_INTERVAL, postData)
                            .then(function (response) {
                                if (response.data.status) {
                                    localStorage.clear();
                                    alertBox(props.intl.formatMessage({ id: "SECURITY.ALERT.SUCCESS_TITLE" }), props.intl.formatMessage({ id: "SECURITY.ALERT.PASSOWRD_CHANGED" }), Globals.ALERT_SUCCESS, props.intl)
                                    setLogout(true);
                                } else
                                    alertBox(props.intl.formatMessage({ id: "SECURITY.ALERT.ERROR_TITLE" }), response.data.exception.message, Globals.ALERT_ERROR, props.intl)
                                setSubmitting(false);
                            })
                            .catch(function (error) {
                                setSubmitting(false);
                                checkHttpStatus(error, props.intl);
                            })
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}

                                <div className="form-group mb-0" style={{ height: 94 }}>
                                    <TextField
                                        id="newPassword"
                                        margin="normal"
                                        label={props.intl.formatMessage({ id: "SECURITY.FORM.NEW_PASSWORD" })}
                                        className="kt-width-full"
                                        name="newPassword"
                                        disabled={false}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.newPassWord}
                                        helperText={touched.newPassword && errors.newPassword}
                                        error={Boolean(touched.newPassword && errors.newPassword)}
                                        onKeyPress={event => { charCodeControl(event); }}
                                        inputProps={{
                                            maxLength: 6, type: "password"
                                        }}
                                        InputProps={{
                                            endAdornment: <a onClick={() => setDialogOpen(true)}><InfoIcon color="primary" size="large" /></a>
                                        }}
                                    />
                                </div>

                                <div className="form-group mb-0" style={{ height: 94 }}>
                                    <TextField
                                        id="repeatPassword"
                                        label={props.intl.formatMessage({ id: "SECURITY.FORM.REPEAT_PASSWORD" })}
                                        margin="normal"
                                        className="kt-width-full"
                                        name="repeatPassword"
                                        disabled={false}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.repeatPassword}
                                        helperText={touched.repeatPassword && errors.repeatPassword}
                                        error={Boolean(touched.repeatPassword && errors.repeatPassword)}
                                        onKeyPress={event => { charCodeControl(event); }}
                                        inputProps={{
                                            maxLength: 6, type: "password"
                                        }}
                                    />
                                </div>
                                <Grid container justify='flex-end'>
                                    <Button variant="secondary" onClick={() => { setLogout(true) }} className={classes.showButton} >
                                        {props.intl.formatMessage({ id: "COMMON.BUTTON_CANCEL" })}
                                    </Button>
                                    <Button variant="primary" type="submit" className={classes.showButton} block>
                                        {props.intl.formatMessage({ id: "SECURITY.BUTTON.NEW_PASSWORD" })}
                                    </Button>
                                </Grid>
                                <Dialog open={dialogOpen}
                                    aria-labelledby="max-width-dialog-title"
                                    maxWidth={"xs"}
                                    fullWidth={true}
                                    style={{ textAlign: "left" }}
                                >
                                    <DialogTitle id="max-width-dialog-title">
                                        {props.intl.formatMessage({ id: "SECURITY.MODAL.PASSWORD_TITLE" })}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <p><span className={classes.boldText}>1. </span>{props.intl.formatMessage({ id: "SECURITY.MODAL.PASSWORD_DESC_1" })}</p>
                                            <p><span className={classes.boldText}>2. </span>{props.intl.formatMessage({ id: "SECURITY.MODAL.PASSWORD_DESC_2" })}</p>
                                            <p><span className={classes.boldText}>3. </span>{props.intl.formatMessage({ id: "SECURITY.MODAL.PASSWORD_DESC_3" })}</p>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ padding: 16 }}>
                                        <MaterialButton onClick={() => setDialogOpen(false)} color="primary">
                                            {props.intl.formatMessage({ id: "COMMON.MODAL_CLOSE" })}
                                        </MaterialButton>
                                    </DialogActions>
                                </Dialog>
                            </form>
                        )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
export default injectIntl(
    connect(
        null,
    )(ChangePassword)
);