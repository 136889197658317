import React from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import * as Globals from "../constants/globals";

const theme = createMuiTheme({
palette: {
    primary:{main:Globals.COLOR_DARK_GRAY, contrastText: "white"},
    secondary:{main:Globals.COLOR_DARK_GRAY,contrastText: "white"}
},
});

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function PdfAndExcelDownloadButtons (props) {
    return (
        <ThemeProvider theme={theme}>
        <Grid container justify="flex-end" style={{marginTop:8}}>
            <Grid item>
                <Button variant='contained' color='primary' size="small" onClick={props.excelOnClick} style={isWidthUp('sm', props.width) ? {width:160} : {}}>
                    <Icon color="default" className='far fa-file-excel' fontSize="small" style={{marginRight:5}} />
                    {isWidthUp('sm', props.width) ? props.intl.formatMessage({id: "COMMON.BUTTON_DOWNLOAD_EXCEL"}) : "XLS"}
                </Button>
            </Grid>
            <Grid item style={{marginLeft:16}}>
                <Button variant='contained' color='secondary' size="small" onClick={props.pdfOnClick} style={isWidthUp('sm', props.width) ? {width:160} : {}}>
                    <Icon color="default" className='far fa-file-pdf' fontSize="small" style={{marginRight:5}} />
                    {isWidthUp('sm', props.width) ? props.intl.formatMessage({id:"COMMON.BUTTON_DOWNLOAD_PDF"}) : "YAZDIR"}
                </Button>
            </Grid>
        </Grid>
        </ThemeProvider>
    )
}
export default injectIntl(
    withWidth()(PdfAndExcelDownloadButtons)

);