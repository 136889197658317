import MockAdapter from "axios-mock-adapter";
import mockAuth from "./mockAuth";

/**
 * Created by gaslan on 3.4.2020
 */
export default function mockAxios(axios) {
  const mock = new MockAdapter(axios);

  mockAuth(mock);

  return mock;
}