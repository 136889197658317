import React from 'react';
import { useState, useEffect } from 'react';
import { connect} from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
import Dropdown from '../../components/Dropdown';
import {get ,post, checkHttpStatus} from '../../utils/RestUtilsWithAxios';
import * as URI from '../../constants/uri';
import * as Globals from '../../constants/globals';
import MyTable from '../../components/MyTable';
import { alertBox } from '../../components/BoxProvider';
import DownloadButtons from '../../components/PdfAndExcelDownloadButtons';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import * as DownloadUtils from '../../utils/downloadUtils';
import {printExpandables,formatDecimal} from '../../utils/ExportUtils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
    },
    showButton: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    textContainer: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30
    },
    unlimitedText: {
        [theme.breakpoints.down('xs')]: {
            fontSize:16
        },
        color: "white",
    },
}));



/**
 * AccountTransactions
 * @param props
 * @return {*}
 * @constructor
 */
function AccountTransactions(props) {
    React.useEffect(()=>{
        get(URI.GET_ACCOUNT_MEMBERS)
        .then(function (response) {
            var setList = [];
            for (var i = 0; i < response.data.length; i++) {
                var newItem = {};
                newItem.value = response.data[i].key;
                newItem.label = response.data[i].text;
                setList.push(newItem);
            }
            setMembers(setList);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    },[]);

    /**
     * getExcelContent
     * @param transactions
     */
    function getExcelContent(transactions) {
        post(URI.TRANSACTIONS_DOWNLOAD_AS_EXCEL, {list:transactions})
        .then(function (response) {
            setExcelContent(response.data);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    }

    /*function getPdfContent(transactions) {
        post(URI.TRANSACTIONS_DOWNLOAD_AS_PDF, {list:transactions})
        .then(function (response) {
            setPdfContent(response.data);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    }*/

    /**
     * downloadAsExcel
     */
    function downloadAsExcel() {
        DownloadUtils.downloadFileByContentExcel(excelContent, "Hesap Hareketleri.xls", Globals.CONTENT_TYPE_EXCEL);
    }

    /**
     * downloadAsPdf
     */
    function downloadAsPdf() {
        DownloadUtils.downloadFileByContent(pdfContent, "Hesap Hareketleri.pdf", Globals.CONTENT_TYPE_PDF);
    }

    const intl = useIntl();

    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    /**
     * beginInit
     * @return {string}
     */
    function beginInit(){
        var date = new Date();
        date.setDate(date.getDate()-90);
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * endInit
     * @return {string}
     */
    function endInit() {
        var date = new Date();
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * memberChanged
     * @param changedValue
     */
    function memberChanged(changedValue) {
        post(URI.GET_ACCOUNTS,{mbrId:changedValue})
        .then(function (response) {
            var setList = [];
            for (var i = 0; i < response.data.length; i++) {
                var newItem = {};
                newItem.value = response.data[i].key;
                newItem.label = response.data[i].text;
                setList.push(newItem);
            }
            setSelectedAccount('');
            setSelectedSubAccount('');
            setSelectedMember(changedValue);
            setSelectedIsin('');
            setAccounts(setList);
            setSubAccounts([]);
            setIsins([]);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    }

    /**
     * accChanged
     * @param changedValue
     */
    function accChanged(changedValue) {
        if(cacheSubAccounts.length === 0){
            get(URI.GET_SUB_ACCOUNTS)
            .then(function (response) {
                var setList = [];
                setList.push({value:'',label:'Seçim Yapınız'});
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setCacheSubAccounts(setList);
                setSubAccounts(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
        }
        else{
            setSubAccounts(cacheSubAccounts);
        }
        setSelectedIsin('');
        setSelectedAccount(changedValue);
        getIsins({memberId:selectedMember,account:changedValue,subAccount:selectedSubAccount});
    }

    /**
     * subAccChanged
     * @param changedValue
     */
    function subAccChanged(changedValue) {
        setSelectedSubAccount(changedValue);
        setSelectedIsin('');
        getIsins({memberId:selectedMember,account:selectedAccount,subAccount:changedValue})
    }

    /**
     * getIsins
     * @param searchBody
     */
    function getIsins(searchBody) {
        post(URI.GET_ISINS,searchBody)
        .then(function (response) {
            var setList = [];
            setList.push({value:'',label:'Seçim Yapınız'});
            for (var i = 0; i < response.data.length; i++) {
                var newItem = {};
                newItem.value = response.data[i].key;
                newItem.label = response.data[i].text;
                setList.push(newItem);
            }
            setIsins(setList);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    }

    /**
     * searchByParameters
     */
    function searchByParameters() {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.
        substring(4,6)+'-'+selectedEndDate.substring(6,8));
        var compareToEnd = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));
        
        if (compareToEnd.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, intl);
            return;
        }
        compareToEnd.setDate(compareToEnd.getDate()+90);

        if(compareToEnd.getTime() < endDate.getTime()){
            alertBox("", 'Seçtiğiniz tarih aralığı 90 günden büyük olamaz', Globals.ALERT_ERROR, intl);
            return;
        }

        if(selectedMember === null || selectedMember === ''){
            alertBox("", 'Üye seçimi yapınız', Globals.ALERT_WARNING, intl);
            return;
        }
        if(selectedAccount === null || selectedAccount === ''){
            alertBox("", 'Hesap seçimi yapınız', Globals.ALERT_WARNING, intl);
            return;
        }
        if(selectedSubAccount===null ||selectedSubAccount===''){
            alertBox("", 'Alt hesap seçimi yapınız', Globals.ALERT_WARNING, intl);
            return;
        }
        enableLoading();
        post(URI.GET_TRANSACTIONS,{beginDate:selectedBeginDate,endDate:selectedEndDate,mbrId:selectedMember,
            account:selectedAccount,subAccount:selectedSubAccount,isin:selectedIsin})
        .then(function (response) {
            setTransactions(response.data);
            getExcelContent(response.data);
            //getPdfContent(response.data);
            if(response.data.length===0){
                setDataFieldMessage("Seçilen tarih aralığında hesap hareketi bulunmamaktadır.");
            }else{
                setDataFieldMessage("");
            }
            disableLoading();
        })
        .catch(function (error) {
            disableLoading();
            checkHttpStatus(error,props.intl);
        })
    }

    /**
     * prepareTransactionItemArray
     * @param trArray
     * @param forPrint
     * @return {Array}
     */
    function prepareTransactionItemArray(trArray,forPrint) {
        var setArray = [];
        for(var i=0;i<trArray.length;i++){
            var valueItem = {};
            valueItem.values = [];
            var timeInfo = trArray[i].hareketSaati.substring(0,2) + ':'+trArray[i].hareketSaati.
            substring(2,4)+':'+trArray[i].hareketSaati.substring(4,6);
            var dateInfo = trArray[i].hareketTarihi + ' ' + timeInfo;
            valueItem.values.push(dateInfo);
            var senderInfo = trArray[i].gonderenKurum + ' - ' + trArray[i].gonderenHesap + ' - ' +
                trArray[i].gonderenAltHesap;
            valueItem.values.push(senderInfo);
            var receiverInfo = trArray[i].alanKurum + ' - ' + trArray[i].alanHesap + ' - ' + trArray[i].alanAltHesap;
            valueItem.values.push(receiverInfo);
            valueItem.values.push(trArray[i].isin);
            valueItem.values.push(trArray[i].talimatAciklamasi);
            if(forPrint){
                valueItem.values.push(formatDecimal(trArray[i].hareketAdedi.toFixed(2)));
            }else {
                valueItem.values.push(trArray[i].hareketAdedi);
            }
            setArray.push(valueItem);
        }
        return setArray;
    }

    /**
     * findSelectedMember
     * @param oid
     * @return {string}
     */
    function findSelectedMember(oid) {
        for(var i=0;i<members.length;i++){
            if(members[i].value === oid)
                return members[i].label;
        }
        return '';
    }

    /**
     * findSelectedAcc
     * @param oid
     * @return {string}
     */
    function findSelectedAcc(oid) {
        for(var i=0;i<accounts.length;i++){
            if(accounts[i].value === oid)
                return accounts[i].label;
        }
        return '';
    }

    /**
     * findSelectedSubAcc
     * @param oid
     * @return {string}
     */
    function findSelectedSubAcc(oid) {
        for(var i=0;i<subAccounts.length;i++){
            if(subAccounts[i].value === oid)
                return subAccounts[i].label;
        }
        return '';
    }

    /**
     * findSelectedIsin
     * @param oid
     * @return {string}
     */
    function findSelectedIsin(oid) {
        for(var i=0;i<isins.length;i++){
            if(isins[i].value === oid)
                return isins[i].label;
        }
        return '';
    }

    /**
     * prepareFilterPanel
     * @return {string}
     */
    function prepareFilterPanel() {
        var filterPanel = '<div>';
        if(selectedBeginDate != null && selectedBeginDate !== '' &&
            selectedEndDate != null && selectedEndDate !== ''){
            filterPanel += '<p><b>Tarih : </b>'+prepareDate(selectedBeginDate) + ' - ' +
                prepareDate(selectedEndDate) + '</p>';
        }
        if(selectedMember != null && selectedMember !== '' &&
            selectedAccount != null && selectedAccount !== ''){
            filterPanel += '<p><b>Hesap : </b>'+findSelectedMember(selectedMember) + ' - ' +
                findSelectedAcc(selectedAccount);
            if(selectedSubAccount != null && selectedSubAccount !== ''){
                filterPanel += ' - '+findSelectedSubAcc(selectedSubAccount);
            }
            if(selectedIsin != null && selectedIsin !== ''){
                filterPanel += ' - '+findSelectedIsin(selectedIsin);
            }
            filterPanel += '</p>';
        }
        filterPanel += '</div>';
        return filterPanel;
    }

    /**
     * prepareDate
     * @param dateStr
     * @return {string}
     */
    function prepareDate(dateStr) {
        return dateStr.substring(6,8)+'.'+dateStr.substring(4,6)+'.'+dateStr.substring(0,4);
    }

    /**
     * prepareHeaderList
     * @return {Array}
     */
    function prepareHeaderList() {
        var list = [];
        for(var i=0;i<transactions.length;i++){
            list.push(transactions[i].explanation + ' Ek Tanımlı');
        }
        return list;
    }

    /**
     * prepareValueList
     * @return {Array}
     */
    function prepareValueList() {
        var list = [];
        for(var i=0;i<transactions.length;i++){
            var addToList = prepareTransactionItemArray(transactions[i].detailList,true);
            list.push(addToList);
        }
        return list;
    }

    /**
     * printThisPage
     */
    function printThisPage() {
        printExpandables(prepareValueList(),prepareHeaderList(),headRows,'HESAP HAREKETLERİ RAPORU',true,
            prepareFilterPanel);
    }

    const [selectedBeginDate,setSelectedBeginDate] = useState(beginInit());
    const [selectedEndDate,setSelectedEndDate] = useState(endInit());
    const [selectedMember,setSelectedMember] = useState('');
    const [members,setMembers] = useState([]);
    const [accounts,setAccounts] = useState([]);
    const [subAccounts,setSubAccounts] = useState([]);
    const [cacheSubAccounts,setCacheSubAccounts] = useState([]);
    const [selectedAccount,setSelectedAccount] = useState('');
    const [selectedSubAccount,setSelectedSubAccount] = useState('');
    const [isins,setIsins] = useState([]);
    const [selectedIsin,setSelectedIsin] = useState('');
    const [transactions,setTransactions] = useState([]);
    const [dataFieldMessage, setDataFieldMessage] = useState('');
    const [excelContent, setExcelContent] = useState([]);
    const [pdfContent, setPdfContent] = useState([]);
    const [showExportButton, setShowExportButton] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * enableLoading - setLoading(true)
     */
    const enableLoading = () => {
        setLoading(true);
    };

    /**
     * disableLoading - setLoading(false)
     */
    const disableLoading = () => {
        setLoading(false);
    };

    const headRows = [
        { id: 'trTime', numeric: false, disablePadding: false, label: 'İşlem Zamanı', style:{minWidth:190} },
        { id: 'senderAcc', numeric: false, disablePadding: false, label: 'Gönderen',
            secondLabel: 'Üye / Hesap / Alt Hesap' },
        { id: 'receiverAcc', numeric: false, disablePadding: false, label: 'Alan',
            secondLabel: 'Üye / Hesap / Alt Hesap' },
        { id: 'isinCode', numeric: false, disablePadding: false, label: 'Kıymet Kodu' },
        { id: 'trExp', numeric: false, disablePadding: false, label: 'Hareket Açıklaması' },
        { id: 'trCount', numeric: true, disablePadding: false, label: 'Adet', decimal:2, fixedDecimal:2 }
    ];
    const alignment = ['left', 'left', 'left', 'left', 'left', 'right'];

    const classes = useStyles();

    useEffect(() => {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.
        substring(4,6)+'-'+selectedEndDate.substring(6,8));
        var beginDate = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));
        
        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, intl);
            return;
        }
      });

    return(

        <div>
            <div className={classes.root}>

                <Paper className={classes.paper}>
                    <Typography variant="h5">Tarih Aralığı</Typography>
                    <Grid container direction="row" spacing={3} style={{marginBottom:16}}>
                        <Grid item xs={6}>
                            <MaterialUIDatePicker containerId="mui-pickers-date" label="Başlangıç Tarihi"
                                                  setContainerSelectedDate={setSelectedBeginDate}
                                                  dateString={selectedBeginDate}/>
                        </Grid>
                        <Grid item xs={6}>
                            <MaterialUIDatePicker containerId="mui-pickers-date2" label="Bitiş Tarihi"
                                                  setContainerSelectedDate={setSelectedEndDate}
                                                  dateString={selectedEndDate}/>
                        </Grid>
                    </Grid>
                    <Typography variant="h5">Hesap Bilgileri</Typography>
                    <Grid container direction="row" alignItems="flex-end" spacing={3} style={{marginBottom:16}}>
                        <Grid item xs={12} sm={6}>
                            <Dropdown name="members" id="members" required={true}
                                      title={intl.formatMessage({ id: "TRANSACTIONS.SELECT.MEMBER" })}
                                      items={members} handleContainerChange={memberChanged}
                                      containerSelectedItem={selectedMember}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Dropdown name="accounts" id="accounts" required={true}
                                      title={intl.formatMessage({ id: "TRANSACTIONS.SELECT.ACCOUNT" })}
                                      items={accounts} handleContainerChange={accChanged}
                                      containerSelectedItem={selectedAccount}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Dropdown name="subAccounts" id="subAccounts" required={true}
                                      title={intl.formatMessage({ id: "TRANSACTIONS.SELECT.SUB_ACCOUNT" })}
                                      items={subAccounts} handleContainerChange={subAccChanged}
                                      containerSelectedItem={selectedSubAccount}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Dropdown name="isins" id="isins"
                                      title={intl.formatMessage({ id: "TRANSACTIONS.SELECT.SECURITY" })}
                                      items={isins} handleContainerChange={setSelectedIsin}
                                      containerSelectedItem={selectedIsin}/>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end">
                            <Button variant="contained" color='primary' size='large'
                                    fullWidth onClick={searchByParameters}
                            className={[classes.showButton, `btn ${clsx(
                                {
                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                }
                            )}`]}
                            >
                                {props.intl.formatMessage({id: "COMMON.BUTTON_SHOW"})}
                            </Button>
                    </Grid>
                    {
                        transactions.length > 0 ?
                        transactions.map((tr,i)=>
                            <MyTable    expandable = {true}
                                        intl={intl}
                                        key={i}
                                        tableRows={prepareTransactionItemArray(tr.detailList,false)}
                                        headRows={headRows}
                                        alignment={alignment}
                                        defaultSortIndex={-1}
                                        defaultSort='asc'
                                        dateTimeColumns={[0]}
                                        numericColumns={[5]}
                                        tableTitle={tr.explanation + ' Ek Tanımlı'}/>)
                    :
                    <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                        {dataFieldMessage}
                    </Typography>
                    }
                    {
                        transactions.length > 0 ?
                        <DownloadButtons pdfOnClick={() => printThisPage()} excelOnClick={() => downloadAsExcel()} />
                        :
                        null
                    }  
                </Paper>
                <Paper className={classes.infoMsg}>
                    <Grid container style={{backgroundColor: "white"}}>
                        <Typography display="inline" variant="subtitle1">
                            {props.intl.formatMessage({id:"ACCOUNT_TRANSCATIONS.MESSAGE.DETAILED_INFO"})}
                        </Typography>
                    </Grid>
                </Paper>
            </div>

        </div>

    )
}
export default injectIntl(
    connect(
      null,
      //portfolio.actions
    )(AccountTransactions)
  );