import React, { useState } from "react";
import { connect} from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';
import ChangePassword from './partials/ChangePassword';
import ChangeWelcomeMessage from './partials/ChangeWelcomeMessage';
import Grid from '@material-ui/core/Grid';
import * as Globals from '../../constants/globals';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    container: {
        padding: theme.spacing(8, 0)
    },
    navButton: {
        maxWidth:'100%',
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Globals.COLOR_TRANSPARENT_RED,
        borderRadius: 4,   
    },
    activeNavButton: {
        maxWidth:'100%',
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Globals.COLOR_FULL_RED,
        borderRadius: 4,  
    },
    nav: {
        backgroundColor: "white",
        marginBottom:16,
        justifyContent: "space-around",
        height: "unset",
        padding: 5,
        borderRadius: 4,
        paddingLeft: 0,
        paddingRight: 0
    },
    navText: {
        color: "white",
        fontSize: 14
    },
}))

/**
 * SecuritySettings
 * @param props
 * @return {*}
 * @constructor
 */
function SecuritySettings(props){
    
    const classes = useStyles();
    const [pageValue, setPageValue] = useState(0);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <BottomNavigation
                    value={pageValue}
                    onChange={(event, newValue) => {
                        setPageValue(newValue);
                    }}
                    showLabels
                    classes={{root: classes.nav}}
                    >
                    <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                    formatMessage({id:"SECURITY.FORM.PASSWORD_TITLE"})}</span>}
                                            classes={{root: pageValue === 0 ?
                                                    classes.activeNavButton :
                                                    classes.navButton}}  />
                    <BottomNavigationAction label={<span className={classes.navText}>{props.intl.
                    formatMessage({id:"SECURITY.FORM.WELCOME_MESSAGE_TITLE"})}</span>}
                                            classes={{root: pageValue === 1 ?
                                                    classes.activeNavButton :
                                                    classes.navButton}} />
                </BottomNavigation>
                
                <Grid container justify='center' alignItems='center' classes={{root:classes.container}}>
                    {
                        pageValue === 0 ?
                        <ChangePassword />
                        :
                        <ChangeWelcomeMessage />
                    }
                </Grid>

            </Paper>
        </div>
    )
}

/**
 * SecuritySettings
 */
export default injectIntl(
    connect(
      null,
    )(SecuritySettings)
);