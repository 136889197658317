import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import {fetchDownload} from '../../utils/RestUtils';
import {SAVE_KAP_DECLARATION, GET_KAP_ATTACHMENT} from "../../constants/uri";
import * as Globals from "../../constants/globals";
import { Formik } from "formik";
import Paper from "@material-ui/core/es/Paper/Paper";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/PostAddOutlined';
import {alertBox} from "../../components/BoxProvider";
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
import Dropdown from '../../components/Dropdown';
import Button from "@material-ui/core/Button";
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import NumberFormat from 'react-number-format';

var bigdecimal = require("bigdecimal");

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    overflowX: {
        overflowX: 'auto',
    },
    tableWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    titleGutter: {
        marginBottom: theme.spacing(1)
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
    flexButton: {
        [theme.breakpoints.up('xs')]: {
            width: 200
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
    },
    glowOnHover:{
        '&:hover': { color: Globals.COLOR_FULL_RED},
    },
    fontBold: {
        fontWeight: 600
    },
    fontLight: {
        fontWeight: 'lighter'
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        position: 'absolute',
        marginLeft: 11,
        marginTop: -6,
        fontSize: 12,
        fontWeight: 'bold'
    },
    thinBox: {
        padding: 16,
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'solid'
    },
    typography: {
        fontSize: 12,
    },
}));

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 12,
        fontWeight: 600
    },
}));

function EnhancedTableHead(props) {
    const headClasses = headStyles();

    const headRows = [
        { id: 'col1', numeric: false, disablePadding: false, label: 'İşlemin Tarihi' },
        { id: 'col2', numeric: true, disablePadding: false, label: 'Alım İşlemine Konu Payların Toplam Nominal Tutarı (TL)' },
        { id: 'col3', numeric: true, disablePadding: false, label: 'Satım İşlemine Konu Payların Toplam Nominal Tutarı (TL)' },
        { id: 'col4', numeric: true, disablePadding: false, label: 'İşlemlerin Net Nominal Tutarı * (TL)' },
        { id: 'col5', numeric: true, disablePadding: false, label: 'Sahip Olunan Payların Gün Başı Nominal Tutarı (TL)' },
        { id: 'col6', numeric: true, disablePadding: false, label: 'Sahip Olunan Payların Gün Sonu Nominal Tutarı (TL)' },
        { id: 'col7', numeric: true, disablePadding: false, label: 'Sahip Olunan Payların Gün Başı Bakiyesinin Sermayeye Oranı (%)' },
        { id: 'col8', numeric: true, disablePadding: false, label: 'Sahip Olunan Oy Haklarının Gün Başı Bakiyesinin Oy Haklarına Oranı (%)' },
        { id: 'col9', numeric: true, disablePadding: false, label: 'Sahip Olunan Payların Gün Sonu Bakiyesinin Sermayeye Oranı (%)' },
        { id: 'col10', numeric: true, disablePadding: false, label: 'Sahip Olunan Oy Haklarının Gün Sonu Bakiyesinin Oy Haklarına Oranı (%)' },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        className={headClasses.headFont}
                    >
                        {
                            <div>
                                {row.label}
                            </div>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        onValueChange={(values) => {
            onChange({
                target: {
                  name: props.name,
                  value: (values.value === "." || values.value === ",") ? '0,' : values.value,
                },
            });
          }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={7}
        isNumericString
        allowNegative={false}
      />
    );
}

function NumberFormatNegative(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        onValueChange={(values) => {
            onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
            });
          }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={7}
        isNumericString
      />
    );
}

function PaySenetleri(props) {

    React.useEffect(()=>{
        setNameOrTitle(localStorage.getItem("NameSurname"));
    },[]);

    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    function beginInit(){
        var date = new Date();
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+(formatMonthOrDay(date.getDate())).toString();
    }

    function islemYapanList() {
        var investorType = localStorage.getItem('InvestorType');
        var arr = [];
        var trf = {value:'T',label:'Tarafımca'};
        var ort = {value:'O',label:'Ortaklığımızca'};
        if (investorType === 'R')
            arr.push(trf);
        arr.push(ort);
        return arr;
    }

    function corrList() {
        var arr= [];
        var yes = {value:'Y',label:'EVET'};
        var no = {value:'N',label:'HAYIR'};
        arr.push(yes);
        arr.push(no);
        return arr;
    }

    function openModal1(forAdd,i) {
        if(forAdd){
            setC1(null);
            tableValues.column2 = ''
            tableValues.column3 = ''
            tableValues.column5 = ''
            tableValues.column6 = ''
            tableValues.column7 = ''
            tableValues.column8 = ''
            tableValues.column9 = ''
            tableValues.column10 = ''
        }else{
            setC1(tableItems[i].islemTarihi);
            tableValues.column2 = tableItems[i].alisNominalTutar
            tableValues.column3 = tableItems[i].satisNominalTutar
            tableValues.column5 = tableItems[i].gunBasiNominalTutar
            tableValues.column6 = tableItems[i].gunSonuNominalTutar
            tableValues.column7 = tableItems[i].gunBasiBakiyesininSermayeyeOrani
            tableValues.column8 = tableItems[i].gunBasiBakiyesininOyHaklarinaOrani
            tableValues.column9 = tableItems[i].gunSonuBakiyesininSermayeyeOrani
            tableValues.column10 = tableItems[i].gunSonuBakiyesininOyHaklarinaOrani
        }
        setOpenTableForAdd(forAdd);
        setSelectedRow(i);
        setOpenForTable(true);
    }

    function openModal2() {
        setOpenForPreview(true);
        var time;
        setInterval(function(){
            time = new Date();
            setCurrentTime(time.getDate().toString().padStart(2, '0') + '/' + (time.getMonth()+1).toString().padStart(2, '0') + '/' + time.getFullYear().toString() + ' ' + time.getHours().toString().padStart(2,'0') + ':' + time.getMinutes().toString().padStart(2,'0') + ':' +  time.getSeconds().toString().padStart(2,'0') );
        }, 1000)
    }

    function handleClose1(isOk,forAdd,i) {
        if(isOk){
            var newItem = { islemTarihi:c1,
                            alisNominalTutar:parseFloat(tableValues.column2),
                            satisNominalTutar:parseFloat(tableValues.column3),
                            netNominalTutar:parseFloat(calculateColumn4()),
                            gunBasiNominalTutar:parseFloat(tableValues.column5),
                            gunSonuNominalTutar:parseFloat(tableValues.column6),
                            gunBasiBakiyesininSermayeyeOrani:parseFloat(tableValues.column7),
                            gunBasiBakiyesininOyHaklarinaOrani:parseFloat(tableValues.column8),
                            gunSonuBakiyesininSermayeyeOrani:parseFloat(tableValues.column9),
                            gunSonuBakiyesininOyHaklarinaOrani:parseFloat(tableValues.column10)
                        };
            if(forAdd){
                tableItems.push(newItem);
            }else{
                tableItems[i] = newItem;
            }
        }
        closeForTable();
    }

    function removeItem(i) {
        //splice metodu kullaninca ekrana yansimiyor
        var newTable = [];
        for(var j=0;j<tableItems.length;j++){
            if(i!==j)
                newTable.push(tableItems[j]);
        }
        setTableItems(newTable);
    }

    function closeForTable() {
        setOpenForTable(false);
    }

    function handleClose2(isOk) {
        if(isOk){
            if(checked)
                sendDisclosure();
            else
                alertBox("", props.intl.formatMessage({id:"KAP_DISCLOSURE.ALERTBOX.FILL_CHECKBOX_MESSAGE"}), Globals.ALERT_WARNING, props.intl);
        }else {
            closeForPreview();
        }
    }

    function handleClose3() {
        downloadFile(successDeclarationId,'Bildirim_'+successDeclarationId+'.pdf');
        closeForDocument();
    }

    function closeForPreview() {
        setOpenForPreview(false);
    }

    function closeForDocument() {
        setOpenForDocument(false);
    }

    function formatDate(date) {
        var formattedDate = date.substring(6) + "/" + date.substring(4,6) + "/" + date.substring(0,4);
        return formattedDate; 
    }

    function formatDecimal(num) {
        if(num == null || num === 'NaN')
            return '';
        var formattedNum = ''
        if(num.includes('.'))
            formattedNum = num.split('.')[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',' + num.split('.')[1]
        else
            formattedNum = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        return formattedNum
    }

    function formatTableItems(table) {
        var newTable= []
        if(table && table.length > 0){
            for(var i=0; i<table.length; i++){
                var newItem = { islemTarihi:formatDate(table[i].islemTarihi),
                    alisNominalTutar:formatDecimal(table[i].alisNominalTutar.toString()),
                    satisNominalTutar:formatDecimal(table[i].satisNominalTutar.toString()),
                    netNominalTutar:formatDecimal(table[i].netNominalTutar.toString()),
                    gunBasiNominalTutar:formatDecimal(table[i].gunBasiNominalTutar.toString()),
                    gunSonuNominalTutar:formatDecimal(table[i].gunSonuNominalTutar.toString()),
                    gunBasiBakiyesininSermayeyeOrani:formatDecimal(table[i].gunBasiBakiyesininSermayeyeOrani.toString()),
                    gunBasiBakiyesininOyHaklarinaOrani:formatDecimal(table[i].gunBasiBakiyesininOyHaklarinaOrani.toString()),
                    gunSonuBakiyesininSermayeyeOrani:formatDecimal(table[i].gunSonuBakiyesininSermayeyeOrani.toString()),
                    gunSonuBakiyesininOyHaklarinaOrani:formatDecimal(table[i].gunSonuBakiyesininOyHaklarinaOrani.toString())
                };
                newTable.push(newItem)
            }
        }
        return newTable;
    }

    function downloadFile(fileOid, fileName) {
        fetchDownload(GET_KAP_ATTACHMENT+fileOid,fileName);

    }

    const clearForm = () => {
        setDate1(beginInit());
        setDate2(beginInit());
        setDate3(beginInit());
        values.eyatUserId = props.myselfId;
        values.subject = '';
        values.company = '';
        values.minPrice = '';
        values.maxPrice = '';
        values.nominalBought = '';
        values.nominalSold = '';
        values.islemYapan = '';
        values.ratio = '';
        values.correction = correctionList[1].value;
        values.tuzelName = '';
        values.gorev = '';
        values.otherNames = '';
        values.declaration = '.......... tarihinde ........... payları ile ilgili olarak ....... TL fiyat aralığından ........... TL toplam nominal tutarlı alış ve/veya ........... TL toplam nominal tutarlı satış işlemi tarafımca/ortaklığımızca gerçekleştirilmiştir. Bu işlemle birlikte ....... sermayesindeki paylarım/oy haklarım ..... tarihi itibariyle %....... sınırına ulaşmıştır/ aşmıştır/altına düşmüştür."\n""\n"........’nin sahip olduğu %.... oranındaki paylar/oy hakları da benimle/ortaklığımızla birlikte hareket etmektedir (Paylar/oy hakları bir sermaye piyasası aracından kaynaklanıyorsa belirtilecektir)."\n""\n"Yönetim kontrolüne sahip olduğum ........ ’nin sahip olduğu %.... oranındaki paylar/oy hakları da benim kontrolüm / ortaklığımızın kontrolü altındadır (Paylar/oy hakları bir sermaye piyasası aracından kaynaklanıyorsa belirtilecektir).'
        setTableItems([]);
        
        document.getElementById('subject').focus();
        document.getElementById('subject').blur();

        setOpenForClear(false);
        alertBox("", props.intl.formatMessage({id:"KAP_DISCLOSURE.ALERTBOX.FORM_CLEARED"}), Globals.ALERT_SUCCESS, props.intl);
    }

    function sendDisclosure() {
        enableLoading();
        var postData={
            templateNo:1,
            startDate:formatDate(date1),
            endDate:formatDate(date2),
            isTemplate:isTemplate === 'YES',
            member:companyTitle,
            memberId:values.company,
            startPrice:formatDecimal(values.minPrice),
            endPrice:formatDecimal(values.maxPrice),
            buyCount:formatDecimal(values.nominalBought),
            sellCount:formatDecimal(values.nominalSold),
            stockRightChangeDate:formatDate(date3),
            stockPercent:formatDecimal(values.ratio),
            isCorrection:values.correction,
            corporateRep:values.tuzelName,
            otherActors:values.otherNames,
            position:values.gorev,
            declerationText: isTemplate === 'YES' ? getDeclerationText(values) : values.declaration,
            subject:values.subject,
            principalEyatUserId:values.eyatUserId,
            isAttorney: values.eyatUserId !== props.myselfId,
            tableItems01: formatTableItems(tableItems)
        };
        post(SAVE_KAP_DECLARATION,postData)
            .then(function (response) {
                setSuccessDeclarationId(response.data);
                closeForPreview();
                setOpenForDocument(true);
                disableLoading();
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl,true);
                disableLoading();
            })
        setChecked(false);
    }

    const classes = useStyles();
    const theme = useTheme();
    const [currentTime, setCurrentTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [date1,setDate1] = useState(beginInit());
    const [date2,setDate2] = useState(beginInit());
    const [date3,setDate3] = useState(beginInit());
    const [companyTitle,setCompanyTitle] = useState('');
    const islemList = islemYapanList();
    const correctionList = corrList();
    const [nameOrTitle,setNameOrTitle] = useState('');
    const [tableItems,setTableItems] = useState([]);
    const [successDeclarationId,setSuccessDeclarationId] = useState('');
    const [isTemplate,setIsTemplate] = useState("YES");
    const [openForTable,setOpenForTable] = useState(false);
    const [openTableForAdd,setOpenTableForAdd] = useState(true);
    const [openForPreview,setOpenForPreview] = useState(false);
    const [openForDocument,setOpenForDocument] = useState(false);
    const [openForClear,setOpenForClear] = useState(false);
    const [c1,setC1] = useState(null);
    const [selectedRow,setSelectedRow] = useState(-1);
    const [checked, setChecked] = useState(false);
    const charLimitWarning1 = "Bu alana maksimum 300 karakter girişi yapılmalıdır. Daha fazla karakter içeren bir metin girişi veya kopyalama yapıldığında MKK'ya gönderilen pdf dosya sadece 300 karakterlik bölümü içerecek şekilde oluşturulur. Bildirimin eksik bilgi içermemesi açısından bu hususun dikkate alınması gerekmektedir.";
    const charLimitWarning2 = "Bu alana maksimum 2500 karakter girişi yapılmalıdır. Daha fazla karakter içeren bir metin girişi veya kopyalama yapıldığında MKK'ya gönderilen pdf dosya sadece 2500 karakterlik bölümü içerecek şekilde oluşturulur. Bildirimin eksik bilgi içermemesi açısından bu hususun dikkate alınması gerekmektedir.";
    const investorType = localStorage.getItem('InvestorType');

    const [values, setValues] = useState({
        eyatUserId: props.myselfId,
        subject: '',
        company: '',
        minPrice: '',
        maxPrice: '',
        nominalBought: '',
        nominalSold: '',
        islemYapan: '',
        ratio: '',
        correction: correctionList[1].value,
        nameAndSurname: nameOrTitle,
        tuzelName: '',
        gorev: '',
        otherNames: '',
        declaration: '.......... tarihinde ........... payları ile ilgili olarak ....... TL fiyat aralığından ........... TL toplam nominal tutarlı alış ve/veya ........... TL toplam nominal tutarlı satış işlemi tarafımca/ortaklığımızca gerçekleştirilmiştir. Bu işlemle birlikte ....... sermayesindeki paylarım/oy haklarım ..... tarihi itibariyle %....... sınırına ulaşmıştır/ aşmıştır/altına düşmüştür.\n\n........’nin sahip olduğu %.... oranındaki paylar/oy hakları da benimle/ortaklığımızla birlikte hareket etmektedir (Paylar/oy hakları bir sermaye piyasası aracından kaynaklanıyorsa belirtilecektir).\n\nYönetim kontrolüne sahip olduğum ........ ’nin sahip olduğu %.... oranındaki paylar/oy hakları da benim kontrolüm / ortaklığımızın kontrolü altındadır (Paylar/oy hakları bir sermaye piyasası aracından kaynaklanıyorsa belirtilecektir).',
    })
    const [tableValues, setTableValues] = useState({
        column2: '',
        column3: '',
        column5: '',
        column6: '',
        column7: '',
        column8: '',
        column9: '',
        column10: '',
    })

    React.useEffect(()=>{
        values.eyatUserId = props.myselfId;
    },[props.myselfId]);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    function handleRadioChange(event) {
        setIsTemplate(event.target.value);
    }

    function handleCheckboxChange() {
        setChecked(!checked);
    }

    const formikRef = (node) => {
        if (node !== null) {
            setValues(node.values);
        }
    }

    const formikRef2 = (node) => {
        if (node !== null) {
            setTableValues(node.values);
        }
    }

    function showGrid(show) {
        if(show)
            return {paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}
        else
            return {display:"none"}
    }

    function calculateColumn4() {

        if(tableValues.column2 !== "" && tableValues.column2 !== "0," && tableValues.column3 !== "" && tableValues.column3 !== "0,"){
            var bigdecimal1 = new bigdecimal.BigDecimal(tableValues.column2.toString())
            var bigdecimal2 = new bigdecimal.BigDecimal(tableValues.column3.toString())

            return (bigdecimal1.subtract(bigdecimal2)).toString()
        }
        else
            return ""
    }

    const getDeclerationText = (values) => {
        return ReactDOMServer.renderToString(<div><b>{formatDate(date1)} - {formatDate(date2)}</b> tarihinde <b>{companyTitle}</b> payları ile
            ilgili olarak <b>{<NumberFormat value={values.minPrice} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />} </b> - <b> {<NumberFormat value={values.maxPrice} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />} </b> TL fiyat
            aralığından <b>{<NumberFormat value={!!values.nominalBought ? values.nominalBought : 0} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />}</b> TL toplam nominal tutarlı alış işlemi
            ve/veya <b>{<NumberFormat value={!!values.nominalSold ? values.nominalSold : 0} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />}</b> TL toplam nominal tutarlı satış işlemi tarafımca/ortaklığımızca gerçekleştirilmiştir.
            Bu işlemle birlikte <b>{companyTitle}</b> sermayesindeki paylarım/oy haklarım <b>{formatDate(date3)}</b> tarihi itibariyle <b>%{<NumberFormat value={values.ratio} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />}</b> sınırına ulaşmıştır/aşmıştır/altına düşmüştür.
            İşlemin detaylarına ilişkin bilgiler aşağıdaki tabloda yer almaktadır.</div>);
        }

    React.useEffect(()=>{
        if (values.islemYapan === "T")
            values.tuzelName = null;
    },[values.islemYapan]);

    return(
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef}
                    initialValues={values}
                    validate={values => {
                        const errors = {};

                        if(!values.eyatUserId){
                            setNameOrTitle('');
                        }else {
                            if (values.eyatUserId === props.myselfId) {
                                setNameOrTitle(localStorage.getItem("NameSurname"));
                            } else {
                                for (var i = 0; i < props.attorneys.length; i++) {
                                    if (props.attorneys[i].value === values.eyatUserId) {
                                        var strArr = props.attorneys[i].label.split(" adına ");
                                        setNameOrTitle(strArr[0]);
                                        break;
                                    }
                                }
                            }
                        }
                        for(var i=0;i<props.companies.length;i++){
                            if(props.companies[i].value===values.company){
                                setCompanyTitle(props.companies[i].label);
                                break;
                            }
                        }
                        var errorControl = false;
                        if (!values.eyatUserId) {
                            errorControl = true;
                            errors.eyatUserId = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.company) {
                            errorControl = true;
                            errors.company = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (isTemplate === 'YES' && !values.minPrice) {
                            errorControl = true;
                            errors.minPrice = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (isTemplate === 'YES' && !values.maxPrice) {
                            errorControl = true;
                            errors.maxPrice = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (isTemplate === 'YES' && parseFloat(values.maxPrice) < parseFloat(values.minPrice)) {
                            errorControl = true;
                            errors.maxPrice = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.MORE_OR_EQUAL"});
                            errors.minPrice = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.LESS_OR_EQUAL"});
                        }
                        if (isTemplate === 'YES' && !values.nominalBought && !values.nominalSold) {
                            errorControl = true;
                            errors.nominalBought = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.FILL_AT_LEAST_ONE"});
                            errors.nominalSold = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.FILL_AT_LEAST_ONE"});
                        }
                        if (isTemplate === 'YES' && !values.islemYapan) {
                            errorControl = true;
                            errors.islemYapan = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (isTemplate === 'YES' && !values.ratio) {
                            errorControl = true;
                            errors.ratio = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (isTemplate === 'YES' && parseFloat(values.ratio) < 0 || parseFloat(values.ratio) > 100 ) {
                            errorControl = true;
                            errors.ratio = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.RATIO_INTERVAL"});
                        }
                        if (!values.correction) {
                            errorControl = true;
                            errors.correction = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.gorev && investorType !== 'R') {
                            errorControl = true;
                            errors.gorev = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.tuzelName && investorType !== 'R') {
                            errorControl = true;
                            errors.tuzelName = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        
                        if (errorControl)
                        alertBox("", "Hatalı / eksik bilgi girişi yaptınız. Lütfen girdiğiniz verileri kontrol ediniz!", Globals.ALERT_ERROR, props.intl);

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        if(isTemplate === 'YES' && tableItems.length === 0){
                            alertBox("", "Lütfen tablodaki alanları doldurunuz.", Globals.ALERT_WARNING, props.intl);
                        }
                        else if(!(date1 > date2 || date3 > date2))
                            openModal2()
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <RadioGroup
                                        row
                                        name="isTemplate"
                                        value={isTemplate}
                                        onChange={handleRadioChange}
                                        >
                                            <FormControlLabel control={<Radio color="secondary" />} label="Şablon Kullanmak İstiyorum" value="YES" />
                                            <FormControlLabel control={<Radio  color="secondary" />} label="Kendim Doldurmak İstiyorum" value="NO" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} container direction="column" justify="flex-end" style={showGrid(true)}>
                                    <Grid item style={{height:70}}>
                                        <Dropdown   required
                                                    noMargin
                                                    name="eyatUserId"
                                                    id="eyatUserId"
                                                    title='Bildirim Yapan'
                                                    items={props.attorneys}
                                                    onChange={handleChange}
                                                    helperText={errors.eyatUserId}
                                                    error={Boolean(errors.eyatUserId)}
                                                    containerSelectedItem={values.eyatUserId}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container direction="column" justify="flex-end" style={showGrid(true)}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6">Konu</Typography>
                                    <Grid item style={{height:70}}>
                                        <TextField
                                            fullWidth
                                            id="subject"
                                            name="subject"
                                            label="Konu"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.subject}
                                            helperText={touched.subject && errors.subject}
                                            error={Boolean(touched.subject && errors.subject)}
                                            InputLabelProps={{
                                                shrink: values.subject
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(isTemplate === "YES")}>
                                    <Typography classes={{h6:classes.titleGutter}}  variant="h6">İşlem Tarihi Aralığı</Typography>
                                    <Grid container spacing={2} style={{height:70, marginBottom:8}}>
                                        <Grid item xs={6}>
                                            <MaterialUIDatePicker   noMargin 
                                                                    containerId="ps-date1"
                                                                    disableAfter={date2}
                                                                    maxDateMessage="İşlem Bitiş Tarihinden İleri Tarih Seçilemez"
                                                                    setContainerSelectedDate={setDate1}
                                                                    dateString={date1}
                                                                    label={"Başlangıç Tarihi"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MaterialUIDatePicker   noMargin 
                                                                    containerId="ps-date2"
                                                                    disableFuture
                                                                    setContainerSelectedDate={setDate2}
                                                                    dateString={date2}
                                                                    label={"Bitiş Tarihi"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(true)}>
                                    <Typography classes={{h6:classes.titleGutter}}  variant="h6">Bildirime Konu Borsa Şirketi</Typography>
                                    <Grid item style={{height:70}}>
                                        <Dropdown   name="company"
                                                    required
                                                    noMargin
                                                    id="company"
                                                    title='Şirket'
                                                    items={props.companies}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.company)}
                                                    helperText={errors.company}
                                                    containerSelectedItem={values.company}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(isTemplate === "YES")}>
                                    <Typography classes={{h6:classes.titleGutter}}  variant="h6">Fiyat Aralığı (TL)</Typography>
                                    <Grid container spacing={2} style={{height:70, marginBottom:8}}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="minPrice"
                                                name="minPrice"
                                                label="Minimum *"
                                                variant="outlined"
                                                value={values.minPrice}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                helperText={touched.minPrice && errors.minPrice}
                                                error={Boolean(touched.minPrice && errors.minPrice)}
                                                InputLabelProps={{
                                                    shrink: values.minPrice
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="maxPrice"
                                                name="maxPrice"
                                                label="Maksimum *"
                                                variant="outlined"
                                                value={values.maxPrice}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                helperText={touched.maxPrice && errors.maxPrice}
                                                error={Boolean(touched.maxPrice && errors.maxPrice)}
                                                InputLabelProps={{
                                                    shrink: values.maxPrice
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(isTemplate === "YES")}>
                                    <Typography classes={{h6:classes.titleGutter}}  variant="h6">Nominal Tutarlar (TL)</Typography>
                                    <Grid container spacing={2} style={{height:70, marginBottom:8}}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="nominalBought"
                                                name="nominalBought"
                                                label="Alınan Nominal Tutar *"
                                                variant="outlined"
                                                value={values.nominalBought}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                helperText={touched.nominalBought && errors.nominalBought}
                                                error={Boolean(touched.nominalBought && errors.nominalBought)}
                                                InputLabelProps={{
                                                    shrink: values.nominalBought
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="nominalSold"
                                                name="nominalSold"
                                                label="Satılan Nominal Tutar *"
                                                variant="outlined"
                                                value={values.nominalSold}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                helperText={touched.nominalSold && errors.nominalSold}
                                                error={Boolean(touched.nominalSold && errors.nominalSold)}
                                                InputLabelProps={{
                                                    shrink: values.nominalSold
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isTemplate === "YES" &&
                                    <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(isTemplate === "YES")}>
                                        <Typography classes={{h6:classes.titleGutter}}  variant="h6">İşlem Yapan</Typography>
                                        <Grid item style={{height:70}}>
                                            <Dropdown   name="islemYapan"
                                                        required
                                                        noMargin
                                                        id="islemYapan"
                                                        title='İşlem Yapan'
                                                        items={islemList}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.islemYapan)}
                                                        helperText={errors.islemYapan}
                                                        containerSelectedItem={values.islemYapan}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(isTemplate === "YES")}>
                                    <Typography classes={{h6:classes.titleGutter}}  variant="h6">Pay/Oy Hakkının İlgili Sınıra Ulaşma/Aşma/Altına Düşme Tarihi</Typography>
                                    <Grid item style={{height:70}}>
                                        <MaterialUIDatePicker   noMargin
                                                                containerId="ps-date3"
                                                                disableAfter={date2}
                                                                maxDateMessage="İşlem Bitiş Tarihinden İleri Tarih Seçilemez"
                                                                setContainerSelectedDate={setDate3}
                                                                dateString={date3}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(isTemplate === "YES")}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6">Ulaşılan Pay/Oy Yüzdesi</Typography>
                                    <Grid item style={{height:70}}>
                                        <TextField
                                            fullWidth
                                            id="ratio"
                                            name="ratio"
                                            label="Pay/Oy Yüzdesi *"
                                            variant="outlined"
                                            value={values.ratio}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.ratio && errors.ratio}
                                            error={Boolean(touched.ratio && errors.ratio)}
                                            InputLabelProps={{
                                                shrink: values.ratio
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end" style={showGrid(true)}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6">Yapılan Açıklama Düzeltme mi?</Typography>
                                    <Grid item style={{height:70}}>
                                        <Dropdown   name="correction"
                                                    required
                                                    noMargin
                                                    id="correction"
                                                    title='Düzeltme'
                                                    items={correctionList}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.correction)}
                                                    helperText={errors.correction}
                                                    containerSelectedItem={values.correction}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider className={classes.divider} />

                                <Grid item xs={12}>
                                    <Typography classes={{h6:classes.titleGutter}} variant="h6"> Kişi Bilgileri</Typography>
                                </Grid>
                                <Grid item xs={12} style={showGrid(true)}>
                                    <TextField
                                        fullWidth
                                        id="nameAndSurname"
                                        name="nameAndSurname"
                                        label="Ad Soyad / Ticaret Ünvanı *"
                                        variant="outlined"
                                        disabled={true}
                                        value={nameOrTitle}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: nameOrTitle
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} style={showGrid(true)}>
                                    <TextField
                                        fullWidth
                                        id="tuzelName"
                                        name="tuzelName"
                                        disabled={!(values.islemYapan === 'O' || investorType !== "R")}
                                        label="Tüzel Kişi Adına Bildirimi Yapanın Adı Soyadı"
                                        variant="outlined"
                                        value={values.islemYapan === "O" || investorType !== "R" ? values.tuzelName : ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.tuzelName && errors.tuzelName}
                                        error={Boolean(touched.tuzelName && errors.tuzelName)}
                                        InputLabelProps={{
                                            shrink: values.tuzelName
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} style={showGrid(true)}>
                                    <TextField
                                        fullWidth
                                        id="gorev"
                                        name="gorev"
                                        label="Görevi"
                                        variant="outlined"
                                        value={values.gorev}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.gorev && errors.gorev}
                                        error={Boolean(touched.gorev && errors.gorev)}
                                        InputLabelProps={{
                                            shrink: values.gorev
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={showGrid(true)}>
                                    <TextField
                                        fullWidth
                                        id="otherNames"
                                        name="otherNames"
                                        label="Varsa Birlikte Hareket Eden Diğer Gerçek/Tüzel Kişiler"
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        rowsMax={10}
                                        value={values.otherNames}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.otherNames && errors.otherNames}
                                        error={Boolean(touched.otherNames && errors.otherNames)}
                                        inputProps={{
                                            maxLength: 300
                                        }}
                                        InputProps={{
                                        endAdornment: <Typography variant="caption" color={values.otherNames.length === 300 ? "error" : "primary"} style={{alignSelf:"flex-end"}}>{300-values.otherNames.length}/{300}</Typography>
                                        }}
                                        InputLabelProps={{
                                            shrink: values.otherNames
                                        }}
                                    />
                                    <Typography variant="caption" className={classes.typography}>{charLimitWarning1}</Typography>
                                </Grid>

                                <Divider className={classes.divider} />
                                
                                <Grid item xs={12} style={showGrid(isTemplate === "NO")}>
                                    <TextField
                                        fullWidth
                                        key={isTemplate}
                                        id="declaration"
                                        name="declaration"
                                        label="Bildirim Metni"
                                        variant="outlined"
                                        multiline
                                        rows={12}
                                        rowsMax={18}
                                        value={values.declaration}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.declaration && errors.declaration}
                                        error={Boolean(touched.declaration && errors.declaration)}
                                        inputProps={{
                                            maxLength: 2500
                                        }}
                                        InputProps={{
                                        endAdornment: <Typography variant="caption" color={values.declaration.length === 2500 ? "error" : "primary"} style={{alignSelf:"flex-end"}}>{2500-values.declaration.length}/{2500}</Typography>
                                        }}
                                        InputLabelProps={{
                                            shrink: values.declaration
                                        }}
                                    />
                                    <Typography variant="caption" className={classes.typography}>{charLimitWarning2}</Typography>
                                </Grid>

                            </Grid>

                            <div className={classes.tableWrapper}>
                                <div className={classes.overflowX}>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            rowCount={tableItems.length}
                                            intl={props.intl}
                                        />
                                        <TableBody>
                                            {tableItems.map((row, i) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={'row' + i}
                                                    >
                                                        <TableCell align="left" key={'col1_' + i} id={'c1_' + i}>{formatDate(row.islemTarihi)}</TableCell>
                                                        <TableCell align="right" key={'col2_' + i} id={'c2_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.alisNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col3_' + i} id={'c3_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.satisNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col4_' + i} id={'c4_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.netNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col5_' + i} id={'c5_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunBasiNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col6_' + i} id={'c6_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunSonuNominalTutar} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col7_' + i} id={'c7_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunBasiBakiyesininSermayeyeOrani} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col8_' + i} id={'c8_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunBasiBakiyesininOyHaklarinaOrani} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col9_' + i} id={'c9_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunSonuBakiyesininSermayeyeOrani} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col10_' + i} id={'c10_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunSonuBakiyesininOyHaklarinaOrani} displayType="text" />
                                                        </TableCell>
                                                        <TableCell align="right" key={'col11_' + i} id={'c11_' + i}><a onClick={()=>openModal1(false,i)}><EditIcon size="large" className={classes.glowOnHover} /></a></TableCell>
                                                        <TableCell align="right" key={'col12_' + i} id={'c12_' + i}><a onClick={()=>removeItem(i)}><DeleteIcon size="large" className={classes.glowOnHover} /></a></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {
                                                 tableItems.length === 0 ?
                                                 <TableRow>
                                                     <TableCell colSpan={12}>
                                                         Tabloda kayıt bulunmamaktadır.
                                                     </TableCell>
                                                 </TableRow>
                                                 : null
                                            }                        
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <Button variant="contained" onClick={()=>openModal1(true,-1)}>
                                                        <AddIcon style={{marginRight:8 }} />
                                                        Tabloya giriş yapmak için tıklayınız
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Typography className={classes.typography} style={{marginTop:8}} variant="caption">( * ) Net satış olması durumunda net nominal tutar negatif gösterilmektedir</Typography>
                                </div>
                            </div>

                            <Divider className={classes.divider} />

                            <Grid container justify="flex-end" spacing={2}>
                                <Grid item className={classes.flexButton} >
                                    <Button variant="contained" color='primary' size='large' fullWidth type='reset' onClick={()=>setOpenForClear(true)}>
                                        FORMU TEMİZLE
                                    </Button>
                                </Grid>
                                <Grid item className={classes.flexButton}>
                                    <Button type='submit' variant="contained" color='primary' size='large' fullWidth>
                                        GÖNDER
                                    </Button>
                                </Grid>
                            </Grid>                         
                        </form>
                    )}
                </Formik>
            </Paper>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openForTable}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    Pay Senetlerine İlişkin Bildirimler
                </DialogTitle>
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef2}
                    initialValues={tableValues}
                    validate={values => {
                        const errors = {};

                        if ( c1 == null){
                            errors.c1 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column2) {
                            errors.column2 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column3) {
                            errors.column3 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column5) {
                            errors.column5 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column6) {
                            errors.column6 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (!values.column7) {
                            errors.column7 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (parseFloat(values.column7) < 0 || parseFloat(values.column7) > 100 ) {
                            errors.column7 = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.RATIO_INTERVAL"});
                        }
                        if (!values.column8) {
                            errors.column8 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (parseFloat(values.column8) < 0 || parseFloat(values.column8) > 100 ) {
                            errors.column8 = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.RATIO_INTERVAL"});
                        }
                        if (!values.column9) {
                            errors.column9 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (parseFloat(values.column9) < 0 || parseFloat(values.column9) > 100 ) {
                            errors.column9 = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.RATIO_INTERVAL"});
                        }
                        if (!values.column10) {
                            errors.column10 = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }
                        if (parseFloat(values.column10) < 0 || parseFloat(values.column10) > 100 ) {
                            errors.column10 = props.intl.formatMessage({id: "KAP_DISCLOSURE.VALIDATION.RATIO_INTERVAL"});
                        }


                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        handleClose1(true,openTableForAdd,selectedRow)
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            <DialogContent>
                                <DialogContentText>   
                                        <MaterialUIDatePicker label="İşlemin Tarihi *" disableFuture containerId="mc1" 
                                        setContainerSelectedDate={setC1} dateString={c1} error={Boolean( errors.c1)}
                                        helperText={errors.c1 }/>
                                        
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="column2"
                                            name="column2"
                                            label="Alım İşlemine Konu Payların Toplam Nominal Tutarı (TL) * "
                                            variant="outlined"
                                            value={values.column2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column2 && errors.column2}
                                            error={Boolean(touched.column2 && errors.column2)}
                                            InputLabelProps={{
                                                shrink: values.column2
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            id="column3"
                                            name="column3"
                                            label="Satım İşlemine Konu Payların Toplam Nominal Tutarı (TL) * "
                                            variant="outlined"
                                            value={values.column3}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column3 && errors.column3}
                                            error={Boolean(touched.column3 && errors.column3)}
                                            InputLabelProps={{
                                                shrink: values.column3
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column4"
                                            name="column4"
                                            label="İşlemlerin Net Nominal Tutarı (TL) *"
                                            disabled
                                            variant="outlined"
                                            value={calculateColumn4()}
                                            InputLabelProps={{
                                                shrink: values.column2 && values.column3
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatNegative,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            fullwidth
                                            margin="normal"
                                            id="column5"
                                            name="column5"
                                            label="Sahip Olunan Payların Gün Başı Nominal Tutarı (TL) * "
                                            variant="outlined"
                                            value={values.column5}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column5 && errors.column5}
                                            error={Boolean(touched.column5 && errors.column5)}
                                            InputLabelProps={{
                                                shrink: values.column5
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column6"
                                            name="column6"
                                            label="Sahip Olunan Payların Gün Sonu Nominal Tutarı (TL) * "
                                            variant="outlined"
                                            value={values.column6}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column6 && errors.column6}
                                            error={Boolean(touched.column6 && errors.column6)}
                                            InputLabelProps={{
                                                shrink: values.column6
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column7"
                                            name="column7"
                                            label="Sahip Olunan Payların Gün Başı Bakiyesinin Sermayeye Oranı (%) * "
                                            variant="outlined"
                                            value={values.column7}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column7 && errors.column7}
                                            error={Boolean(touched.column7 && errors.column7)}
                                            InputLabelProps={{
                                                shrink: values.column7
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column8"
                                            name="column8"
                                            label="Sahip Olunan Oy Haklarının Gün Başı Bakiyesinin Oy Haklarına Oranı (%) * "
                                            variant="outlined"
                                            value={values.column8}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column8 && errors.column8}
                                            error={Boolean(touched.column8 && errors.column8)}
                                            InputLabelProps={{
                                                shrink: values.column8
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column9"
                                            name="column9"
                                            label="Sahip Olunan Payların Gün Sonu Bakiyesinin Sermayeye Oranı (%) * "
                                            variant="outlined"
                                            value={values.column9}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column9 && errors.column9}
                                            error={Boolean(touched.column9 && errors.column9)}
                                            InputLabelProps={{
                                                shrink: values.column9
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            id="column10"
                                            name="column10"
                                            label="Sahip Olunan Oy Haklarının Gün Sonu Bakiyesinin Oy Haklarına Oranı (%) * "
                                            variant="outlined"
                                            value={values.column10}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.column10 && errors.column10}
                                            error={Boolean(touched.column10 && errors.column10)}
                                            InputLabelProps={{
                                                shrink: values.column10
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                        />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ padding: 16 }}>
                                <Button onClick={()=>handleClose1(false,openTableForAdd,-1)} color="primary">
                                    Vazgeç
                                </Button>
                                <Button type='submit' variant="contained" color="primary">
                                    Ekle
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>


            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={openForPreview}
                onClose={closeForPreview}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    <Grid container justify='space-between' alignItems='center' >
                        <Typography display='inline' variant='h6'>Pay Senetlerine İlişkin Bildirimler</Typography>
                        <Typography display='inline' variant='body2'>{currentTime}</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            <Typography gutterBottom variant='h6' align='center' style={{color:'#800000'}}>SÜREKLİ BİLGİLERE İLİŞKİN ÖZEL DURUM AÇIKLAMASI</Typography>
                            <p><span className={classes.fontBold}>Konu: </span>{values.subject}</p>
                            <p><span className={classes.fontBold}>Yapılan Açıklama Düzeltme mi?: </span>{values.correction === 'Y' ? 'EVET' : 'HAYIR'}</p>
                            <p><span className={classes.fontBold}>Bildirime Konu Borsa Şirketi: </span>{companyTitle}</p>
                            <br/>
                            <div style={{position:'relative'}}>
                                <div className={classes.thinBox}>
                                {
                                    isTemplate === "YES" ?
                                        <div><b>{formatDate(date1)} - {formatDate(date2)}</b> tarihinde <b>{companyTitle}</b> payları ile
                                            ilgili olarak <b>{<NumberFormat value={values.minPrice} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />} </b> - <b> {<NumberFormat value={values.maxPrice} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />} </b> TL fiyat
                                            aralığından <b>{<NumberFormat value={!!values.nominalBought ? values.nominalBought : 0} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />}</b> TL toplam nominal tutarlı alış işlemi
                                            ve/veya <b>{<NumberFormat value={!!values.nominalSold ? values.nominalSold : 0} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />}</b> TL toplam nominal tutarlı satış işlemi tarafımca/ortaklığımızca gerçekleştirilmiştir.
                                            Bu işlemle birlikte <b>{companyTitle}</b> sermayesindeki paylarım/oy haklarım <b>{formatDate(date3)}</b> tarihi itibariyle <b>%{<NumberFormat value={values.ratio} thousandSeparator="." decimalSeparator="," isNumericString displayType="text" />}</b> sınırına ulaşmıştır/aşmıştır/altına düşmüştür.
                                                İşlemin detaylarına ilişkin bilgiler aşağıdaki tabloda yer almaktadır.</div>
                                    :
                                    
                                            <p>{values.declaration}</p>
                                        
                                    
                                }
                                </div>
                            </div>
                            <br/><br/>
                            <p><span className={classes.fontBold}>Ad Soyad/Ticaret Ünvanı:</span> {nameOrTitle}</p>
                            <p><span className={classes.fontBold}>Tüzel Kişi Adına Bildirimi Yapanın Adı Soyadı: </span>{values.tuzelName}</p>
                            <p><span className={classes.fontBold}>Görevi: </span>{values.gorev}</p>
                            <p><span className={classes.fontBold}>Varsa Birlikte Hareket Eden Diğer Gerçek/Tüzel Kişiler: </span>{values.otherNames}</p>
                            {
                                tableItems.length > 0 ?
                                <div>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{marginBottom: 16}}
                                    >
                                        <EnhancedTableHead
                                            rowCount={tableItems.length}
                                            intl={props.intl}
                                        />
                                        <TableBody>
                                            {tableItems.map((row,i)=>{
                                                    return(
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={'rowPreview' + i}
                                                        >
                                                            <TableCell align="left" key={'column1_' + i} id={'column1_' + i}>{formatDate(row.islemTarihi)}</TableCell>
                                                            <TableCell align="right" key={'column2_' + i} id={'column2_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.alisNominalTutar} displayType="text" />                                                                        
                                                            </TableCell>
                                                            <TableCell align="right" key={'column3_' + i} id={'column3_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.satisNominalTutar} displayType="text"/>
                                                            </TableCell>
                                                            <TableCell align="right" key={'column4_' + i} id={'column4_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.netNominalTutar} displayType="text"/>
                                                            </TableCell>
                                                            <TableCell align="right" key={'column5_' + i} id={'column5_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunBasiNominalTutar} displayType="text"/>
                                                            </TableCell>
                                                            <TableCell align="right" key={'column6_' + i} id={'column6_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunSonuNominalTutar} displayType="text"/>
                                                            </TableCell>
                                                            <TableCell align="right" key={'column7_' + i} id={'column7_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunBasiBakiyesininSermayeyeOrani} displayType="text"/>
                                                            </TableCell>
                                                            <TableCell align="right" key={'column8_' + i} id={'column8_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunBasiBakiyesininOyHaklarinaOrani} displayType="text"/>
                                                            </TableCell>
                                                                <TableCell align="right" key={'column9_' + i} id={'column9_' + i}>
                                                            <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunSonuBakiyesininSermayeyeOrani} displayType="text"/>
                                                                </TableCell>
                                                            <TableCell align="right" key={'column10_' + i} id={'column10_' + i}>
                                                                <NumberFormat decimalScale={7} decimalSeparator="," thousandSeparator="." value={row.gunSonuBakiyesininOyHaklarinaOrani} displayType="text"/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                        </TableBody>
                                    </Table>
                                    <Typography className={classes.typography} style={{marginTop:8}} variant="caption">( * ) Net satış olması durumunda net nominal tutar negatif gösterilmektedir</Typography>
                                </div>
                                :
                                null
                            }
                            
                            {
                                values.eyatUserId !== props.myselfId ?
                                <p>
                                    <span className={classes.fontBold}>
                                        Bu bildirim {nameOrTitle} adına vekaleten {localStorage.getItem("NameSurname")} tarafından yapılmıştır.
                                    </span>
                                </p>
                                : null
                            }
                            <FormControlLabel style={{marginBottom:0}}
                                control = {
                                    <Checkbox
                                        checked = {checked}
                                        onChange = {handleCheckboxChange}
                                        value = {checked}
                                        color = 'secondary'
                                    />
                                }
                                label = {props.intl.formatMessage({id: "KAP_DISCLOSURE.CHECKBOX.APPROVE_DISCLOSURE"})}
                            >
                            </FormControlLabel>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding:16}}>
                    <Button onClick={()=>handleClose2(false)} color="primary">
                        Vazgeç
                    </Button>
                    <Button onClick={()=>handleClose2(true)} variant="contained" color="primary" >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>
        
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openForDocument}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    İşlem Tamamlandı
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                            Bildiriminiz önizlemede görüntülendiği şekilde gönderilmiştir. <br/><span className={classes.fontBold}>0212 334 57 00/Dahili 57 17</span> numaralı telefondan teyit almanız gerekmektedir.
                            <br/><br/>
                            <Typography variant='h6'>Bildirim No:
                                <Typography variant='h6' component='span' classes={{h6:classes.fontLight}}>{" "+successDeclarationId}</Typography>
                            </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding:16}}>
                    <Button onClick={()=>handleClose3()} variant="contained" color="primary">
                        BİLDİRİMİ İNDİR
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openForClear}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    Formu Temizle
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                            Girdiğiniz tüm bilgiler temizlenecektir. Onaylıyor musunuz?
                            {/* <br/><br/>
                            <Typography variant='h6'>Bildirim No:
                                <Typography variant='h6' component='span' classes={{h6:classes.fontLight}}>{" "+successDeclarationId}</Typography>
                            </Typography> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding:16}}>
                    <Button onClick={()=>setOpenForClear(false)} color="primary">
                        VAZGEÇ
                    </Button>
                    <Button onClick={()=>clearForm()} variant="contained" color="primary">
                        TEMİZLE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default injectIntl(
    connect(
        null,
    )(PaySenetleri)
);