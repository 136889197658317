import React from 'react';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {GET_SERMAYE_INFOS, SERMAYE_INFOS_DOWNLOAD_AS_EXCEL, SERMAYE_INFOS_DOWNLOAD_AS_PDF} from "../../constants/uri";
import {checkHttpStatus, get} from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/es/Paper/Paper";
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from "@material-ui/core/es/Typography/Typography";
import MyTable from '../../components/MyTable';
import {makeStyles} from "@material-ui/core/styles/index";
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
}));

/**
 * SermayePage
 * @param props
 * @return {*}
 * @constructor
 */
function SermayePage(props) {
    
    const classes = useStyles();
    const [sermayeInfos, setSermayeInfos] = useState([]);
    const [fade,setFade] = useState(false);
    
    
    React.useEffect(()=>{
        get(GET_SERMAYE_INFOS)
            .then(function (response) {
                setSermayeInfos(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    },[]);

    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for(var i=0;i<array.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].titleInfo);
            valueItem.values.push(array[i].timeInfo);
            setArray.push(valueItem);
        }
        return setArray;
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        get(serviceName)
            .then(function (response) {
                if (serviceName === SERMAYE_INFOS_DOWNLOAD_AS_EXCEL)
                    DownloadUtils.downloadFileByContentExcel(response.data, "Sermaye Değişiklik.xls",
                        Globals.CONTENT_TYPE_EXCEL);
                else if (serviceName === SERMAYE_INFOS_DOWNLOAD_AS_PDF)
                    DownloadUtils.downloadFileByContent(response.data, "Sermaye Değişiklik.pdf",
                        Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    const headRows = [
        { id: 'titleCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"RIGHTS_INFORMATION.TABLE.STOCK"}) },
        { id: 'timeCol', numeric: false, date: true, disablePadding: false, label: props.intl.
            formatMessage({id:"RIGHTS_INFORMATION.TABLE.SERMAYE_DATE"}), style:{minWidth:260} }
    ];
    const alignment = ['left', 'right'];

    return(
        <div>
            {
                sermayeInfos.length > 0 ?
                    <Paper className={classes.paper} >
                        <Fade in={fade}>
                            <div>
                                <MyTable expandable = {false}
                                        intl={props.intl}
                                        tableRows={prepareItemArray(sermayeInfos)}
                                        headRows={headRows}
                                        alignment={alignment}
                                        defaultSort='desc'
                                        defaultSortIndex={1}
                                        dateTimeColumns={[1]}
                                        printOption={true}
                                        downloadAsExcel={() => download(SERMAYE_INFOS_DOWNLOAD_AS_EXCEL)}
                                        printHeader='SERMAYE DEĞİŞİKLİK BİLGİLERİ'
                                />
                                <Grid container style={{marginTop:20}}>
                                    <InfoIcon size="small" style={{marginRight:8}} />
                                    <Typography display="inline" variant="subtitle1">
                                        {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DETAILED_INFO"})} 
                                    </Typography>
                                </Grid>
                            </div>
                        </Fade>
                    </Paper>
                    :
                    <Paper className={classes.paper} >
                        <Fade in={fade}>
                            <Typography variant="subtitle1"> {props.intl.
                            formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.SRM_NOT_FOUND"})} </Typography>
                        </Fade>
                    </Paper>
            }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    ) (SermayePage)
);