import React , { useEffect, useState } from 'react';
import {injectIntl} from "react-intl";
import {connect, shallowEqual, useDispatch, useSelector} from "react-redux";
import {actions} from "../store/ducks/auth.duck";
import { checkHttpStatus, get } from "../../app/utils/RestUtilsWithAxios";
import {REFRESH_SESSION} from '../../app/constants/uri';
import Button from "@material-ui/core/Button";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import * as Globals from "../constants/globals";
import {  Redirect } from "react-router-dom";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function TimeOutBox(props) {
    const { isReRender } = useSelector(
        ({auth}) => ({
            isReRender: auth.reRender
        }),
        shallowEqual
    );

    const [timeLeft, setTimeLeft] = useState(180);

    useEffect(() => {
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    function refreshSession() {
        get(REFRESH_SESSION).then(function (response) {
            if (response.data.status === 'OK') {
                localStorage.removeItem(Globals.TOKEN);
                localStorage.setItem(Globals.TOKEN, response.data.token);
                dispatch(actions.setTimeOut(false));
                if(isReRender)
                    window.location.reload();
            } else {
                dispatch(actions.logout());
            }
        }).catch(function (error) {
            checkHttpStatus(error, props.intl);
        })
    }

    const dispatch = useDispatch();
    return(
        <Dialog
            maxWidth={'md'}
            open={true}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                OTURUM UZATMA
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p><b>Oturumunuz sona ermiştir.Uzatmak istiyor musunuz ?</b></p>
                </DialogContentText>
                <DialogContentText>
                    <p><b>Kalan Süre : {timeLeft} </b></p>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: 16 }}>
                <Button onClick={()=>dispatch(actions.logout())} color="primary">
                    Hayır
                </Button>
                <Button onClick={()=>refreshSession()} variant="contained" color="primary">
                    Evet
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default injectIntl(
    connect(
        null,
    )(TimeOutBox)
);