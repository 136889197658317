import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import GkReportPage from './GkReportPage';
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import {GET_CURRENT_GK_REPORTS, 
    GET_OLD_GK_REPORTS,
    GKREPORT_DOWNLOAD_AS_EXCEL,
    GKREPORT_DOWNLOAD_AS_PDF
 } from "../../constants/uri";
import clsx from 'clsx';
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from "@material-ui/core/Button";
import { Typography,TextField } from '@material-ui/core';
import * as Globals from '../../constants/globals';
import { alertBox } from '../../components/BoxProvider';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    navButton: {
        maxWidth:'100%',
        marginLeft: 2,
        marginRight: 2,
        backgroundColor: Globals.COLOR_TRANSPARENT_RED,
        borderRadius: 4,   
    },
    activeNavButton: {
        maxWidth:'100%',
        marginLeft: 2,
        marginRight: 2,
        backgroundColor: Globals.COLOR_FULL_RED,
        borderRadius: 4,  
    },
    nav: {
        justifyContent: "space-around",
        height: "unset",
        padding: 5,
        borderRadius: 4,
        paddingLeft: 0,
        paddingRight: 0
    },
    navText: {
        color: "white",
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10
        },
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    whiteText: {
        color:'white'
    }
}));

/**
 * GeneralGkReportPage
 * @param props
 * @return {*}
 * @constructor
 */
function GeneralGkReportPage(props) {
    React.useEffect(()=>{
        changeReportList(isActive)
    },[]);

    /**
     * changeReportList
     * @param reportState
     */
    function changeReportList(reportState) {
        var url;
        if(reportState)
            url=GET_CURRENT_GK_REPORTS;
        else
            url=GET_OLD_GK_REPORTS;
        var beginDateParam;
        var endDateParam;
        if(reportState) {
            beginDateParam = selectedActiveBeginDate;
            endDateParam = selectedActiveEndDate;
        }else{
            beginDateParam = selectedOldBeginDate;
            endDateParam = selectedOldEndDate;
        }
        var endDate = new Date(endDateParam.substring(0,4)+'-'+endDateParam.
        substring(4,6)+'-'+endDateParam.substring(6,8));
        var beginDate = new Date(beginDateParam.substring(0,4)+'-'+beginDateParam.
        substring(4,6)+'-'+beginDateParam.substring(6,8));
        
        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, props.intl);
            return;
        }
        enableLoading();
        post(url,{beginDate:beginDateParam,endDate:endDateParam,exchCode:borsaCode})
            .then(function (response) {
                setGkReports([]);
                setGkReports(response.data);
                setFade(true);
                disableLoading();
            })
            .catch(function (error) {
                disableLoading();
                checkHttpStatus(error,props.intl);
            })
        document.getElementById("picker1").style.display = reportState ? "flex" : "none";
        document.getElementById("picker2").style.display = reportState ? "flex" : "none";
        document.getElementById("picker3").style.display = !reportState ? "flex" : "none";
        document.getElementById("picker4").style.display = !reportState ? "flex" : "none";
    }

    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    /**
     * beginInit
     * @param activeControl
     * @return {string}
     */
    function beginInit(activeControl){
        var date = new Date();
        if(!activeControl)
            date.setFullYear(date.getFullYear()-1);
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).
        toString()+(formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * endInit
     * @param activeControl
     * @return {string}
     */
    function endInit(activeControl) {
        var date = new Date();
        if(activeControl)
            date.setFullYear(date.getFullYear()+1);
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).
        toString()+(formatMonthOrDay(date.getDate())).toString();
    }

    const handleChange =  event => {
        setBorsaCode(event.target.value.toUpperCase());
    };

    /**
     * handleTabChange
     * @param event
     * @param newValue
     */
    function handleTabChange(event, newValue) {
        setFade(false);
        setPageValue(newValue);
        setIsActive(newValue === 0);
        changeReportList(newValue === 0);
    }

    useEffect(() => {
        var beginDateParam;
        var endDateParam;
        if(isActive) {
            beginDateParam = selectedActiveBeginDate;
            endDateParam = selectedActiveEndDate;
        }else{
            beginDateParam = selectedOldBeginDate;
            endDateParam = selectedOldEndDate;
        }
        var endDate = new Date(endDateParam.substring(0,4)+'-'+endDateParam.
        substring(4,6)+'-'+endDateParam.substring(6,8));
        var beginDate = new Date(beginDateParam.substring(0,4)+'-'+beginDateParam.
        substring(4,6)+'-'+beginDateParam.substring(6,8));
        
        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, props.intl);
            return;
        }
      });

    const classes = useStyles();
    const theme = useTheme();
    const [fade,setFade] = React.useState(false);
    const [pageValue, setPageValue] = React.useState(0);
    const [gkReports,setGkReports] = React.useState([]);
    const [isActive,setIsActive] = React.useState(true);
    const [selectedActiveBeginDate,setSelectedActiveBeginDate] = React.useState(beginInit(true));
    const [selectedActiveEndDate,setSelectedActiveEndDate] = React.useState(endInit(true));
    const [selectedOldBeginDate,setSelectedOldBeginDate] = React.useState(beginInit(false));
    const [selectedOldEndDate,setSelectedOldEndDate] = React.useState(endInit(false));
    const [borsaCode, setBorsaCode] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    /**
     * enableLoading - setLoading(true)
     */
    const enableLoading = () => {
        setLoading(true);
    };
    /**
     * disableLoading - setLoading(false)
     */
    const disableLoading = () => {
        setLoading(false);
    };

    return(
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Hidden xsDown>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">Tarih Aralığı</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">Borsa Kodu</Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Typography variant="h5">Tarih Aralığı</Typography>
                </Hidden>
                <Grid container style={{marginBottom:24}}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={6} sm={3} id="picker1" >
                            <MaterialUIDatePicker containerId="mui-pickers-date"
                                                    setContainerSelectedDate={setSelectedActiveBeginDate}
                                                    dateString={selectedActiveBeginDate}/>
                        </Grid>
                        <Grid item xs={6} sm={3} id="picker3" >
                            <MaterialUIDatePicker containerId="mui-pickers-date3"
                                                    setContainerSelectedDate={setSelectedOldBeginDate}
                                                    dateString={selectedOldBeginDate}/>
                        </Grid>
                        <Grid item xs={6} sm={3} id="picker2" >
                            <MaterialUIDatePicker containerId="mui-pickers-date2"
                                                    setContainerSelectedDate={setSelectedActiveEndDate}
                                                    dateString={selectedActiveEndDate}/>
                        </Grid>
                        <Grid item xs={6} sm={3} id="picker4" >
                            <MaterialUIDatePicker containerId="mui-pickers-date4"
                                                    setContainerSelectedDate={setSelectedOldEndDate}
                                                    dateString={selectedOldEndDate}/>
                        </Grid>
                        
                        <Hidden xsDown>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    margin="normal"
                                    id="borsaKodu"
                                    label="Borsa Kodu"
                                    type="numeric"
                                    variant="outlined"
                                    value={borsaCode}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Hidden smUp>
                        <Typography variant="h5" style={{marginTop:24}}>Borsa Kodu</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    margin="normal"
                                    id="borsaKodu"
                                    label="Borsa Kodu"
                                    type="numeric"
                                    variant="outlined"
                                    value={borsaCode}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Hidden>             
                </Grid>
                
                <Grid container justify="flex-end" style={{marginBottom:24}}>
                    <Button classes={{label:classes.whiteText}}
                            variant="contained"
                            color='primary'
                            size='large'
                            fullWidth onClick={()=>changeReportList(isActive)}
                            className={[classes.showButton,  `btn ${clsx(
                                {
                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                }
                            )}`]}>
                        {props.intl.formatMessage({id: "COMMON.BUTTON_SHOW"})}
                    </Button>
                </Grid>
                <Grid>
                    <BottomNavigation
                        value={pageValue}
                        onChange={handleTabChange}
                        showLabels
                        classes={{root: classes.nav}}
                        >
                        <BottomNavigationAction
                            label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"GK.TABS.ACTIVE"})}</span>}
                            classes={{root: pageValue === 0 ? classes.activeNavButton : classes.navButton}}  />
                        <BottomNavigationAction
                            label={<span className={classes.navText}>{props.intl.
                            formatMessage({id:"GK.TABS.PAST"})}</span>}
                            classes={{root: pageValue === 1 ? classes.activeNavButton : classes.navButton}} />
                    </BottomNavigation>
                    {
                        <div>
                            <Fade in={fade}>
                                        <GkReportPage gkReports={gkReports}
                                                      isActiveReport={isActive}
                                                      dataReady={fade}/>
                            </Fade>
                        </div>
                        
                    }
                        
                </Grid>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(GeneralGkReportPage)
);