import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { injectIntl, useIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import * as Globals from "../../constants/globals";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { YRTS_QUERY, YRTS_DETAIL_QUERY_DOWNLOAD_EXCEL, } from '../../constants/uri';
import { post, get, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import DownloadButtons from '../../components/PdfAndExcelDownloadButtons';
import { printThisPage, stableSort, getSorting } from '../../utils/ExportUtils';
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
import * as DownloadUtils from '../../utils/downloadUtils';
import TablePagination from '@material-ui/core/TablePagination';
import { alertBox } from '../../components/BoxProvider';
import MyTable from '../../components/MyTable';
import Fade from '@material-ui/core/Fade';
import Spinner from 'react-bootstrap/Spinner'
import format from "date-fns/format";
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    tableWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    overflowX: {
        overflowX: 'auto',
    },
    showButton: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: 200
        },
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30
    },
}))

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 12,
        fontWeight: 600
    },
}));

/**
 * HpksTransfer
 * @param props
 * @return {*}
 * @constructor
 */
function YrtsQuery(props) {
    const classes = useStyles();
    const [tableItems, setTableItems] = useState([]);
    const [selectedBeginDate, setSelectedBeginDate] = useState(beginInit());
    const [selectedEndDate, setSelectedEndDate] = useState(endInit());
    const [fade,setFade] = useState(false);
    const [loading, setLoading] = useState(false);

    const headRows = [
        { id: 'sorgulamaZamani', numeric: false, disablePadding: false, label: 'Sorgulama Tarihi' },
        { id: 'sorgulayanKurumUnvan', numeric: false, disablePadding: false, label: 'Sorgulayan Yatırım Kuruluşunun Ünvanı' },
    ];
    
    const alignment = ['left', 'left'];

    /**
     * beginInit
     * @return {string}
     */
    function beginInit() {
        var date = new Date();
        date.setDate(date.getDate() - 30);
        return date.getFullYear().toString() + (formatMonthOrDay(date.getMonth() + 1)).toString() +
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * endInit
     * @return {string}
     */
    function endInit() {
        var date = new Date();
        return date.getFullYear().toString() + (formatMonthOrDay(date.getMonth() + 1)).toString() +
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if (m < 10)
            return '0' + m;
        else
            return m;
    }

    /**
     * searchByParameters
     */
    function searchByParameters() {
        setLoading(true);
        var endDate = new Date(selectedEndDate.substring(0, 4) + '-' + selectedEndDate.substring(4, 6) + '-' + selectedEndDate.substring(6, 8));
        var beginDate = new Date(selectedBeginDate.substring(0, 4) + '-' + selectedBeginDate.substring(4, 6) + '-' + selectedBeginDate.substring(6, 8));
        var compareDate = new Date(selectedBeginDate.substring(0, 4) + '-' + selectedBeginDate.substring(4, 6) + '-' + selectedBeginDate.substring(6, 8));

        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, intl);
            return;
        }
        compareDate.setDate(compareDate.getDate() + 30);

        if (compareDate.getTime() < endDate.getTime()) {
            alertBox("", 'Seçtiğiniz tarih aralığı 30 günden büyük olamaz', Globals.ALERT_ERROR, intl);
            return;
        }


        let postData = {
            baslangicTarihi: beginDate,
            bitisTarihi: endDate,
            mkkSicilNo: '',
            islemReferansi: ''
        }

        post(YRTS_QUERY, postData)
            .then(function (response) {
                setFade(true);
                setLoading(false);
                if (response.data.riskQueryDetailList.length === 0) {
                    setTableItems([]);
                } else {
                    setTableItems(response.data.riskQueryDetailList);
                }
            })
            .catch(function (error) {
                setLoading(false);
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     *  downloadAsExcel
     */
    function downloadAsExcel() {
        var postData = [];
        for (var i = 0; i < tableItems.length; i++) {
            postData.push(
                {
                    sorgulamaZamani: formatDate(tableItems[i].sorgulamaZamani),
                    sorgulayanKurumUnvan: tableItems[i].sorgulayanKurumUnvan
                });
        }

        post(YRTS_DETAIL_QUERY_DOWNLOAD_EXCEL, postData)
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, "Yatırım Kuruluşu Sorgu Sonuçları.xls",
                    Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     * formatDate
     * @param dateStr
     */
    function formatDate(dateStr) {
        var date = new Date(dateStr);
        var formatedDate = format(date, "dd.MM.yyyy HH:mm");
        return formatedDate;
    }

    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for(var i=0;i<array.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(formatDate(array[i].sorgulamaZamani));
            valueItem.values.push(array[i].sorgulayanKurumUnvan);
            setArray.push(valueItem);
        }
        return setArray;
    }

    const intl = useIntl();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Tarih Aralığı</Typography>
                <Grid container direction="row" spacing={3} style={{ marginBottom: 16 }}>
                    <Grid item xs={6}>
                        <MaterialUIDatePicker containerId="mui-pickers-date" label="Başlangıç Tarihi"
                            setContainerSelectedDate={setSelectedBeginDate}
                            dateString={selectedBeginDate} />
                    </Grid>
                    <Grid item xs={6}>
                        <MaterialUIDatePicker containerId="mui-pickers-date2" label="Bitiş Tarihi"
                            setContainerSelectedDate={setSelectedEndDate}
                            dateString={selectedEndDate} />
                    </Grid>
                </Grid>

                <Grid container direction="row" spacing={3} style={{ marginBottom: 16 }}>
                    <Grid item xs={8}>
                        <Grid container style={{backgroundColor: "white"}}>
                            <Typography display="inline" variant="subtitle1">
                                {props.intl.formatMessage({id:"YRTS.QUERY.YRTS_INFO"})}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justify="flex-end">
                            <Button variant="contained" color='primary' size='large'
                                fullWidth onClick={searchByParameters}
                                className={[classes.showButton, `btn ${clsx(
                                    {
                                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                    }
                                )}`]}
                            >
                                {props.intl.formatMessage({ id: "COMMON.BUTTON_SHOW" })}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                

            </Paper>
            <div>
                {
                    loading ?
                    <Paper className={classes.paper} >
                        <Grid container style={{ flex: 1, justifyContent: "center"}}>
                            <Spinner animation="border" />
                        </Grid>
                    </Paper>
                    :
                    tableItems.length > 0 ?
                        <Paper className={classes.paper} > 
                            <Fade in={fade}>
                                <div>
                                    <MyTable expandable = {false}
                                            intl={props.intl}
                                            tableRows={prepareItemArray(tableItems)}
                                            headRows={headRows}
                                            alignment = {alignment}
                                            defaultSort={'desc'}
                                            defaultSortIndex={0}
                                            dateTimeColumns={[0]}
                                            printOption={true}
                                            downloadAsExcel={() => downloadAsExcel()}
                                            printHeader='Yatırım Kuruluşu Sorgu Sonuçları'
                                    />
                                </div>
                            </Fade>
                        </Paper>
                        :
                        <Paper className={classes.paper} >
                            <Fade in={fade}>
                                <Typography variant="subtitle1"> {props.intl.
                                formatMessage({id:"YRTS.QUERY.EMPTY_MESSAGE"})} </Typography>
                            </Fade>
                        </Paper>
                }
            </div>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(YrtsQuery)
);