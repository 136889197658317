import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";
import { ACIKRIZA_BEYAN, ACIKRIZA_SORGU } from "../../constants/uri";
import { checkHttpStatus, get, post } from "../../utils/RestUtilsWithAxios";
import * as Globals from '../../constants/globals';
import { alertBox } from '../../components/BoxProvider';

import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import Spinner from 'react-bootstrap/Spinner'


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    header: {
        textAlign: "center",
    },
    actions: {
        paddingTop: 8,
        paddingBottom: 8,
        justifyContent: 'center',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
        boxShadow: "0px 4px 80px grey"
    },
    list: {
        height: 300,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
        marginTop: 4
    },
    collapse: {
        "& .MuiCollapse-wrapperInner": {
            display: "flex",
            flexDirection: "column"
        }
    },
    button: {
        margin: theme.spacing(0.5, 0)
    }
}))

/**
 * ExpressConsent
 * @param props
 * @return {*}
 * @constructor
 */
function ExpressConsent(props) {
    const classes = useStyles();
    const [checkBox, setCheckBox] = useState(false);
    const [read, setRead] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [checked, setChecked] = useState([]);
    const [initialRightList, setInitialRightList] = useState([]);
    const [initialBeyanKapsam, setInitialBeyanKapsam] = useState("");
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [saved, setSaved] = useState(false);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [controlDialog, setControlDialog] = useState(false);
    const [controlDialogText, setControlDialogText] = useState("");
    const [radioButtonSelected, setRadioButtonSelected] = useState("ALL");
    const [openYrtsInfo, setOpenYrtsInfo] = useState(false);
    const [loading, setLoading] = useState(true);



    /**
     * handleCheckBox
     */
    function handleCheckBox() {
        setCheckBox(!checkBox);
    }

    useEffect(() => {
        setEnabled(read && checkBox);
    }, [read, checkBox])

    useEffect(() => {
        acikRizaSorgu();
    }, [saved])

    /**
     * acikRizaSorgu
     */
    function acikRizaSorgu() {
        get(ACIKRIZA_SORGU).then(function (response) {
            setRadioButtonSelected(response.data.beyanKapsam);
            setLeft(response.data.leftList);
            setRight(response.data.rightList);
            setInitialRightList(response.data.rightList);
            setInitialBeyanKapsam(response.data.beyanKapsam);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            checkHttpStatus(error, props.intl);
        });
    }

    /**
     * openDataSet
     */
    function checkListChanged() {
        if (right.length !== initialRightList.length) {
            return true;
        } else {
            for (var i = 0; i < right.length; i++) {
                let objIsEqual = false;
                for (var j = 0; j < initialRightList.length; j++) {
                    if (right[i].memberId === initialRightList[j].memberId) {
                        objIsEqual = true;
                        break;
                    }
                }
                if (!objIsEqual) {
                    return true;
                }
            }
            return false;
        }
    }


    function controlBeforeSave() {
        let kurumUyeKoduList = [];

        if (radioButtonSelected === 'PARTIAL') {
            if (checkListChanged()) {
                for (var i = 0; i < right.length; i++) {
                    kurumUyeKoduList.push(right[i].memberId);
                }
                save((left.length > 0 && right.length === 0) ? 'NONE' : radioButtonSelected, kurumUyeKoduList);
            } else {
                alertBox("", props.intl.formatMessage({ id: "YRTS.EXPRESS_CONSENT.CHANGED_LIST_WARN" }),
                    Globals.ALERT_INFO, props.intl);
            }
        } else {
            if (initialBeyanKapsam === radioButtonSelected) {
                alertBox("", props.intl.formatMessage({ id: "YRTS.EXPRESS_CONSENT.CHANGED_BEYAN_WARN" }),
                    Globals.ALERT_INFO, props.intl);
            } else {
                save(radioButtonSelected, kurumUyeKoduList);
            }
        }
    }

    /**
     * save
     */
    function save(beyanKapsam, kurumUyeKoduList) {
        let postData = {
            beyanKapsam: beyanKapsam,
            kurumUyeKoduList: kurumUyeKoduList,
            mkkSicilNo: '',
            islemReferansi: ''
        }
        post(ACIKRIZA_BEYAN, postData, props.intl).then(function (response) {
            alertBox("", props.intl.formatMessage({ id: "COMMON.ALERT.MSG_SUCCESS_SAVE" }),
                Globals.ALERT_INFO, props.intl);
            setSaved(!saved);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl);
            setSaved(!saved);
        });
    }

    /**
     * handleRadioChange
     */
    function handleRadioChange(event) {
        setRadioButtonSelected(event.target.value);
    }

    /**
     * openDataSet
     */
    function openDataSet() {
        setRead(true);
        setOpenInfo(true);
    }

    /**
     * openDataSet
     */
    function openYrtsInfoSet() {
        setOpenYrtsInfo(true);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) =>
        intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleSwap = () => {
        setRight(left);
        setLeft(right);
    };

    const CardSelector = (
        title,
        items,
        side
    ) => {
        const [expanded, setExpanded] = React.useState(true);

        const handleExpandClick = () => {
            setExpanded(!expanded);
        };

        return (
            <Card
                style={{ display: "flex", flexDirection: "column", width: "400px" }}
                component={Paper}
            >
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={
                                numberOfChecked(items) === items.length && items.length !== 0
                            }
                            indeterminate={
                                numberOfChecked(items) !== items.length &&
                                numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{ "aria-label": "all items selected" }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} seçilen kurum`}
                    action={
                        <IconButton
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    }
                />
                <Collapse in={expanded} className={classes.collapse}>
                    <List className={classes.list} dense component="div" role="list">
                        {items.map((value) => {
                            const labelId = `transfer-list-all-item-${value}-label`;

                            return (
                                <ListItem
                                    key={value.memberId}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(value)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={value.mbrTitle}
                                    />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>

                </Collapse>
            </Card>
        );
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <br />
                <Typography variant="h5" className={classes.header}>
                    KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AÇIK RIZA BEYANI
                </Typography>
                <br />
                <Typography>
                    Sermaye Piyasası Kanunu’nun 87 inci maddesi ve Sermaye Piyasası Kurulu tarafından çıkarılan Veri 
					Depolama Kuruluşuna Yapılacak Raporlamalara İlişkin Esaslar Hakkında Tebliğ düzenlemeleri
					çerçevesinde hesabımın bulunduğu yatırım kuruluşları, Sermaye Piyasası Kurulu, Borsa İstanbul A.Ş, 
					İstanbul Takas ve Saklama Bankası A.Ş. ve Merkezi Kayıt Kuruluşu A.Ş. tarafından Merkezi Kayıt Kuruluşu A.Ş’nin 
					Yatırımcı Risk Takip Sistemine (YRTS) 
					&nbsp;
                    <a className="kt-link" style={{ color: "#000000", textDecoration: "underline" }}
                        onClick={() => openYrtsInfoSet()}>
                        (bilgi için tıklayınız)
                    </a> 
					&nbsp; 
					bildirilmiş olan tarafıma ilişkin bilgilerimin 
					&nbsp;
                    <a className="kt-link" style={{ color: "#000000", textDecoration: "underline" }}
                        onClick={() => openDataSet()}>
                        <b>(bilgi seti için tıklayınız)</b>
                    </a> 
					&nbsp; 
					Merkezi Kayıt Kuruluşu A.Ş. üyesi yatırım kuruluşları tarafından Yatırımcı Risk Takip Sisteminden (YRTS) sorgulanabilmesine, 
					görüntülenip rapor alınabilmesine açık rızam bulunduğunu; bu açık rızamı her zaman iptal edebileceğimi bildiğimi, 
					konu hakkında tarafıma gerekli aydınlatmanın yapıldığını; işbu metni okuduğumu ve anladığımı; kabul ve beyan ederim.
                </Typography>


                <div>
                    {
                        loading ?
                            <Grid container style={{ flex: 1, justifyContent: "center" }}>
                                <Spinner animation="border" />
                            </Grid>
                            :
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40, marginTop: 20 }}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <RadioGroup
                                            row
                                            name="radioButtonSelected"
                                            value={radioButtonSelected}
                                            onChange={handleRadioChange}
                                        >
                                            <FormControlLabel control={<Radio color="secondary" />} label="Tüm kuruluşlara onay veriyorum" value="ALL" />
                                            <FormControlLabel control={<Radio color="secondary" />} label="Hiçbir kuruluşa onay vermiyorum" value="NONE" />
                                            <FormControlLabel control={<Radio color="secondary" />} label="Yatırım kuruluşu bazında seçim yapmak istiyorum" value="PARTIAL" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>


                            </div>
                    }
                </div>

                <div style={{ display: (radioButtonSelected === "PARTIAL" ? 'flex' : 'none'), justifyContent: 'center', marginBottom: 40, marginTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                        className={classes.root}
                    >
                        <Grid item>
                            <Paper elevation={3}>{CardSelector("Yatırım Kuruluşları Listesi", left, "left")}</Paper>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleSwap}
                                >
                                    <SwapHorizIcon></SwapHorizIcon>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Paper elevation={3}>{CardSelector("Açık Rıza Verilen Yatırım Kuruluşları", right, "right")}</Paper>
                        </Grid>
                    </Grid>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40, marginTop: 20 }}>
                    <FormControlLabel className={classes.header}
                        control={
                            <Checkbox
                                checked={checkBox}
                                onChange={handleCheckBox}
                                value={checkBox}
                                color='primary'
                            />
                        }
                        label="ONAYLIYORUM"
                    >
                    </FormControlLabel>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={() => controlBeforeSave()} color="primary" style={{ "width": "25%" }} autoFocus
                        variant='contained' size="large" disabled={!enabled}>
                        <FormattedMessage id="BTN_SAVE" />
                    </Button>
                </div>

                <Grid container style={{ marginTop: 40 }}>
                    <InfoIcon size="small" style={{ marginRight: 8 }} />
                    <Typography display="inline" variant="subtitle1">
                        “Kaydet” butonu, <b>bilgi seti incelendikten</b> ve “ONAYLIYORUM” kutucuğu işaretlendikten sonra aktif olacaktır.
                    </Typography>
                </Grid>
                <div>
                    <Dialog
                        open={openInfo}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Bilgi Seti"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li>SPK tarafından verilen işlem yasaklarına ilişkin bilgiler</li>
                                    <li>SPK tarafından verilen diğer tedbirlere ilişkin bilgiler</li>
                                    <li>Aracı kurumlar tarafından verilen 6 ay nakde zorlanma cezası bilgisi</li>
                                    <li>Kaldıraçlı Alım Satım Verileri (İşlem tarihi, pozisyon tutarı, teminat tutarı)</li>
                                    <li>Yatırımcının Banka ve Aracı Kurumlardaki toplam hesap sayısı</li>
                                    <li>Yatırımcının Banka ve Aracı Kurumlardaki bakiyeli hesap sayısı</li>
                                    <li>Yatırımcının haciz durumu (EVET/HAYIR)</li>
                                    <li>Yatırımcının iflas durumu (EVET/HAYIR) (EVET ise İflas Tarihi)</li>
                                    <li>Türev Verileri: Yatırımcının VİOP'ta gerçekleştirdiği işlemlere ilişkin bilgiler (Sözleşme Türü, Varlık Sınıfı, Sözleşmelerin toplam piyasa ve teminat değeri)</li>
                                    <li>Yatırımcının MKK sicil numarası</li>
                                    <li>Aracı kurumlar tarafından Kredi Alım işlemi için tahsis edilen toplam kredi limiti (TL)</li>
                                    <li>Yatırımcıların Ödünç Aldığı menkul kıymetler için Aracı kurumlar tarafından tahsis edilen toplam limit (TL)</li>
                                    <li>Aracı kurumlar tarafından Açığa Satış İşlemi için tahsis edilen toplam limit (TL)</li>
                                    <li>Aracı Kurumlar tarafından Toplam Tahsis edilen limit (TL)</li>
                                    <li>Bankalar tarafından Krediler Tebliği çerçevesinde tahsis edilen limit (TL)</li>
                                    <li>Kredi Alım İşlemi için kullandırılabilir kredi limiti (TL)</li>
                                    <li>Ödünç alınan menkul kıymetler için kullandırılabilir limit (TL)</li>
                                    <li>Açığa Satış İşlemi için kullandırılabilir limit (TL)</li>
                                    <li>Toplam Kullandırılabilir limit (TL)</li>
                                    <li>Bankalar tarafından Krediler Tebliği çerçevesindeki kullandırılabilir limiti (TL)</li>
                                    <li>Kredi Alım için toplam kullanılan kredi (TL)</li>
                                    <li>Ödünç alınan menkul kıymetlerin toplam cari piyasa değeri (TL)</li>
                                    <li>Açığa satışı yapılan menkul kıymetlerin toplam cari piyasa değeri (TL)</li>
                                    <li>Toplam Risk Tutarı (TL)</li>
                                    <li>Bankalar tarafından Krediler Tebliği çerçevesinde kullandırılan kredi tutarı (TL)</li>
                                    <li>Değerlenmiş toplam teminat tutarı</li>
                                    <li>Bankalar tarafından kullandırılan krediler karşılığında verilen teminatların piyasa değeri (TL)</li>
                                    <li>Yatırımcıya limit tahsis eden aracı kurum sayısı</li>
                                    <li>Yatırımcının Krediler Tebliği ve Organize Piyasalardaki Takas Yükümlülüklerini yerine getirememesi karşılığında oluşan toplam temerrüt tutarı (TL)</li>
                                    <li>Özkaynak tamamlama bildirimleri ( İşlem Türü-Tutar)</li>
                                </ul>
                            </DialogContentText>
                        </DialogContent>


                        <DialogActions style={{ padding: 16 }}>
                            <Button onClick={() => setOpenInfo(false)} color="primary" variant='contained' autoFocus>
                                {props.intl.formatMessage({ id: "ALERTBOX.BTN.OK.TITLE" })}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openYrtsInfo}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {/*<DialogTitle id="alert-dialog-title">{"Yatırımcı Risk Takip Sistemi (YRTS)"}</DialogTitle>*/}
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
                                Yatırımcı Risk Takip Sistemi (YRTS)  uygulaması,
                                yatırım kuruluşları tarafından sermaye piyasalarında işlem yapan yatırımcıların kredili işlemlerinin,
                                teminatlarına ait bilgilerin MKK’ya iletilerek, bu değerler üzerinden hesaplanacak risklerin, finansal piyasalardaki paydaşlara ,
                                bütüncül bir değerlendirme yapılabilmelerini teminen iletilmesi işlevini yerine getirmektedir.
                            </DialogContentText>
                        </DialogContent>


                        <DialogActions style={{ padding: 16 }}>
                            <Button onClick={() => setOpenYrtsInfo(false)} color="primary" variant='contained' autoFocus>
                                {props.intl.formatMessage({ id: "ALERTBOX.BTN.OK.TITLE" })}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullWidth={true}
                        maxWidth={'sm'}
                        open={controlDialog}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogContent>
                            <DialogContentText>
                                {controlDialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ padding: 16 }}>
                            <Button onClick={() => setControlDialog(false)} color="primary">
                                {props.intl.formatMessage({ id: "YRTS.EXPRESS_CONSENT.CONTROL_DIALOG_BUTTON_BACK" })}
                            </Button>
                            <Button onClick={() => save()} variant="contained" color="primary">
                                {props.intl.formatMessage({ id: "YRTS.EXPRESS_CONSENT.CONTROL_DIALOG_BUTTON_SAVE" })}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(ExpressConsent)
);