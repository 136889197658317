export const TCKN_VKN_IDENTITY = /^[0-9]{8}|[0-9]{10}|[0-9]{11}$/i
export const PASSWORD          = /^[0-9]{6}$/i
export const EMAIL             = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const MOBILE            = /^[0][5][0-9]{9}$/i
export const WELCOME           = /^(?!\s*$)(.+){3,}/i

export const SHOW_POPUP_RELEASE  = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;


export const NAME_SURNAME_TITLE = /(\s*|[a-zA-Z\s,.'\-\pL]{1,255})/
export const ID_TYPE = /^(TCKN|YKN|MKN|VKN|SCL|PAN)$/i
export const TCKN = /^(?!9)[0-9]{11}$/i
export const YKN = /^9[0-9]{10}$/i
export const VKN = /^[0-9]{10}$/i
export const MKN_SCL_PAN = /^[a-zA-Z0-9]{8,16}$/i
export const HPKS_MOBILE = /^(?:(?:00|\+)\d{2}|0)[1-9](?:\d{9})$/i
export const HPKS_MAIL = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/i
export const ADDRESS = /[a-zA-Z\\s,.'\\-\\pL]{1,600}/i
export const ADDRESS_COUNTRY = /[a-zA-Z\\s,.'\\-\\pL]{1,600}/i
export const ADDRESS_CITY = /[a-zA-Z0-9\s,.'\-\pL]{1,100}/i
export const ADDRESS_COUNTY = /[a-zA-Z0-9\s,.'\-\pL]{1,100}/i
export const COUNTRY_CODE = /[A-Z]{2}/i

export const SHARE_SERIAL_NO = /^[a-zA-Z0-9\s,.'\-\pL]{1,30}$/i
export const SHARE_ORDER_NO = /^[0-9]{1,3}$/i
export const SHARE_GROUP_NO = /^[A-Z0]{1,3}$/
export const SHARE_VALUE = /^[1-9][0-9]{0,19}$/i
export const SHARE_TOTAL_VALUE = /^\d+(\,\d{1,5})?$/i
export const SHARE_NOMINAL_VALUE = /^\d+(\,\d{1,5})?$/i
export const MKK_CERT_REF = /^(\s*|[a-zA-Z0-9]{12})$/i
export const EXPLANATION = /^[a-zA-Z\s,.'\\-\\pL]{1,600}$/i