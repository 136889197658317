/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, {useEffect,useState} from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import TimeOutBox from '../components/TimeOutBox';
import LogoutCounter from '../components/LogoutCounter';
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import {checkAuthorization} from "../utils/authUtils";
import * as Globals from "../constants/globals";
import {checkHttpStatus, get} from "../utils/RestUtilsWithAxios";
import {GET_ACTIVE_SURVEY_STATUS, MAINTENANCE_CONTROL} from "../constants/uri";
import MaintenancePage from "../pages/errors/MaintenancePage";
import InvestorSatisfactionSurvey2 from "../pages/Survey/InvestorSatisfactionSurvey2";


var intl = undefined;

export const Routes = withRouter(({ history }) => {
  const { isAuthorized,isTimeOut, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: checkAuthorization(),
      isTimeOut: auth.userTimeOut,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  const [maintenance, setMaintenance] = React.useState(false);
    useEffect(() => {
        localStorage.setItem(Globals.MAINTENANCE_PAGE, false);
        get(MAINTENANCE_CONTROL)
            .then(function (response) {
                if (!response.data.status) {
                    setMaintenance(true);
                    localStorage.setItem(Globals.MAINTENANCE_PAGE, true);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error,intl);
            });
    });

    //Survey 
    const [isSurveyOpen,setIsSurveyOpen]=useState(false)
    function getActiveSurveyStatus() {
      get(GET_ACTIVE_SURVEY_STATUS).then(function (response) {
          setIsSurveyOpen(response.data);
      }).catch(function (error) {
      });
  }
    useEffect(()=>{
      if(isAuthorized){
        getActiveSurveyStatus()
      }
    },[isAuthorized])

    
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <>
    {isAuthorized && isSurveyOpen && <InvestorSatisfactionSurvey2 isSurveyOpen={isSurveyOpen}/>}
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
            maintenance == true ?
                (<MaintenancePage/>)
                :
                (<AuthPage/>)
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
            maintenance == true ?
                (<Redirect to="/maintenance" />)
                :
                (<Redirect to="/auth/login" />)
        ) : (
          <Layout>
              {isTimeOut ? <TimeOutBox/> : null}
              <LogoutCounter additionalLimit={3} token={localStorage.getItem(Globals.TOKEN)}/>
              <HomePage userLastLocation={userLastLocation} />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
    </>
  );
});
