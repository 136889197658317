import React from 'react';
import {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, checkHttpStatus } from '../../../utils/RestUtilsWithAxios';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import LendingTable from './LendingTable';
import DownloadButtons from '../../../components/PdfAndExcelDownloadButtons';
import { GET_LENDING_DATA, 
    BORROWING_ACTIVITY_DOWNLOAD_AS_EXCEL,  
    BORROWING_ACTIVITY_DOWNLOAD_AS_PDF    } from '../../../constants/uri';
import * as DownloadUtils from '../../../utils/downloadUtils';
import Icon from '@material-ui/core/Icon';
import * as Globals from '../../../constants/globals';
import Grid from '@material-ui/core/Grid';
import {formatDecimal,printThisPage,stableSort,getSorting} from "../../../utils/ExportUtils";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    }, 
}))

/**
 * Lending
 * @param props
 * @return {*}
 * @constructor
 */
function Lending (props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [fade, setFade] = useState(false);

    const headRows = [
        { id: 'member', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.MEMBER"}) },
        { id: 'isin', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ISIN"}) },
        { id: 'extraDefinition', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ADDITIONAL_DEF"}) },
        { id: 'unit', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.UNIT"}) },
        { id: 'activityResult', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ACTIVITY_RESULT"}) },
        { id: 'activityType', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ACTIVITY_TYPE"}) },
        { id: 'contractNo', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.CONTRACT_NO"}) },
        { id: 'commission', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.COMMISSION"}) },
        { id: 'valorDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.VALOR_DATE"}) },
        { id: 'expiryDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.EXPIRY_DATE"}) },
    ];

    useEffect(()=>{
        get(GET_LENDING_DATA)
        .then(function (response) {
            setData(response.data);
            setFade(true);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })    
    },[]);

    /**
     * prepareItemArr
     * @param trArray
     * @return {*}
     */
    function prepareItemArr(trArray) {
        var setArray = [];
        for(var i=0;i<trArray.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(trArray[i].member+' / '+trArray[i].account);
            valueItem.values.push(trArray[i].isin);
            valueItem.values.push(trArray[i].extraDefinition);
            if(trArray[i].unit == null){
                valueItem.values.push('');
            }else{
                valueItem.values.push(formatDecimal(trArray[i].unit.toFixed(3)));
            }
            valueItem.values.push(trArray[i].activityResult);
            valueItem.values.push(trArray[i].activityType);
            valueItem.values.push(trArray[i].contractNo);
            if(trArray[i].commission == null){
                valueItem.values.push('');
            }else{
                valueItem.values.push(formatDecimal(Number(trArray[i].commission.replace(',','.')).toFixed(2)));
            }
            valueItem.values.push(trArray[i].valorDate);
            valueItem.values.push(trArray[i].expiryDate);
            setArray.push(valueItem);
        }
        return stableSort(setArray,getSorting("desc",9,[9]));
    }

    /**
     * downloadAsExcel
     */
    function downloadAsExcel() {
        get(BORROWING_ACTIVITY_DOWNLOAD_AS_EXCEL)
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, "Ödünç İşlemleri.xls",
                    Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     * downloadAsPdf
     */
    function downloadAsPdf() {
        get(BORROWING_ACTIVITY_DOWNLOAD_AS_PDF)
            .then(function (response) {
                DownloadUtils.downloadFileByContent(response.data, "Ödünç İşlemleri.pdf",
                    Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <Fade in={fade}>
                    {
                        data.length > 0 ?
                        <div>
                            <LendingTable lendingData={data} intl={props.intl} />
                            <DownloadButtons pdfOnClick={() => printThisPage(prepareItemArr(data),headRows,
                                'ÖDÜNÇ İŞLEMLERİ RAPORU',false)} excelOnClick={() => downloadAsExcel()} />
                        </div>
                        :
                        <Typography variant="subtitle1"> {props.intl.formatMessage({id:"LENDING.MESSAGE.NO_RECORDS"})}
                        </Typography>
                    }
                </Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(Lending)
);