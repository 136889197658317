import React from 'react';
import { useState } from 'react';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { GET_REDEMPTION_COUPON_PAYMENT_INFOS, RCP_INFOS_DOWNLOAD_AS_EXCEL, RCP_INFOS_DOWNLOAD_AS_PDF } from "../../constants/uri";
import { checkHttpStatus, get } from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/es/Paper/Paper";
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from "@material-ui/core/es/Typography/Typography";
import MyTable from '../../components/MyTable';
import { makeStyles } from "@material-ui/core/styles/index";
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import format from "date-fns/format";
import { formatDecimal } from '../../utils/ExportUtils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
}));

/**
 * RedemptionCouponPaymentInfoPage
 * @param props
 * @return {*}
 * @constructor
 */
function RedemptionCouponPaymentInfoPage(props) {

    const classes = useStyles();
    const [debtInstrumentInfos, setDebtInstrumentInfos] = useState([]);
    const [fade,setFade] = useState(false);
    
    
    React.useEffect(()=>{
        get(GET_REDEMPTION_COUPON_PAYMENT_INFOS)
            .then(function (response) {
                setDebtInstrumentInfos(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }, []);

    /**
     * formatDate
     * @param dateStr
     */
    function formatDate(dateStr) {
        var date = new Date(dateStr);
        var formatedDate = format(date, "dd/MM/yyyy");
        return formatedDate;
    }

    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for (var i = 0; i < array.length; i++) {
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].assetDescCol);
            valueItem.values.push(array[i].isinCol);
            valueItem.values.push(array[i].assetClassCol);
            valueItem.values.push(array[i].assetTypeCol);
            valueItem.values.push(array[i].processTypeCol);
            valueItem.values.push(formatDate(array[i].paymentDateCol));
            valueItem.values.push(array[i].interestDividenDistRationCol);
            setArray.push(valueItem);
        }
        return setArray;
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        get(serviceName)
            .then(function (response) {
                if (serviceName === RCP_INFOS_DOWNLOAD_AS_EXCEL)
                    DownloadUtils.downloadFileByContentExcel(response.data, "İtfa ve Kupon Ödeme Bilgileri.xls", Globals.CONTENT_TYPE_EXCEL);
                else if (serviceName === RCP_INFOS_DOWNLOAD_AS_PDF)
                    DownloadUtils.downloadFileByContent(response.data, "İtfa ve Kupon Ödeme Bilgileri.pdf", Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    const headRows = [
        { id: 'assetDescCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ASSET_DESC" }) },
        { id: 'isinCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ISIN" }) },
        { id: 'assetClassCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ASSET_CLASS" }) },
        { id: 'assetTypeCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ASSET_TYPE" }) },
        { id: 'processTypeCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_PROCESS_TYPE" }) },
        { id: 'paymentDateCol', numeric: false, date: true, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_PAYMENT_DATE" }) },
        { id: 'interestDividenDistRationCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_INTEREST_DIVIDEND_DISTRIBUTION_RATIO" }) }
    ];
    const alignment = ['left', 'left', 'left', 'left', 'left', 'right', 'right' ];

    return (
        <div>
            {
                debtInstrumentInfos.length > 0 ?
                    <Paper className={classes.paper} > 
                        <Fade in={fade}>
                            <div>
                                <MyTable expandable = {false}
                                        intl={props.intl}
                                        tableRows={prepareItemArray(debtInstrumentInfos)}
                                        headRows={headRows}
                                        alignment={alignment}
                                        defaultSort='desc'
                                        defaultSortIndex={5}
                                        dateTimeColumns={[5]}
                                        numericColumns={[6]}
                                        printOption={true}
                                        downloadAsExcel={() => download(RCP_INFOS_DOWNLOAD_AS_EXCEL)}
                                        printHeader='İTFA VE KUPON ÖDEME BİLGİLERİ'
                                />
                                <Grid container style={{marginTop:20}}>
                                    <InfoIcon size="small" style={{marginRight:8}} />
                                    <Typography display="inline" variant="subtitle1">
                                        {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DETAILED_INFO"})} 
                                    </Typography>
                                </Grid>
                            </div>
                        </Fade>
                    </Paper>
                    :
                    <Paper className={classes.paper} >
                        <Fade in={fade}>
                            <Typography variant="subtitle1"> {props.intl.
                            formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.RCPI_NOT_FOUND"})} </Typography>
                        </Fade>
                    </Paper>
            }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(RedemptionCouponPaymentInfoPage)
);