import { Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import React, {  useState } from "react";
import { SAVE_SURVEY } from "../../constants/uri";
import { post } from "../../utils/RestUtilsWithAxios";
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    '@media (max-width:760px)':{
        fontSize:"0.8rem",
        "& root.MuiRating-icon":{
            marginTop:"1rem",
            fontSize:"1rem"
        }
    },

      "& p":{
          marginBottom:0
      },
      "& #survey-header":{
          paddingBottom:"5px",
          textAlign:"center",
      },
    "& .MuiDialog-paper":{
        border:"1px solid #9D2333",
        
    },
    "& .MuiDialogTitle-root":{
        paddingLeft:0,
    },
    "& .MuiFormGroup-root":{
        marginBottom:0
    },
    "& .radio-helper-text":{
        margin:0,
        padding:0,
        color:"#dc3545",
        fontSize:"12px",
        width:"100%",
        marginBottom:"10px",
        '@media (max-width:760px)':{
            fontSize:"0.7rem",
        }

    },
    "& #survey-later":{
        color:"#646569",
    },
    "& #save-survey":{
        color:"#fff",
        backgroundColor:"#646569",
        transition:"background-color all 1s ease 0.2s",
        pointer:"cursor",
        boxShadow:"0px 3px 1px -2px rgba(0,0,0,0.4), 0px 2px 2px 0px rgba(0,0,0,0.3), 0px 1px 5px 0px rgba(0,0,0,0.3)",
        marginLeft:"10px",
        "&:hover":{
            backgroundColor:"#000",
            opacity:"0.8"
        }
    },
    "& #survey-comment":{
            fontWeight:"600",
    },
    "& .MuiRating-icon":{
        marginTop:"1rem",
        fontSize:"3rem",
        '@media (max-width:760px)':{
            fontSize:"2rem",
            marginTop:"0.4rem",
        }
    }
  },
});


export default function InvestorSatisfactionSurvey({isSurveyOpen}){
    const classes = useStyles();

    const [isSurveySend,setIsSurveySend]=useState(false)
    
    const [rating,setRating]=useState('')
    const [content,setContent]=useState('')
    const [comments,setComments]=useState('')
    
    
    const [validQ1,setValidQ1]=useState(false)
    const [validQ2,setValidQ2]=useState(false)
    const handleComments=(value)=>{
        if(value.length<=500)
        {
            setComments(value)
        }
    }

    function saveSurvey() {
        if(rating!=='' && content!==''){
            setIsSurveySend(true)
            post(SAVE_SURVEY, {
                surveyQ1Result:parseInt(rating),
                surveyQ2Result:parseInt(content),
                surveyQ3Result:comments
            })
                .then(function (response) {
                    setIsSurveySend(true)
                })
                .catch(function (error) {
                });
        }
        if(rating===''){
            setValidQ1(true)
        }
        if(content===''){
            setValidQ2(true)  
        }
       
    }
 
    function surveyLater(){
        setIsSurveySend(true)
    }
   
    
    return(
       <>
       <Dialog id="survey-dialog"
        className={classes.root}
       open={isSurveyOpen&&!isSurveySend} fullWidth maxWidth="sm">
        <div style={{
            width:"80%",
            margin:"auto",
            fontWeight:"bold",
            display:"flex",
            flexDirection:"column",
            
           
        }}>
        <DialogTitle id="survey-header">e-YATIRIMCI'yı değerlendiriniz.</DialogTitle>
        <div style={{marginBottom:"20px"}} className="kt-divider">
              <span style={{border:"none",opacity:"0.4",borderBottom:"1px solid #9D2333"}} />
        </div>
        <p>1. Kullanım Kolaylığı</p>
        
        
        <Box component="fieldset" mb={3} borderColor="transparent" marginBottom="0px">
        <Rating name="rating" value={rating} size="large" onChange={(event,value)=>{
            setRating(value)
            setValidQ1(false)
        }}/>
        <FormHelperText className="radio-helper-text">{validQ1&&"Lütfen bir değer seçiniz"}</FormHelperText>

        </Box>

        <p>2. İçerik</p>
       
        <Box component="fieldset" mb={3} borderColor="transparent" marginBottom="0px">
        <Rating name="content" value={content} size="large" onChange={(event,value)=>{
            setContent(value)
            setValidQ2(false)
        }}/>
        <FormHelperText className="radio-helper-text">{validQ2&&"Lütfen bir değer seçiniz"}</FormHelperText>

        </Box>
       
       
        <p style={{marginBottom:"10px"}}>3. Görüşleriniz</p>
        
        <TextField
        
        id="survey-comment"
        
        multiline
        rows={5}
        variant="outlined"
        onChange={(e)=>handleComments(e.target.value)}
        value={comments}
        />
        <div 
        id="survey-button-group"
        style={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"flex-end",
            marginBottom:"30px",
            marginTop:"20px",
        }}>
        <Button id="survey-later" onClick={surveyLater} >Daha sonra hatırlat</Button>
        <Button id="save-survey" onClick={saveSurvey} variant="contained">Gönder</Button>
        </div>
        </div>
       </Dialog>
       
       </>
    )
}