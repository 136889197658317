export const ESA40 = "İletilen MKK sicil bilgisi ile HPKS’de kayıtlı sicil bilgisi eşleşmemektedir. " +
    "Payı ihraç eden şirketle iletişime geçerek düzeltilmesini talep ediniz."

export const ESA41= "Pay Sahibi bilgileri HPKS’de kayıtlı bilgiler ile eşleşmemektedir. " +
    "Payı ihraç eden şirketle iletişime geçerek düzeltilmesini talep ediniz."

export const ESA42=  "İletilen MKK Pay Referans Numarası hatalıdır. Kontrol ederek tekrar deneyiniz."

export const ESA43= "İletilen pay bilgileri HPKS’de kayıtlı pay bilgileri ile eşleşmemektedir. " +
    "Kontrol ederek tekrar deneyiniz."

export const ESA44= "Pay devir için uygun değildir. Payı ihraç eden şirketle iletişime geçiniz."

export const ESA45= "Devreden bilgilerine erişilemedi."

export const ESA46= "MKS Sicil No değeri boş ise, yalnızca TCKN/YKN ile devralma iletilebilir."

export const ESA47= "İletilen MKK sicil bilgisi ile HPKS’de kayıtlı sicil bilgisi eşleşmemektedir. " +
    "Payı ihraç eden şirketle iletişime geçerek düzeltilmesini talep ediniz."

export const ESA48=  "Kimlik türü ile kimlik bilgileri uyuşmamaktadır. " +
    "Kimlik bilgilerinin kimlik türüne uygun olarak girilmesi gerekmektedir."

export const ESA25=  "İletilen MKK sicil bilgisi ile HPKS’de kayıtlı sicil bilgisi eşleşmemektedir. " +
    "Payı ihraç eden şirketle iletişime geçerek düzeltilmesini talep ediniz."

export const CODE_500= "Beklenmeyen bir hata oluştu. İlettiğiniz bilgileri kontrol ederek daha sonra " +
    "tekrar deneyiniz."

export const CODE_1111= "Beklenmeyen bir hata oluştu. İlettiğiniz bilgileri kontrol ederek " +
    "daha sonra tekrar deneyiniz."