/**
 * printThisPage
 * @param rows
 * @param headRows
 * @param mainHeader
 * @param fromMyTable
 */
export function printThisPage(rows,headRows,mainHeader,fromMyTable) {

    var w = window.screen.width-200, h = window.screen.height-200;
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    var printWindow = window.open('', '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, ' +
        'scrollbars=no, resizable=no, copyhistory=no');
    var content = '<html>' + getHead(mainHeader);
    content += '<body onafterprint="setTimeout(function() {window.close();},1000);"><div class="printed-div">' +
        '<div><table><tr><td><div class="logo-eyat-print"></div></td>' +
        '<td><h6 style="float: right;">Sayın '+localStorage.getItem("NameSurname")+'</h6></td>' +
        '<td><div class="logo-print" style="background-position: right"></div></td></tr></table></div>'+
        '<h3 style="color: #ff3f34" align="center">'+mainHeader+'</h3>'+
        prepareRows(rows,headRows,fromMyTable)+
        '</div>';
    content += '</body></html>';
    printWindow.document.write(content);
    setTimeout(function() {printWindow.print();},1000);
}

/**
 * getHead
 * @param title
 * @return {string}
 */
export function getHead(title) {
    var head = '<head>' +
        '<title></title>' +
        '<style type="text/css">'+
        '   .printed-div{\n' +
        '       display:block;\n' +
        '   }\n' +
        '   .logo-eyat-print{\n' +
        '       width:291px;\n' +
        '       height:50px;\n' +
        '       display: block;\n' +
        '       background-image: url("/media/logos/red-logo-tr-169-58.png");\n' +
        '       background-repeat: no-repeat;\n'+
        '       background-position: left;'+
        '   }\n' +
        '   .logo-print{\n' +
        '       width:291px;\n' +
        '       height:50px;\n' +
        '       display: block;\n' +
        '       background-image: url("/media/logos/MKK_logo_158_47.png");\n' +
        '       background-repeat: no-repeat;\n'+
        '       background-position: left;'+
        '   }\n' +
        '   table {\n' +
        '       font-family: arial, sans-serif;\n' +
        '       border-collapse: collapse;\n' +
        '       width: 100%;\n' +
        '   }\n' +
        '   td, th {\n' +
        '       text-align: left;\n' +
        '       padding: 8px;\n' +
        '   }\n' +
        '   tr:nth-child(even) {\n' +
        '       background-color: #dddddd;\n' +
        '       opacity: 0.5;'+
        '   }'+
        '@media print {\n' +
        '   table { page-break-inside:auto }\n' +
        '    tr    { page-break-inside:avoid; page-break-after:auto }'+
        '}'+
        '</style>'+
        '</head>';
    return head;
}

/**
 * prepareRows
 * @param rows
 * @param headRows
 * @param fromMyTable
 * @return {string}
 */
function prepareRows(rows,headRows,fromMyTable) {
    var tableContent = '<table>';
    tableContent += '<tr>';
    for(var k=0;k<headRows.length;k++){
        if(!headRows[k].clickable){
            if(headRows[k].secondLabel){
                tableContent += '<th>'+headRows[k].label+'<br/>'+headRows[k].secondLabel+'</th>';
            }else{
                tableContent += '<th>'+headRows[k].label+'</th>';
            }
        }
    }
    tableContent += '</tr>';
    for (var i=0;i<rows.length;i++){
        tableContent += '<tr>';
        for(var j=0;j<rows[i].values.length;j++){
            if(!headRows[j].clickable){
                var printValue;
                if(rows[i].values[j] == null || rows[i].values[j] === '')
                    printValue = '';
                else {
                    if(fromMyTable && headRows[j].numeric){
                        if(headRows[j].decimal) {
                            if(rows[i].values[j].toString().split('.').length === 2) {
                                printValue = formatDecimal(rows[i].values[j].toFixed(headRows[j].decimal));
                                if (!headRows[j].fixedDecimal) {
                                    var dotArr = printValue.split(',');
                                    if (dotArr.length === 2) {
                                        var nonFixed = dotArr[1];
                                        for (var z = dotArr[1].length - 1; z >= 0; z--) {
                                            if (dotArr[1].charAt(z) === '0') {
                                                nonFixed = nonFixed.substring(0, z);
                                            }else break;
                                        }
                                        printValue = dotArr[0] + ',' + nonFixed;
                                    }
                                }
                            }else{
                                printValue = formatDecimal(rows[i].values[j]);
                            }
                        }
                        else
                            printValue = formatDecimal(rows[i].values[j]);
                    }else {
                        printValue = rows[i].values[j];
                    }
                }
                tableContent += '<td>'+printValue+'</td>';
            }
        }
        tableContent += '</tr>';
    }
    tableContent += '</table>';
    return tableContent;
}

/**
 * getExtremElemValues
 * @param keys
 * @param values
 * @return {Array}
 */
function getExtremElemValues(keys, values) {
    var returnVal = [];
    keys.map((key, i) => {
        var newValues = [];
        var flag = false;
        values[i].map((val) => {
            if (newValues.length > 0) {
                newValues.map((newVal, index) => {
                    if (val.stockName === newVal.id) {
                        newValues[index].rows.push(val);
                        flag = true;
                    }
                })
            }
            if (!flag) {
                newValues.push({id: val.stockName, rows: [val]})
            }
            flag = false;
        });
        var addElem = {keyVal : key,rowVal : newValues};
        returnVal.push(addElem);
    });
    return returnVal;
}

/**
 * printExtremTableItems
 * @param keys
 * @param values
 * @param headRows
 * @param arrNo
 * @param mainHeader
 */
export function printExtremTableItems(keys, values, headRows,arrNo,mainHeader) {
    var elemArr = getExtremElemValues(keys,values);
    var w = window.screen.width-200, h = window.screen.height-200;
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    var printWindow = window.open('', '', 'toolbar=no, location=no, directories=no, status=no, ' +
        'menubar=no, scrollbars=no, resizable=no, copyhistory=no');
    var content = '<html>' + getHead(mainHeader);
    content += '<body onafterprint="setTimeout(function() {window.close();},1000)"><div class="printed-div">' +
        '<div><table><tr><td><div class="logo-eyat-print"></div></td>' +
        '<td><h6 style="float: right;">Sayın '+localStorage.getItem("NameSurname")+'</h6></td>' +
        '<td><div class="logo-print" style="background-position: right"></div></td></tr></table></div>'+
        '<h3 style="color: #ff3f34" align="center">'+mainHeader+'</h3>'+
        prepareExtremRows(elemArr,headRows,arrNo)+
        '</div>';
    content += '</body></html>';
    printWindow.document.write(content);
    setTimeout(function() {printWindow.print();},1000);
}

/**
 * prepareExtremRows
 * @param elemArr
 * @param headRows
 * @param arrNo
 * @return {string}
 */
function prepareExtremRows(elemArr,headRows,arrNo) {
    var colCount =0;
    for(var t=0;t<headRows.length;t++){
        if(!headRows[t].clickable)
            colCount++;
    }
    var tableContent = '<table>';
    for(var m=0;m<elemArr.length;m++){
        //ana başlık
        var keyValToHeader;
        if(arrNo === 1){
            keyValToHeader = panelHeaderSelector(elemArr[m].keyVal);
        }else{
            keyValToHeader = elemArr[m].keyVal;
        }
        tableContent += '<tr style="height: 20px;background-color:white;"></tr>';
        tableContent += '<tr style="background-color: white;opacity: inherit;"><td colspan="'+colCount+'">' +
            '<h3 style="color: red;">'+keyValToHeader+'</h3></td></tr>';
        for(var l=0;l<elemArr[m].rowVal.length;l++){
            tableContent += '<tr style="height: 20px;background-color:white;"></tr>';
            tableContent += '<tr style="background-color: rgba(153,225,221,0.41);opacity: inherit;">' +
                '<td colspan="'+colCount+'"><h3>'+elemArr[m].rowVal[l].id+'</h3></td></tr>';
            var trList = prepareExtremItemArray(elemArr[m].rowVal[l].rows,arrNo);
            tableContent += '<tr style="background-color: white;opacity: inherit;">';
            for(var k=0;k<headRows.length;k++){
                if(!headRows[k].clickable){
                    if(headRows[k].secondLabel){
                        tableContent += '<th>'+headRows[k].label+'<br/>'+headRows[k].secondLabel+'</th>';
                    }else{
                        tableContent += '<th>'+headRows[k].label+'</th>';
                    }
                }
            }
            tableContent += '</tr>';
            for (var i=0;i<trList.length;i++){
                tableContent += '<tr>';
                for(var j=0;j<trList[i].values.length;j++){
                    if(!headRows[j].clickable){
                        tableContent += '<td>'+trList[i].values[j]+'</td>';
                    }
                }
                tableContent += '</tr>';
            }
        }
    }
    tableContent += '</table>';
    return tableContent;
}

/**
 * prepareExtremItemArray
 * @param trArray
 * @param arrNo
 * @return {Array}
 */
function prepareExtremItemArray(trArray,arrNo) {
    if(arrNo === 1)
        return prepareSecurityBasedArr(trArray);
    if(arrNo === 2)
        return prepareNoDisposalArr(trArray);
}

/**
 * prepareSecurityBasedArr
 * @param trArray
 * @return {Array}
 */
function prepareSecurityBasedArr(trArray) {
    var setArray = [];
    for(var i=0;i<trArray.length;i++){
        var valueItem = {};
        valueItem.values = [];
        valueItem.values.push(trArray[i].account);
        valueItem.values.push(trArray[i].addDefCode);
        valueItem.values.push(trArray[i].subAccCode);
        valueItem.values.push(formatDecimal(trArray[i].count.toFixed(3)));
        trArray[i].price==="-"?valueItem.values.push("-"):valueItem.values.push(formatDecimal(trArray[i].price.toFixed(2)));
        valueItem.values.push('%'+formatDecimal((trArray[i].ratio * 100).toFixed(2)));
        valueItem.values.push(trArray[i].lastChangeDate);
        trArray[i].marketValue==="-"?valueItem.values.push("-"):valueItem.values.push(formatDecimal(trArray[i].marketValue.toFixed(2)));
        setArray.push(valueItem);
    }
    return setArray;
}

/**
 * prepareNoDisposalArr
 * @param trArray
 * @return {Array}
 */
function prepareNoDisposalArr(trArray) {
    var setArray = [];
    for(var i=0;i<trArray.length;i++){
        var valueItem = {};
        valueItem.values = [];
        valueItem.values.push(trArray[i].memberIvrCode);
        valueItem.values.push(formatDecimal(trArray[i].balance.toFixed(3)));
        valueItem.values.push(trArray[i].explanation);
        setArray.push(valueItem);
    }
    return setArray;
}

/**
 * getExpandableTableItems
 * @param valueList
 * @param colList
 * @param headRows
 * @return {string}
 */
export function getExpandableTableItems(valueList, colList, headRows){
    var colCount =0;
    for(var t=0;t<headRows.length;t++){
        if(!headRows[t].clickable)
            colCount++;
    }
    var tableContent = '';
    tableContent += '<table>';
    for(var l=0;l<valueList.length;l++){
        tableContent += '<tr style="height: 20px;background-color:white;"></tr>';
        tableContent += '<tr style="background-color: rgba(153,225,221,0.41);opacity: inherit;">' +
            '<td colspan="'+colCount+'"><h3>'+colList[l]+'</h3></td></tr>';
        tableContent += '<tr style="height: 20px;background-color:white;"></tr>';
        var trList = valueList[l];
        tableContent += '<tr style="background-color: white;opacity: inherit;">';
        for(var k=0;k<headRows.length;k++){
            if(!headRows[k].clickable){
                if(headRows[k].secondLabel){
                    tableContent += '<th>'+headRows[k].label+'<br/>'+headRows[k].secondLabel+'</th>';
                }else{
                    tableContent += '<th>'+headRows[k].label+'</th>';
                }
            }
        }
        tableContent += '</tr>';
        for (var i=0;i<trList.length;i++){
            tableContent += '<tr>';
            for(var j=0;j<trList[i].values.length;j++){
                if(!headRows[j].clickable){
                    var printValue;
                    if(trList[i].values[j] == null)
                        printValue = '';
                    else
                        printValue = trList[i].values[j];
                    tableContent += '<td>'+printValue+'</td>';
                }
            }
            tableContent += '</tr>';
        }
    }
    tableContent += '</table>';
    return tableContent;
}

/**
 * printExpandables
 * @param valueList
 * @param colList
 * @param headRows
 * @param mainHeader
 * @param filterPanel
 * @param filterPanelFunction
 */
export function printExpandables(valueList, colList, headRows,mainHeader,filterPanel,filterPanelFunction) {
    var w = window.screen.width-200, h = window.screen.height-200;
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    var printWindow = window.open('', '', 'toolbar=no, location=no, directories=no, status=no, ' +
        'menubar=no, scrollbars=no, resizable=no, copyhistory=no');
    var content = '<html>'+getHead(mainHeader);
    content += '<body onafterprint="setTimeout(function() {window.close();},1000)"><div class="printed-div">'+
        '<div><table><tr><td><div class="logo-eyat-print"></div></td>' +
        '<td><h6 style="float: right;">Sayın '+localStorage.getItem("NameSurname")+'</h6></td>' +
        '<td><div class="logo-print" style="background-position: right"></div></td></tr></table></div>'+
        '<h3 style="color: #ff3f34" align="center">'+mainHeader+'</h3>';
    if(filterPanel){
        content += filterPanelFunction();
    }
    content += getExpandableTableItems(valueList, colList, headRows)+
        '</div>';
    content += '</body></html>';
    printWindow.document.write(content);
    setTimeout(function() {printWindow.print();},1000);
}

/**
 * panelHeaderSelector
 * @param key
 * @return {string}
 */
function panelHeaderSelector(key) {
    switch(key){
        case "Fon":
            return "FON";
        case "HS":
            return "PAY SENEDİ";
        case "BYF":
            return "BORSA YATIRIM FONU";
        case "DB":
            return "DEVLET İÇ BORÇLANMA ARAÇLARI";
        case "DBA":
            return "DIŞ BORÇLANMA ARAÇLARI";
        case "VDK":
            return "DİĞER MENKUL KIYMETLER";
        case "EUS":
            return "ELEKTRONİK ÜRÜN SENEDİ";
        case "HK":
            return "HAKLAR";
        case "OST":
            return "ÖZEL SEKTÖR BORÇLANMA ARAÇLARI";
        case "VR":
            return "YAPILANDIRILMIŞ ÜRÜNLER";

        default:
            return "PORTFOLIO.PANEL.UNKNOWN";
    }
}

/**
 * formatDecimal
 * @param num
 * @return {string}
 */
export function formatDecimal(num) {
    if(num == null)
        return '';
    var toFormat = num.toString();
    var formattedNum = '';
    if(toFormat.includes('.'))
        formattedNum = toFormat.split('.')[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',' + toFormat.split('.')[1]
    else
        formattedNum = toFormat.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    return formattedNum
}

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @param dateTimeColumns
 * @param numericColumns
 * @return {number}
 */
function desc(a, b, orderBy, dateTimeColumns, numericColumns) {
    if(dateTimeColumns && dateTimeColumns.some(dc => dc == orderBy)){
        const dateIndices = [ 6, 7, 8, 9, 3, 4, 0, 1 ];
        const timeIndices = [11, 12, 14, 15]
        var r = 0;
        if(a.values[orderBy] == null && b.values[orderBy] != null)
            return 1;
        else if(a.values[orderBy] != null && b.values[orderBy] == null)
            return -1;
        else if (a.values[orderBy] == null && b.values[orderBy] == null)
            return 0;
        else{
            for(var i=0; i<dateIndices.length; i++){
                r = a.values[orderBy].charCodeAt(dateIndices[i]) - b.values[orderBy].charCodeAt(dateIndices[i]);
                if(r !== 0)
                    return r;
            }
            if(a.values[orderBy].length > 10)
                for(var i=0; i<timeIndices.length; i++){
                    r = a.values[orderBy].charCodeAt(timeIndices[i]) - b.values[orderBy].charCodeAt(timeIndices[i]);
                    if(r !== 0)
                        return r;
                }
            return r;
        }
    }
    else if (numericColumns && numericColumns.some(dc => dc == orderBy)) {
        if(orderBy === -1)
            return 0;
        if (b.values[orderBy] < a.values[orderBy]) {
            return -1;
        }
        if (b.values[orderBy] > a.values[orderBy]) {
            return 1;
        }
        return 0;
    }
    else {
        var value1 = a.values[orderBy] !== null ? a.values[orderBy] : "";
        var value2 = b.values[orderBy] !== null ? b.values[orderBy] : "";
        if(value1 == null)
            value1 = "";
        if(value2 == null)
            value2 = "";
        return value2.toString().localeCompare(value1.toString());
    }
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @param dateTimeColumns
 * @param numericColumns
 * @return {function(*=, *=): number}
 */
export function getSorting(order, orderBy, dateTimeColumns, numericColumns) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, dateTimeColumns, numericColumns) :
        (a, b) => -desc(a, b, orderBy, dateTimeColumns, numericColumns);
}

/**
 * formatDate
 * @param dateStr
 */
export function formatDate(dateStr) {
    var formatedDate =  dateStr.substring(6,8)  + '.' +  dateStr.substring(4,6) + '.' +  dateStr.substring(0,4)
    return formatedDate;
}
