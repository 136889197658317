import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if(orderBy !== 'valorDate' && orderBy !== 'expiryDate'){
        if (orderBy === 'unit' || orderBy === 'contractNo' || orderBy === 'commission') {
            if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
                return -1;
            }
            if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
                return 1;
            }
            return 0;
        } else if (orderBy === 'member') {
            var value1 = a[orderBy]  + " / " + a["account"];
            var value2 = b[orderBy]  + " / " + b["account"];
            return value2.toString().localeCompare(value1.toString());
        } else 
            return b[orderBy].toString().localeCompare(a[orderBy].toString());
    }
    else {
        if(a[orderBy] == null && b[orderBy] != null)
            return 1;
        else if(a[orderBy] != null && b[orderBy] == null)
            return -1;
        else if (a[orderBy] == null && b[orderBy] == null)
            return 0;
        else{
            const dateIndices = [ 6, 7, 8, 9, 3, 4, 0, 1 ];
            var r = 0;
            for(var i=0; i<dateIndices.length; i++){
                r = a[orderBy].charCodeAt(dateIndices[i]) - b[orderBy].charCodeAt(dateIndices[i]);
                if(r !== 0)
                    return r;
            }
            return r;
        }
    }
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 13,
        fontWeight: 600
    },
    fontBold : {
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'member', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.MEMBER"}) },
        { id: 'isin', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ISIN"}) },
        { id: 'extraDefinition', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ADDITIONAL_DEF"}) },
        { id: 'unit', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.UNIT"}) },
        { id: 'activityResult', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ACTIVITY_RESULT"}) },
        { id: 'activityType', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.ACTIVITY_TYPE"}) },
        { id: 'contractNo', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.CONTRACT_NO"}) },
        { id: 'commission', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.COMMISSION"}) },
        { id: 'valorDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.VALOR_DATE"}) },
        { id: 'expiryDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "LENDING.TABLE.EXPIRY_DATE"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                        {
                            row.secondLabel ?
                            <div>
                                {row.label}<br/>
                                <Typography classes={{subtitle1:headClasses.fontBold}}
                                            variant="subtitle1">{row.secondLabel}</Typography>
                            </div>
                            :
                            <div>{row.label}</div>
                        }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13
    }
}));
/**
 * LendingTable
 * @param props
 * @return {*}
 * @constructor
 */
export default function LendingTable (props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('expiryDate');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.lendingData;


    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    
    return (
                <div>    
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                intl={props.intl}
                            />
                            <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.name + index}
                                        >
                                            <TableCell component="th" key={"member_"+index}
                                                       id={"member_"+index}
                                                       className={classes.fontSize13}>{row.member} / {row.account}
                                                       </TableCell>
                                            <TableCell key={"isin_"+index}
                                                       id={"isin_"+index}
                                                       className={classes.fontSize13}>{row.isin}</TableCell>
                                            <TableCell key={"extraDefinition_"+index}
                                                       id={"extraDefiniton_"+index}
                                                       className={classes.fontSize13}>{row.extraDefinition}</TableCell>
                                            <TableCell align="right" key={"unit_"+index}
                                                       id={"unit_"+index} className={classes.fontSize13}>
                                                <NumberFormat decimalScale={3} fixedDecimalScale={3}
                                                              value={row.unit} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                            </TableCell>
                                            <TableCell key={"activityResult_"+index}
                                                       id={"activityResult_"+index}
                                                       className={classes.fontSize13}>{row.activityResult}</TableCell>
                                            <TableCell key={"activityType_"+index} id={"activityType_"+index}
                                                       className={classes.fontSize13}>{row.activityType}</TableCell>
                                            <TableCell align="right" key={"contractNo_"+index}
                                                       id={"contractNo_"+index}
                                                       className={classes.fontSize13}>{row.contractNo}</TableCell>
                                            <TableCell align="right" key={"commission_"+index}
                                                       id={"commission_"+index} className={classes.fontSize13}>
                                                <NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                              value={row.commission} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                            </TableCell>
                                            <TableCell align="right" key={"valorDate_"+index}
                                                       id={"valorDate_"+index}
                                                       className={classes.fontSize13}>{row.valorDate}</TableCell>
                                            <TableCell align="right" key={"expiryDate_"+index}
                                                       id={"expiryDate_"+index}
                                                       className={classes.fontSize13}>{row.expiryDate}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
    )
}