/**
 * fetchDownload
 * @param url
 * @param fileName
 */
export function fetchDownload(url,fileName){
    fetch(url, GET_STREAM())
        .then((response) =>{
            if(response.status !== 200){
                throw 'status error';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            // When no more data needs to be consumed, close the stream
                            if (done) {
                                controller.close();
                                return;
                            }
                            // Enqueue the next data chunk into our target stream
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            var url2 = window.URL.createObjectURL(blob);
            a.href = url2;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url2);
            a.remove();
        })
        .catch(err => {
            console.error(err);
        });
}

/**
 * GET_STREAM
 * @return {{method: string, headers: {Accept: string, "Content-Type": string, Authorization: string | null, "screen-path": string, channel: string}}}
 * @constructor
 */
export const GET_STREAM = () => {
    return {
        method: 'GET',
        headers: {
            'Accept': 'application/octet-stream',
            'Content-Type': 'application/octet-stream',
            'Authorization': localStorage.getItem('token'),
            'screen-path': window.location.pathname,
            'channel': 'WEB'
        }
    }
};
