import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { toAbsoluteUrl } from "../../../_metronic";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(9),
        },
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
}))

/**
 * UserGuideline
 * @param props
 * @return {*}
 * @constructor
 */
function UserGuideline(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography>e-YATIRIMCI kullanıcı kılavuzuna aşağıdan ulaşabilirsiniz.</Typography>
                <br />
                <a href={toAbsoluteUrl("/media/files/e-Yatırımcı_Kullanım_Kılavuzu.pdf")} target='_blank'>
                    <Button variant='contained' color='secondary' size="medium" style={{ width: 160 }}>
                        <Icon color="default" className='far fa-file-pdf' fontSize="small" style={{ marginRight: 5 }}
                        />
                        İNDİR
                    </Button>
                </a>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(UserGuideline)
);