import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { alertBox } from '../../components/BoxProvider';
import * as Globals from '../../constants/globals';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from "@material-ui/core/Button";
import * as URI from '../../constants/uri';
import {checkHttpStatus, post, get} from "../../utils/RestUtilsWithAxios";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
    },
    formControl: {
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(8,0),
        },
    },
    group: {
        margin: theme.spacing(1, 0),
    },
    flexButton: {
        [theme.breakpoints.up('xs')]: {
            width: 200
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        marginTop: theme.spacing(6)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
    boxTitle: {
        color: Globals.COLOR_DARK_GRAY,
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        marginLeft: 22,
        marginTop: -8,
    },
    thinBox: {
        borderColor: Globals.COLOR_DARK_GRAY,
        padding: 24,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid'
    },
}))

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function AgentshipPermission(props) {
    const { intl } = props;
    const classes = useStyles();
    const [value, setValue] = useState("");
    const [repFlag, setRepFlag] = useState("");

    useEffect(() => {
        get(URI.GET_REP_FLAG, props.intl).then(function (response) {
            setValue(response.data.repFlag);
            setRepFlag(response.data.repFlag);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }, []);

    /**
     *
     * @param event
     */
    function handleRadioChange(event) {
        setValue(event.target.value);
    }

    /**
     * setValue
     */
    function cancelRepFlagValue() {
        setValue(repFlag);
    }

    /**
     * saveRepFlagValue
     */
    function saveRepFlagValue() {
        post(URI.SET_REP_FLAG, { repFlag: value }, props.intl).then(function (response) {
            if (response.data.status) {
                setRepFlag(value);
                alertBox("", intl.formatMessage({ id: "COMMON.ALERT.MSG_SUCCESS_SAVE" }), Globals.ALERT_SUCCESS, intl);
            } else{
                setValue(repFlag);
                alertBox("", response.data.exception, Globals.ALERT_ERROR, intl);
            }
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container alignItems='center' justify='space-around' className={classes.formControl}>
                    <Grid style={{maxWidth:450}}>
                        <h3>Sayın {localStorage.getItem('NameSurname')}</h3>
                        <Typography variant="subtitle1">Elektronik Genel Kurul Sisteminde temsilci olma durumunuzu bu
                            sayfadan yönetebilirsiniz. Yapılacak işlem öncesinde genel kurula katılım için vekil tayin
                            edecek yatırımcının ilgili vekilini Elektronik Genel Kurul Sisteminde ya da Yatırım
                            Kuruluşu aracığı ile Kuruluşumuza bildirmesi gerekmektedir. Genel kurula vekaleten
                            katılacak kişinin bu ekrandan "Elektronik Genel Kurul Sisteminde Temsilci olmak istiyorum."
                            tercihini yaparak "Kaydet" fonksiyonu ile iletmelidir.</Typography>
                        
                        <Divider className={classes.divider} />
                        <Grid item style={{position:'relative'}}>
                            <div className={classes.boxTitle}>Temsilcilik Durumu</div>
                            <div className={classes.thinBox}>
                                <FormControl component="fieldset" variant='outlined'>
                                    <RadioGroup
                                        
                                        name="pledgeSelect"
                                        value={value}
                                        onChange={handleRadioChange}
                                    >
                                        <FormControlLabel value="0" control={<Radio color="secondary" />} label="
                                        Elektronik Genel Kurul Sisteminde Temsilci olmak istiyorum." value="Y" />
                                        <FormControlLabel value="1" control={<Radio color="secondary" />} label="
                                        Elektronik Genel Kurul Sisteminde Temsilci olmak istemiyorum." value="N" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid container justify="flex-end">
                            <Grid item className={classes.flexButton}>
                                <Button variant="contained" color='primary' size='large' fullWidth
                                        onClick={() => saveRepFlagValue()} >
                                    Kaydet
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
export default injectIntl(
    connect(
        null,
    )(AgentshipPermission)
);