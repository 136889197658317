import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NumberFormat from 'react-number-format';
import { Link, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if (orderBy === 'count' || orderBy === 'price' || orderBy === 'marketValue' || orderBy === 'ratio') {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    } else 
        return b[orderBy].toString().localeCompare(a[orderBy].toString());
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        ['@media (max-width:1024px)']: {
            fontSize: 11
        },
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'accNo',     numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.MEMBER"}) },
        { id: 'mbrAccNo',   numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ACCOUNT_NAME"}) },
        { id: 'stockClass',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.CLASS"}), style:{maxWidth:80} },
        { id: 'exchangeCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.EXCHANGE_CODE"}) },
        { id: 'stockName',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.STOCK_NAME"}) },
        { id: 'addDefCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ACCOUNT_DEF"}) },
        { id: 'subAccCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.SUB_ACCOUNT"}) },
        { id: 'count',      numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.COUNT"}) },
        { id: 'price',      numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.PRICE"}) },
        { id: 'marketValue', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.MARKET_VALUE"}) },
        { id: 'ratio', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ALLOCATION"}) },
        { id: 'xmarketDesc', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.XMARKET_DESC"}) },
        { id: 'info', numeric: false, disablePadding: false, label: "Detay" },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13,
        ['@media (max-width:1024px)']: {
            fontSize: 11
        },
    }
}));
/**
 * GenericPortfolio
 * @param props
 * @return {*}
 * @constructor
 */
export default function GenericPortfolio (props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('accNo');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.securities.accountBalanceInfos;
    const [openInfo,setOpenInfo] = React.useState(false);
    const [balanceInfoSelected,setBalanceInfoSelected] = React.useState();
    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    
    return (   
                <div>
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                intl={props.intl}
                            />
                            <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.name + index}
                                        >
                                            <TableCell component="th" key={"accNo"+index} id={"accNo_"+index}
                                                       className={classes.fontSize13}> {row.accNo} </TableCell>
                                            <TableCell component="th" key={"account_"+index} id={"account_"+index}
                                                       className={classes.fontSize13}> {row.mbrAccNo} </TableCell>
                                            <TableCell component="th" key={"stockClass_"+index}
                                                       id={"stockClass_"+index}
                                                       className={classes.fontSize13}> {row.stockClass} </TableCell>
                                            <TableCell component="th" key={"exchangeCode_"+index}
                                                       id={"exchangeCode_"+index}
                                                       className={classes.fontSize13}> {row.exchangeCode} </TableCell>
                                            <TableCell component="th" key={"stockName_"+index}
                                                       id={"stockName_"+index}
                                                       className={classes.fontSize13}><a
                                                href={row.stockUrl} target="_blank"> {row.stockName} - {row.isin} </a></TableCell>
                                            <TableCell component="th" key={"addDefCode_"+index}
                                                       id={"addDefCode_"+index}
                                                       className={classes.fontSize13}> {row.addDefCode} </TableCell>
                                            <TableCell component="th" key={"subAccCode_"+index}
                                                       id={"subAccCode_"+index}
                                                       className={classes.fontSize13}> {row.subAccCode} </TableCell>
                                            <TableCell align="right"  key={"count_"+index}
                                                       id={"count_"+index} className={classes.fontSize13}>
                                                <NumberFormat decimalScale={props.roundedNumbers ? 0 : 3}
                                                              fixedDecimalScale={props.roundedNumbers ? 0 : 3}
                                                              value={row.count} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                            </TableCell>
                                            <TableCell align="right"  key={"price_"+index} id={"price_"+index}
                                                       className={classes.fontSize13}>
                                                <NumberFormat decimalScale={2} fixedDecimalScale={2} value={row.price}
                                                              decimalSeparator=',' thousandSeparator='.'
                                                              displayType='text' />
                                            </TableCell>
                                            <TableCell align="right"  key={"marketValue_"+index}
                                                       id={"marketValue_"+index} className={classes.fontSize13}>
                                                <NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                              value={row.marketValue} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                            </TableCell>
                                            <TableCell align="right"  key={"ratio_"+index} id={"ratio_"+index}
                                                       className={classes.fontSize13}>
                                                <NumberFormat prefix={'%'} decimalScale={2} fixedDecimalScale={2}
                                                              value={row.ratio * 100} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                            </TableCell>
                                            <TableCell component="th" key={"xmarketDesc"+index} id={"xmarketDesc_"+index}
                                                       className={classes.fontSize13}> {row.xmarketDesc} </TableCell>

                                            <TableCell>
                                                {row?.stockClass == 'HS' &&
                                                <Button color="primary" onClick={() => {setOpenInfo(true);setBalanceInfoSelected(row)}} style={{marginLeft:'auto'}}>
                                                     <InfoIcon fontSize='medium' style={{marginRight:8}} />
                                                </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                    <div>
                        <Dialog
                            open={openInfo}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <div class="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr className="fw-bold fs-6 text-gray-800">
                                            <th>Kıymet Adı</th>
                                            <th>Kıymet Türü</th>
                                            <th>Sermaye</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{balanceInfoSelected?.stockName}</td>
                                            <td>{balanceInfoSelected?.secTypeDesc}</td>
                                            <td><NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                              value={balanceInfoSelected?.capital} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' /></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br></br>
                                        <div><DialogContentText> {props.intl.formatMessage({id: "PORTFOLIO.BALANCEDETAIL.TEXT1"})} </DialogContentText>
                                             <DialogContentText> {props.intl.formatMessage({id: "PORTFOLIO.BALANCEDETAIL.TEXT2"})} </DialogContentText>
                                        </div>
                           </DialogContent>
                            <DialogActions style={{padding:16}}>
                                <Button onClick={() => setOpenInfo(false)} color="primary" autoFocus>
                                    {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
    )
}