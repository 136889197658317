import React from 'react';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {GET_DIVIDEND_TRANSACTION_REPORTS,
        DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL} from "../../constants/uri";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import MyTable from '../../components/MyTable';
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/es/Typography/Typography";
import FilterPanel from './FilterPanel';
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import { alertBox } from '../../components/BoxProvider';
import { stableSort,getSorting} from '../../utils/ExportUtils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

/**
 * DividendReport
 * @param props
 * @return {*}
 * @constructor
 */
function DividendTransactions(props) {
    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for(var i=0;i<array.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].todemeTarihi);
            valueItem.values.push(array[i].isin + " - " + array[i].isinDesc);
            valueItem.values.push(array[i].exchCode);
            valueItem.values.push(array[i].addDefCode);
            valueItem.values.push(array[i].ykTitle);
            valueItem.values.push(array[i].mulkMbrAccNo);  
            valueItem.values.push(array[i].tkullanilanBakiye);  
            valueItem.values.push(array[i].tbrutOran);
            valueItem.values.push(array[i].tnetOran);
            valueItem.values.push(array[i].tbrutTemettu);  
            valueItem.values.push(array[i].tnetTemettu);  
            setArray.push(valueItem);
        }
        return  stableSort(setArray, getSorting("asc", 0));;
    }
    
    const headRows = [
        { id: 'tOdemeTarihi', numeric: false, date: true, disablePadding: false, label: 'Ödeme Tarihi' },
        { id: 'isinAndIsınDesc', numeric: false, disablePadding: false, label: 'Kıymet Adı' },
        { id: 'exchCode',  numeric: false, disablePadding: false, label: 'Menkul Kıymet Kodu'},
        { id: 'addDefCode',  numeric: false, disablePadding: false, label: 'Ek Tanım ' },
        { id: 'ykTitle',  numeric: false, disablePadding: false, label: 'Üye' },
        { id: 'mulkMbrAccNo',  numeric: false, disablePadding: false, label: 'Hesap Numarası' },
        { id: 'tkullanilanBakiye',  numeric: false, disablePadding: false, label: 'Kullanılan Bakiye' },
        { id: 'tBrutOran',  numeric: false, disablePadding: false, label: 'Brüt Oran'},
        { id: 'tNetOran',  numeric: false, disablePadding: false, label: 'Net Oran'},
        { id: 'tBrutTemettu',  numeric: false, disablePadding: false, label: 'Brüt Temettü Miktarı' },
        { id: 'tNetTemettu',  numeric: false, disablePadding: false, label: 'Net Temettü Miktarı'   }      
    ];

    const alignment = ['left', 'left','right','right'];

    /**
     * filterReports
     * @param selectedBeginDate
     * @param selectedEndDate
     * @param selectedIhrMember
     */
    function filterReports(selectedBeginDate,selectedEndDate,selectedIhrMember) {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.substring(4,6)+'-'+selectedEndDate.
        substring(6,8));
        var beginDate = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));
        
        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, props.intl);
            return;
        }
        post(GET_DIVIDEND_TRANSACTION_REPORTS,{beginDate:selectedBeginDate,endDate:selectedEndDate})
            .then(function (response) {
                setReportList([]);
                setReportList(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        post(serviceName, {dividendTransactionResponseList: reportList})
            .then(function (response) {
                if (serviceName === DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL)
                    DownloadUtils.downloadFileByContentExcel(response.data, "Temettü İşlemleri.xls", Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }



    const [reportList,setReportList] = useState([]);
    const [fade,setFade] = useState(false);
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Paper className={classes.paper} >
            <FilterPanel initOnOpen={true} filterReports={filterReports} ihrMembers={props.ihrMembers}/>
                <Fade in={fade}>
                    {
                        reportList.length > 0 ?
                            <div> 
                                <MyTable
                             expandable = {false}
                             intl={props.intl}
                             tableRows={prepareItemArray(reportList)}
                             headRows={headRows}
                             alignment = {alignment}
                             defaultSort='asc'
                             defaultSortIndex={0}
                             dateTimeColumns={[0]}
                             printOption={true}
                             downloadAsExcel={() => download(DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL)}
                             printHeader='TEMETTÜ BİLGİLERİ RAPORU'
                                />
                            </div>
                            :
                            <Typography variant="subtitle1">
                                {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DATA_NOT_FOUND"})}
                            </Typography> 
                    }
                </Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(DividendTransactions)
);