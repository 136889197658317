import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import PrivacyStatement from "./PrivacyStatement";
import Link from "@material-ui/core/Link";

/**
 * Created by gaslan on 3.4.2020
 */
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {openPrivacyStatement: false};
    }

    handleClickOpen() {
        this.setState({
            openPrivacyStatement: true
        });
    }

    /**
     *
     * @return {*}
     */
  render() {

    const today = new Date().getFullYear();

    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
      >
      <PrivacyStatement openPrivacyStatement={this.state.openPrivacyStatement} closePrivacyStatement={()=>this.setState({openPrivacyStatement: false })}/>
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__copyright">
              &nbsp;&copy;&nbsp;Copyright&nbsp;&nbsp;
            {today.toString()}&nbsp;&nbsp;
            <a
              href="https://www.mkk.com.tr"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-link"
            >
              MKK. Bütün hakları saklıdır.
            </a>
          </div>
          <div className="kt-footer__menu">
            <Link
                onClick={() => this.handleClickOpen()}
                component="button"
                //href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="kt-footer__menu-link kt-link"
            >
                Çekince Bildirimi
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

/**
 *
 * @param store
 * @return {{fluid: boolean, footerClasses: *, footerContainerClasses: *}}
 */
const mapStateToProps = store => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
