import React from 'react';
import {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {post, get, checkHttpStatus} from '../../utils/RestUtilsWithAxios';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import {
    GET_CONFIG_PARAMS, SAVE_CONFIG_PARAM, DELETE_CONFIG_PARAM
} from '../../constants/uri';
import Button from "@material-ui/core/Button";
import {Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import {alertBox} from "../../components/BoxProvider";
import * as Globals from "../../constants/globals";
import ParameterTable from "./partials/ParameterTable";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width: 200
        },
    },
    whiteText: {
        color: 'white'
    }
}))

function ParameterDefinition(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [fade, setFade] = useState(false);
    const [addParameter, setAddParameter] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [deleteControl, setDeleteControl] = useState(false);
    const [updateControl, setUpdateControl] = useState(false);
    const [saveControl, setSaveControl] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    function getParameters() {
        post(GET_CONFIG_PARAMS, {key: searchKey})
            .then(function (response) {
                setFade(true);
                setData(response.data);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            });
    }

    function saveParameter() {
        post(SAVE_CONFIG_PARAM, {key: key, value: value, id: selectedItem != null ? selectedItem.id : null})
            .then(function (response) {
                if (response.data.status) {
                    clear();
                    getParameters();
                    alertBox("", props.intl.formatMessage({id: "COMMON.ALERT.MSG_SUCCESS_SAVE"}), Globals.ALERT_SUCCESS, props.intl);
                } else {
                    alertBox("", response.data.exception, Globals.ALERT_ERROR, props.intl);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            });
    }

    function deleteParameter() {
        post(DELETE_CONFIG_PARAM, {key: selectedItem.key, value: selectedItem.value, id: selectedItem.id})
            .then(function (response) {
                if (response.data.status) {
                    clear();
                    getParameters();
                    alertBox("", props.intl.formatMessage({id: "COMMON.ALERT.MSG_SUCCESS_SAVE"}), Globals.ALERT_SUCCESS, props.intl);
                } else {
                    alertBox("", response.data.exception, Globals.ALERT_ERROR, props.intl);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            });
    }

    const handleChange = event => {
        setSearchKey(event.target.value.toUpperCase());
    };

    useEffect(() => {
        if (selectedItem != null) {
            setKey(selectedItem.key);
            setValue(selectedItem.value);
        }
    }, [updateControl]);

    function clear() {
        setKey("");
        setValue("");
        setSelectedItem({});
        setAddParameter(false);
        setDeleteControl(false);
        setUpdateControl(false);
        setSaveControl(false);
    }

    return (
        <div className={classes.root}>
            <div>
                <Dialog
                    open={deleteControl}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    style={{textAlign: "left"}}
                >
                    <DialogTitle
                        id="form-dialog-title">{props.intl.formatMessage({id: "PARAMETER_DEFINITION.DIALOG.TITLE"})}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            {props.intl.formatMessage({id: "PARAMETER_DEFINITION.DIALOG.WARNING"})}
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button color="primary"
                                onClick={() => {
                                    clear();
                                }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_NO"})}
                        </Button>
                        <Button
                            color="primary" variant="contained"
                            onClick={() => {
                                deleteParameter()
                            }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_YES"})}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={addParameter || updateControl}
                    fullWidth
                    maxWidth={'md'}
                    aria-labelledby="form-dialog-title"
                    style={{textAlign: "left"}}
                >
                    <DialogTitle
                        id="form-dialog-title">{props.intl.formatMessage({id: "PARAMETER_DEFINITION.DIALOG.TITLE"})}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <TextField
                                value={key}
                                label="Key"
                                margin="normal"
                                className="kt-width-full"
                                name="key"
                                variant="outlined"
                                onChange={(event) => setKey(event.target.value.toUpperCase())}
                            />
                            <TextField
                                value={value}
                                id="value"
                                type="text"
                                label="Value"
                                margin="normal"
                                className="kt-width-full"
                                name="value"
                                variant="outlined"
                                multiline
                                rows={6}
                                rowsMax={10}
                                onChange={(event) => setValue(event.target.value)}
                                inputProps={{
                                    maxLength: 1000
                                }}
                                InputLabelProps={{
                                    shrink: value
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button color="primary"
                                onClick={() => {
                                    clear();
                                }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_CANCEL"})}
                        </Button>
                        <Button
                            color="primary" variant="contained"
                            onClick={() => {
                                setSaveControl(true);
                            }}
                        >
                            {props.intl.formatMessage({id: "BTN_SAVE"})}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={saveControl}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    style={{textAlign: "left"}}
                >
                    <DialogTitle
                        id="form-dialog-title">{props.intl.formatMessage({id: "PARAMETER_DEFINITION.DIALOG.TITLE"})}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            {props.intl.formatMessage({id: "PARAMETER_DEFINITION.DIALOG.SAVE_WARNING"})}
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{padding: 16}}>
                        <Button color="primary"
                                onClick={() => {
                                    setSaveControl(false)
                                }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_NO"})}
                        </Button>
                        <Button
                            color="primary" variant="contained"
                            onClick={() => {
                                saveParameter()
                            }}
                        >
                            {props.intl.formatMessage({id: "COMMON.BUTTON_YES"})}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                value={searchKey}
                                label="Key"
                                margin="normal"
                                className="kt-width-full"
                                name="key"
                                variant="outlined"
                                onChange={(event) => handleChange(event)}
                                InputProps={{
                                    endAdornment: <Button variant='contained' color='secondary'
                                                          onClick={() => getParameters()}>
                                        <FormattedMessage id="ADMIN_AUTHORIZATION.SEARCH_BUTTON"/>
                                    </Button>
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Fade in={true} timeout={100}>
                    {
                        data.length > 0 ?
                            <div>
                                <ParameterTable value={data} intl={props.intl} setDeleteControl={setDeleteControl}
                                                setUpdateControl={setUpdateControl} setSelectedItem={setSelectedItem}/>
                            </div>
                            :
                            <Typography style={{marginTop:24}} variant="subtitle1">{props.intl.formatMessage({id: "PARAMETER_DEFINITION.EMPTY_MESSAGE"})}</Typography>
                    }
                </Fade>
                <div style={{textAlign: 'end', paddingTop: 16}}>
                    <Button classes={{label: classes.whiteText}} type='submit' size='large' variant="contained"
                            color='primary' fullWidth className={[classes.showButton]}
                            onClick={() => setAddParameter(true)}>
                        {props.intl.formatMessage({id: "PARAMETER_DEFINITION.ADD_BUTTON"})}
                    </Button>
                </div>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(ParameterDefinition)
);