import React from 'react';
import { useState, useEffect } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from "@material-ui/core/Button";
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
 import * as Globals from '../../constants/globals';
import { alertBox } from '../../components/BoxProvider';

const useStyles = makeStyles(theme => ({
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
}));

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function FilterPanel(props) {
    
    const classes = useStyles();
    React.useEffect(()=>{
        if(props.initOnOpen){
            props.filterReports(selectedBeginDate,selectedEndDate,selectedIhrMember);
        }
    },[]);

    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    /**
     * beginInit
     * @return {string}
     */
    function beginInit(){
        var date = new Date();
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
            (formatMonthOrDay(date.getDate()-1)).toString();
    }

    /**
     * endInit
     * @return {string}
     */
    function endInit() {
        var date = new Date();
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
            (formatMonthOrDay(date.getDate()-1)).toString();
    }

    useEffect(() => {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.substring(4,6)+'-'+selectedEndDate.
        substring(6,8));
        var beginDate = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));
 
        if (endDate.toDateString() == new Date().toDateString()) {
         alertBox("", 'Bitiş Tarihi bugün seçilemez', Globals.ALERT_ERROR, props.intl);
         return;
         }
    

        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, props.intl);
            return;
        }

      });

    const [selectedBeginDate,setSelectedBeginDate] = useState(beginInit());
    const [selectedEndDate,setSelectedEndDate] = useState(endInit());
    const [selectedIhrMember,setSelectedIhrMember] = useState('');

    return(
        <div>
        <Hidden xsDown>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h5">Tarih Aralığı</Typography>
                </Grid>
            
            </Grid>
        </Hidden>
        <Hidden smUp>
            <Typography variant="h5">Tarih Aralığı</Typography>
        </Hidden>
        <Grid container style={{marginBottom:24}}>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={6} sm={3}>
                    <MaterialUIDatePicker containerId="mui-pickers-date"
                                          setContainerSelectedDate={setSelectedBeginDate}
                                          dateString={selectedBeginDate}/>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MaterialUIDatePicker containerId="mui-pickers-date2"
                                          setContainerSelectedDate={setSelectedEndDate}
                                          dateString={selectedEndDate} 
                                          maxDateMessage="İşlem Bitiş Tarihinden İleri Tarih Seçilemez"
                                          disableFuture={true}/>
                </Grid>
               
            </Grid>
          
        </Grid>

        <Grid container justify="flex-end" style={{marginBottom:24}}>
            <Button variant="contained" color='primary' size='large' fullWidth className={classes.showButton}
                    onClick={()=>props.filterReports(selectedBeginDate,selectedEndDate,selectedIhrMember)}>
                {props.intl.formatMessage({id: "COMMON.BUTTON_SHOW"})}
            </Button>
        </Grid>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(FilterPanel)
);