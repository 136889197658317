import swal from 'sweetalert'
import './swal.css'


/**
 *
 * @param _title
 * @param _msg
 * @param _type
 * @param intl
 */
export function alertBox(_title, _msg, _type, intl) {
    swal({
        title:                  _title,
        text:                   _msg,
        icon:                   _type,
        button:                 intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"}),
        className:              'swal-modal'
    });
};


