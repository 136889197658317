import React, { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  GET_ABS_SETTINGS, GET_ANNOUNCEMENT, GET_REPORT_SUBMISSION,
  UPDATE_NOTIFICATION_PREFERENCES,
} from "../../constants/uri";
import { checkHttpStatus, get, post } from "../../utils/RestUtilsWithAxios";
import { alertBox } from "../../components/BoxProvider";
import * as Globals from "../../constants/globals";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import AnnouncementSubscription from "./partials/AnnouncementSubscription";
import ReportSubmissionSystem from "./partials/ReportSubmissionSystem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginBottom: theme.spacing(2),
    margin: "auto",
    padding: 30,
    paddingTop: 20,
  },
  link: {
    color: "red",
  },
  formControl: {
    margin: theme.spacing(3, 0),
  },
  group: {
    display: "flex",
  },
  radio: {
    width: "100%",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#aa332340",
  },
  showButton: {
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },
  },
  whiteText: {
    color: "white",
  },
}));

/**
 * YBS
 * @param props
 * @return {*}
 * @constructor
 */
function NotificationPreferences(props) {
  const { intl } = props;
  const classes = useStyles();
  const [saveControl, setSaveControl] = React.useState(false);
  const [sms, setSms] = React.useState(false);
  const [eposta, setEposta] = React.useState(false);
  const [outputTransaction, setOutputTransaction] = React.useState(false);    
  const [allNotification, setAllNotification] = React.useState(false);
  const [epostaPeriod, setEpostaPeriod] = useState("");
  const [update, setUpdate] = React.useState(true);
  const [fade, setFade] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [
    announcementSubscriptionRows,
    setAnnouncementSubscriptionRows,
  ] = React.useState([]);
  const [reportSubmissionRows, setReportSubmissionRows] = React.useState([]);

  /**
   * enableLoading - setLoading(true)
   */
  const enableLoading = () => {
    setLoading(true);
  };
  /**
   * disableLoading - setLoading(false)
   */
  const disableLoading = () => {
    setLoading(false);
  };

  function getABS(){
    get(GET_ABS_SETTINGS)
        .then(function(response) {
          setSms(response.data.sms);
          setEposta(response.data.email);
          setEpostaPeriod(response.data.emailPeriodCode);
          setAllNotification(response.data.all);
          setUpdate(false);
          setFade(response.data.email);
        })
        .catch(function(error) {
          checkHttpStatus(error, props.intl);
        });
  }

  React.useEffect(() => {
    if(update){
      getABS();

      get(GET_ANNOUNCEMENT)
          .then(function(response) {
            setAnnouncementSubscriptionRows(response.data);
            setUpdate(false);
          })
          .catch(function(error) {
            checkHttpStatus(error, props.intl);
          });

      get(GET_REPORT_SUBMISSION)
          .then(function(response) {
            setReportSubmissionRows(response.data);
            setUpdate(false);
          })
          .catch(function(error) {
            checkHttpStatus(error, props.intl);
          });
    }
  }, [update]);

  useEffect(() => {
    if(eposta || sms){
      setOutputTransaction(true)
    }else {
      setOutputTransaction(false)
      setAllNotification(false);
    }
  },[eposta,sms])

  /**
   * handleEpostaCheckBox
   */
  function handleEpostaCheckBox() {
    setEposta(!eposta);
    setFade(!eposta);
    if (!eposta) setEpostaPeriod("INSTANT");
    else setEpostaPeriod("");
  }

  /**
   * handleSmsCheckBox
   */
  function handleSmsCheckBox() {
    setSms(!sms);
   }

  /**
   * handleAllNotificationCheckBox
   */
  function handleAllNotificationCheckBox() {
    setAllNotification(!allNotification);
  }

  /**
   * handleRadioChange
   * @param event
   */
  function handleRadioChange(event) {
    setEpostaPeriod(event.target.value);
  }

  /**
   * saveNotificationPreference
   */
  function saveNotificationPreference() {
    if (!eposta && !sms) setSaveControl(true);
    else updateAbsSettings();
  }

  /**
   * updateAbsSettings
   */
  function updateAbsSettings() {
    enableLoading();
    post(
      UPDATE_NOTIFICATION_PREFERENCES,
      {
        ecasSettingsInput: {
          chkEmail: eposta,
          chkSms: sms,
          chkAll: allNotification,
          emailPeriodCode: epostaPeriod,
        },
        announcementInfos: announcementSubscriptionRows,
        reportSubmissionInfos: reportSubmissionRows,
      },
      props.intl
    )
      .then(function(response) {
        if (response.data.status) {
          setUpdate(true);
          alertBox(
            "",
            intl.formatMessage({ id: "COMMON.ALERT.MSG_SUCCESS_SAVE" }),
            Globals.ALERT_SUCCESS,
            intl
          );
        } else {
          alertBox("", response.data.exception, Globals.ALERT_ERROR, intl);
          getABS();
        }
        disableLoading();
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
        disableLoading();
      });
    setSaveControl(false);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div>
          <Typography style={{ color: "black" }} variant="h6" id="tableTitle">
            <b> YBS- Yatırımcı Bildirim Servisi</b>
          </Typography>
          <Typography variant="body1">
            YBS, Merkezi Kayıt Kuruluşu'nun yatırımcı bilgilendirme hizmetleri
            kapsamında devreye aldığı sistemdir.
          </Typography>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <Grid container direction="column">
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: "red" }}>
                Bildirim Kanalı
              </Typography>
              <br />
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={eposta}
                    onChange={handleEpostaCheckBox}
                    value={eposta}
                    color="secondary"
                  />
                }
                label={props.intl.formatMessage({
                  id: "NOTIFICATION_PREFERENCE.CHECKBOX_EMAIL_MSG",
                })}
              ></FormControlLabel>
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={sms}
                    onChange={handleSmsCheckBox}
                    value={sms}
                    color="secondary"
                  />
                }
                label={props.intl.formatMessage({
                  id: "NOTIFICATION_PREFERENCE.CHECKBOX_SMS_MSG",
                })}
              ></FormControlLabel>
            </Grid>
            <Grid item xs={12}>
              <Fade in={true}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <Typography variant="body1" style={{ color: "red" }}>
                    Bildirim Sıklığı
                  </Typography>
                  <br />

                  <RadioGroup
                    row
                    name="epostaPeriodSelect"
                    className={classes.group}
                    value={epostaPeriod}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      disabled={!fade}
                      value="0"
                      control={<Radio color="secondary" />}
                      label="Anlık"
                      value="INSTANT"
                    />
                    <FormControlLabel
                      disabled={!fade}
                      value="1"
                      control={<Radio color="secondary" />}
                      label="Günlük"
                      value="DAILY"
                    />
                    <FormControlLabel
                      disabled={!fade}
                      value="2"
                      control={<Radio color="secondary" />}
                      label="Haftalık"
                      value="WEEKLY"
                    />

                  
                  </RadioGroup>
                </FormControl>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: "red" }}>
                Bildirim Türü
              </Typography>
              <br />

              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={outputTransaction}
                    value={outputTransaction}
                    color="secondary"
                    readOnly
                  />
                }
                label={props.intl.formatMessage({
                  id:
                    "NOTIFICATION_PREFERENCE.OUTPUT_TRANSACTION_NOTIFICATION_MSG",
                })}
              ></FormControlLabel>

              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={allNotification}
                    onChange={handleAllNotificationCheckBox}
                    value={allNotification}
                    color="secondary"
                  />
                }
                label={props.intl.formatMessage({
                  id:
                    "NOTIFICATION_PREFERENCE.INPUT_TRANSACTION_NOTIFICATION_MSG",
                })}
              ></FormControlLabel>
            </Grid>
          </Grid>

          <InfoIcon size="small"  />
                    <Typography
                      display="inline"
                      variant="subtitle1"
                      style={{ marginLeft: 4 }}
                    >
                    Hesabınızın bulunduğu kurumdan başka bir kuruma 
                    rehin/teminat verecekseniz,
                    yatırımcı bildirim sistemine üye olmanız, 
                    SPK kararıyla zorunlu tutulmuştur.
                    </Typography>
           
          <Divider className={classes.divider} />
          <Typography style={{ color: "black" }} variant="h6" id="tableTitle">
            <b>Genel Kurul Bildirim Servisi </b>
          </Typography>
          <Typography variant="body1">
            Genel Kurul çağrı bildirimleri bu hizmetle sunulmaktadır
          </Typography>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <Grid item xs={12}>
            <AnnouncementSubscription
              announcementSubscriptionRows={announcementSubscriptionRows}
              setAnnouncementSubscriptionRows={setAnnouncementSubscriptionRows}
            />
          </Grid>
          
          <Divider className={classes.divider} />
          <Typography style={{ color: "black" }} variant="h6" id="tableTitle">
            <b>Aylık Rapor Gönderim Servisi </b>
          </Typography>
          <Typography variant="body1">
            Portföye ilişkin raporların aylık olarak gönderildiği bilgilendirme
            hizmetidir.
          </Typography>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
         
            <Typography variant="body1" style={{ color: "red" }}>
              Rapor Tercihi
            </Typography>
            <Grid item xs={12}>
            <ReportSubmissionSystem
              reportSubmissionRows={reportSubmissionRows}
              setReportSubmissionRows={setReportSubmissionRows}
            />
          </Grid>
          <InfoIcon size="small"    style={{ marginLeft: 10 }}/>
          <Typography
            display="inline"
            variant="subtitle1"
            style={{ marginLeft: 2 }}
          >
            Tercih edilen raporlar tanımlama yapılan tarihi izleyen ayın 8'ine
            kadar e-posta adresinize gönderilmektedir..
          </Typography>
          <div style={{ textAlign: "end", paddingTop: 16 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
              onClick={() => saveNotificationPreference()}
              classes={{ label: classes.whiteText }}
              className={[
                classes.showButton,
                `btn ${clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                })}`,
              ]}
            >
              {props.intl.formatMessage({ id: "BTN_SAVE" })}
            </Button>
          </div>
          <Dialog
            open={saveControl}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            style={{ textAlign: "left" }}
          >
            <DialogTitle id="form-dialog-title">
              {props.intl.formatMessage({
                id: "NOTIFICATION_PREFERENCE.MODAL.END_ABS_SUBSCRIPTION_TITLE",
              })}
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1">
                {props.intl.formatMessage({
                  id:
                    "NOTIFICATION_PREFERENCE.MODAL.END_ABS_SUBSCRIPTION_CONTENT",
                })}
              </Typography>
            </DialogContent>
            <DialogActions style={{ padding: 16 }}>
              <Button
                color="primary"
                onClick={() => {
                  setSaveControl(false);
                }}
              >
                {props.intl.formatMessage({ id: "COMMON.BUTTON_CANCEL" })}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  updateAbsSettings();
                }}
              >
                {props.intl.formatMessage({ id: "COMMON.BUTTON_ACCEPT" })}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </div>
  );
}

/**
 * injectIntl
 */
export default injectIntl(NotificationPreferences);
