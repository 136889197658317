import jwtDecode            from 'jwt-decode';

/**
 * getToken
 * @return {*}
 */
export function getToken() {
    try{
        return jwtDecode(localStorage.getItem("token"));
    } catch(err) {
        return null;
    }
}

/**
 * checkAuthorization
 * @return {boolean}
 */
export function checkAuthorization() {
    var status = false;
    try {
        if (getToken() && getToken().userInfo)
            if(getToken().userInfo.loginStatus === "preLogin")
                status = false;
            else if (getToken().userInfo.loginStatus === "fullLogin")
                status = true;

    } catch(err) {
        console.error(err);
    }

    return status
}

/**
 * isUserInvestor
 */
export function isUserInvestor() {
    return getToken().userInfo.role.some((item, i) => item === "INVESTOR");
}