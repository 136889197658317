import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import { alertBox } from '../../components/BoxProvider';
import { GET_AVAILABLE_LIMIT } from '../../constants/uri';
import * as Globals from '../../constants/globals';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(9),
        },
    },
    fontLight: {
        fontWeight: 'lighter'
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        marginLeft: 22,
        marginTop: -8,
        fontSize: 16
    },
    textInBox: {
        color: "white"
    },
}))

/**
 * ContactUs
 * @param props
 * @return {*}
 * @constructor
 */
function ContactUs(props) {
    const classes = useStyles();
    const [fade, setFade] = useState(false);

    useEffect(()=>{
        setFade(true)
    },[]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Fade in={fade}><div>    
                    <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>Bize Ulaşın</Typography>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid item xs={12}>
                                <Typography  variant='h6' display='inline'>{props.intl.
                                formatMessage({ id: "CONTACT_US.COMPANY" })}</Typography>
                                <Typography  variant='h6' display='inline'
                                             classes={{h6: classes.fontLight}}>Merkezi Kayıt Kuruluşu A.Ş.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography  variant='h6' display='inline'>{props.intl.
                                formatMessage({ id: "CONTACT_US.ADDRESS" })}</Typography>
                                <Typography  variant='h6' display='inline'
                                             classes={{h6: classes.fontLight}}>Reşitpaşa Mahallesi Borsa
                                    İstanbul Caddesi No.:4, 34467 Sarıyer/İSTANBUL</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography  variant='h6' display='inline'>{props.intl.
                                formatMessage({ id: "CONTACT_US.PHONE" })}</Typography>
                                <Typography  variant='h6' display='inline'
                                             classes={{h6: classes.fontLight}}>0 (212) 334 57 00</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography  variant='h6' display='inline'>{props.intl.
                                formatMessage({ id: "CONTACT_US.FAX" })}</Typography>
                                <Typography  variant='h6' display='inline'
                                             classes={{h6: classes.fontLight}}>0 (212) 334 57 57</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography  variant='h6' display='inline'>{props.intl.
                                formatMessage({ id: "CONTACT_US.CALL_CENTER" })}</Typography>
                                <Typography  variant='h6' display='inline'
                                             classes={{h6: classes.fontLight}}>444 0 655 (MKK)</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography  variant='h6' display='inline'>{props.intl.
                                formatMessage({ id: "CONTACT_US.EMAIL" })}</Typography>
                                <Typography  variant='h6' display='inline'
                                             classes={{h6: classes.fontLight}}>yatirimcidestek@mkk.com.tr</Typography>
                            </Grid>
                    </Grid>
                </div></Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(ContactUs)
);