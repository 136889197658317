import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    return b[orderBy].toString().localeCompare(a[orderBy].toString());
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 13,
        fontWeight: 600
    },
    fontBold : {
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'member', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.MEMBER"}) },
        { id: 'accountNo', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.ACCOUNT_NO"}) },
        { id: 'isin', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.ISIN"}) },
        { id: 'amount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.AMOUNT"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {
                                row.secondLabel ?
                                    <div>
                                        {row.label}<br/>
                                        <Typography classes={{subtitle1:headClasses.fontBold}}
                                                    variant="subtitle1">{row.secondLabel}</Typography>
                                    </div>
                                    :
                                    <div>{row.label}</div>
                            }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13
    }
}));
/**
 * MessageTable
 * @param props
 * @return {*}
 * @constructor
 */
export default function MessageTable (props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('member');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.successRows;


    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
                <div className={classes.tableWrapper}>
                    <Toolbar
                        className={clsx(classes.root)}
                    >
                        <div className={classes.title}>
                            <Typography variant="h6" id="tableSuccessMessageTitle">
                                {props.title}
                            </Typography>
                        </div>
                    </Toolbar>
            <div className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        intl={props.intl}
                    />
                    <TableBody>
                        {stableSort(rows, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={'row' + index}
                                    >
                                        <TableCell align="left" key={"member_"+index}
                                                   id={"member_"+index}
                                                   className={classes.fontSize13}>{row.member}</TableCell>
                                        <TableCell align="left" key={"accountNo_"+index}
                                                   id={"accountNo_"+index}
                                                   className={classes.fontSize13}>{row.accountNo}</TableCell>
                                        <TableCell align="left" key={"isin_"+index}
                                                   id={"isin_"+index}
                                                   className={classes.fontSize13}>{row.isin}</TableCell>
                                        <TableCell align="left" key={"amount_"+index}
                                                   id={"amount_"+index}
                                                   className={classes.fontSize13}>{row.amount}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}