import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NumberFormat from 'react-number-format';
import * as Globals from "../../../constants/globals";

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if (orderBy === 'balance') {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    } else 
        return b[orderBy].toString().localeCompare(a[orderBy].toString());
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'memberIvrCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.INTERMEDIARY_NAME"}) },
        { id: 'balance', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.COUNT"}) },
        { id: 'explanation', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"PORTFOLIO.TABLE.DESCRIPTION"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const tableStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13
    },
    panelWrapper: {
        
    },
    panelHeader: {
        
    },
    panelContent: {
        flexDirection: "column"
    }
}));

/**
 * NoDisposalRightsTable
 * @param props
 * @return {*}
 * @constructor
 */
function NoDisposalRightsTable (props) {
    const classes = tableStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('memberIvrCode');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.data.rows


    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    
    return (
                <div>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {props.data.stockName}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{root: classes.panelContent}}>
                            <div className={classes.tableWrapper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        intl={props.intl}
                                    />
                                    <TableBody>
                                        {
                                            stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.name + index}
                                                        >
                                                            <TableCell component="th" key= {'memberIvrCode' + index}
                                                                       id={'memberIvrCode' + index} scope="row"
                                                                       className={classes.fontSize13}>
                                                                {row.memberIvrCode} </TableCell>
                                                            <TableCell align="right" key= {'balance' + index}
                                                                       id={'balance' + index} scope="row"
                                                                       className={classes.fontSize13}>
                                                                <NumberFormat
                                                                    decimalScale={props.roundedNumbers ? 0 : 3}
                                                                    fixedDecimalScale={props.roundedNumbers ? 0 : 3}
                                                                    value={row.balance} decimalSeparator=','
                                                                    thousandSeparator='.' displayType='text' />
                                                            </TableCell>
                                                            <TableCell component="th" key= {'explanation' + index}
                                                                       id={'explanation' + index} scope="row"
                                                                       className={classes.fontSize13}>
                                                                {row.explanation} </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                                nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                                labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>        
                </div>
    )
}

/**
 * panelHeaderSelector
 * @param key
 * @return {string}
 */
function panelHeaderSelector(key) {
    switch(key){
        case "HACİZ":
            return "PORTFOLIO.PANEL.DISTRAINT";
        case "REHİN":
            return "PORTFOLIO.PANEL.PLEDGE_AND_COLLETERAL";
        case "HAPİS":
            return "PORTFOLIO.PANEL.SENTENCE";
        
        default:
            return "PORTFOLIO.PANEL.UNKNOWN";
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    panelWrapper: {
        
    },
    panelHeader: {
        backgroundColor: Globals.COLOR_LIGHT_GRAY,
        color: 'white',
        borderRadius: 4,
        marginBottom: 16,
        minHeight: 36
    },
    panelIcon: {
        color: 'white'
    },
    panelText: {
      margin: '6px 0'
    },
    panelContent: {
        flexDirection: "column"
    }
}));
/**
 * NoDisposalRightsPortfolio
 * @param props
 * @return {*}
 * @constructor
 */
export default function NoDisposalRightsPortfolio (props) {
    const classes = useStyles();
    const keys = Object.keys(props.securities);
    const values = Object.values(props.securities);

    return (
        <div className={classes.root}>
          {
              keys.map((key, i) => 
                {
                    var newValues = [];
                    var flag = false;
                    values[i].map((val) => {
                        if(newValues.length > 0) {
                            newValues.map((newVal, index) => {
                                if(val.stockName === newVal.stockName){
                                    newValues[index].rows.push(val);
                                    flag = true;
                                }
                            })
                        }
                        if(!flag){
                            newValues.push({stockName: val.stockName, rows: [val]})
                        }
                        flag = false;
                });
                return (
                <ExpansionPanel classes={{root:classes.panelWrapper}}>
                    <ExpansionPanelSummary
                        classes={{root:classes.panelHeader, content: classes.panelText, expandIcon: classes.panelIcon}}
                        expandIcon={<ExpandMoreIcon />}
                    >
                    <Typography variant="h6">{ props.intl.formatMessage({id: panelHeaderSelector(key)}) }</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{root: classes.panelContent}}>
                    {
                        newValues.map(val => (
                            <NoDisposalRightsTable intl={props.intl} data={val}
                                                   roundedNumbers={props.roundedNumbers} />
                        ))
                    }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                )
            }
              )
          }
        </div>
    );
}