import React, { useState } from "react";
import { connect} from "react-redux";
import { injectIntl } from "react-intl"; 
import { Formik } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import UpdateButton from "@material-ui/core/Button";
import * as Regex from "../../constants/Regexp";
import * as Globals from '../../constants/globals';
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import NumberFormat from 'react-number-format';
import { useIntl } from "react-intl";

import { clearTimer, intervalFunc } from "../../utils/countDown";
import { alertBox } from '../../components/BoxProvider';
import { get, post, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import * as URI from '../../constants/uri';
import Divider from "@material-ui/core/Divider";

/**
 * NumberFormatCustom
 * @param props
 * @return {*}
 * @constructor
 */
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        onValueChange={(values) => {
            onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
            });
          }}
        format="# ### ### ## ##"
        isNumericString
      />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    formWrapper: {
        padding: theme.spacing(8,0)
    },
    form: {
        width: '100%',
        maxWidth:450
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#aa332340"
    },
    gutter: {
        marginBottom: theme.spacing(3),
    }
}))

/**
 * UserInfo
 * @param props
 * @return {*}
 * @constructor
 */
function UserInfo(props){

    React.useEffect(()=>{
        get(URI.GET_MAIL_AND_GSM)
        .then(function (response) {
            if (response.data.email && response.data.email != null)
                setUserMail(response.data.email);
            if (response.data.phone && response.data.phone != null)
                setUserPhone(response.data.phone);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    },[]);

    const classes = useStyles();
    const intl = useIntl();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [helpText, setHelpText] = useState("");
    const [countDown, setCountDown] = useState(false);
    const [sendCodeDisabled,setSendCodeisabled] = useState(true);
    const [valueType, setValueType] = useState("");
    const [userMail,setUserMail] = useState('');
    const [userPhone,setUserPhone] = useState('');
    /**
     * charCodeControl
     * @param event
     */
    const charCodeControl = (event) => {
        var charCode = (event.charCode) ?
            event.charCode :
            ((event.keyCode) ?
                event.keyCode :
                ((event.which) ?
                    event.which : 0));
        var valid = (charCode >= 48 && charCode <= 57);
        if (!valid) {
            event.preventDefault();
        }
    };
    /**
     * sendApproveCode
     * @param value
     * @param type
     */
    const sendApproveCode = (value, type) => {
        if (value !== null && value !== "") {
            if((type === Globals.EMAIL && value === userMail) || type === Globals.MOBILE && value === userPhone ){
                alertBox("",intl.formatMessage({id:"USER_INFO.ALERT_NO_UPDATE"}), Globals.ALERT_WARNING, intl);
                return
            }
            if ((type === Globals.EMAIL && Regex.EMAIL.test(value)) ||
                (type === Globals.MOBILE && Regex.MOBILE.test(value))) {
                var sendType = null;
                if(type === Globals.EMAIL)
                    sendType = 'EMAIL';
                if(type === Globals.MOBILE)
                    sendType = 'SMS';
                post(URI.GET_CODE_FOR_UPDATE, { value: value, type: sendType })
                .then(function (response) {
                    if (response.data.status === 'OK') {
                        setDialogOpen(true);
                        setValueType(type);
                        setCountDown(true);
                        if (type === Globals.EMAIL) {
                            setHelpText(intl.formatMessage({ id: "SIGNUP.MESSAGE.EMAIL_APPROVE_TEXT" }) +
                                " " + intl.formatMessage({ id: "SIGNUP.MESSAGE.VALID_FOR_TEXT" }));
                        }
                        else if (type === Globals.MOBILE) {
                            setHelpText(intl.formatMessage({ id: "SIGNUP.MESSAGE.SMS_APPROVE_TEXT" }) +
                                intl.formatMessage({ id: "SIGNUP.MESSAGE.VALID_FOR_TEXT" }));
                        }
                    } else {
                        alertBox("", "Onay kodu gönderilemedi", Globals.ALERT_ERROR,intl);
                    }
                })
                .catch(function (error) {
                    checkHttpStatus(error,props.intl);
                })
            }
        }
    };
    /**
     * removeTimer - setCountDown(false)
     */
    const removeTimer = () => {
        setCountDown(false);
        clearTimer();
    }
    /**
     * confirmApproveCode
     * @param changedValue
     */
    const confirmApproveCode = (changedValue) => {
        var confirmCode = document.getElementById('confirmCode').value;
        post(URI.CONFIRM_CODE_FOR_UPDATE,{ value: confirmCode, type: '' })
        .then(function (response) {
            removeTimer();
            if (response.data.status === 'OK') {
                setCountDown(false);
                setDialogOpen(false);
                setHelpText("");
                if(valueType === Globals.EMAIL)
                    setUserMail(changedValue)
                else
                    setUserPhone(changedValue)
                alertBox("", "Bilgileriniz Başarıyla Güncellenmiştir", Globals.ALERT_INFO,intl);

            } else {
                alertBox("", "Bilgileriniz güncellenemedi", Globals.ALERT_ERROR,intl);
            }
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    };
    /**
     * timeIsUp - setCountDown(false)
     */
    const timeIsUp = () => {
        setCountDown(false);
        setHelpText(intl.formatMessage({ id: "SIGNUP.MESSAGE.TIME_IS_UP_TEXT" }));
        setSendCodeisabled(false);
    };

    return(
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <Grid container xs={12} justify='center' alignItems='center' classes={{root:classes.formWrapper}}>
                    <Grid container style={{maxWidth:450}} direction='column'>
                        <h3>
                            {props.intl.formatMessage({id:"USER_INFO.TITLE"})}
                        </h3>

                        <Divider className={classes.divider}/>
                        <Typography gutterBottom variant="body1" classes={{body1: classes.gutter}}>
                            {props.intl.formatMessage({id:"USER_INFO.HELP_TEXT"})}
                        </Typography>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                email: userMail,
                                phone: userPhone
                            }}
                            validate={values => {
                                const errors = {};

                                // setUserMail(values.email);
                                // setUserPhone(values.phone);

                                if (!Regex.EMAIL.test(values.email)) {
                                    errors.email = intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_FIELD"
                                    });
                                }

                                if (!Regex.MOBILE.test(values.phone)) {
                                    errors.phone = intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_FIELD"
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {

                            }}
                        >
                            {({
                                values,
                                status,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    {status && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}

                                    <div className="form-group mb-0" style={{height:94}}>
                                        <TextField
                                            id="mailArea"
                                            margin="normal"
                                            label={intl.formatMessage({id:"SIGNUP.INPUT.EMAIL"})}
                                            className="kt-width-full"
                                            name="email"
                                            disabled={false}
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(touched.email && errors.email)}
                                            InputProps={{
                                                endAdornment:   <UpdateButton   type="reset"
                                                                                variant="contained"
                                                                                color='secondary'
                                                                                onClick={() => sendApproveCode(
                                                                                    values.email, Globals.EMAIL)}
                                                                >
                                                                    GÜNCELLE
                                                                </UpdateButton>
                                            }}
                                        />

                                    </div>
                                    <div className="form-group mb-0" style={{height:94}}>
                                        <TextField
                                            id="phoneArea"
                                            label="Cep Telefonu Numarası"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="phone"
                                            disabled={false}
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phone}
                                            helperText={touched.phone && errors.phone}
                                            error={Boolean(touched.phone && errors.phone)}
                                            onKeyPress={event => { charCodeControl(event); }}
                                            InputProps={{
                                                placeholder: "05XXXXXXXXX",
                                                startAdornment: "+9",
                                                endAdornment:   <UpdateButton   type="reset"
                                                                                variant="contained"
                                                                                color='secondary'                                
                                                                                onClick={() => sendApproveCode(
                                                                                    values.phone, Globals.MOBILE)}
                                                                >
                                                                    GÜNCELLE
                                                                </UpdateButton>,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />

                                    </div>
                                    <div className="form-group mb-0">
                                    </div>

                                    <div>
                                        <Dialog
                                            open={dialogOpen}
                                            // onClose={handleClose4}
                                            aria-labelledby="form-dialog-title"
                                            fullWidth={true}
                                            style={{ textAlign: "left" }}
                                        >
                                            <DialogTitle id="form-dialog-title">
                                                {intl.formatMessage({id:"USER_INFO.MODAL.TITLE"})}
                                            </DialogTitle>
                                            <DialogContent>
                                                <Typography gutterBottom variant='body1'>
                                                    Sayın {localStorage.getItem('NameSurname')}
                                                </Typography>
                                                <TextField
                                                    autoFocus
                                                    autoComplete="off"
                                                    margin="dense"
                                                    id="confirmCode"
                                                    label="Onay Kodu"
                                                    type="numeric"
                                                    variant="outlined"
                                                    inputProps={{ maxLength: 6}}
                                                    onKeyPress={(event) => charCodeControl(event)}
                                                />
                                                <div>
                                                    <DialogContentText style={{marginBottom:0}}>
                                                        <br/>
                                                        <Typography variant='subtitle1' id='infoArea3'>{helpText}
                                                        </Typography>
                                                    </DialogContentText>
                                                    {countDown === true ? (intervalFunc(3,() =>timeIsUp()),
                                                        setCountDown(false),setSendCodeisabled(true)) : null}
                                                    <span id="timerArea" ></span>
                                                </div>
                                            </DialogContent>
                                            <DialogActions style={{padding:16}}>
                                                <Button color="primary"
                                                        disabled={sendCodeDisabled}
                                                        onClick={()=>{
                                                            removeTimer();
                                                            if(valueType === Globals.EMAIL )
                                                                sendApproveCode(values.email, Globals.EMAIL)
                                                            else
                                                                sendApproveCode(values.phone, Globals.MOBILE)
                                                        }}>
                                                    Onay Kodu Gönder
                                                </Button>
                                                <Button color="primary"
                                                        onClick={() => {setDialogOpen(false); removeTimer()}}
                                                >
                                                    Vazgeç
                                                </Button>
                                                <Button variant="contained" color="primary"
                                                        disabled={!sendCodeDisabled}
                                                        onClick={() => {confirmApproveCode(
                                                            valueType === Globals.EMAIL ?
                                                                values.email :
                                                                values.phone)}}
                                                >
                                                    Onayla
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(UserInfo)
);