import React from 'react';
import {useState} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { LIST_MEMBER_TRANSACTION, KFS_TRANSACTION_DOWNLOAD_EXCEL } from "../../constants/uri";
import {checkHttpStatus, get, post} from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/es/Paper/Paper";
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import Spinner from 'react-bootstrap/Spinner';
import Typography from "@material-ui/core/es/Typography/Typography";
import MyTable from '../../components/MyTable';
import {makeStyles} from "@material-ui/core/styles/index";
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import {
    printExtremTableItems,
    printExpandables,
    printThisPage,
    formatDecimal,
    stableSort,
    getSorting
} from '../../utils/ExportUtils';
import {alertBox} from "../../components/BoxProvider";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    backdrop: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        color: '#646c9a'
    },
    projectStateLabel:{
        color:"rgba(0,0,0,0.87) !important",
        fontWeight:"bold",
        fontSize:"1.1rem"
    },
    radioButtonGroup:{
        display:"flex",
        justifyContent:"left",
        textAlign:"left",
        marginBottom:"10px"
    }
    
}));

function KFSTransaction(props) {

    const classes = useStyles();
    const [kfsTransaction, setKfsTransaction] = useState([]);
    const [fade, setFade] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openInfo,setOpenInfo] = React.useState(false);
    const [projectStateButton,setProjectStateButton]=useState('active')

    React.useEffect(() => {
        get(LIST_MEMBER_TRANSACTION)
            .then(function (response) {
                if (response.data.status) {
                    setKfsTransaction(response.data.projectList);
                } else {
                    if(response.data.exception==="İletilen ID'ye ait KFS sisteminde tanımlı aktif üye bulunmamaktadır.")
                    {
                        alertBox("", "Aktif ya da Başarılı KFS kaydınız bulunmamaktadır.", Globals.ALERT_ERROR, props.intl);
                    }else{
                        alertBox("", response.data.exception, Globals.ALERT_ERROR, props.intl);
                    }
                }
                setFade(true);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                checkHttpStatus(error, props.intl);
            });
    }, []);

    function prepareGenericTransactionArray(trArray) {
        var setArray = [];
        for (var i = 0; i < trArray.length; i++) {
            var valueItem = {};
            if(projectStateButton=='active'){
               if(trArray[i].projectState=='PA'){
                valueItem.values = [];
                valueItem.values.push(trArray[i].platformName);
                valueItem.values.push(trArray[i].projectName);
                valueItem.values.push(props.intl.formatMessage({id: convertKeyToDesc(trArray[i].projectFundType)}));
                valueItem.values.push(trArray[i].projectStartDate);
                valueItem.values.push(trArray[i].projectEndDate);
                valueItem.values.push(props.intl.formatMessage({id: convertKeyToDesc(trArray[i].projectState)}));
                valueItem.values.push(trArray[i].projectFundAmount);
                valueItem.values.push(trArray[i].addFundRate);
                valueItem.values.push(formatDecimal(trArray[i].totalProjectFundAmount));
                valueItem.values.push(trArray[i].platformWebAddress);
                setArray.push(valueItem);
               } 
            }else if(projectStateButton=='success'){
                if(trArray[i].projectState=='PS'){
                valueItem.values = [];
                valueItem.values.push(trArray[i].platformName);
                valueItem.values.push(trArray[i].projectName);
                valueItem.values.push(props.intl.formatMessage({id: convertKeyToDesc(trArray[i].projectFundType)}));
                valueItem.values.push(trArray[i].projectStartDate);
                valueItem.values.push(trArray[i].projectEndDate);
                valueItem.values.push(props.intl.formatMessage({id: convertKeyToDesc(trArray[i].projectState)}));
                valueItem.values.push(trArray[i].projectFundAmount);
                valueItem.values.push(trArray[i].addFundRate);
                valueItem.values.push(formatDecimal(trArray[i].totalProjectFundAmount));
                valueItem.values.push(trArray[i].platformWebAddress);
                setArray.push(valueItem);
                }
            }else{
                valueItem.values = [];
                valueItem.values.push(trArray[i].platformName);
                valueItem.values.push(trArray[i].projectName);
                valueItem.values.push(props.intl.formatMessage({id: convertKeyToDesc(trArray[i].projectFundType)}));
                valueItem.values.push(trArray[i].projectStartDate);
                valueItem.values.push(trArray[i].projectEndDate);
                valueItem.values.push(props.intl.formatMessage({id: convertKeyToDesc(trArray[i].projectState)}));
                valueItem.values.push(trArray[i].projectFundAmount);
                valueItem.values.push(trArray[i].addFundRate);
                valueItem.values.push(formatDecimal(trArray[i].totalProjectFundAmount));
                valueItem.values.push(trArray[i].platformWebAddress);
                setArray.push(valueItem);
            }
        }
            return stableSort(setArray, getSorting("asc", 0));
    }
    

    /**
     * download
     * @param serviceName
     */

    function download(serviceName) {
        var data;
        if(projectStateButton=='active'){
            data=kfsTransaction.filter(function(item){
                return item.projectState=="PA"
            });
        }else if(projectStateButton=='success'){
            data=kfsTransaction.filter(function(item){
                return item.projectState=="PS"
            });
        }else{
            data=kfsTransaction
        }
        
        var postData = {
            projectList: data
        };

        post(KFS_TRANSACTION_DOWNLOAD_EXCEL, postData)
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, "KFS Yatırımlarım.xls",
                    Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            });
    }

    const headRows = [
        {
            id: 'platformName',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PLATFORM_NAME"})
        },
        {
            id: 'projectName',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PROJECT_NAME"})
        },
        {
            id: 'projectFundType',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.FUND_TYPE"})
        },
        {
            id: 'projectStartDate',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PROJECT_START_DATE"})
        },
        {
            id: 'projectEndDate',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PROJECT_END_DATE"})
        },
        {
            id: 'projectState',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PROJECT_STATE"})
        },
        {
            id: 'projectFundAmount',
            numeric: true,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PROJECT_FUND_AMOUNT"})
        },
        {
            id: 'addFundRate',
            numeric: true,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.ADD_FUND_RATE"})
        },
        {
            id: 'totalProjectFundAmount',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.TOTAL_PROJECT_FUND_AMOUNT"})
        },
        {
            id: 'platformWebAddress',
            numeric: false,
            disablePadding: false,
            label: props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.PLATFORM_WEB_ADDRESS"})
        },
    ];
    const alignment = ['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left'];

    function convertKeyToDesc(key) {
        switch(key){
            case "SHRE":
                return "KFS_TRANSACTION.FUND_TYPE.SHRE";
            case "DBIT":
                return "KFS_TRANSACTION.FUND_TYPE.DBIT";
            case "PA":
                return "KFS_TRANSACTION.PROJECT_STATE.PA";
            case "PS":
                return "KFS_TRANSACTION.PROJECT_STATE.PS";
            default:
                return "PORTFOLIO.PANEL.UNKNOWN";
        }
    }

    return (
        <div>
            {
                loading ?
                    <Paper className={classes.paper}>
                        <Grid container style={{flex: 1, justifyContent: "center"}}>
                            <Spinner animation="border"/>
                        </Grid>
                    </Paper>
                    :
                    kfsTransaction !== null && kfsTransaction.length > 0 ?
                        <Paper className={classes.paper}>
                            
                            <div style={{width:"100%", textAlign:"right"}}>
                                <div className={classes.radioButtonGroup}>
                                <FormControl >
                                    <FormLabel className={classes.projectStateLabel}>Projenin durumu</FormLabel>
                                    <RadioGroup row defaultValue={projectStateButton} onChange={(e)=>setProjectStateButton(e.target.value)}>
                                    <FormControlLabel value='active' control={<Radio/>} label="Aktif"/>
                                    <FormControlLabel value='success' control={<Radio/>} label="Başarılı"/>
                                    <FormControlLabel value='all' control={<Radio/>} label="Tümü"/>
                                    </RadioGroup>
                                </FormControl>
                                <Button color="primary" onClick={() => setOpenInfo(true)} style={{marginLeft:'auto'}}>
                                    <InfoIcon fontSize='medium' style={{marginRight:8}} />
                                </Button>
                                </div>
                                
                                
                            </div>
                            <div>
                                <Dialog
                                    open={openInfo}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"KFS YATIRIMLARIM"}</DialogTitle>
                                    <DialogTitle id="alert-dialog-title">{"Bilgilendirme"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                            {"KFS Yatırımlarım ekranında yalnızca aktif ve başarılı durumdaki projeler listelenmektedir. Daha önce yatırım yaptığınız projeler bu ekranda listelenmekte olup mevcut fonlama tutarlarıyla ilgili bilgi edinmek için ilgili platformu ziyaret edebilirsiniz."}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{padding:16}}>
                                        <Button onClick={() => setOpenInfo(false)} color="primary" autoFocus>
                                            {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <Fade in={fade}>
                                <div>
                                    
                                    <MyTable expandable={false}
                                             intl={props.intl}
                                             tableRows={prepareGenericTransactionArray(kfsTransaction)}
                                             headRows={headRows}
                                             alignment={alignment}
                                             defaultSort='asc'
                                             defaultSortIndex={0}
                                             printOption={true}
                                             dateTimeColumns={[3, 4]}
                                             printHeader='KFS Yatırımlarım'
                                             downloadAsExcel={() => download()}
                                    />
                                </div>
                            </Fade>
                        </Paper>
                        :
                        <Paper className={classes.paper}>
                            <Fade in={fade}>
                                <Typography
                                    variant="subtitle1"> {props.intl.formatMessage({id: "KFS_TRANSACTION.TABLE.EMPTY_MESSAGE"})} </Typography>
                            </Fade>
                        </Paper>
            }
        </div>
    )
}

export default injectIntl(
    connect(
        null,
    )(KFSTransaction)
);