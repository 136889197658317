import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import {checkAuthorization} from "../../utils/authUtils";
import * as Globals from "../../constants/globals";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  IsLogedIn: "[IsLogedIn] Action",
  SetTimeIsOut: "[SetTimeIsOut] Action",
  SetReRender: "[SetReRender] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  userState: false,
  userTimeOut:false,
  reRender:true
};

export const reducer = persistReducer(
    { storage, key: "eyatirimci-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const { authToken } = action.payload;

          return { authToken, user: undefined };
        }

        case actionTypes.Register: {
          const { authToken } = action.payload;

          return { authToken, user: undefined };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          localStorage.removeItem(Globals.TOKEN);
          return initialAuthState;
        }

        case actionTypes.UserLoaded: {
          const { user } = action.payload;

          return { ...state, user };
        }

        case actionTypes.SetTimeIsOut : {
          const myState = action.payload;
          return { ...state, userTimeOut:myState};
        }

        case actionTypes.SetReRender : {
            const reRenderState = action.payload;
            return { ...state, reRender:reRenderState};
        }

        case actionTypes.IsLogedIn: {

          const userState  = checkAuthorization();

          return {...state, userState};
        };

        default:
          return state;
      }
    }
);

export const actions = {
  isLogedIn: () => ({ type: actionTypes.IsLogedIn}),
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setTimeOut: setTimeOutState => ({type: actionTypes.SetTimeIsOut,payload:setTimeOutState}),
  setReRender: setReRenderState => ({type: actionTypes.SetReRender,payload:setReRenderState})
};

export function* saga() {
 yield takeLatest(actionTypes.Login, function* loginSaga() {
   yield put(actions.requestUser());
 });

 yield takeLatest(actionTypes.Register, function* registerSaga() {
   yield put(actions.requestUser());
 });

 yield takeLatest(actionTypes.UserRequested, function* userRequested() {
   const { data: user } = yield getUserByToken();

   yield put(actions.fulfillUser(user));
 });

}
