import React from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NumberFormat from 'react-number-format';
import {printThisPage} from '../utils/ExportUtils';
import DownloadButtons from '../../app/components/PdfAndExcelDownloadButtons';
import * as Globals from "../constants/globals";

/**
 * @param a
 * @param b
 * @param orderBy
 * @param dateTimeColumns
 * @param numericColumns
 */
function desc(a, b, orderBy, dateTimeColumns, numericColumns) {
    if(dateTimeColumns && dateTimeColumns.some(dc => dc == orderBy)){
        const dateIndices = [ 6, 7, 8, 9, 3, 4, 0, 1 ];
        const timeIndices = [11, 12, 14, 15]
        var r = 0;
        if(a.values[orderBy] == null && b.values[orderBy] != null)
            return 1;
        else if(a.values[orderBy] != null && b.values[orderBy] == null)
            return -1;
        else if (a.values[orderBy] == null && b.values[orderBy] == null)
            return 0;
        else{
            for(var i=0; i<dateIndices.length; i++){
                r = a.values[orderBy].charCodeAt(dateIndices[i]) - b.values[orderBy].charCodeAt(dateIndices[i]);
                if(r !== 0)
                    return r;
            }
            if(a.values[orderBy].length > 10)
                for(var i=0; i<timeIndices.length; i++){
                    r = a.values[orderBy].charCodeAt(timeIndices[i]) - b.values[orderBy].charCodeAt(timeIndices[i]);
                    if(r !== 0)
                        return r;
                }
            return r;
        }
    }
    else if (numericColumns && numericColumns.some(dc => dc == orderBy)) {
        if(orderBy === -1)
            return 0;
        if (b.values[orderBy] < a.values[orderBy]) {
            return -1;
        }
        if (b.values[orderBy] > a.values[orderBy]) {
            return 1;
        }
        return 0;
    } 
    else {
        var value1 = a.values[orderBy] !== null ? a.values[orderBy] : "";
        var value2 = b.values[orderBy] !== null ? b.values[orderBy] : "";
        if(value1 == null)
            value1 = "";
        if(value2 == null)
            value2 = "";
        return value2.toString().localeCompare(value1.toString());
    }
}

/**
 *
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 *
 * @param order
 * @param orderBy
 * @param dateTimeColumns
 * @param numericColumns
 * @return {*}
 */
function getSorting(order, orderBy, dateTimeColumns, numericColumns) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, dateTimeColumns, numericColumns) : (a, b) => -desc(a, b, orderBy, dateTimeColumns, numericColumns);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        fontWeight : 600
    },
    fontBold:{
        fontWeight : 600
    }
}));
/**
 * @param props
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort,headRows } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    return (
        <TableHead>
            <TableRow>
                {headRows.map((row,i) => (
                    <TableCell
                        key={row.id}
                        align={row.numeric || row.date ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === i ? order : false}
                        className={headClasses.headFont}
                        style={row.style ? row.style : null}
                    >
                        <TableSortLabel
                            active={orderBy === i}
                            direction={order}
                            onClick={createSortHandler(i)}
                        >
                            {
                                row.secondLabel ?
                                <div>
                                {row.label}<br/>
                                    <Typography classes={{subtitle1:headClasses.fontBold}} variant="subtitle1">{row.secondLabel}</Typography>
                                </div>
                                :
                                <div>{row.label}</div>
                            }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    grid: {
        paddingTop: 0,
        paddingBottom: 0
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13
    },
    titleText: {
        color: "white"
    },
    panelContent: {
        flexDirection: "column"
    },
    panelHeader: {
        backgroundColor: Globals.COLOR_LIGHT_GRAY,
        color: 'white',
        borderRadius: 4,
        marginBottom: 16,
        minHeight: 36
    },
    panelText: {
      margin:'6px 0'
    },
    panelIcon: {
        color: 'white'
    },
}));

function FullTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState(props.defaultSort);
    const [orderBy, setOrderBy] = React.useState(props.defaultSortIndex);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.tableRows;

    /**
     *
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     *
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     *
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
            <div>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            rowCount={rows.length}
                            onRequestSort={handleRequestSort}
                            headRows={props.headRows}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy, props.dateTimeColumns, props.numericColumns))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => {}}
                                            tabIndex={-1}
                                            key={index}
                                            selected={false}
                                        >
                                            {
                                                row.values.map((v,j)=>
                                                    <TableCell  key={j}
                                                                align={props.headRows[j].numeric || props.headRows[j].date ? 'right' : 'left' }
                                                                className={classes.fontSize13}
                                                    >
                                                        {
                                                            /*tablo icinde bir alan tiklanabilir ise props olarak alinan fonksiyon cagrilir
                                                            * fonksiyona v degeri(id tarzi bir parametre) ve array inexi verilir
                                                            * fonksiyon icinde index switch edilerek hangi kolon degerinden cagrildiysa ona gore islem yapilabilir
                                                            * boylece birden fazla kolon ayni fonksiyon ile tiklanabilir hale gelir*/
                                                            props.headRows[j].clickable && v != null ?
                                                                <a className='clickableArea' onClick={()=>props.clickableFunc(v,j)}>
                                                                    <InfoIcon color="primary" style={{fontSize:32}}/>
                                                                </a>//buraya uygun bir icon koyulabilir
                                                            :
                                                            props.headRows[j].numeric && v != null ?
                                                                <NumberFormat   decimalScale={props.headRows[j].decimal ? props.headRows[j].decimal : 0 }
                                                                                fixedDecimalScale={props.headRows[j].fixedDecimal ? props.headRows[j].fixedDecimal : 0}
                                                                                value={v}
                                                                                decimalSeparator=','
                                                                                thousandSeparator='.'
                                                                                displayType='text' />
                                                            :
                                                            v
                                                        }
                                                    </TableCell>)
                                            }
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {
                    props.printOption && rows.length >0 ?
                    <div>
                        <DownloadButtons pdfOnClick={() => printThisPage(rows,props.headRows,props.printHeader,true)}
                                         excelOnClick={props.downloadAsExcel}
                        />
                    </div>
                        : null
                }
        </div>
    );

}

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function MyTable(props) {
    const classes = useStyles();

    return (
        props.expandable ?
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary classes={{root:classes.panelHeader, content: classes.panelText, expandIcon: classes.panelIcon}}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6">{props.tableTitle}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{root: classes.panelContent}}>
                        <FullTable  headRows={props.headRows}
                                    tableRows={props.tableRows}
                                    defaultSort={props.defaultSort}
                                    defaultSortIndex={props.defaultSortIndex}
                                    dateTimeColumns={props.dateTimeColumns}
                                    numericColumns={props.numericColumns}
                                    clickableFunc={props.clickableFunc}
                                    printOption = {props.printOption}
                                    downloadAsExcel = {props.downloadAsExcel}
                                    printHeader={props.printHeader}
                                    intl={props.intl} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
            :
            <div className={classes.root}>
                <FullTable  headRows={props.headRows}
                            tableRows={props.tableRows}
                            defaultSort={props.defaultSort}
                            defaultSortIndex={props.defaultSortIndex}
                            dateTimeColumns={props.dateTimeColumns}
                            numericColumns={props.numericColumns}
                            clickableFunc={props.clickableFunc}
                            printOption = {props.printOption}
                            downloadAsExcel = {props.downloadAsExcel}
                            printHeader={props.printHeader}
                            intl={props.intl}
                />
            </div>
    )
}