import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField, Button, Typography } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import * as Regex from "../../constants/Regexp";
import * as URI from '../../constants/uri';
import * as Globals from '../../constants/globals';
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import NumberFormat from 'react-number-format';
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import CountDownContainer from "../../utils/CountDownContainer";
import { alertBox } from '../../components/BoxProvider';
import { Redirect } from "react-router-dom";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => {
          onChange({
              target: {
                name: props.name,
                value: values.value,
              },
          });
        }}
      format="# ### ### ## ##"
      isNumericString
    />
  );
}

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function Registration(props) {
  const { intl } = props;
  const [valueType, setValueType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [countDown, setCountDown] = useState(false);
  const [confirmCodeBlock,setConfirmCodeBlock] = useState(false);
  const [isApprovedEmail, setIsApprovedEmail] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [isApprovedPhone, setIsApprovedPhone] = useState(false);
  const [disabledPhone, setDisabledPhone] = useState(false);
  const [logout, setLogout] = useState(false);
  const [debugMode,setDebugMode] = useState(false);

  React.useEffect(()=>{
      try {
          var searchStr = window.location.search;
          if(searchStr != null && searchStr !== ''){
              var searchArr = searchStr.split('=');
              if(searchArr.length === 2){
                  if(searchArr[0] === '?debugMode'){
                      if(searchArr[1]==='true'){
                          setDebugMode(true);
                      }
                  }
              }
          }
      } catch (err) {
          console.log(err);
      }
  },[]);

    /**
     * charCodeControl
     * @param event
     */
  const charCodeControl = (event) => {
    var charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode :
        ((event.which) ? event.which : 0));
    var valid = (charCode >= 48 && charCode <= 57);
    if (!valid) {
      event.preventDefault();
    }
  };

    /**
     * sendApproveCode
     * @param value
     * @param type
     */
  const sendApproveCode = (value, type) => {
    if (value !== null && value !== "") {
      if ((type === Globals.EMAIL && Regex.EMAIL.test(value)) || (type === Globals.MOBILE && Regex.MOBILE.
          test(value))) {
        var postData = {};
        postData['type'] = type
        postData['value'] = value;
        post(URI.SEND_APPROVE_CODE, postData, props.intl).then(function (response) {
          if (response.data !== null && response.data.status) {
            localStorage.removeItem(Globals.TOKEN);
            localStorage.setItem(Globals.TOKEN, response.data.token);
            setDialogOpen(true);
            setValueType(type);
            setCountDown(true);
            setConfirmCodeBlock(false);
            if (type === Globals.EMAIL) {
              setDisabledEmail(true);
              setIsApprovedEmail(false);
              setHelpText(intl.formatMessage({ id: "SIGNUP.MESSAGE.EMAIL_APPROVE_TEXT" }) + " " + intl.
              formatMessage({ id: "SIGNUP.MESSAGE.VALID_FOR_TEXT" }));
            }
            else if (type === Globals.MOBILE) {
              setDisabledPhone(true);
              setIsApprovedPhone(false);
              setHelpText(intl.formatMessage({ id: "SIGNUP.MESSAGE.SMS_APPROVE_TEXT" }) + intl.
              formatMessage({ id: "SIGNUP.MESSAGE.VALID_FOR_TEXT" }));
            }
          } else {
            var error = response.data.exception;
            alertBox("", error.message, Globals.ALERT_ERROR, intl);
          }
        }).catch(function (error) {
          checkHttpStatus(error, props.intl)
        });
      }
    }
  }
    /**
     * removeTimer - setCountDown(false)
     */
  const removeTimer = () => {
    setCountDown(false);
}

    /**
     * confirmApproveCode
     */
  const confirmApproveCode = () => {
    var confirmCode = document.getElementById('confirmCode').value;
    var confirmUrl;
    if(debugMode){
      confirmUrl = URI.CONFIRM_LOGIN_CODE_DEBUG2;
    }else{
      confirmUrl = URI.CONFIRM_APPROVE_CODE;
    }
    post(confirmUrl, { 'confirmVal': confirmCode }, props.intl).then(function (response) {
      if (response.data !== null && response.data.status) {
        localStorage.removeItem(Globals.TOKEN);
        localStorage.setItem(Globals.TOKEN, response.data.token);
        setDialogOpen(false);
        setHelpText("");
        setCountDown(false);
        if (valueType === Globals.EMAIL)
          setIsApprovedEmail(true);
        else if (valueType === Globals.MOBILE)
          setIsApprovedPhone(true);
      } else {
        var error = response.data.exception;
        if('ER170' === error.code){
            setCountDown(false);
            setConfirmCodeBlock(true);
        }
        alertBox("", error.message, Globals.ALERT_ERROR, intl);
      }
    }).catch(function (error) {
      checkHttpStatus(error, props.intl)
    });
  }

    /**
     * handleApproveCodeInput
     * @param event
     */
  const handleApproveCodeInput = (event) => {
    var inputTextVal = event.target.value;
    if(inputTextVal.length > 6){
        document.getElementById('confirmCode').value = inputTextVal.substring(0,6);
    }
    var regEx = /[0-9]/g;
    if(!regEx.test(inputTextVal.charAt(inputTextVal.length - 1))){
        document.getElementById('confirmCode').value = inputTextVal.substring(0,inputTextVal.length - 1);
    }
    var newVal = document.getElementById('confirmCode').value;
    var newReg = /^\d+$/;
    if((newVal.length > 0) && (!newReg.test(newVal))){
        document.getElementById('confirmCode').value = '';
    }
};
    /**
     * timeIsUp
     */
  const timeIsUp = () => {
    setCountDown(false);
    if(document.getElementById("infoArea3") === null) {
    }
    else{
      setHelpText(intl.formatMessage({ id: "SIGNUP.MESSAGE.TIME_IS_UP_TEXT" }));
    }
    if (valueType === Globals.EMAIL)
      setDisabledEmail(false);
    else if (valueType === Globals.MOBILE)
      setDisabledPhone(false);
  };

  if (logout) {
    return <Redirect to="/auth/login" />;
  }

  var country = localStorage.getItem('Country');
  var investorType = localStorage.getItem('InvestorType');

  return (
    <div className="kt-login__body">
      <div className="kt-login__form" style={{maxWidth:600}}>
        <div className="kt-login__title" style={{marginBottom:32}}>
          <h3>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            email: "",
            phone: "",
            greetingMessage: "",
            acceptTerms: true
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !Regex.EMAIL.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }
            if (country === "TR" && (investorType === "R" || investorType === "null")) {
              if (!values.phone) {
                errors.phone = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (!Regex.MOBILE.test(values.phone)) {
                errors.phone = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }
            }

            if(country === "TR" && investorType !== "R" && values.phone !== '') {
                if (!Regex.MOBILE.test(values.phone)) {
                    errors.phone = intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_FIELD"
                    });
                }
            }

            if(country !== "TR" && values.phone !== '' ) {
                 if (!Regex.MOBILE.test(values.phone)) {
                    errors.phone = intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_FIELD"
                    });
                }
            }
            if (!values.greetingMessage) {
              errors.greetingMessage = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.acceptTerms) {
              errors.acceptTerms = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
              var postData = {};
            if (country === "TR" && (investorType === "R" || investorType === "null") && isApprovedEmail
                && isApprovedPhone) {
              postData['email'] = values.email;
              postData['phone'] = values.phone;
              postData['greetingMessage'] = values.greetingMessage;
            }
            else if(investorType !== "R" && isApprovedEmail  ) {
                postData['email'] = values.email;
                postData['greetingMessage'] = values.greetingMessage;
                if(values.phone !== '' && isApprovedPhone)
                    postData['phone'] = values.phone;
            }
            else if(country !== "TR" && isApprovedEmail) {
                postData['email'] = values.email;
                postData['greetingMessage'] = values.greetingMessage;
                if(values.phone !== '' && isApprovedPhone)
                    postData['phone'] = values.phone;
            }
            else {
                alertBox("", intl.formatMessage({ id: "SIGNUP.MESSAGE.MSG_CONFIRM_CONTACT" }),
                    Globals.ALERT_ERROR, intl);
                setSubmitting(false);
            }

            if(Object.keys(postData).length > 0) {
              post(URI.CREATE_NEW_USER, postData, props.intl).then(function (response) {
                if (response.status) {
                  localStorage.clear();
                  alertBox("", intl.formatMessage({ id: "SIGNUP.MESSAGE.MSG_SUCCESS_SAVE" }),
                      Globals.ALERT_SUCCESS, intl);
                  setLogout(true);

                }
              }).catch(function (error) {
                checkHttpStatus(error, props.intl)
              });
              }

          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0" style={{height:94}}>
                  <TextField
                    margin="normal"
                    label={intl.formatMessage({ id: "SIGNUP.INPUT.EMAIL" })}
                    className="kt-width-full"
                    name="email"
                    disabled={disabledEmail}
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    InputProps={{
                      endAdornment: <Button variant='contained' color='secondary'
                                            onClick={() => sendApproveCode(values.email, Globals.EMAIL)}>Onayla
                      </Button>
                    }}
                  />

                </div>
                <div className="form-group mb-0" style={{height:94}}>
                  <TextField
                    label={intl.formatMessage({ id: "SIGNUP.INPUT.MOBILE" })}
                    margin="normal"
                    className="kt-width-full"
                    name="phone"
                    disabled={disabledPhone}
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onKeyPress={event => { charCodeControl(event); }}
                    InputProps={{
                      placeholder: "05XXXXXXXXX",
                      startAdornment: "+9",
                      endAdornment: <Button variant='contained' color='secondary'
                                            onClick={() => sendApproveCode(values.phone, Globals.MOBILE)}>Onayla
                      </Button>,
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </div>
                <div className="form-group mb-0" style={{height:94}}>
                  <TextField
                    margin="normal"
                    label={intl.formatMessage({ id: "SIGNUP.INPUT.GREETING_MESSAGE" })}
                    className="kt-width-full"
                    name="greetingMessage"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.greetingMessage}
                    inputProps={{ maxLength: 50 }}
                    helperText={touched.greetingMessage && errors.greetingMessage}
                    error={Boolean(touched.greetingMessage && errors.greetingMessage)}
                  />
                </div>
                <div className="form-group mb-0">
                  <span className="form-text text-muted">
                    e-Yatırımcı servisleri aracılığı ile tarafıma yapılan bildirimlerin işlemler gerçekleştikten
                      sonra yapıldığını ve bu bildirimlerin işlemlerin geçerliliğini etkilemediğini bildiğimi,
                      hesaplarımdaki işlemlerin tespitini/kontrolünü bilgi gelsin yada gelmesin hesaplarımı kontrol
                      ederek yapmam gerektiğini bildiğimi,  e-Yatırımcı servislerine herhangi bir nedenle erişememekten
                      veya e-Yatırımcı hizmetlerini alamamaktan, e-Yatırımcı üyeliğim nedeniyle yapılacak bildirimin
                      tarafıma herhangi bir nedenle geç veya eksik gelmesi ya da hiç gelmemesinden, içerik sağlayanlar
                      tarafından erişime sunulan bilgi ve belgelerin hatalı olmasından doğabilecek zararlardan MKK’yı
                      sorumlu tutmayacağımı ve MKK’dan herhangi bir tazmin talebinde bulunmayacağımı kabul ve taahhüt
                      ederim.
                  </span>
                </div>

                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        Üyelik taahhütnamesini okudum, kabul ediyorum.
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                      />
                    }
                  />
                </div>

                <div className="kt-login__actions">
                  <Button onClick={() => setLogout(true)}
                          color='primary'
                          size='large'
                          variant="contained"
                          fullWidth
                          style={{ width: '45%', maxWidth: 200 }}
                  >
                    Vazgeç
                </Button>

                  <Button
                    disabled={isSubmitting || !values.acceptTerms}
                    type="submit"
                    color='primary'
                    size='large'
                    variant="contained"
                    fullWidth
                    style={{ width: '45%', maxWidth: 200 }}
                  >
                    Kayıt Ol
                </Button>
                </div>
                <div>
                  <Dialog
                    open={dialogOpen}
                    // onClose={handleClose4}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    style={{ textAlign: "left" }}
                  >
                    <DialogTitle id="form-dialog-title">
                      
                    </DialogTitle>
                    <DialogContent>

                      <Typography gutterBottom variant='body1'>Sayın {localStorage.getItem('NameSurname')}</Typography>

                      <TextField
                        autoFocus
                        autoComplete="off"
                        margin="dense"
                        id="confirmCode"
                        label="Onay Kodu"
                        type="numeric"
                        variant="outlined"
                        onChange={(event)=>handleApproveCodeInput(event)}
                        onKeyPress={(event) => charCodeControl(event)}
                      />
                      <div>
                        <br/>
                        <DialogContentText style={{marginBottom:0}}>
                          <Typography variant='subtitle1' id='infoArea3'>{helpText}</Typography>
                        </DialogContentText>
                          {
                              countDown ? <CountDownContainer minutes={3} endFunction={timeIsUp}/> : null
                          }
                      </div>
                    </DialogContent>
                    <DialogActions style={{padding:16}}>
                      <Button color="primary"
                              disabled={countDown && !confirmCodeBlock}
                              onClick={() => {valueType === Globals.EMAIL ? sendApproveCode(values.email,valueType) :
                                  sendApproveCode(values.phone,valueType)}}
                      >
                        Onay Kodu Gönder
                       </Button>
                      <Button color="primary"
                        onClick={() => {setDialogOpen(false); removeTimer()}}
                      >
                        Vazgeç
                      </Button>
                      <Button variant="contained" color="primary"
                              disabled={!countDown}
                        onClick={() => {confirmApproveCode()}}
                      >
                        Onayla
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </form>
            )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Registration)
);