import axios from "axios";
import * as Globals from "../constants/globals";
import { alertBox } from "../components/BoxProvider";
import { Redirect } from "react-router-dom";
import React from 'react';
import { actions } from "../store/ducks/auth.duck";
import myStore from "../store/store";

/**
 * postHeader
 * @return {{headers: {Accept: string, "Content-Type": string, Authorization: string | null, "screen-path": string, channel: string}}}
 */
export const postHeader = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': localStorage.getItem('token'),
            'screen-path': window.location.pathname,
            'channel': 'WEB' 
        }
    }
}
/**
 * getHeader
 * @return {{headers: {Accept: string, "Content-Type": string, Authorization: string | null, "screen-path": string, channel: string}}}
 */
export const getHeader = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': localStorage.getItem('token'),
            'screen-path': window.location.pathname,
            'channel': 'WEB' 
        }
    }
}
/**
 * apiCallPost
 * @type {AxiosInstance}
 */
export const apiCallPost = axios.create()
/**
 * apiCallGet
 * @type {AxiosInstance}
 */
export const apiCallGet = axios.create()

apiCallPost.interceptors.response.use(function (response) {
    if (response.headers.token) {
        updateToken(response.headers.token);
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

apiCallGet.interceptors.response.use(function (response) {
    if (response.headers.token) {
        updateToken(response.headers.token);
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

/**
 * updateToken
 * @param token
 */
export function updateToken(token) {
    localStorage.removeItem(Globals.TOKEN);
    localStorage.setItem(Globals.TOKEN, token);
    myStore.dispatch(actions.setTimeOut(false));
}

/**
 * checkHttpStatus
 * @param error
 * @param intl
 * @param notReRender
 * @return {string}
 */
export function checkHttpStatus(error, intl, notReRender) {
    var errCode = '';
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.data.status === 500) {
            alertBox(intl.formatMessage({ id: "COMMON.ALERT.ERROR_TITLE" }), error.response.data.message,
                Globals.ALERT_ERROR, intl);
        } else if (error.response.data.status === 503) {
            window.location.assign("http://hata.mkk.com.tr/bakimCalismasi.html");
        }
        else if (error.response.data.status === "ER129") {
            myStore.dispatch(actions.setTimeOut(true));
            if (notReRender)
                myStore.dispatch(actions.setReRender(false));
            else
                myStore.dispatch(actions.setReRender(true));
        } else if (error.response.data.status === "ER166") {
            alertBox(intl.formatMessage({ id: "COMMON.ALERT.NOT_REFRESH" }), error.message, Globals.ALERT_ERROR, intl);
        } else if (error.response.data.status === "ER167") {
            alertBox(intl.formatMessage({ id: "COMMON.ALERT.AFTER_FIVE_MINUTES" }), '', Globals.ALERT_ERROR, intl);
            setTimeout(() => { myStore.dispatch(actions.logout()) }, 1500)
        } else if (error.response.data.status === "ER168") {
            myStore.dispatch(actions.logout());
        } else {
            alertBox(intl.formatMessage({ id: "COMMON.ALERT.UNEXPECTED_ERROR" }), error.message,
                Globals.ALERT_ERROR, intl);
        }
        if (error.response.data.status === 500) {
            errCode = error.response.data.code;
        } else {
            errCode = error.response.data.status;
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errCode = 'ERR-request';
        console.log(error.request);
        alertBox(intl.formatMessage({ id: "COMMON.ALERT.UNEXPECTED_ERROR" }), error.message, Globals.ALERT_ERROR, intl);
    } else {
        // Something happened in setting up the request that triggered an Error
        errCode = 'ERR-unexpected';
        console.log('Error', error.message);
        alertBox(intl.formatMessage({ id: "COMMON.ALERT.UNEXPECTED_ERROR" }), error.message, Globals.ALERT_ERROR, intl);
    }
    console.log(error.config);
    return errCode;
}

/**
 * get
 * @param URL ->URL
 * @return {Promise<AxiosResponse<T>>}
 */
export function get(URL) {
    return apiCallGet.get(URL, getHeader());
}

/**
 * post
 * @param URL ->URL
 * @param body ->body
 * @return {Promise<AxiosResponse<T>>}
 */
export function post(URL, body) {
    return apiCallPost.post(URL, JSON.stringify(body), postHeader());
}

/**
 * enableSpinner
 */
export function enableSpinner() {
    document.getElementById('loaderId222').style.display = 'flex';
}

/**
 * disableSpinner
 */
export function disableSpinner() {
    document.getElementById('loaderId222').style.display = 'none';
}
