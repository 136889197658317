import React from 'react';
import { useState } from 'react';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { GET_GENERIC_PORTFOLIO, KFS_PORTFOLIO_DOWNLOAD_EXCEL, } from "../../constants/uri";
import { checkHttpStatus, get, post } from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/es/Paper/Paper";
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import Spinner from 'react-bootstrap/Spinner'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from "@material-ui/core/es/Typography/Typography";
import MyTable from '../../components/MyTable';
import { makeStyles } from "@material-ui/core/styles/index";
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import {printExtremTableItems,printExpandables,printThisPage,formatDecimal,stableSort,getSorting} from '../../utils/ExportUtils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    backdrop: { 
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1, 
        color: '#646c9a'
    },
}));

/**
 * KFSPortfolio
 * @param props
 * @return {*}
 * @constructor
 */
function KFSPortfolio(props) {

    const classes = useStyles();
    const [kfsPortfolioInfos, setKfsPortfolioInfos] = useState([]);
    const [fade,setFade] = useState(false);
    const [loading,setLoading] = useState(true);
    
    
    React.useEffect(()=>{
        post(GET_GENERIC_PORTFOLIO,{'dateField': beginInit(), 'checkbox': false})
                .then(function (response) {
                    var filteredAccountBalanceInfos = response.data.accountBalanceInfos.filter(function (accBalInf) {
                        return accBalInf.kfs == "K";
                      });
                    setKfsPortfolioInfos(filteredAccountBalanceInfos);
                    setFade(true);
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    checkHttpStatus(error,props.intl);
                })
    }, []);

    /**
     * prepareGenericPortfolioItemArr
     * @param trArray
     * @return stableSort(setArray, getSorting("asc", 0))
     */
    function prepareGenericPortfolioItemArr(trArray) {
        var setArray = [];
        for(var i=0;i<trArray.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(trArray[i].accNo);
            valueItem.values.push(trArray[i].mbrAccNo);
            valueItem.values.push(trArray[i].stockName + " - " + trArray[i].isin);
            valueItem.values.push(trArray[i].addDefCode);
            valueItem.values.push(trArray[i].subAccCode);
            valueItem.values.push(formatDecimal(trArray[i].count.toFixed(3)));
            setArray.push(valueItem);
        }
        return stableSort(setArray, getSorting("asc", 0));
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        var postData = {
            accountBalanceInfos: kfsPortfolioInfos
        };

        post(KFS_PORTFOLIO_DOWNLOAD_EXCEL, postData)
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, "KFS Portföy Sonuçları.xls",
                    Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }


    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    /**
     * beginInit
     * @return {string}
     */
    function beginInit() {
        var date = new Date();
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
            (formatMonthOrDay(date.getDate())).toString();
    }

    const headRows = [
        { id: 'accNo',     numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.MEMBER"}) },
        { id: 'mbrAccNo',   numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ACCOUNT_NAME"}) },
        { id: 'stockName',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.STOCK_NAME"}) },
        { id: 'addDefCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.ACCOUNT_DEF"}) },
        { id: 'subAccCode', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.SUB_ACCOUNT"}) },
        { id: 'count',      numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.COUNT"}) },
    ];
    const alignment = ['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left'];

    return (
        <div>
            {
                loading ? 
                /*<Backdrop className={classes.backdrop} open={loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>*/
                <Paper className={classes.paper} >
                    <Grid container style={{ flex: 1, justifyContent: "center"}}>
                        <Spinner animation="border" />
                    </Grid>
                </Paper>
                :
                kfsPortfolioInfos.length > 0 ?
                    <Paper className={classes.paper} > 
                        <Fade in={fade}>
                            <div>
                                <MyTable expandable = {false}
                                        intl={props.intl}
                                        tableRows={prepareGenericPortfolioItemArr(kfsPortfolioInfos)}
                                        headRows={headRows}
                                        alignment={alignment}
                                        defaultSort='asc'
                                        defaultSortIndex={0}
                                        printOption={true}
                                        downloadAsExcel={() => download()}
                                        printHeader='KFS PORTFÖYÜM'
                                />
                                {/*<Grid container style={{marginTop:20}}>
                                    <InfoIcon size="small" style={{marginRight:8}} />
                                    <Typography display="inline" variant="subtitle1">
                                        {props.intl.formatMessage({id:"KFS_PORTFOLIO_NOTE"})} 
                                    </Typography>
                                </Grid>*/}
                            </div>
                        </Fade>
                    </Paper>
                    :
                    <Paper className={classes.paper} >
                        <Fade in={fade}>
                            <Typography variant="subtitle1"> {props.intl.
                            formatMessage({id:"KFS_PORTFOLIO_EMPTY_MESSAGE"})} </Typography>
                        </Fade>
                    </Paper>
            }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(KFSPortfolio)
);