import React from 'react';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {GET_SERMAYE_ARTIRIM_REPORTS, 
    SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_EXCEL, 
    SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_PDF} from "../../constants/uri";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import MyTable from '../../components/MyTable';
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/es/Typography/Typography";
import HkFilterPanel from './HkFilterPanel';
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import { alertBox } from '../../components/BoxProvider';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

/**
 * SermayeArtirimReportPage
 * @param props
 * @return {*}
 * @constructor
 */
function SermayeArtirimReportPage(props){
    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for(var i=0;i<array.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].kiymet);
            valueItem.values.push(array[i].bedelliOrani);
            valueItem.values.push(array[i].artirimTarihi);
            valueItem.values.push(array[i].rucanHkSonTarihi);
            valueItem.values.push(array[i].bedelsizArtisOrani !== 0 ? array[i].bedelsizArtisOrani : "");
            valueItem.values.push(array[i].temettudenBedelsizOrani);
            valueItem.values.push(array[i].digerBedelsizOrani);
            setArray.push(valueItem);
        }
        return setArray;
    }

    const headRows = [
        { id: 'titleCol', numeric: false, disablePadding: false, label: 'Kıymet' },
        { id: 'oran1Col', numeric: true, disablePadding: false, label: 'Bedelli Oranı', decimal:5 },
        { id: 'date1Col', numeric: false, date: true, disablePadding: false, label: 'Sermaye Artırım Tarihi' },
        { id: 'date2Col', numeric: false, date: true, disablePadding: false,
            label: 'Rüçhan Hakkı Kullanım Son Tarihi' },
        { id: 'oran2Col', numeric: true, disablePadding: false, label: 'Bedelsiz Artış Oranı', decimal:5 },
        { id: 'oran3Col', numeric: true, disablePadding: false, label: 'Temettüden Bedelsiz Oranı', decimal:5 },
        { id: 'oran4Col', numeric: true, disablePadding: false, label: 'Diğer Bedelsiz Oranı', decimal:5 }
    ];

    const alignment = ['left', 'right', 'left','right','right','right','right'];

    /**
     * filterReports
     * @param selectedBeginDate
     * @param selectedEndDate
     * @param selectedIhrMember
     */
    function filterReports(selectedBeginDate,selectedEndDate,selectedIhrMember) {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.substring(4,6)+'-'+selectedEndDate.
        substring(6,8));
        var beginDate = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));
        
        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, props.intl);
            return;
        }
        post(GET_SERMAYE_ARTIRIM_REPORTS,{beginDate:selectedBeginDate,endDate:selectedEndDate,mbrId:selectedIhrMember})
            .then(function (response) {
                setReportList([]);
                setReportList(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        post(serviceName, {sermayeArtirimList: reportList})
            .then(function (response) {
                if (serviceName === SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_EXCEL)
                    DownloadUtils.downloadFileByContentExcel(response.data, "Sermaye Artırım.xls",
                        Globals.CONTENT_TYPE_EXCEL);
                else if (serviceName === SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_PDF)
                    DownloadUtils.downloadFileByContent(response.data, "Sermaye Artırım.pdf",
                        Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    const [reportList,setReportList] = useState([]);
    const [fade,setFade] = useState(false);
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <HkFilterPanel initOnOpen={true} filterReports={filterReports} ihrMembers={props.ihrMembers}/>
                <Fade in={fade}>
                    {
                        reportList.length > 0 ?
                            <div>
                                <MyTable expandable = {false}
                                        intl={props.intl}
                                        tableRows={prepareItemArray(reportList)}
                                        headRows={headRows}
                                        alignment = {alignment}
                                        defaultSort='desc'
                                        defaultSortIndex={2}
                                        dateTimeColumns={[2,3]}
                                        numericColumns={[1, 4, 5, 6]}
                                        printOption={true}
                                        downloadAsExcel={() => download(SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_EXCEL)}
                                        printHeader='SERMAYE ARTIRIM BİLGİLERİ RAPORU'
                                />
                            </div>
                            :
                            <Typography align='center' classes={{h6: classes.messageContainer}} variant='h6'>
                                {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DATA_NOT_FOUND"})}
                            </Typography>

                    }
                </Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(SermayeArtirimReportPage)
);