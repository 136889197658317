import objectPath from "object-path";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import LayoutConfig from "../layout/LayoutConfig";
import MenuConfig from "../layout/MenuConfig";

export const actionTypes = {
  SetMenuConfig: "builder/SET_MENU_CONFIG",
  SetLayoutConfigs: "builder/SET_LAYOUT_CONFIGS",
  SetLayoutConfigsWithPageRefresh: "builder/SET_LAYOUT_CONFIGS_WITH_PAGE_REFRESH",
  SetHtmlClassService: "builder/SET_HTML_CLASS_SERVICE"
};
/**
 *
 * @type {{getClasses: function(*, *), getAttributes: function(*, *), getConfig: function(*, *=), getLogo: function({builder: *}), getStickyLogo: function(*=)}}
 */
export const selectors = {
    /**
     *
     * @param store
     * @param params
     * @return {string}
     */
  getClasses: (store, params) => {
    const { htmlClassServiceObjects } = store.builder;

    return htmlClassServiceObjects
      ? htmlClassServiceObjects.getClasses(params.path, params.toString)
      : "";
  },
    /**
     *
     * @param store
     * @param params
     * @return {Array}
     */
  getAttributes: (store, params) => {
    if (params.path) {
      // if path is specified, get the value within object
      const { htmlClassServiceObjects } = store.builder;

      return htmlClassServiceObjects
          ? htmlClassServiceObjects.getAttributes(params.path)
          : [];    }

    return [];
  },
    /**
     *
     * @param state
     * @param path
     * @return {*}
     */
  getConfig: (state, path) => {
    const { layoutConfig } = state.builder;

    if (path) {
      // if path is specified, get the value within object
      return objectPath.get(layoutConfig, path);
    }

    return "";
  },
    /**
     *
     * @param layoutConfig
     * @return {*}
     */
  getLogo: ({ builder: { layoutConfig } }) => {
    const menuAsideLeftSkin = objectPath.get(layoutConfig, "brand.self.skin");
    // set brand logo
    const logoObject = objectPath.get(layoutConfig, "self.logo");
    let logo;
    if (typeof logoObject === "string") {
      logo = logoObject;
    }

    if (typeof logoObject === "object") {
      logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
    }

    if (typeof logo === "undefined") {
      try {
        const logos = objectPath.get(this.layoutConfig, "self.logo");
        logo = logos[Object.keys(logos)[0]];
      } catch (e) {
        console.log(e);
      }
    }
    return logo;
  },
    /**
     *
     * @param store
     * @return {string}
     */
  getStickyLogo: store => {
    const { layoutConfig } = store.builder;
    let logo = objectPath.get(layoutConfig, "self.logo.sticky");
    if (typeof logo === "undefined") {
      logo = selectors.getLogo(store);
    }
    return logo + "";
  }
};

const initialState = {
  menuConfig: MenuConfig,
  layoutConfig: LayoutConfig,
  htmlClassServiceObjects: undefined
};

export const reducer = persistReducer(
  {
    storage,
    key: "build-eyatirimci",
    blacklist: ["htmlClassServiceObjects"]
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.SetMenuConfig:
        return { ...state, menuConfig: payload };

      case actionTypes.SetLayoutConfigs:
        return { ...state, layoutConfig: payload };

      case actionTypes.SetLayoutConfigsWithPageRefresh: {
        return {...state, layoutConfig: payload};
      }
      case actionTypes.SetHtmlClassService:
        return { ...state, htmlClassServiceObjects: payload };

      default:
        return state;
    }
  }
);
/**
 *
 * @type {{setMenuConfig: function(*): {payload: *, type: string}, setLayoutConfigs: function(*): {payload: *, type: string}, setLayoutConfigsWithPageRefresh: function(*): {payload: *, type: string}, setHtmlClassService: function(*): {payload: *, type: string}}}
 */
export const actions = {
    /**
     *
     * @param payload
     * @return {{payload: *, type: string}}
     */
  setMenuConfig: payload => ({ payload, type: actionTypes.SetMenuConfig }),
    /**
     *
     * @param payload
     * @return {{payload: *, type: string}}
     */
  setLayoutConfigs: payload => ({
    payload,
    type: actionTypes.SetLayoutConfigs
  }),
    /**
     *
     * @param payload
     * @return {{payload: *, type: string}}
     */
  setLayoutConfigsWithPageRefresh: payload => ({
    payload,
    type: actionTypes.SetLayoutConfigsWithPageRefresh
  }),
    /**
     *
     * @param payload
     * @return {{payload: *, type: string}}
     */
  setHtmlClassService: payload => ({
    payload,
    type: actionTypes.SetHtmlClassService
  })
};
