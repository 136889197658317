import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {checkHttpStatus, get} from "../../utils/RestUtilsWithAxios";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {GET_ATTORNEYS,GET_KAP_MEMBERS} from "../../constants/uri";
import * as Globals from "../../constants/globals";
import PaySenetleri from './PaySenetleri';
import PayDisi from './PayDisi';
import {alertBox} from "../../components/BoxProvider";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {Button} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    navButton: {
        maxWidth:'100%',
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Globals.COLOR_TRANSPARENT_RED,
        borderRadius: 4,
    },
    activeNavButton: {
        maxWidth:'100%',
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Globals.COLOR_FULL_RED,
        borderRadius: 4,
    },
    nav: {
        backgroundColor: "white",
        marginBottom:16,
        justifyContent: "space-around",
        height: "unset",
        padding: 5,
        borderRadius: 4,
        paddingLeft: 0,
        paddingRight: 0
    },
    navText: {
        color: "white",
        fontSize: 14
    },
}));

/**
 * GeneralContainer
 * @param props
 * @return {*}
 * @constructor
 */
function GeneralContainer(props) {
    React.useEffect(()=>{
        get(GET_ATTORNEYS)
            .then(function (response) {
                var setList = [];
                for (var i = 0; i < response.data.length; i++) {
                    if(i===0){
                        setMyselfId(response.data[i].key);
                    }
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setAttorneys(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            });
        get(GET_KAP_MEMBERS)
            .then(function (response) {
                var setList = [];
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setCompanies(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            });
    },[]);

    const classes = useStyles();
    const [pageValue, setPageValue] = React.useState(0);
    const [attorneys,setAttorneys] = React.useState([]);
    const [myselfId,setMyselfId] = React.useState('');
    const [companies,setCompanies] = React.useState([]);
    const [openInfo,setOpenInfo] = React.useState(true);

    return(
        <div className={classes.root}>

                <Dialog
                    open={openInfo}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true }
                >
                    <DialogContent>
                        <div className="swal-icon swal-icon--info"></div>
                        <div className="swal-title" >Uyarı!</div>
                        <div className="swal-text" >
                        Bu alandan Sermaye Piyasası Kurulu’nun II-15.1 sayılı Özel Durumlar Tebliği’nin 11, 12 ve 22. maddeleri kapsamında yapılan işlemlerin Merkezi Kayıt Kuruluşu A.Ş.’ye bildirimi yapılmaktadır.<br></br><br></br>Mevzuat kapsamında kamuya açıklanma zorunluluğu bulunmayan işlemler bildirilmemelidir.<br></br><br></br> <u>Ayrıca, bu alandan test amaçlı bildirim gönderimi kesinlikle yapılmamalıdır.</u>
                        </div>
                    </DialogContent>
                    <DialogActions style={{padding:16}}>
                        <Button onClick={() => setOpenInfo(false)} color="primary" autoFocus>
                            {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                        </Button>
                    </DialogActions>
                </Dialog>


            <BottomNavigation
                value={pageValue}
                onChange={(event, newValue) => {
                    setPageValue(newValue);
                }}
                showLabels
                classes={{root: classes.nav}}
            >
                <BottomNavigationAction
                    label={<span className={classes.navText}>Pay Senetlerine İlişkin Bildirimler</span>}
                    classes={{root: pageValue === 0 ? classes.activeNavButton : classes.navButton}}  />
                <BottomNavigationAction
                    label={<span className={classes.navText}>Pay Dışında Sermaye Piyasası Araçlarına İlişkin
                        Bildirimler</span>}
                    classes={{root: pageValue === 1 ? classes.activeNavButton : classes.navButton}} />
            </BottomNavigation>
            {
                pageValue === 0 ?
                    <PaySenetleri attorneys={attorneys} myselfId={myselfId} companies={companies}/>
                :
                    <PayDisi attorneys={attorneys} myselfId={myselfId} companies={companies}/>
            }


        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(GeneralContainer)
);