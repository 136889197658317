import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as Globals from "../../constants/globals";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(9),
        },
    },
    fontLight: {
        fontWeight: 'lighter'
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        marginLeft: 22,
        marginTop: -8,
        fontSize: 16
    },
    textInBox: {
        color: "white"
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30
    },
    box: {
        padding: 10,
        borderRadius: 4,
    },
    unlimitedText: {
        [theme.breakpoints.down('xs')]: {
            fontSize:16
        },
        color: "white",
    },
}))


/**
 * HpksLinkPage
 * @param props
 * @return {*}
 * @constructor
 */
function HpksLinkPage(props) {
    const classes = useStyles();
    const [fade, setFade] = useState(false);
    const [color, setColor] = useState(Globals.COLOR_DARK_GRAY);

    useEffect(()=>{
        setFade(true)
    },[]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Fade in={fade}><div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className={classes.box} style={{backgroundColor:color}}>
                                <Typography classes={{h5: classes.unlimitedText}} variant="h5">
                                    {props.intl.formatMessage({ id: "HPKS" })}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography  variant='h6' display='inline' classes={{h6: classes.fontLight}}><Link
                                to={{ pathname: "https://hpks.mkk.com.tr/auth/login" }} target={"_blank"}>
                                Hamiline Pay Kayıt Sistemi(HPKS)</Link></Typography>
                        </Grid>

                    </Grid>
                </div></Fade>
            </Paper>
            <Paper className={classes.infoMsg}>
                <Grid container style={{backgroundColor: "white"}}>
                    <Typography display="inline" variant="subtitle1">
                        {props.intl.formatMessage({id:"HPKS.MESSAGE.DETAILED_INFO"})}
                    </Typography>
                </Grid>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(HpksLinkPage)
);