import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import { alertBox } from '../../components/BoxProvider';
import { GET_AVAILABLE_LIMIT } from '../../constants/uri';
import * as Globals from '../../constants/globals';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
    },
    box: {
        padding: 10,
        borderRadius: 4,
    },
    thinBox: {
        padding: 32,
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'solid'
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        marginLeft: 22,
        marginTop: -8,
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize:12
        },
    },
    textInBox: {
        color: "white",
    },
    moneyText: {
        [theme.breakpoints.down('xs')]: {
            fontSize:20
        },
        color: "white",
    },
    unlimitedText: {
        [theme.breakpoints.down('xs')]: {
            fontSize:16
        },
        color: "white",
    }
    
}))

/**
 * KFSLimit
 * @param props
 * @return {*}
 * @constructor
 */
function KFSLimit(props) {
    const classes = useStyles();
    const [limitData, setLimitData] = useState(null);
    const [color, setColor] = useState(Globals.COLOR_DARK_GRAY);
    const [fade, setFade] = useState(false);

    useEffect(()=>{
        get(GET_AVAILABLE_LIMIT)
        .then(function(response){
            setLimitData(response.data);
            if(!response.data.status){
                alertBox("", response.data.exception, Globals.ALERT_ERROR, props.intl);
            }
            if(response.data.availableLimit === "UNLIMITED"){
                setColor(Globals.COLOR_FULL_RED);
            }
            setFade(true);
        })
        .catch(function(error){
            checkHttpStatus(error,props.intl);
        })
    },[]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Fade in={fade}>
                {
                    limitData && limitData.status ?
                    <div style={{position:'relative'}}>
                        <div className={classes.boxTitle} style={{color:color}} >{props.intl.
                        formatMessage({ id: "KFS_LIMIT.YOUR_LIMIT_HEADER" })}</div>
                        <div className={classes.thinBox} style={{borderColor:color}}>
                            <div className={classes.box} style={{backgroundColor:color}}>
                                {
                                    limitData.availableLimit && limitData.availableLimit !== 'UNLIMITED' ?
                                    <div>
                                        <Typography classes={{h6: classes.textInBox}}
                                                    gutterBottom variant="h6">Açık Limit</Typography>
                                        <Typography classes={{h3: classes.moneyText}} display="inline" variant="h3">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                          value={limitData.availableLimit.replace(".",",")}
                                                          decimalSeparator=','
                                                          thousandSeparator='.'
                                                          displayType='text' />
                                        </Typography>
                                        <Typography classes={{h6: classes.textInBox}} style={{marginLeft:5}}
                                                    display="inline" variant="h6">TL</Typography>
                                    </div>
                                    :
                                    <Typography classes={{h5: classes.unlimitedText}} variant="h5">
                                        {props.intl.formatMessage({ id: "KFS_LIMIT.UNLIMITED_MESSAGE" })}
                                    </Typography>
                                }
                            </div>                      
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant='h6'>
                            {props.intl.formatMessage({ id: "ALERT.KFS_LIMIT.CANNOT_BE_SHOWN" })}
                        </Typography>
                    </div>
                }
                </Fade>
            </Paper>
            <Paper className={classes.paper}>
                <Fade in={fade}>
                {
                    limitData && limitData.status ?
                    <div style={{position:'relative'}}>
                        <div className={classes.boxTitle} style={{color:color}} >{props.intl.
                        formatMessage({ id: "KFS_LIMIT.YOUR_DEBT_BASED_LIMIT_HEADER" })}</div>
                        <div className={classes.thinBox} style={{borderColor:color}}>
                            <div className={classes.box} style={{backgroundColor:color}}>
                                {
                                    limitData.availableLimitForDbit && limitData.availableLimitForDbit !== 'UNLIMITED' ?
                                    <div>
                                        <Typography classes={{h6: classes.textInBox}}
                                                    gutterBottom variant="h6">Borçlanmaya Dayalı Açık Limit</Typography>
                                        <Typography classes={{h3: classes.moneyText}} display="inline" variant="h3">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                          value={limitData.availableLimitForDbit.replace(".",",")}
                                                          decimalSeparator=','
                                                          thousandSeparator='.'
                                                          displayType='text' />
                                        </Typography>
                                        <Typography classes={{h6: classes.textInBox}} style={{marginLeft:5}}
                                                    display="inline" variant="h6">TL</Typography>
                                    </div>
                                    :
                                    <Typography classes={{h5: classes.unlimitedText}} variant="h5">
                                        {props.intl.formatMessage({ id: "KFS_LIMIT.DEBT_BASED_UNLIMITED_MESSAGE" })}
                                    </Typography>
                                }
                            </div>                      
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant='h6'>
                            {props.intl.formatMessage({ id: "ALERT.KFS_DEBT_BASED_LIMIT.CANNOT_BE_SHOWN" })}
                        </Typography>
                    </div>
                }
                </Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(KFSLimit)
);