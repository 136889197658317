import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import {PieChart} from 'react-minimal-pie-chart';
import Button from '@material-ui/core/Button';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import * as Globals from "../../../constants/globals";


const useStyles = makeStyles(theme => ({
    totalValueBox: {
        backgroundColor: Globals.COLOR_DARK_GRAY,
        padding: 10,
        borderRadius: 4,
        margin: 8,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: 4
        },
    },
    totalValueTitle: {
        color: "white"
    },
    totalValueContent: {
        color: "white",
        [theme.breakpoints.down('xs')]: {
            fontSize:14
        },
    },
    selectedRatioBox: {
        padding: 10,
        borderRadius: 4,
        margin: 8,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: 4
        },
    },
    selectedValueBox:{
        padding: 10,
        borderRadius: 4,
        margin: 8,
        display:"flex",
        flex:1,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: 4
        },
    },
    selectedMarketValue: {
        textAlign: "center",
        fontSize: 16,
        position:"absolute"
    },
    chartWrapper: {
        position:"relavite",
        [theme.breakpoints.up('sm')]: {
            maxWidth: 240
        },
    },
    animatedItem: {
        animation: `$myEffect 800ms cubic-bezier(0.83, 0, 0.17, 1) `
    },
    "@keyframes myEffect": {
        "0%": {
          opacity: 0,
          transform: "scaleX(0)"
        },
        "100%": {
          opacity: 1,
          transform: "scaleX(1)"
        }
    },
}))

/**
 * PortfolioPieChart
 * @param props
 * @return {*}
 * @constructor
 */
function PortfolioPieChart(props) {

    const color = ["#97616d", "#6380a9", "#b16787", "#785e58", "#d8725d", "#785e58", "#b16787", "#6380a9",
        "#97616d", "#a04130"];

    const classes = useStyles();
    const theme = useTheme();
    const notxs = useMediaQuery(theme.breakpoints.up('sm'));
    const [selectedSegment, setSelectedSegment] = React.useState(null);
    const [openInfo,setOpenInfo] = React.useState(false);
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        var tempData = [];
        props.data.map((d, i) => {
            var item={
                    color: color[i%9],
                    title: d.type,
                    value: d.ratio < 5 ? 5 : d.ratio
                }
            tempData.push(item)
        })
        setData(tempData);
     }, [])

     React.useEffect(() => {
        var tempData = [];
        props.data.map((d, i) => {
            var item={
                    color: color[i%9],
                    title: d.type,
                    value: d.ratio < 1 ? 1 : d.ratio
                }
            tempData.push(item)
        })
        setData(tempData);
     }, [props.data])
    


    return ( 
            <Grid container item direction="row-reverse" xs={12} md={8}>
                <Grid container justify="center" alignItems="center" item xs={12} sm className={classes.chartWrapper}>
                    <div style={{width:"100%", textAlign:"right"}}>
                        <Button color="primary" onClick={() => setOpenInfo(true)} style={{marginLeft:'auto'}}>
                            <InfoIcon fontSize='medium' style={{marginRight:8}} />
                        </Button>
                    </div>
                    <div>
                        <Dialog
                            open={openInfo}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"PORTFÖY VE HESAP BİLGİLERİ"}</DialogTitle>
                            <DialogTitle id="alert-dialog-title">{"Bilgilendirme"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_1"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_2"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_3"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_4"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_5"})}
                                    <ul style={{ listStyleType: "disc" }}>
                                        <li>Pay Senetleri bakiye kaydı 1 adet = 1 TL/Nominal sermayedir. (Yabancı para cinsinden ihraç edilen pay senetleri için izahnamede belirtilen kapsamda izleme yapılabilmektedir. 1 adet = 2 Euro sermaye gibi.)</li>
                                        <li>Devlet ve Özel Sektör Borçlanma Araçları, Kira Sertifikaları, Varlığa/İpoteğe Dayalı ve Varlık/İpotek Teminatlı Menkul Kıymetler ve benzer özellikteki sermaye piyasası araçları için bakiye adedi = Nominal değerdir.</li>
                                        <li>Yatırım Fonları ve Borsa Yatırım Fonları bakiye adedi = Katılım payıdır.</li>
                                        <li>Varantlar, Gayrimenkul Sertifikaları ve diğer sertifikalar için bakiye adedi = İhraç edildikleri adettir. (Sertifika adedi)</li>
                                        <li>Elektronik Ürün Senetleri (ELÜS) için bakiye adedi = Kilogramdır.</li>
                                    </ul>
                                </DialogContentText>
                                {/*<DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_4"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_5"})}
                                </DialogContentText>*/}
                            </DialogContent>
                            <DialogActions style={{padding:16}}>
                                <Button onClick={() => setOpenInfo(false)} color="primary" autoFocus>
                                    {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div style={{maxWidth: notxs ? 240 : 200}}>
                        <PieChart
                            data={data}
                            radius={PieChart.defaultProps.radius - 6}
                            lineWidth={60}
                            startAngle={240}
                            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                            segmentsShift={(index) => (index === selectedSegment ? 5 : 1)}
                            animate
                            animationEasing="cubic-bezier(0.83, 0, 0.17, 1)"
                            animationDuration={800}
                            label={({ dataEntry }) =>
                                {   if(dataEntry.value <= 5)
                                        return ""
                                    else if (dataEntry.value.toString().includes("."))
                                        return '%' + parseFloat(dataEntry.value.toString()+"1").toFixed(2)
                                    else
                                        return '%' + dataEntry.value.toString()
                                }
                            }
                            labelPosition={100 - 60/2 }
                            labelStyle={
                                {
                                fill: '#fff',
                                opacity: 0.8,
                                fontSize: 6,
                                pointerEvents: 'none',
                                }
                            
                            }
                            onClick={(_, index) => {
                                setSelectedSegment(index === selectedSegment ? null : index);
                            }}
                            // onMouseOver={(_, index) => {
                            //     setHovered(index);
                            // }}
                            // onMouseOut={() => {
                            //     setHovered(undefined);
                            // }}
                        />
                    </div>
                </Grid>

                <Grid className={classes.animatedItem} container direction="column" item xs={12} sm >
                    {
                        props.totalMarketValue ?
                        <Grid direction="column" justify="center" item className={classes.totalValueBox}>
                            <Typography classes={{subtitle2: classes.totalValueTitle}}
                                        gutterBottom variant="subtitle2">{props.intl.
                            formatMessage({id: "PORTFOLIO.COMMON.TOTAL_MARKET_VALUE"})}</Typography>
                            <Typography classes={{h4: classes.totalValueContent}} display="inline"
                                        gutterBottom variant="h4">
                                <NumberFormat decimalScale={2} fixedDecimalScale={2} value={props.totalMarketValue}
                                              decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                <Typography classes={{h6: classes.totalValueContent}} display="inline"
                                            variant="h6"> TL</Typography>
                            </Typography>
                        </Grid>
                        : null
                    }
                    {   selectedSegment !== null ?
                        <Grid item className={classes.totalValueBox} style={{backgroundColor:color[selectedSegment]}}>
                            <Typography variant="h6"
                                        classes={{h6:classes.totalValueContent}}>{props.data[selectedSegment].type}
                                        </Typography>
                        </Grid>
                        :
                        null
                    }
                    <Grid style={{display:"flex"}}>
                        {
                            selectedSegment !== null ?
                            <Grid item className={classes.selectedRatioBox}
                                  style={{backgroundColor:color[selectedSegment]}}>
                                <div>
                                    <Typography classes={{subtitle2: classes.totalValueTitle}}
                                                gutterBottom variant="subtitle2">Portföy Dağılımı</Typography>
                                    <Typography classes={{h4: classes.totalValueContent}} display="inline"
                                                gutterBottom variant="h4">
                                        <NumberFormat prefix="%" value={props.data[selectedSegment].ratio}
                                                      decimalSeparator=',' thousandSeparator='.' decimalScale={2}
                                                      displayType='text' />
                                    </Typography>
                                </div>
                            
                            </Grid>
                            :
                            null
                        }
                        {
                            selectedSegment !== null ?
                            <Grid continer item className={classes.selectedValueBox}
                                  style={{backgroundColor:color[selectedSegment]}}>
                                <div>
                                    <Typography classes={{subtitle2: classes.totalValueTitle}}
                                                gutterBottom variant="subtitle2">Piyasa Değeri</Typography>
                                    <Typography classes={{h4: classes.totalValueContent}} display="inline"
                                                gutterBottom variant="h4">
                                        {props.data[selectedSegment].value=="-"?"":<NumberFormat decimalScale={2} fixedDecimalScale={2}
                                                      value={props.data[selectedSegment].value} decimalSeparator=','
                                                      thousandSeparator='.' displayType='text' />}
                                        <Typography classes={{h6: classes.totalValueContent}} display="inline"
                                                    variant="h6"> {props.data[selectedSegment].value=="-"?"-":"TL"}</Typography>
                                    </Typography>
                                </div>
                            </Grid>
                            :
                            <Grid container item className={classes.selectedValueBox}
                                  style={{backgroundColor:Globals.COLOR_LIGHT_GRAY}}>
                                <div>
                                    <Typography classes={{h6: classes.totalValueContent}} display="inline"
                                                gutterBottom variant="h6">
                                        Grafiğe tıklayarak bir kıymet türü seçiniz
                                    </Typography>
                                </div>
                            </Grid>
                        }          
                    </Grid>
                </Grid>
            </Grid>
    )

}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(PortfolioPieChart)
);