import React, { useEffect } from "react";
import { useState } from 'react';
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
import MaterialUIDatePicker from '../../../components/EyatUIDatePicker';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {checkHttpStatus, get, post} from "../../../utils/RestUtilsWithAxios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import {
    GET_AUTOMATIC_BLOCKAGE_ACCOUNTS, GET_AUTOMATIC_BLOCKAGE_ADD_DEF_CODES,
    GET_AUTOMATIC_BLOCKAGE_ALL_ISIN_TYPES, GET_AUTOMATIC_BLOCKAGE_AMOUNT, GET_AUTOMATIC_BLOCKAGE_ISIN_ACCOUNT,
    GET_AUTOMATIC_BLOCKAGE_ISIN_BY_ISIN_TYPES,
    GET_AUTOMATIC_BLOCKAGE_ISIN_MEMBER, GET_AUTOMATIC_BLOCKAGE_ISIN_TYPES_ACCOUNT,
    GET_AUTOMATIC_BLOCKAGE_ISIN_TYPES_MEMBER, GET_AUTOMATIC_BLOCKAGE_MEMBERS,
    GET_PARAMETER_DATA, SET_AUTOMATIC_BLOCKAGE,GET_CONFIG_PARAM
} from "../../../constants/uri";
import clsx from 'clsx';
import {alertBox} from "../../../components/BoxProvider";
import * as Globals from "../../../constants/globals";
import { Formik, } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    formControl: {
        width: '100%',
    },
    title: {
        flex: '0 0 auto',
        textAlign: 'left'
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    whiteText: {
        color:'white'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

/**
 * ApplyAutomaticBlockage
 * @param props
 * @return {*}
 * @constructor
 */
function ApplyAutomaticBlockage(props) {
    const classes = useStyles();
    const [selectedBeginDate,setSelectedBeginDate] = useState(beginInit());
    const [selectedEndDate,setSelectedEndDate] = useState(endInit());

    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    /**
     * beginInit
     * @return {string}
     */
    function beginInit(){
        var date = new Date();
        date.setDate(date.getDate());
        return date.getFullYear().toString()+(formatMonthOrDay(date.getMonth()+1)).toString()+
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * endInit
     * @return {string}
     */
    function endInit() {
        var date = new Date();
        var day = 60 * 60 * 24 * 1000;
        var endDate = new Date(date.getTime() + day);
        return endDate.getFullYear().toString()+(formatMonthOrDay(endDate.getMonth() + 1)).toString()+
            (formatMonthOrDay(endDate.getDate())).toString();
    }
    const [values, setValues] = React.useState({
        startDate: '',
        endDate: '',
        blockageTypes: '',
        isinTypes: '',
        members: '',
        isin: '',
        addDefCode: '',
        mbrAccNo: '',
        amount: '',
    });

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [blockageTypes, setBlockageTypes] = React.useState([]);
    const [timeRangeParam, setTimeRangeParam] = React.useState('');
    const [isinTypes, setIsinTypes] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [isin, setIsin] = React.useState([]);
    const [addDefCode, setAddDefCode] = React.useState([]);
    const [mbrAccNo, setMbrAccNo] = React.useState([]);
    const [amount, setAmount] = React.useState('');
    const [checked, setCheckBox] = useState(true);
    const [infoList, setInfoList] = useState([]);
    const [openInfo,setOpenInfo] = React.useState(false);
    const [loading, setLoading] = useState(false);
    /**
     * enableLoading - setLoading(true)
     */
    const enableLoading = () => {
        setLoading(true);
    };
    /**
     * disableLoading - setLoading(false)
     */
    const disableLoading = () => {
        setLoading(false);
    };

    const formikRef = (node) => {
        if (node !== null) {
            setValues(node.values);
        }
    }


    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        getBlockageType();
    }, []);

    useEffect(() =>{
        clearFieldsData();
        if(values.blockageTypes[0] === "1") {
            getIsinType();
        }
        else if(values.blockageTypes[0] === "2") {
            getAllIsinTypes();
        }
        else if(values.blockageTypes[0] === "3") {
            getMembers();
        }
        else if(values.blockageTypes[0] === "4") {
            getMembers();
        }
        else if(values.blockageTypes[0] === "5") {
            getMembers();
        }
    },[values.blockageTypes])

    useEffect(() => {
        if(values.blockageTypes[0] === "3") {
            getIsinType();
        }
        else if(values.blockageTypes[0] === "4") {
            getIsinTypesMember(values.members.key);
            values.isin = '';
        }
        else if(values.blockageTypes[0] === "5") {
            getAccounts(values.members.key);
            values.isin = '';
            values.isinTypes = '';
        }
        setAmount('');
        values.addDefCode = '';
        if (document.getElementById("manualAmount") != null)
            document.getElementById("manualAmount").value = null;
    },[values.members])

    useEffect(() => {
        if(values.blockageTypes[0] === "2") {
            getIsinByIsinTypes(values.isinTypes.key);
        }
        else if(values.blockageTypes[0] === "4") {
            getIsinMember(values.members.key,values.isinTypes.key);
        }
        else if(values.blockageTypes[0] === "5") {
            getIsinAccount(values.members.key,values.isinTypes.key,values.mbrAccNo.key);
        }
        setAmount('');
        values.addDefCode = '';
        if (document.getElementById("manualAmount") != null)
            document.getElementById("manualAmount").value = null;
    },[values.isinTypes])

    useEffect(() => {
        values.addDefCode = '';
        if(values.blockageTypes[0] === "1" || values.blockageTypes[0] === "3")
            getAddDefCodes(values.isinTypes[1], values.isin.key);
        else
            getAddDefCodes(values.isinTypes.key, values.isin.key);

        setAmount('');
        setCheckBox(true);
    },[values.isin]) 

    useEffect(() => {
        if(values.blockageTypes[0] === "5") {
            getAmount(values.isinTypes.key, values.members.key, values.mbrAccNo.key, values.addDefCode.key,
                values.isin.key);
        }
    },[values.addDefCode])

    useEffect(() => {
        if(values.blockageTypes[0] === "5") {
            getIsinTypesAccount(values.members.key,values.mbrAccNo.key);
        }
        setAmount('');
        values.isin = '';
        values.addDefCode = '';
        if (document.getElementById("manualAmount") != null)
            document.getElementById("manualAmount").value = null;
    },[values.mbrAccNo])

    /**
     * clearFieldsData
     */
    function clearFieldsData() {

        setIsinTypes([]);
        setMembers([]);
        setIsin([]);
        setAddDefCode([]);
        setMbrAccNo([]);
        setAmount('');
        values.isinTypes = '';
        values.members = '';
        values.isin= '';
        values.addDefCode= '';
        values.mbrAccNo= '';
        values.amount= '';

    }

    /**
     * getBlockageType
     */
    function getBlockageType() {
        post(GET_PARAMETER_DATA, {name:'BI002_BLOCKAGE_TYPE'}, props.intl).then(function (response) {
            setBlockageTypes(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }

    /**
     * getIsinType
     */
    function getIsinType() {
        post(GET_PARAMETER_DATA, {name:'BI002_ISIN_TYPE'}, props.intl).then(function (response) {
            setIsinTypes(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }

    /**
     * getAllIsinTypes
     */
    function getAllIsinTypes() {
        get(GET_AUTOMATIC_BLOCKAGE_ALL_ISIN_TYPES,props.intl).then(function (response) {
            setIsinTypes(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }

    /**
     * getMembers
     */
    function getMembers() {
        get(GET_AUTOMATIC_BLOCKAGE_MEMBERS,props.intl).then(function (response) {
            setMembers(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }

    /**
     * getIsinByIsinTypes
     * @param isinType
     */
    function getIsinByIsinTypes(isinType) {
        post(GET_AUTOMATIC_BLOCKAGE_ISIN_BY_ISIN_TYPES, {isinType: isinType}, props.intl).then(function (response) {
            setIsin(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });

    }

    /**
     * getIsinTypesMember
     * @param member
     */
    function getIsinTypesMember(member) {
        post(GET_AUTOMATIC_BLOCKAGE_ISIN_TYPES_MEMBER, {member: member}, props.intl).then(function (response) {
            setIsinTypes(response.data);

        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        })
        
    }

    /**
     * getIsinMember
     * @param member
     * @param isinType
     */
    function getIsinMember(member,isinType) {
        post(GET_AUTOMATIC_BLOCKAGE_ISIN_MEMBER, {member: member, isinType: isinType}, props.intl).
        then(function (response) {
            setIsin(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
        
    }

    /**
     * getAddDefCodes
     * @param isinType
     * @param isin
     */
    function getAddDefCodes(isinType,isin) {
        post(GET_AUTOMATIC_BLOCKAGE_ADD_DEF_CODES, {isinType: isinType, isin:isin}, props.intl).
        then(function (response) {
            setAddDefCode(response.data);

        }).catch(function (error) {
            checkHttpStatus(error,props.intl)
        })
    }

    /**
     * getAccounts
     * @param member
     */
    function getAccounts(member) {
        post(GET_AUTOMATIC_BLOCKAGE_ACCOUNTS, {member: member}, props.intl).then(function (response) {
            setMbrAccNo(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        })
    }

    /**
     * getIsinTypesAccount
     * @param member
     * @param mbrAccNo
     */
    function getIsinTypesAccount(member, mbrAccNo) {
        post(GET_AUTOMATIC_BLOCKAGE_ISIN_TYPES_ACCOUNT, {member:member, mbrAccNo:mbrAccNo}, props.intl).
        then(function (response) {
            setIsinTypes(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        })
    }

    /**
     * getIsinAccount
     * @param member
     * @param isinType
     * @param mbrAccNo
     */
    function getIsinAccount(member, isinType, mbrAccNo) {
        post(GET_AUTOMATIC_BLOCKAGE_ISIN_ACCOUNT, {member:member, isinType:isinType, mbrAccNo:mbrAccNo}, props.intl).
        then(function (response) {
            setIsin(response.data);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        })
    }

    /**
     * getAmount
     * @param isinType
     * @param member
     * @param mbrAccNo
     * @param addDefCode
     * @param isin
     */
    function getAmount(isinType, member, mbrAccNo, addDefCode, isin) {
        post(GET_AUTOMATIC_BLOCKAGE_AMOUNT, {isinType:isinType, member:member, mbrAccNo:mbrAccNo,
            addDefCode:addDefCode, isin:isin}, props.intl).then(function (response) {
            setAmount(formatDecimal(response.data.toString()));
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        })
    }

    /**
     * formatDecimal
     * @param num
     * @return {string}
     */
    function formatDecimal(num) {
        var formattedNum = ''
        if(num.includes('.'))
            formattedNum = num.split('.')[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',' + num.split('.')[1]
        else
            formattedNum = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        return formattedNum
    }

    /**
     * handleCheckBox
     */
    function handleCheckBox() {
        setCheckBox(!checked);
    }

    var re = new RegExp("^[0-9]+([,]){0,1}[0-9]*}?$");

    /**
     * handleManuelAmountChange
     * @param event
     */
    function handleManuelAmountChange(event) {
        if(event.target.value)
            if (!re.test(event.target.value)) {
                document.getElementById("manualAmount").value = event.target.value.
                substring(0,event.target.value.length-1);
            }
    }

    const headStyles = makeStyles(theme => ({
        headFont : {
            fontSize: 13,
            fontWeight : 600
        }
    }));

    const headClasses = headStyles();

    /**
     *  autoBlockageInfoTable
     *  @param rows
     */
    function autoBlockageInfoTable(rows) {

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                        <Toolbar
                            className={clsx(classes.root)}
                        >
                            <div className={classes.title}>
                                <Typography style={{color: "black"}} variant="h6" id="tableSuccessMessageTitle">
                                    İşlem Sonuçlarınız
                                </Typography>
                            </div>
                        </Toolbar>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={headClasses.headFont}>Üye</TableCell>
                                        <TableCell className={headClasses.headFont}>Hesap</TableCell>
                                        <TableCell className={headClasses.headFont}>Kıymet Türü</TableCell>
                                        <TableCell className={headClasses.headFont}>Kıymet</TableCell>
                                        <TableCell className={headClasses.headFont}>Ek Tanım</TableCell>
                                        <TableCell className={headClasses.headFont}>Adet</TableCell>
                                        <TableCell className={headClasses.headFont}>Başlangıç Tarihi</TableCell>
                                        <TableCell className={headClasses.headFont}>Bitiş Tarihi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow key={row.member}>
                                            <TableCell className={classes.fontSize13}>{row.member}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.accountNo}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.isinType}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.isin}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.addDef}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.amount}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.startDate}</TableCell>
                                            <TableCell className={classes.fontSize13}>{row.endDate}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                </Paper>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Formik
                    initialValues={values}
                    innerRef={formikRef}
                    validate={values => {
                        const errors = {};
                        if (!values.blockageTypes) {
                            errors.blockageTypes = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (!values.isinTypes) {
                            errors.isinTypes = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if(!values.members && (values.blockageTypes[0] === "3" || values.blockageTypes[0] === "4"
                                || values.blockageTypes[0] === "5")) {
                            errors.members = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if(!values.mbrAccNo && values.blockageTypes[0] === "5") {
                            errors.mbrAccNo = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if(!values.isin && (values.blockageTypes[0] === "2" || values.blockageTypes[0] === "4"
                                || values.blockageTypes[0] === "5")) {
                            errors.isin = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if(!values.addDefCode && (values.blockageTypes[0] === "2" || values.blockageTypes[0] === "4"
                                || values.blockageTypes[0] === "5")) {
                            errors.addDefCode = props.intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        enableLoading();
                        var isinTypeKey;
                        var isinTypeText;
                        if(values.blockageTypes[0] === "1" || values.blockageTypes[0] === "3") {
                            isinTypeKey = values.isinTypes[0];
                            isinTypeText = values.isinTypes[1];
                        }
                        else {
                            isinTypeKey = values.isinTypes.key;
                            isinTypeText = values.isinTypes.text;
                        }

                        var clearDotsFromAmount = amount.replace(/\./g, '');
                        var manualAmount = document.getElementById("manualAmount") !== null ?
                            document.getElementById("manualAmount").value : '0';
                        var clearDotsFromManualAmount = manualAmount.replace(/\./g, '');

                        var postData = {member:values.members.text,
                            isinTypeKey: isinTypeKey,
                            isinTypeText: isinTypeText,
                            mbrAccNo:values.mbrAccNo.text,
                            isinKey: values.isin.key,
                            isinText: values.isin.text,
                            addDefCode: values.addDefCode.text,
                            existingAmount: clearDotsFromAmount,
                            blockageClassKey: values.blockageTypes[0],
                            blockageClassText: values.blockageTypes[1],
                            manualAmount: clearDotsFromManualAmount,
                            startDate: selectedBeginDate,
                            endDate: selectedEndDate,
                            amountCheck: checked

                        }
                        post(SET_AUTOMATIC_BLOCKAGE, postData, props.intl ).then(function (response) {
                            setInfoList(response.data.infoList);
                            if(response.data.resultMsg != null)
                                alertBox("", response.data.resultMsg, Globals.ALERT_INFO, props.intl);
                            setSubmitting(false)
                            disableLoading();
                        }).catch(function (error) {
                            disableLoading();
                            setSubmitting(false);
                            checkHttpStatus(error, props.intl)
                        })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <Typography> 
                                Otomatik blokaj sistemi yatırımcıların günün herhangi bir saatinde gerek mevcut
                                kıymetlerine gerekse gelecekte hesabında bulunacak kıymetlere yönelik kıymet ve adet
                                bazında bloke altına alma talimatı vermelerine imkan sağlamaktadır.
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: 'end'}}>
                        <Button className={classes.button} color="primary" onClick={() => setOpenInfo(true)}>
                            <InfoIcon fontSize='small' style={{marginRight:8}}/>
                            {props.intl.formatMessage({id: "AUTOMATIC_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO_POPUP"})}
                        </Button>
                        <div>
                            <Dialog
                                open={openInfo}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Blokaj koyma işlemleri hakkında bilgi"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                        {props.intl.
                                        formatMessage({id: "AUTOMATIC_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO_POPUP_1"})}
                                    </DialogContentText>
                                    <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                        {props.intl.
                                        formatMessage({id: "AUTOMATIC_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO_POPUP_2"})}
                                    </DialogContentText>
                                    <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                        {props.intl.
                                        formatMessage({id: "AUTOMATIC_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO_POPUP_3"})}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions style={{padding:16}}>
                                    <Button onClick={() => setOpenInfo(false)} color="primary" autoFocus>
                                        {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.title}>
                            <Typography style={{color: "black"}} variant="h6" id="tableTitle">
                                Otomatik Blokaj Talimatı Ekleme
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <MaterialUIDatePicker
                            noMargin
                            containerId="mui-pickers-date1"
                            setContainerSelectedDate={setSelectedBeginDate}
                            dateString={selectedBeginDate}
                            label={"Başlangıç Tarihi"}
                            disablePast={true}
                            style={{width: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <MaterialUIDatePicker
                            noMargin
                            containerId="mui-pickers-date2"
                            setContainerSelectedDate={setSelectedEndDate}
                            dateString={selectedEndDate}
                            label={"Bitiş Tarihi"}
                            disablePast={true}
                            style={{width: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <FormControl required  variant="outlined" className={classes.formControl}>
                            <InputLabel ref={inputLabel} htmlFor="blockageTypes">
                                Blokaj Türü
                            </InputLabel>
                            <Select
                                value={values.blockageTypes}
                                onChange={handleChange}
                                input={<OutlinedInput labelWidth={labelWidth} name="blockageTypes" id="blockageTypes"
                                error={errors.blockageTypes && touched.blockageTypes && errors.blockageTypes}
                                />}
                            >
                                {blockageTypes.map((item,i)=><MenuItem selected={i===0} key={i} value={item}>{item[1]}
                                </MenuItem>)}
                            </Select>
                            <FormHelperText>{errors.blockageTypes}</FormHelperText>
                        </FormControl>
                    </Grid>
                    { values.blockageTypes[0] === "1" || values.blockageTypes[0] === "2" ||
                    values.blockageTypes[0] === "3" || values.blockageTypes[0] === "4" ||
                    values.blockageTypes[0] === "5" ?
                    <Grid item xs={12} sm={6} lg={4}>
                        {   values.blockageTypes[0] === "1"  ?
                        <FormControl required variant="outlined" className={classes.formControl}>
                            <InputLabel ref={inputLabel} htmlFor="isinTypes">
                                Kıymet Türü
                            </InputLabel>
                            <Select
                                value={values.isinTypes}
                                onChange={handleChange}
                                input={<OutlinedInput labelWidth={labelWidth} name="isinTypes" id="isinTypes"
                                error={errors.isinTypes && touched.isinTypes && errors.isinTypes}
                                />}
                            >
                                {isinTypes.map((item,i)=><MenuItem selected={i===0} key={i} value={item}>{item[1]}
                                </MenuItem>)}
                            </Select>
                            <FormHelperText>{errors.isinTypes}</FormHelperText>
                        </FormControl> : values.blockageTypes[0] === "2" ?
                                <FormControl required variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="isinTypes">
                                        Kıymet Türü
                                    </InputLabel>
                                    <Select
                                        value={values.isinTypes}
                                        onChange={handleChange}
                                        input={<OutlinedInput labelWidth={labelWidth} name="isinTypes" id="isinTypes"
                                        error={errors.isinTypes && touched.isinTypes && errors.isinTypes}
                                        />}
                                    >
                                        {isinTypes.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                           value={item}>{item.text}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>{errors.isinTypes}</FormHelperText>
                                </FormControl>
                                : values.blockageTypes[0] === "3" || values.blockageTypes[0] === "4" ||
                                values.blockageTypes[0] === "5" ?
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel ref={inputLabel} htmlFor="members">
                                            Kurum
                                        </InputLabel>
                                        <Select
                                            value={values.members}
                                            onChange={handleChange}
                                            input={<OutlinedInput labelWidth={labelWidth} name="members" id="members"
                                            />}
                                            error={errors.members && touched.members && errors.members}
                                        >
                                            {members.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                             value={item}>{item.text}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{errors.members}</FormHelperText>
                                    </FormControl>
                                    : null

                        }
                    </Grid> : null
                    }
                    { values.blockageTypes[0] === "2" || values.blockageTypes[0] === "3" ||
                    values.blockageTypes[0] === "4" || values.blockageTypes[0] === "5" ?
                    <Grid item xs={12} sm={6} lg={4}>
                        { values.blockageTypes[0] === "3" || values.blockageTypes[0] === "4"  ?
                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="isinTypes">
                                    Kıymet Türü
                                </InputLabel>
                                <Select
                                    value={values.isinTypes}
                                    onChange={handleChange}
                                    input={<OutlinedInput labelWidth={labelWidth} name="isinTypes" id="isinTypes"
                                    error={errors.isinTypes && touched.isinTypes && errors.isinTypes}
                                    />}
                                >
                                    { values.blockageTypes[0] === "3" ?
                                        isinTypes.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                          value={item}>{item[1]}</MenuItem>)
                                        :
                                        isinTypes.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                          value={item}>{item.text}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText>{errors.isinTypes}</FormHelperText>
                            </FormControl>
                            : values.blockageTypes[0] === "2" ?
                        <FormControl required variant="outlined" className={classes.formControl}>
                            <InputLabel ref={inputLabel} htmlFor="isin">
                                Kıymet
                            </InputLabel>
                            <Select
                                value={values.isin}
                                onChange={handleChange}
                                input={<OutlinedInput labelWidth={labelWidth} name="isin" id="isin"
                                error={errors.isin && touched.isin && errors.isin}
                                />}
                            >
                                {isin.map((item,i)=><MenuItem selected={i===0} key={i} value={item}>{item.text}
                                </MenuItem>)}
                            </Select>
                            <FormHelperText>{errors.isin}</FormHelperText>
                        </FormControl> : values.blockageTypes[0] === "5" ?
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel ref={inputLabel} htmlFor="mbrAccNo">
                                            Hesap
                                        </InputLabel>
                                        <Select
                                            value={values.mbrAccNo}
                                            onChange={handleChange}
                                            input={<OutlinedInput labelWidth={labelWidth} name="mbrAccNo" id="mbrAccNo"
                                            error={errors.mbrAccNo && touched.mbrAccNo && errors.mbrAccNo}
                                            />}
                                        >
                                            {mbrAccNo.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                              value={item}>{item.text}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{errors.mbrAccNo}</FormHelperText>
                                    </FormControl> : null
                        }
                    </Grid> : null
                    }
                    { values.blockageTypes[0] === "4" ||  values.blockageTypes[0] === "2" ||
                    values.blockageTypes[0] === "5" ?
                    <Grid item xs={12} sm={6} lg={4}>
                        { values.blockageTypes[0] === "4" ?
                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="isin">
                                    Kıymet
                                </InputLabel>
                                <Select
                                    value={values.isin}
                                    onChange={handleChange}
                                    input={<OutlinedInput labelWidth={labelWidth} name="isin" id="isin"
                                    error={errors.isin && touched.isin && errors.isin}
                                    />}
                                >
                                    {isin.map((item,i)=><MenuItem selected={i===0} key={i} value={item}>{item.text}
                                    </MenuItem>)}
                                </Select>
                                <FormHelperText>{errors.isin}</FormHelperText>
                            </FormControl>
                            : values.blockageTypes[0] === "2" ?
                        <FormControl required variant="outlined" className={classes.formControl}>
                            <InputLabel ref={inputLabel} htmlFor="addDefCode">
                                Ek Tanım
                            </InputLabel>
                            <Select
                                value={values.addDefCode}
                                onChange={handleChange}
                                input={<OutlinedInput labelWidth={labelWidth} name="addDefCode" id="addDefCode"
                                error={errors.addDefCode && touched.addDefCode && errors.addDefCode}
                                />}
                            >
                                {addDefCode.map((item,i)=><MenuItem selected={i===0} key={i} value={item}>{item.text}
                                </MenuItem>)}
                            </Select>
                            <FormHelperText>{errors.addDefCode}</FormHelperText>
                        </FormControl> : values.blockageTypes[0] === "5" ?
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel ref={inputLabel} htmlFor="isinTypes">
                                            Kıymet Türü
                                        </InputLabel>
                                        <Select
                                            value={values.isinTypes}
                                            onChange={handleChange}
                                            input={<OutlinedInput labelWidth={labelWidth}
                                                                  name="isinTypes"
                                                                  id="isinTypes"
                                            error={errors.isinTypes && touched.isinTypes && errors.isinTypes}
                                            />}
                                        >
                                            {isinTypes.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                               value={item}>{item.text}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{errors.isinTypes}</FormHelperText>
                                    </FormControl> : null
                        }
                    </Grid> : null
                    }
                    { values.blockageTypes[0] === "4" ||  values.blockageTypes[0] === "5" ?
                    <Grid item xs={12} sm={6} lg={4}>
                        {
                            values.blockageTypes[0] === "4" ?
                                <FormControl required variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="addDefCode">
                                        Ek Tanım
                                    </InputLabel>
                                    <Select
                                        value={values.addDefCode}
                                        onChange={handleChange}
                                        input={<OutlinedInput labelWidth={labelWidth} name="addDefCode" id="addDefCode"
                                        error={errors.addDefCode && touched.addDefCode && errors.addDefCode}
                                        />}
                                    >
                                        {addDefCode.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                            value={item}>{item.text}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>{errors.addDefCode}</FormHelperText>
                                </FormControl>
                                : values.blockageTypes[0] === "5" ?
                                <FormControl required variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="isin">
                                        Kıymet
                                    </InputLabel>
                                    <Select
                                        value={values.isin}
                                        onChange={handleChange}
                                        input={<OutlinedInput labelWidth={labelWidth} name="isin" id="isin"
                                        error={errors.isin && touched.isin && errors.isin}
                                        />}
                                    >
                                        {isin.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                      value={item}>{item.text}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>{errors.isin}</FormHelperText>
                                </FormControl> : null
                        }
                    </Grid> : null
                    }
                    {
                        values.blockageTypes[0] === "5" ?
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="addDefCode">
                                    Ek Tanım
                                </InputLabel>
                                <Select
                                    value={values.addDefCode}
                                    onChange={handleChange}
                                    input={<OutlinedInput labelWidth={labelWidth} name="addDefCode" id="addDefCode"
                                    error={errors.addDefCode && touched.addDefCode && errors.addDefCode}
                                    />}
                                >
                                    {addDefCode.map((item,i)=><MenuItem selected={i===0} key={i}
                                                                        value={item}>{item.text}</MenuItem>)}
                                </Select>
                                <FormHelperText>{errors.addDefCode}</FormHelperText>
                            </FormControl>
                        </Grid> : null
                    }
                    { values.blockageTypes[0] === "5" ?
                    <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                            id="existingAmount"
                            name="existingAmount"
                            label="Mevcut Adet"
                            className={classes.formControl}
                            value={amount}
                        // onChange={handleChange('name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment:
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked = {checked}
                                                onChange = {handleCheckBox}
                                                value = {checked}
                                                color = 'primary'
                                            />
                                        }
                                        label="Tümü"
                                    />
                            }}
                        />
                    </Grid> :null
                    }
                    { values.blockageTypes[0] === "5"  && !checked ?
                        <Grid item xs={12} sm={6} lg={4}>
                            <TextField
                                id="manualAmount"
                                label="Adet"
                                className={classes.formControl}
                                onChange={event => handleManuelAmountChange(event)}
                                variant="outlined"

                            />
                        </Grid> :null
                    }
                        {  infoList !== null &&  infoList.length > 0 ?
                        <Grid item xs={12}>
                            {autoBlockageInfoTable(infoList)}
                        </Grid> : null
                        }
                    <Grid item xs={12}>
                        <div style={{textAlign:'end', paddingTop:16}}>
                            <Button classes={{label:classes.whiteText}} type='submit' variant="contained"
                                    color='primary' size='large' fullWidth
                                    className={[classes.showButton, `btn ${clsx(
                                {
                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                }
                            )}`]}
                            >
                                {props.intl.formatMessage({id: "AUTOMATIC_BLOCKAGE.BUTTON.ADD_BLOCKAGE"})}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                </form>
                    )}
                </Formik>
            </Paper>
        </div>
    );
}


export default injectIntl(
    connect(
        null,
        //investorBlockage.actions
    ) (ApplyAutomaticBlockage)
);