import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {checkHttpStatus, get, post} from "../../../utils/RestUtilsWithAxios";
import {GET_INVESTOR_BLOCKAGE_DATA, SET_OR_CANCEL_BLOCKAGE_DATA} from "../../../constants/uri";
import {  Form } from "react-bootstrap";
import * as Globals from "../../../constants/globals";
import {alertBox} from "../../../components/BoxProvider";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import NumberFormat from 'react-number-format';
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SuccessMessageTable from "./SuccessMessageTable";
import ErrorMessageTable from "./ErrorMessageTable";
import { Formik } from "formik";

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if (orderBy === 'amount') {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    } else 
        return b[orderBy].toString().localeCompare(a[orderBy].toString());
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const classes = headStyles();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const headRows = [
        { id: 'member',         numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.MEMBER"}) },
        { id: 'accountNo',      numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.ACCOUNT_NO"}) },
        { id: 'isin',           numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.ISIN"}) },
        { id: 'stockClass',    numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.STOCK_CLASS"}) },
        { id: 'addDefCode',     numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.ADDD_DEF_CODE"}) },
        { id: 'subAccount',     numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.SUB_ACCOUNT"}) },
        { id: 'amount',         numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.AMOUNT"}) },
        { id: 'blockageAmount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "INVESTOR_BLOCKAGE.TABLE.HEADROWS.BLOCKAGE_AMOUNT"}) },
    ];
    
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all desserts' }}
                    />
                </TableCell>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={classes.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
/**
 * useToolbarStyles
 * @type {<Styles<Theme, {}, string>>}
 */
const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
}));

/**
 * EnhancedTableToolbar
 * @param props
 * @return {*[]}
 * @constructor
 */
const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const [openInfo,setOpenInfo] = React.useState(false);

    return (
        [
        <div style={{width:"100%", textAlign:"right"}}>
            <Button color="primary" onClick={() => setOpenInfo(true)} style={{marginLeft:'auto'}}>
                <InfoIcon fontSize='small' style={{marginRight:8}} />
                {props.intl.formatMessage({id: "INVESTOR_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO_POPUP"})}
            </Button>
        </div>,
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} satır seçildi
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        {props.pageTitle}
                    </Typography>
                )}
            </div>
            <div>
                <Dialog
                    open={openInfo}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Blokaj koyma işlemleri hakkında bilgi"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                            {props.intl.formatMessage({id: "INVESTOR_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO"})}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                            {props.intl.formatMessage({id: "AUTOMATIC_BLOCKAGE.MESSAGE.APPLY_BLOCKAGE_INFO_POPUP_3"})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding:16}}>
                        <Button onClick={() => setOpenInfo(false)} color="primary" variant='contained' autoFocus>
                            {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Toolbar>
        ]
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    headFont: {
        fontSize: 13,
        fontWeight: 600
    },
    fontSize13: {
        fontSize: 13
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    whiteText: {
        color:'white'
    }
}));
/**
 * ApplyBlockageTable
 * @param props
 * @return {*}
 * @constructor
 */
export default function ApplyBlockageTable(props) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('member');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showApplyBlockage, setShowApplyBlockage] = useState(true);
    const [showPage, setShowPage] = useState(true);
    const [rows, setRows] = useState([])
    const [successRows, setSuccessRows] = useState([]);
    const [errorRows, setErrorRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fade, setFade] = useState(false);

    /**
     * enableLoading - setLoading(true)
     */
    const enableLoading = () => {
        setLoading(true);
    };

    /**
     * disableLoading - setLoading(false)
     */
    const disableLoading = () => {
        setLoading(false);
    };

    useEffect(() => {
        getApplyBlockageData();
    }, [])

    /**
     * getApplyBlockageData
     */
    function getApplyBlockageData() {
        get(GET_INVESTOR_BLOCKAGE_DATA).then(function (response) {
            setRows(response.data.putBlockageList)
            setFade(true)
        }).catch(function (error) {
            checkHttpStatus(error,props.intl);
        });
    }

    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleSelectAllClick
     * @param event
     */
    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map((row , i) => (row.rowId));
            rows.map((row, i) => {
                rows[i].blockageAmount = row.amount;
            })
            setSelected(newSelecteds);
            return;
        }
        rows.map((row, i) => {
            rows[i].blockageAmount = 0;
        })
        setSelected([]);
    }

    /**
     * handleClick
     * @param event
     * @param rowId
     */
    function handleClick(event, rowId) {
        if(event.target.checked) {
            rows[rowId].blockageAmount = rows[rowId].amount;
        }
        else
            rows[rowId].blockageAmount = 0;

        const selectedIndex = selected.indexOf(rowId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, rowId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);

    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    /**
     * changeValue
     * @param index
     */
    function changeValue(index) {
        if(document.getElementById('txtBlockageAmount_'+ index) != null) {
        document.getElementById('txtBlockageAmount_'+ index).value =
            document.getElementById('amount_' + index).innerHTML.substring(
                document.getElementById('amount_' + index).innerHTML.indexOf(">")+1,
                document.getElementById('amount_' + index).innerHTML.indexOf("</span>"))
        }
        if(document.getElementById('helperText_'+index) !== null &&
            document.getElementById('helperText_'+index).style.display === "block")
            document.getElementById('helperText_'+index).style.display = "none";
    }

    /**
     * clearValue
     * @param index
     */
    function clearValue(index) {
        if(document.getElementById('txtBlockageAmount_'+ index) != null) {
            if(document.getElementById('txtBlockageAmount_'+ index).value ===
                document.getElementById('amount_' + index).innerHTML.substring(
                    document.getElementById('amount_' + index).innerHTML.indexOf(">")+1,
                    document.getElementById('amount_' + index).innerHTML.indexOf("</span>")))
            document.getElementById('txtBlockageAmount_'+ index).value = 0;
        }

    }

    useEffect(() => {
        rows.map((row , i) => {
            if(isSelected(row.rowId))
                changeValue(row.rowId)
            else
                clearValue(row.rowId)

            if(document.getElementById('helperText_'+row.rowId) != null &&
                parseFloat(row.blockageAmount) > row.amount) {
                document.getElementById('helperText_'+row.rowId).style.display = "block";
            }
        });

    }, [page])

    var re = new RegExp("^[0-9]+([,]){0,1}[0-9]*}?$");

    /**
     * handleTextChange
     * @param event
     * @param component
     * @param index
     */
    function handleTextChange(event,component,index) {
        document.getElementById('helperText_'+index).style.display = "none"
        if(event.target.value) {
            if (!re.test(event.target.value)) {
                document.getElementById(component+index).value =
                    event.target.value.substring(0,event.target.value.length-1);
            }
            rows[index].blockageAmount = event.target.value
        }
    }

    /**
     * handleTextBlur
     * @param event
     * @param component
     * @param index
     */
    function handleTextBlur(event,component,index) {

        if(document.getElementById(component+index).value === '') {
            document.getElementById(component+index).value = 0
        }

        var amountPart1 = undefined;
        var amountPart2 = undefined;
        var blockageAmountPart1 = undefined;
        var blockageAmountPart2 = undefined;
        var amount = document.getElementById('amount_' + index).innerHTML.substring(
            document.getElementById('amount_' + index).innerHTML.indexOf(">")+1,
            document.getElementById('amount_' + index).innerHTML.indexOf("</span>"))

        if(amount.includes(",")) {
            amountPart1 = amount.split(',')[0].replace(/\./g, '');
            amountPart2 = amount.split(',')[1];
            }
        else
            amountPart1 = amount.replace(/\./g, '');

        if(document.getElementById(component+index).value.includes(",")) {
            blockageAmountPart1 = document.getElementById(component+index).value.split(',')[0].replace(/\./g, '')
            blockageAmountPart2 = document.getElementById(component+index).value.split(',')[1]
            }
        else
        blockageAmountPart1 = document.getElementById(component+index).value.replace(/\./g, '')

        if(parseInt(amountPart1)<parseInt(blockageAmountPart1)) {
            document.getElementById('helperText_'+index).style.display = "block";

        }
        else if(parseInt(amountPart1)===parseInt(blockageAmountPart1) &&
            parseInt(amountPart2)< parseInt(blockageAmountPart2 )) {
            document.getElementById('helperText_'+index).style.display = "block"
        }
        else
            document.getElementById(component+index).value = event.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    }

    /**
     * handleTextSelect
     * @param event
     * @param component
     * @param index
     */
    function handleTextSelect(event,component,index) {

        if(document.getElementById('helperText_'+index).style.display !== "none") {
            document.getElementById(component+index).value = ''
            document.getElementById('helperText_'+index).style.display = "none"
        }
        else{
            if(document.getElementById(component+index).value === "0")
                document.getElementById(component+index).value = ''
            document.getElementById(component+index).value = event.target.value.replace(/\./g, '');
        }
    }

    const isSelected = member => selected.indexOf(member) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            { rows.length > 0 ? showApplyBlockage ?
            <Paper className={classes.paper}>
                <Fade in={fade}>
                <Formik
                    initialValues={{  }}
                    onSubmit={(values, { setSubmitting }) => {
                        enableLoading();
                        var blockageList = [];
                        var postData = {};
                        rows.map((row,index) => {
                            if ( !(row.blockageAmount === 0 || row.blockageAmount === '') ) {
                                var clearDots = row.blockageAmount.toString().includes(",") ?
                                    row.blockageAmount.toString().replace(/\./g, '') :
                                    row.blockageAmount.toString();
                                row.blockageAmount = clearDots.replace(',','.');
                                if(parseFloat(row.blockageAmount) <= row.amount )
                                    blockageList.push(row);
                            }
                        })

                        postData['blockageType'] = "P";
                        postData['blockageList'] = blockageList;

                        if(postData.blockageList.length === 0) {
                            alertBox("", props.intl.formatMessage({id: "INVESTOR_BLOCKAGE.MESSAGE.BLOCKAGE_COUNT"}),
                                Globals.ALERT_WARNING, props.intl);
                            disableLoading();
                            setSubmitting(false);
                        }
                        else {
                            post(SET_OR_CANCEL_BLOCKAGE_DATA, postData, props.intl).then(function (response) {
                                setSuccessRows(response.data.successList);
                                setErrorRows(response.data.errorList);
                                getApplyBlockageData();
                                if (response.data.successList.length > 0)
                                    alertBox("", response.data.successInfoMessage, Globals.ALERT_INFO, props.intl);
                                setShowApplyBlockage(false);
                                disableLoading();
                                setSubmitting(false);
                            }).catch(function (error) {
                                checkHttpStatus(error, props.intl)
                                disableLoading();
                                setSubmitting(false);
                            });
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                <Form onSubmit={handleSubmit} >
                <EnhancedTableToolbar numSelected={selected.length} pageTitle={props.pageTitle} intl={props.intl}/>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            intl={props.intl}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const   isItemSelected = isSelected(row.rowId);
                                    isItemSelected ?  changeValue(row.rowId) : clearValue(row.rowId);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            id= {row.rowId}
                                            key={row.rowId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={event => handleClick(event, row.rowId)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': row.rowId}}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'member_' + row.rowId}
                                                       id={'member_' + row.rowId}         >{row.member}</TableCell>
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'accountNo_' + row.rowId}
                                                       id={'accountNo_' + row.rowId}      >{row.accountNo}</TableCell>
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'isin_' + row.rowId}
                                                       id={'isin_' + row.rowId}           >{row.isin +" / "+row.memberIvrCode}</TableCell>
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'stockClass_' + row.rowId}
                                                       id={'stockClass_' + row.rowId}   >{row.stockClass}</TableCell>           
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'addDefCode_' + row.rowId}
                                                       id={'addDefCode_' + row.rowId}     >{row.addDefCode}</TableCell>
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'subAccount_' + row.rowId}
                                                       id={'subAccount_' + row.rowId}     >{row.subAccount}</TableCell>
                                            <TableCell className={classes.fontSize13}
                                                       align="left" key={'amount_' + row.rowId}
                                                       id={'amount_' + row.rowId}         >
                                                <NumberFormat decimalScale={3} fixedDecimalScale={3}
                                                              value={row.amount} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                                </TableCell>
                                            <TableCell className={classes.fontSize13} align="left"
                                                       key={'blockageAmount_' + row.rowId}
                                                       id={'blockageAmount_' + row.rowId} >
                                                <OutlinedInput
                                                    id = {'txtBlockageAmount_' + row.rowId}
                                                    onChange={event => handleTextChange(event, 'txtBlockageAmount_',
                                                        row.rowId)}
                                                    onBlur={event => handleTextBlur(event, 'txtBlockageAmount_',
                                                        row.rowId)}
                                                    onClick={event => handleTextSelect(event, 'txtBlockageAmount_' ,
                                                        row.rowId)}
                                                    aria-describedby={'helperText' + row.rowId}
                                                    align="left"
                                                    defaultValue={row.blockageAmount}
                                                />
                                                    <FormHelperText style={{display:'none',
                                                        textAlign:'left',
                                                        marginLeft:'auto',
                                                        color:'red'}}
                                                                    id={'helperText_' + row.rowId}>Blokaj konulacak
                                                        adet mevcut adetten fazla olamaz</FormHelperText>


                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <div style={{textAlign:'end', paddingTop:16}}>
                    <Button classes={{label:classes.whiteText}} type='submit' size='large' variant="contained"
                            color='primary' fullWidth className={[classes.showButton, `btn ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                    )}`]} >
                        {props.intl.formatMessage({id: "INVESTOR_BLOCKAGE.TABS.APPLY.BLOCKAGE"})}
                    </Button>
                </div>
                </Form>)}
                </Formik>
                </Fade>
            </Paper> :
                <div>
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                    {<SuccessMessageTable successRows={successRows} intl={props.intl} title={props.intl.
                    formatMessage({id: "INVESTOR_BLOCKAGE.MESSAGE.TABLE.TITLE.SUCCESS_MESSAGE"})}/>}
                    {<ErrorMessageTable   successRows={errorRows}   intl={props.intl} title={props.intl.
                    formatMessage({id: "INVESTOR_BLOCKAGE.MESSAGE.TABLE.TITLE.ERROR_MESSAGE"})}/>}
                    <div style={{textAlign:'end'}}>
                        <Button color="primary" size='large'
                                onClick={() => {props.requestInvestorBlockageData();setShowApplyBlockage(true);
                                setSelected([])}}>
                            {props.intl.formatMessage({id: "AUTH.GENERAL.BACK_BUTTON"})}
                        </Button>
                    </div>
                        </Paper>
                    </div>
                </div> :
                <Paper className={classes.paper} >
                    <Fade in={fade}>
                    <Typography variant="subtitle1"> {props.intl.
                    formatMessage({id:"INVESTOR_BLOCKAGE.MESSAGE.RECORD_NOT_FOUND"})} </Typography>
                    </Fade>
                </Paper>

            }
        </div>
    );
}