/**
 * base64ToArrayBuffer
 * @param base64
 * @return {Uint8Array}
 */
export function base64ToArrayBuffer(base64) {
    const BASE64_MARKER = ';base64,';
    const base64Index = base64.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64Str = base64.substring(base64Index);
    var binaryString = window.atob(base64Str);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
const saveByteArray = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, name, fType) {
        var blob = new Blob(data, { type: fType }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

/**
 * downloadFileByContentExcel
 * @param file
 * @param name
 * @param type
 */
export function downloadFileByContentExcel(file, name, type) {
    const bytes = base64ToArrayBuffer('data:application/excel;base64,' + file.content);
    saveByteArray([bytes], name, type);
}

/**
 * downloadFileByContent
 * @param file
 * @param name
 * @param type
 */
export function downloadFileByContent(file, name, type) {
    const bytes = base64ToArrayBuffer('data:application/pdf;base64,' + file.content);
    saveByteArray([bytes], name, type);
}