import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if(orderBy !== 'enterExitDate'){
        if (orderBy === 'amount') {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        } else 
            return b[orderBy].toString().localeCompare(a[orderBy].toString());
    }
    else {
        if(a[orderBy] == null && b[orderBy] != null)
            return 1;
        else if(a[orderBy] != null && b[orderBy] == null)
            return -1;
        else if (a[orderBy] == null && b[orderBy] == null)
            return 0;
        else{
            const dateIndices = [ 6, 7, 8, 9, 3, 4, 0, 1 ];
            var r = 0;
            for(var i=0; i<dateIndices.length; i++){
                r = a[orderBy].charCodeAt(dateIndices[i]) - b[orderBy].charCodeAt(dateIndices[i]);
                if(r !== 0)
                    return r;
            }
            return r;
        }
    }
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 15,
        fontWeight : 600
    },
    fontBold: {
        fontWeight: 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'senderMemberAccount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.SENDER"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.SENDER_SUBTITLE"}) },
        { id: 'receiverMemberAccount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.RECEIVER"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.RECEIVER_SUBTITLE"}) },
        { id: 'safeKeepingMemberAccount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.KEEPER"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.KEEPER_SUBTITLE"}) },
        { id: 'isinInfo', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ISIN_INFO"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ISIN_INFO_SUBTITLE"}), style:{minWidth:180} },
        { id: 'enterExitDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ENTER_EXIT_DATE"}) },
        { id: 'amount', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.AMOUNT"}) },
        { id: 'type', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.TYPE"}) },
        { id: 'altArtFlag', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ALT_ART_FLAG"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                        {
                            row.secondLabel ?
                            <div>
                                {row.label}<br/>
                                <Typography classes={{subtitle1:headClasses.fontBold}}
                                            variant="subtitle1">{row.secondLabel}</Typography>
                            </div>
                            :
                            <div>{row.label}</div>
                        }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize15:{
        fontSize: 15
    }
}));
/**
 * PledgeTable
 * @param props
 * @return {*}
 * @constructor
 */
export default function PledgeTable (props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('enterExitDate');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.pledgeData;

    React.useEffect(() => {
        setPage(0);
        console.log(rows)
    }, [rows]);

    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    
    return (
                <div>    
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                intl={props.intl}
                            />
                            <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.name + index}
                                        >
                                            <TableCell component="th" key={"sender_"+index}
                                                       id={"sender_"+index}
                                                       className={classes.fontSize15}> {row.senderMemberAccount}
                                                       </TableCell>
                                            <TableCell component="th" key={"receiver_"+index}
                                                       id={"receiver_"+index}
                                                       className={classes.fontSize15}> {row.receiverMemberAccount}
                                                       </TableCell>
                                            <TableCell component="th" key={"keeper_"+index}
                                                       id={"keeper_"+index}
                                                       className={classes.fontSize15}> {row.safeKeepingMemberAccount}
                                                       </TableCell>
                                            <TableCell component="th" key={"isinInfo_"+index}
                                                       id={"isinInfo_"+index}
                                                       className={classes.fontSize15}> {row.isinInfo}
                                                       </TableCell>
                                            <TableCell align="right" key={"enterExitDate_"+index}
                                                       id={"enterExitDate_"+index}
                                                       className={classes.fontSize15}> {row.enterExitDate}
                                                       </TableCell>
                                            <TableCell align="right" key={"amount_"+index}
                                                       id={"amount_"+index} className={classes.fontSize15}>
                                                <NumberFormat decimalScale={3} fixedDecimalScale={3}
                                                              value={row.amount} decimalSeparator=','
                                                              thousandSeparator='.' displayType='text' />
                                            </TableCell>
                                            <TableCell component="th" key={"type_"+index}
                                                       id={"type_"+index} className={classes.fontSize15}>{row.type}
                                                       </TableCell>
                                            <TableCell component="th" key={"altArtFlag_"+index}
                                                       id={"altArtFlag_"+index}
                                                       className={classes.fontSize15}>{row.altArtFlag}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
    )
}