import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import PrivacyStatement from "../../../_metronic/layout/footer/PrivacyStatement";
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

const useStyles = makeStyles(theme => ({
  fontLight: {
      fontWeight: 'lighter',
      fontSize: 15
  },
  fontSize15: {
    fontSize: 15
  }

}))

function AuthPage(props) {

  const classes = useStyles();
  const [showContactUs, setShowContactUs] = React.useState(false);
  const [security, setSecurity] = React.useState(false);
  const [showPrivacyStatement, setShowPrivacyStatement] = React.useState(false);
  const [appStoreButton, setAppStoreButton] = React.useState(true);
  function redirectTo() {
      //localStorage.clear();
      try {
          var searchStr = window.location.search;
          if(searchStr != null && searchStr !== ''){
              return '/auth/login'+searchStr;
          }
          return '/auth/login';
      }catch (err) {
          console.log(err);
          return '/auth/login';
      }
  }
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        {
        isMobile && (isAndroid || isIOS) ?
        <Snackbar
          open={appStoreButton}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          children={
            <div style={{position: 'relative', width: '100%', height: 84, background: '#666', borderRadius: 10, padding: 15, boxShadow: " 0px 4px 10px rgba(0,0,0,0.5)"}}>
              <a href="#">
                <div style={{float: 'left', marginRight: 10, borderRadius:8, background: 'white', width: 54, height: 54, padding: 10, boxShadow: " 4px 4px 10px rgba(0,0,0,0.3)"}}>
                  <img style={{maxHeight:'100%'}} src="/media/logos/eyatirimci.ico" />
                </div>
                <div style={{float: 'left'}}>
                  <text style={{color: 'white', fontWeight: 'bold'}}>e-YATIRIMCI Mobil</text>
                  <a href={isIOS ? 'https://apps.apple.com/tr/app/e-investor/id1529630101?itsct=apps_box&itscg=30200' : isAndroid ? 'https://play.google.com/store/apps/details?id=tr.com.mkk.EYATMobileApp' : '#'}>
                    <div style={{border:'1px solid white', borderRadius: 4, padding:'5px 20px 5px 20px', textAlign: 'center', marginTop:5}}>
                      <text style={{color: 'white'}}> UYGULAMAYI İNDİR</text>
                    </div>
                  </a>
                </div>
              </a>
              <a style={{position: 'absolute', top: 15, right: 15}} onClick={()=>{setAppStoreButton(false)}}>
                <CloseIcon fontSize='small' style={{color:'white'}} />
              </a>
            </div>
          }
          action={
            <Button color="inherit" size="small">
              Undo
            </Button>
          }
        /> : null
        }
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/zemin_kirmizi.png")})`
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    height="70"
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/white-logo-tr.png")}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <Hidden xsDown>
                  <div className="kt-grid__item kt-grid__item--middle">
                    <h3 className="kt-login__title">e-YATIRIMCI'ya Hoş Geldiniz</h3>
                    <h4 className="kt-login__subtitle">
                        Yatırımcılar, MKK’da tutulan kayıtlarına ilişkin 7x24 hesapları hakkında bilgi alabilir, yatırımcı blokajı işlemlerini gerçekleştirebilir ve ücretsiz olarak sunulan çeşitli bilgilendirme ve raporlama hizmetlerinden yararlanabilir.
                    </h4>
                  </div>
                </Hidden>
              </div>
              <div style={{height: 36, marginBottom: 15}}>
                <a style={{marginRight: 20}}  href='https://play.google.com/store/apps/details?id=tr.com.mkk.EYATMobileApp'>
                  <img style={{maxWidth: '100%', maxHeight: '100%', boxShadow:"0 0 10px rgba(0,0,0,0.5)"}} src="/media/logos/google-play-badge.png" />
                </a>
                <a href='https://apps.apple.com/tr/app/e-investor/id1529630101?itsct=apps_box&itscg=30200'>
                  <img style={{maxWidth: '100%', maxHeight: '100%', boxShadow:"0 0 10px rgba(0,0,0,0.5)"}} src="/media/logos/app-store-badge.png" />
                </a>
              </div>
              <div className="kt-grid__item">
                  <div style={{marginBottom:'10px', width: 228.5,height:80}}>
                      <a href="#">
                          <img style={{width:'100%', height:'100%'}} alt="Logo" src="/media/logos/mkk-logo-login-new-left-resized-2.png"/>
                      </a>
                  </div>
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy;  Copyright {new Date().getFullYear()} MKK. Bütün hakları saklıdır.
                  </div>
                  <div className="kt-login__menu">
                    <Link onClick={()=>setShowPrivacyStatement(true)} className="kt-link">
                      Gizlilik
                    </Link>
                    <Link onClick={()=>setSecurity(true)} className="kt-link">
                      Güvenlik
                    </Link>
                    <Link onClick={()=>setShowContactUs(true)} className="kt-link">
                      İletişim
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Dialog
              open={showContactUs}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              style={{ textAlign: "left" }}
            >
              <DialogTitle id="form-dialog-title">
                <Typography variant='h5'>
                  {props.intl.formatMessage({ id: "CONTACT_US.TITLE" })}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontSize15}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "CONTACT_US.COMPANY" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>Merkezi Kayıt Kuruluşu A.Ş.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontSize15}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "CONTACT_US.ADDRESS" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>Reşitpaşa Mahallesi Borsa İstanbul Caddesi No.:4, 34467 Sarıyer/İSTANBUL</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontSize15}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "CONTACT_US.PHONE" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>0 (212) 334 57 00</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontSize15}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "CONTACT_US.FAX" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>0 (212) 334 57 57</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontSize15}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "CONTACT_US.CALL_CENTER" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>444 0 655 (MKK)</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontSize15}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "CONTACT_US.EMAIL" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>yatirimcidestek@mkk.com.tr</Typography>
                        </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: 16 }}>
                <Button
                  variant="contained" color="primary"
                  onClick={() => setShowContactUs(false)}
                >
                  {props.intl.formatMessage({ id: "COMMON.MODAL_CLOSE" })}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={security}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              style={{ textAlign: "left" }}
            >
              <DialogTitle id="form-dialog-title">
                <Typography variant='h5'>
                  {props.intl.formatMessage({ id: "SECURITY.TITLE" })}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "SECURITY.TEXT.PARAGRAPH1" })}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "SECURITY.TEXT.PARAGRAPH2" })}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEMS.HEADER1" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM1" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM2" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM3" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM4" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM5" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEMS.FOOTER1" })}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>{props.intl.formatMessage({ id: "SECURITY.TEXT.PARAGRAPH3" })}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography classes={{h6:classes.fontLight}} variant='h6' >{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEMS.HEADER2" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM6" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM7" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM8" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM9" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM10" })}</Typography>
                            <Typography classes={{h6:classes.fontLight}} variant='h6' >•	{props.intl.formatMessage({ id: "SECURITY.TEXT.ITEM11" })}</Typography>
                        </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: 16 }}>
                <Button
                  variant="contained" color="primary"
                  onClick={() => setSecurity(false)}
                >
                  {props.intl.formatMessage({ id: "COMMON.MODAL_CLOSE" })}
                </Button>
              </DialogActions>
            </Dialog>

            <PrivacyStatement openPrivacyStatement={showPrivacyStatement} closePrivacyStatement={()=>setShowPrivacyStatement(false)}/>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to={redirectTo()} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  withWidth()(AuthPage)
);