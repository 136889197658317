import React from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles/index";
import {
        GET_DOCUMENT_INFOS,
        GET_GK_DETAIL,
        DOWNLOAD_DOCUMENT,
        GKREPORT_DOWNLOAD_AS_EXCEL,
        GKREPORT_DOWNLOAD_AS_PDF
 } from "../../constants/uri";
import {checkHttpStatus, post,get} from "../../utils/RestUtilsWithAxios";
import MyTable from '../../components/MyTable';
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import {fetchDownload} from '../../utils/RestUtils';
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import {
    Dialog,
    DialogTitle,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    boldText: {
        fontWeight:'bold'
    },
    filledTitle: {
        marginBottom: 5,
        backgroundColor: Globals.COLOR_DARK_GRAY,
        padding: 10,
        color: 'white',
        borderRadius: 4
    },
    textContainer: {
        padding:10
    },
    glowOnHover:{
        '&:hover': { color: Globals.COLOR_FULL_RED},
    },
    messageContainer: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
}));

/**
 * GkReportPage
 * @param props
 * @return {*}
 * @constructor
 */
function GkReportPage(props) {
    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for(var i=0;i<array.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].title);
            valueItem.values.push(array[i].place);
            valueItem.values.push(array[i].isin);
            valueItem.values.push(array[i].exchCode);
            valueItem.values.push(array[i].gkTuru);
            valueItem.values.push(array[i].tarih);
            setArray.push(valueItem);
        }
        return setArray;
    }

    /**
     * downloadFile
     * @param fileOid
     * @param fileName
     */
    function downloadFile(fileOid, fileName) {
        fetchDownload(DOWNLOAD_DOCUMENT+fileOid,fileName);
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        post(serviceName, {list: props.gkReports, isCurrent: props.isActiveReport})
            .then(function (response) {
                if (serviceName === GKREPORT_DOWNLOAD_AS_EXCEL){
                    var fileName = props.isActiveReport ? "Aktif Genel Kurul.xls" : "Geçmiş Genel Kurul.xls";
                    DownloadUtils.downloadFileByContentExcel(response.data, fileName, Globals.CONTENT_TYPE_EXCEL);
                } else if (serviceName === GKREPORT_DOWNLOAD_AS_PDF) {
                    var fileName = props.isActiveReport ? "Aktif Genel Kurul.pdf" : "Geçmiş Genel Kurul.pdf";
                    DownloadUtils.downloadFileByContent(response.data, fileName, Globals.CONTENT_TYPE_PDF);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     * handleClose
     */
    function handleClose() {
        setOpen(false);
        setSelectedEgkDetail({});
        setSelectedEgkRef(-1);
        setSelectedEgkFileList([]);
    }

    /**
     * openModal
     */
    function openModal() {
        setOpen(true);
    }

    /**
     * egkDetailSelected
     * @param egkRefNo
     * @param rowNo
     */
    function egkDetailSelected(egkRefNo, rowNo) {
        post(GET_GK_DETAIL,{egkRef:egkRefNo})
            .then(function (response) {
                setSelectedEgkDetail(response.data);
                get(GET_DOCUMENT_INFOS+egkRefNo)
                    .then(function (response) {
                        setSelectedEgkFileList(response.data);
                        setSelectedEgkRef(egkRefNo);
                        openModal();
                    })
                    .catch(function (error) {
                        checkHttpStatus(error,props.intl);
                    })
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    }

    const classes = useStyles();
    const headRowsCurr = [
        { id: 'titleCol', numeric: false, disablePadding: false, label: 'Şirket' },
        { id: 'placeCol', numeric: false, disablePadding: false, label: 'Yer' },
        { id: 'isinCol', numeric: false, disablePadding: false, label: 'Kıymet' },
        { id: 'exchCodeCol', numeric: false, disablePadding: false, label: 'Borsa Kodu' },
        { id: 'typeCol', numeric: false, disablePadding: false, label: 'Genel Kurul Türü' },
        { id: 'timeCol', numeric: false, date: true, disablePadding: false, label: 'Tarih' }
    ];
    const headRowsOld = [
        { id: 'titleCol', numeric: false, disablePadding: false, label: 'Şirket' },
        { id: 'placeCol', numeric: false, disablePadding: false, label: 'Yer' },
        { id: 'isinCol', numeric: false, disablePadding: false, label: 'Kıymet' },
        { id: 'exchCodeCol', numeric: false, disablePadding: false, label: 'Borsa Kodu' },
        { id: 'typeCol', numeric: false, disablePadding: false, label: 'Genel Kurul Türü' },
        { id: 'timeCol', numeric: false, date: true, disablePadding: false, label: 'Tarih' }
    ];
    const alignment = ['left', 'left', 'left','left','left','left','left'];

    const gkReports = props.gkReports;
    const [open, setOpen] = React.useState(false);
    const [selectedEgkDetail,setSelectedEgkDetail] = React.useState({});
    const [selectedEgkRef,setSelectedEgkRef] = React.useState(-1);
    const [selectedEgkFileList,setSelectedEgkFileList] = React.useState([]);

    return(
        <div>
            {
                
                props.dataReady ?
                    gkReports.length > 0 ?    
                        <div>
                            <MyTable expandable = {false}
                                        intl={props.intl}
                                        tableRows={prepareItemArray(gkReports)}
                                        headRows={props.isActiveReport ? headRowsCurr : headRowsOld}
                                        alignment = {alignment}
                                        defaultSort={props.isActiveReport ? 'desc' : 'asc'}
                                        defaultSortIndex={5}
                                        dateTimeColumns={[5]}
                                        printOption={true}
                                        downloadAsExcel={() => download(GKREPORT_DOWNLOAD_AS_EXCEL)}
                                        printHeader={props.isActiveReport ?
                                            "AKTİF GENEL KURUL BİLGİLERİ RAPORU" :
                                            "GEÇMİŞ GENEL KURUL BİLGİLERİ RAPORU"}
                            />
                        </div>
                    :
                        <Typography align='center' classes={{h6: classes.messageContainer}} variant='h6'>
                            {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DATA_NOT_FOUND"})}
                        </Typography>
                :
                null
            }
            {
                props.isActiveReport && selectedEgkRef !== -1 ?
                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'md'}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogTitle id="max-width-dialog-title">
                            {props.intl.formatMessage({id: "GK.MODAL.TITLE"})}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography className={classes.filledTitle}
                                            variant='h6'>{props.intl.formatMessage({id: "GK.MODAL.MEETING_DETAILS"})}
                                            </Typography>
                                <div className={classes.textContainer}>
                                    <p><span
                                        className={classes.boldText}>Şirket : </span>{selectedEgkDetail.mbrTitle}</p>
                                    <p><span className={classes.boldText}>Tarih : </span>{selectedEgkDetail.tarih}</p>
                                    <p><span className={classes.boldText}>Yer : </span>{selectedEgkDetail.yer}</p>
                                    <p><span className={classes.boldText}>Tür : </span>{selectedEgkDetail.gkTuru}</p>
                                    <p><span className={classes.boldText}>Durum : </span>{selectedEgkDetail.durum}</p>
                                </div>
                                <Typography className={classes.filledTitle}
                                            variant='h6'>{props.intl.formatMessage({id: "GK.MODAL.AGENDA_ITEMS"})}
                                            </Typography>
                                <div className={classes.textContainer}>
                                    {
                                        selectedEgkDetail.gundemMaddeleri.map((m,i)=>{
                                            return <p><span
                                                className={classes.boldText}>{m.itemNo}. </span>{m.itemDesc}</p>
                                        })
                                    }
                                </div>
                                <Typography className={classes.filledTitle}
                                            variant='h6'>{props.intl.formatMessage({id: "GK.MODAL.DOCUMENTS"})}
                                            </Typography>
                                <div>
                                {
                                    selectedEgkFileList.map((f,i)=>{
                                        return (
                                            <a onClick={()=>downloadFile(f.docId,f.fileName)}>
                                                <Grid container justify='space-between' alignItems='flex-end'
                                                      className={[classes.textContainer,
                                                          classes.glowOnHover]} spacing={2}>
                                                    <Grid item>
                                                        <Icon className='far fa-file' style={{fontSize:24}} />
                                                        <Typography display="inline" variant='subtitle1'
                                                                    style={{marginLeft:32}}>{f.fileName}
                                                                    ({f.docTypeName})</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography display="inline" variant='subtitle1'>
                                                            <NumberFormat decimalScale={2} value={f.fileSize/1024}
                                                                          decimalSeparator=','
                                                                          thousandSeparator='.'
                                                                          displayType='text' /> KB
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </a>
                                        )
                                    })
                                }
                                </div>
                            </DialogContentText>

                        </DialogContent>
                        <DialogActions style={{padding:16}}>
                            <Button onClick={handleClose} color="primary">
                                {props.intl.formatMessage({id:"COMMON.MODAL_CLOSE"})}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                : null
            }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(GkReportPage)
);