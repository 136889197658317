import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import {
  APP_PROMOTION,
  GET_PROMOTION,
  GET_PROMOTION_APP,
  PROMOTION_REPORT_DOWNLOAD_AS_EXCEL,
} from "../../constants/uri";
import { checkHttpStatus, get, post } from "../../utils/RestUtilsWithAxios";
import * as Globals from "../../constants/globals";
import { alertBox } from "../../components/BoxProvider";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import AddIcon from "@material-ui/icons/PostAddOutlined";
import * as DownloadUtils from "../../utils/downloadUtils";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginBottom: theme.spacing(2),
    margin: "auto",
    padding: 30,
  },
  formControl: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(8, 0),
    },
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  flexButton: {
    [theme.breakpoints.up("xs")]: {
      width: 200,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    marginTop: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#aa332340",
  },
  boxTitle: {
    color: Globals.COLOR_DARK_GRAY,
    backgroundColor: "white",
    paddingLeft: 10,
    paddingRight: 10,
    position: "absolute",
    marginLeft: 22,
    marginTop: -8,
  },
  thinBox: {
    borderColor: Globals.COLOR_DARK_GRAY,
    padding: 24,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "700",
  },
}));

/**
 * PromotionApp
 * @param props
 * @return {*}
 * @constructor
 */
function PromotionApp(props) {
  const classes = useStyles();
  const [checked, setCheckBox] = useState(false);
  const [dialogChecked, setDialogCheckBox] = useState(false);
  const [promotionInfo, setPromotionInfo] = useState(null);
  const [promotionAppInfo, setPromotionAppInfo] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [rizaBeyanSelect, setRizaBeyanSelect] = useState("");
  const [rizaBeyanChanged, setRizaBeyanChanged] = useState(false);

  /**
   * handleCheckBox
   */
  function handleCheckBox() {
    setCheckBox(!checked);
  }

  /**
   * handleCheckboxChange
   */
  function handleCheckboxChange() {
    setDialogCheckBox(!dialogChecked);
  }

  /**
   * handleRadioChange
   * @param event
   */
  function handleRadioChange(event) {
    setRizaBeyanChanged(true);
    setRizaBeyanSelect(event.target.value);
    if (dialogChecked) {
      setEnabled(true);
    }
  }

  /**
   * handleClose2
   * @param isOk
   */
  function handleClose2(isOk) {
    if (isOk) {
      if (dialogChecked) {
        if (rizaBeyanChanged) setEnabled(true);
        setOpenInfo(false);
      } else
        alertBox(
          "",
          "'KVKK kapsamındaki haklarım konusunda bilgilendirildim.' " +
            "seçeneğini işaretlemeniz gerekmektedir. ",
          Globals.ALERT_WARNING,
          props.intl
        );
    } else {
      setDialogCheckBox(false);
      setOpenInfo(false);
      setEnabled(false);
    }
  }

  useEffect(() => {
    getPromotion();
  }, []);

  /**
   * getPromotion
   */
  function getPromotion() {
    get(GET_PROMOTION)
      .then(function(response) {
        setPromotionInfo(response.data);
        //setCheckBox(response.data.applyStatus);
        if (response.data.applyStatus) {
          setRizaBeyanSelect("YES");
        } else {
          setRizaBeyanSelect("NO");
        }
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  useEffect(() => {
    getPromotionApp();
  }, []);

  /**
   * getPromotion
   */
  function getPromotionApp() {
    get(GET_PROMOTION_APP)
      .then(function(response) {
        setPromotionAppInfo(response.data);
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  /**
   * save
   */
  function save() {
    let confirm = true;
    if (rizaBeyanSelect === "NO") {
      confirm = false;
    }

    var postData = {
      promotionId: promotionInfo.promotionId,
      applyStatus: confirm,
    };

    post(APP_PROMOTION, postData, props.intl)
      .then(function(response) {
        getPromotionApp();
        alertBox(
          "",
          props.intl.formatMessage({ id: "COMMON.ALERT.MSG_SUCCESS_SAVE" }),
          Globals.ALERT_INFO,
          props.intl
        );
      })

      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  /**
   * openDataSet
   */
  function openDataSet() {
    //setEnabled(true);
    setOpenInfo(true);
  }

  /**
   * download
   * @param serviceName
   */
  function download() {
    var postData = {
      promotionId: promotionInfo.promotionId,
    };
    post(PROMOTION_REPORT_DOWNLOAD_AS_EXCEL, postData, props.intl)
      .then(function(response) {
        DownloadUtils.downloadFileByContentExcel(
          response.data,
          "Promosyon Açık Rıza Bilgileri.xls",
          Globals.CONTENT_TYPE_EXCEL
        );
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {promotionInfo ? (
          <div style={{ position: "relative" }}>
            <Grid
              container
              alignItems="center"
              justify="space-around"
              className={classes.formControl}
            >
              <Grid style={{ maxWidth: 600 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6} style={{ textAlign: "end" }}></Grid>
                </Grid>

                <br />

                <br />
                <Typography>
                  6698 sayılı Kişisel Verilerin Korunması Kanunu 8. maddesi
                  uyarınca, Merkezi Kayıt Kuruluşu A.Ş.’de sermaye piyasası
                  mevzuatı çerçevesinde işlenen
                  <Typography>
                    <ul style={{ listStyleType: "disc" }}>
                      <li>TCKN/YKN </li>
                      <li>Ad Soyad</li>
                    </ul>
                  </Typography>
                  ile iletişim bilgilerimin (e-posta adresi, cep telefonu) pay
                  sahibi olduğum şirket tarafından pay sahiplerine yönelik
                  düzenlenen promosyon uygulamalarından faydalanabilmem
                  amacıyla, promosyon uygulama süresi içinde pay sahibi olduğum
                  şirkete aktarılmasına ilişkin açık rızam vardır.
                </Typography>

                <br />

                <br />

                <Button variant="contained" onClick={() => openDataSet()}>
                  <AddIcon style={{ marginRight: 8 }} />
                  AYDINLATMA METNİ İÇİN TIKLAYINIZ
                </Button>

                <Grid container style={{ marginTop: 40 }}>
                  <InfoIcon size="small" style={{ marginRight: 8 }} />
                  <Typography display="inline" variant="subtitle1">
                    "AYDINLATMA METNİ" onaylandıktan sonra sonra Kaydet butonu
                    aktif olacaktır.
                  </Typography>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: -30,
                  }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <RadioGroup
                      row
                      name="rizaBeyanSelect"
                      value={rizaBeyanSelect}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        control={<Radio color="secondary" />}
                        label="Kabul ediyorum"
                        value="YES"
                      />
                      <FormControlLabel
                        control={<Radio color="secondary" />}
                        label="Kabul etmiyorum"
                        value="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <Grid
                  container
                  justify="flex-end"
                  style={{ position: "relative", top: -100 }}
                >
                  <Button
                    onClick={() => save()}
                    color="primary"
                    size="large"
                    autoFocus
                    fullWidth
                    variant="contained"
                    disabled={!enabled}
                    className={classes.flexButton}
                  >
                    <FormattedMessage id="BTN_SAVE" />
                  </Button>
                </Grid>

                {promotionAppInfo
                  ? promotionAppInfo.recordStatus == "A"
                    ? [
                        <Grid
                          container
                          style={{ position: "relative", top: -60 }}
                        >
                          <Typography display="inline" variant="subtitle1">
                            {promotionAppInfo.createDate} tarihi itibariyle
                            verdiğiniz açık rıza beyanınız bulunmaktadır.
                          </Typography>
                        </Grid>,
                      ]
                    : null
                  : null}

                {(promotionAppInfo && promotionAppInfo.recordStatus == "D") ||
                "" == promotionAppInfo
                  ? [
                      <Grid
                        container
                        style={{ position: "relative", top: -60 }}
                      >
                        <Typography display="inline" variant="subtitle1">
                          Açık rıza beyanınız bulunmamaktadır.
                        </Typography>
                      </Grid>,
                    ]
                  : null}

                <Grid
                  container
                  justify="flex-end"
                  style={{ position: "relative", top: -100 }}
                >
                  <Button
                    onClick={() => download()}
                    color="primary"
                    size="large"
                    autoFocus
                    fullWidth
                    variant="contained"
                    className={classes.flexButton}
                  >
                    {props.intl.formatMessage({
                      id: "COMMON.PROMOTION_LOG_BUTTON_DOWNLOAD_EXCEL",
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Typography variant="h6">
              {props.intl.formatMessage({ id: "PROMOTION.CANNOT_BE_SHOWN" })}
            </Typography>
          </div>
        )}

        <div>
          <Dialog
            open={openInfo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Aydınlatma Metni"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: "black" }}
              >
                Kişisel verilerimin e-YATIRIMCI uygulamasıyla sunulan
                hizmetlerden faydalanabilmem amacıyla işlendiği, kişisel
                verilerimin benim sisteme kaydettiklerim ile hesabımın bulunduğı
                kurumlar tarafından sermaye piyasası mevzuatı çerçevesinde
                Merkezi Kayıt Kuruluşunda hesap açılması için zorunlu olan
                (kimlik, iletişim, mükellefiyet gibi) verilerim olduğu, söz
                konusu verilerimin mevzuatla yetkilendirmiş merciiler dışında
                ancak açık rızam varsa pay sahibi olduğum şirketlerin pay
                sahiplerine yönelik sunacağı promosyon uygulamalarından
                faydalanabilmem için bu şirkete aktarılabileceği ve 6698 sayılı
                Kişisel Verilerin Korunması Kanununun 11 inci maddesi uyarınca
                aşağıda sayılan haklara sahip olduğum konusunda
                bilgilendirildim:
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: "black" }}
              >
                <ol style={{ listStyleType: "lower-alpha" }}>
                  <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                  <li>
                    Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                  </li>
                  <li>
                    Kişisel verilerin işlenme amacını ve bunların amacına uygun
                    kullanılıp kullanılmadığını öğrenme,
                  </li>
                  <li>
                    Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                    üçüncü kişileri bilme,
                  </li>
                  <li>
                    Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
                    bunların düzeltilmesini isteme,
                  </li>
                  <li>
                    Kanunun 7 nci maddede öngörülen şartlar çerçevesinde kişisel
                    verilerin silinmesini veya yok edilmesini isteme,
                  </li>
                  <li>
                    (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel
                    verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                  </li>
                  <li>
                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                    analiz edilmesi suretiyle kişinin kendisi aleyhine bir
                    sonucun ortaya çıkmasına itiraz etme,
                  </li>
                  <li>
                    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                    zarara uğraması hâlinde zararın giderilmesini talep etme.
                  </li>
                </ol>
              </DialogContentText>
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={dialogChecked}
                    onChange={handleCheckboxChange}
                    value={dialogChecked}
                    color="secondary"
                  />
                }
                label="KVKK kapsamındaki haklarım konusunda bilgilendirildim."
              ></FormControlLabel>
            </DialogContent>

            <DialogActions style={{ padding: 16 }}>
              <Button onClick={() => handleClose2(false)} color="primary">
                Vazgeç
              </Button>
              <Button
                onClick={() => handleClose2(true)}
                variant="contained"
                color="primary"
              >
                Tamam
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </div>
  );
}

/**
 * injectIntl
 */
export default injectIntl(connect(null)(PromotionApp));
