import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles , useTheme  } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import GmInfoTable from './GmInfoTable';
import { GET_HPKS_GM, GET_HPKS_ID_TYPES, DOWNLOAD_EXCEL_HPKS_GM } from '../../constants/uri';
import { post, get, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DownloadButtons from '../../components/PdfAndExcelDownloadButtons';
import {printThisPage} from '../../utils/ExportUtils';
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import Fade from '@material-ui/core/Fade';
import { Formik } from "formik";
import Dropdown from '../../components/Dropdown';
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';
import * as Regex from "../../constants/Regexp";
import TextField from '@material-ui/core/TextField';
import {alertBox} from "../../components/BoxProvider";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    box: {
        padding: 10,
        borderRadius: 4,
    },
    thinBox: {
        padding: 32,
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'solid'
    },
    unlimitedText: {
        [theme.breakpoints.down('xs')]: {
            fontSize:16
        },
        color: "white",
    },
    titleGutter: {
        marginBottom: theme.spacing(1)
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30
    },
}))

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 12,
        fontWeight: 600
    },
}));

/**
 * prepareItemArr
 * @param gmList
 * @return {Array}
 */
function prepareItemArr(gmList) {
    var setArray = [];
    if(gmList) {
        for(var i=0;i<gmList.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(gmList[i].companyTitle);
            valueItem.values.push(gmList[i].gmDate);
            valueItem.values.push(gmList[i].gmAddress);
            valueItem.values.push(gmList[i].explanation);
            setArray.push(valueItem);
        }
    }
    return setArray;
}

const headRows = [
    { id: 'companyTitle', numeric: false, disablePadding: false, label: "Şirket" },
    { id: 'gmDate', numeric: false, disablePadding: false, label: "Tarih" },
    { id: 'gmAddress', numeric: false, disablePadding: false, label: "Adres" },
    { id: 'explanation', numeric: false, disablePadding: false, label: "Açıklama" },
];

/**
 * HpksGm
 * @param props
 * @return {*}
 * @constructor
 */
function HpksGm(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [gmList, setGmList] = useState();
    const [account, setAccount] = useState(null);
    const [fade, setFade] = useState(false);
    const [color, setColor] = useState(Globals.COLOR_DARK_GRAY);
    const [hasUserId, setHasUserId] = useState(true);
    const [showMessage, setShowMessage] = useState(true);
    const [idTypeList, setIdTypeList] = useState([]);

    const [selectedIdType, setSelectedIdType] = useState(null);
    const [selectedPanSclMnkNo, setSelectedPanSclMnkNo] = useState(null);
    
    const [values, setValues] = useState({
        idType: '',
        panSclMknNo: ''
    });

    /**
     * formikRef
     * @param node
     */
    const formikRef = (node) => {
        if (node !== null) {
            setValues(node.values);
        }
    }

    useEffect(() => {
        get(GET_HPKS_ID_TYPES)
            .then(function (response) {
                var typeList = [];
                for (var i = 0; i < response.data.length; i++) {
                    if( response.data[i].key != "TCKN" &&
                        response.data[i].key != "VKN" &&
                        response.data[i].key != "YKN" &&
                        response.data[i].key != "MKN" ){
                        var newItem = {};
                        newItem.value = response.data[i].key;
                        newItem.label = response.data[i].text;
                        typeList.push(newItem);
                    }
                }
                setIdTypeList(typeList);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }, [hasUserId]);

    useEffect(()=>{
        let gmListParameters = {
            isQueryPage: "N",
            idType: "",
            panSclMknNo: "" 
        }

        post(GET_HPKS_GM, gmListParameters)
        .then(function(response){
            if(response.data.hasUserId === 'N') {
                setHasUserId(false);
                setShowMessage(false);
            } else {
                setShowMessage(true);
                setHasUserId(true);
                setGmList(response.data.gmList);
            }
            setFade(true);
        })
        .catch(function(error){
            checkHttpStatus(error,props.intl);
        })
    },[]);

    /**
     * downloadAsExcel
     * @param serviceName
     */
    function downloadAsExcel(serviceName) {
        let gmParameters = {
            isQueryPage: hasUserId ? 'N' : 'Y',
            idType: selectedIdType,
            panSclMknNo: selectedPanSclMnkNo 
        }

        post(serviceName, gmParameters)
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, "Genel Kurul.xls", Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    return (
        <div className={classes.root}>
            {!hasUserId ? <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Formik
                        enableReinitialize
                        validateOnBlur={false}
                        validateOnChange={false}
                        innerRef={formikRef}
                        initialValues={values}
                        validate={values => {
                            // TODO idType ve panSclMknNo alanları eğer hasUserId false
                            // ise 'zorunlu' ve 'regex kontrolleri burada yapılacak'
                            const errors = {};

                            if (!values.idType) {
                                errors.idType = props.intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" });
                            } else if (!Regex.ID_TYPE.test(values.idType)) {
                                errors.idType = props.intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_FIELD" });
                            }


                            let idReg = Regex.MKN_SCL_PAN;
                            if (!values.panSclMknNo) {
                                errors.panSclMknNo = props.intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" });
                            } else if (!idReg.test(values.panSclMknNo)) {
                                errors.panSclMknNo = props.intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_FIELD" });
                            }

                            if (Object.keys(errors).length !== 0) {
                                alertBox("", "Hatalı / eksik bilgi girişi yaptınız. Lütfen girdiğiniz alanları " +
                                    "ve tabloyu kontrol ediniz!", Globals.ALERT_WARNING, props.intl);
                            }
                            return errors;

                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setSelectedIdType(values.idType);
                            setSelectedPanSclMnkNo(values.panSclMknNo);

                            let gmListParameters = {
                                isQueryPage: "Y",
                                idType: values.idType,
                                panSclMknNo: values.panSclMknNo 
                            }
                    
                            post(GET_HPKS_GM, gmListParameters)
                            .then(function(response){
                                setShowMessage(true);
                                setGmList(response.data.gmList);
                                setFade(true);
                            })
                            .catch(function(error){
                                checkHttpStatus(error,props.intl);
                            })
                            setSubmitting(false);

                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            //handleReset,
                            isSubmitting
                        }) => (
                                <form onSubmit={handleSubmit} autoComplete="off">

                                    {status && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}

                                    <Grid container direction="row" spacing={3}>
                                        <Grid container item xs={12} sm={6} spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography classes={{ h6: classes.titleGutter }}
                                                            variant="h6">Kimlik Türü</Typography>
                                                <Grid item style={{ height: 70 }}>
                                                    <Dropdown name="idType"
                                                        noMargin
                                                        id="idType"
                                                        title='Kimlik ID Türü Seç'
                                                        items={idTypeList}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.idType)}
                                                        helperText={errors.idType}
                                                        containerSelectedItem={values.idType}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography classes={{ h6: classes.titleGutter }}
                                                            variant="h6">Kimlik Numarası</Typography>
                                                <Grid item style={{ height: 70 }}>
                                                    <TextField
                                                        fullWidth
                                                        id="panSclMknNo"
                                                        name="panSclMknNo"
                                                        label="Kimlik Numarası"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.panSclMknNo}
                                                        helperText={touched.panSclMknNo && errors.panSclMknNo}
                                                        error={Boolean(touched.panSclMknNo && errors.panSclMknNo)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item xs={6}>
                                                <Button variant="contained"
                                                        color='primary'
                                                        fullWidth type='submit'>SORGULA</Button>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                </form>
                            )}
                    </Formik>
                </Paper>
            
            </div> : null}
            { showMessage ?
                <Paper className={classes.paper}>

                {
                    gmList ?    
                        <div>
                            <GmInfoTable accInfoData={gmList} intl={props.intl} selectAccount={setAccount} />
                        </div>
                        :
                        <Typography align='center' classes={{h6: classes.messageContainer}} variant='h6'>
                            {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DATA_NOT_FOUND"})}
                        </Typography>
                }
            
                { gmList ?
                    <div style={{marginTop:10}}>
                        <Grid container style={{backgroundColor: "white"}}>
                            <DownloadButtons pdfOnClick={() => printThisPage(prepareItemArr(gmList),headRows,
                                'GENEL KURUL RAPORU')}
                                             excelOnClick={() => downloadAsExcel(DOWNLOAD_EXCEL_HPKS_GM,
                                                 "Genel Kurul.xls")} style={{marginBottom:8}}/>
                        </Grid>
                    </div> :null
                }
    
            </Paper> :null}

            <Paper className={classes.infoMsg}>
                <Grid container style={{backgroundColor: "white"}}>
                             <Typography display="inline" variant="subtitle1">
                                {props.intl.formatMessage({id:"HPKS.MESSAGE.DETAILED_INFO"})}
                            </Typography>
                        </Grid>
            </Paper>
        
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(HpksGm)
);