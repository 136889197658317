
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary:{main:'#7e1e12', contrastText: "white"}
    },
});

const useStyles = makeStyles(theme => ({
    actions: {
        paddingTop: 8, 
        paddingBottom: 8,
        justifyContent: 'center',
    }
}));
/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function PrivacyStatement(props) {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const classes = useStyles();

    React.useEffect(() => {
        setOpen(props.openPrivacyStatement);
        setScroll('body');
    }, [props]);

    function handleClose() {
        setOpen(false);
        props.closePrivacyStatement();
    }

    return (
        <div>
            <Dialog
                open={open}
                scroll={scroll}
                fullWidth={true}
                fullScreen={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/zemin_gri.png")})`, backgroundRepeat  : 'no-repeat', 
                backgroundSize: 'cover', }}>
                    <DialogTitle id="alert-dialog-title" style={{textAlign:'center',color: 'white'}}>
                        {<FormattedMessage id="PRIVACY_STATEMENT.TITLE"  />}
                    </DialogTitle>
                    <DialogTitle id="alert-dialog-title" style={{textAlign:'center',color: 'white'}}>
                        {<FormattedMessage id="PRIVACY_STATEMENT.COMPANY"  />}
                    </DialogTitle>
                </div>
                <DialogTitle id="alert-dialog-title">
                    {<FormattedMessage id="PRIVACY_STATEMENT.TITLE_NOTICE"  />}
                    </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_STATEMENT.TITLE_NOTICE.CONTENT"  />}
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {<FormattedMessage id="PRIVACY_THE_USE_OF_INFORMATION_ON_THIS_SITE.TITLE"  />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_THE_USE_OF_INFORMATION_ON_THIS_SITE.CONTENT_1"  />}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_THE_USE_OF_INFORMATION_ON_THIS_SITE.CONTENT_2"  />}
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {<FormattedMessage id="PRIVACY_RIGHT_TO_CHANGE_THE_CONTENT_OF_THIS_SITE.TITLE" />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_RIGHT_TO_CHANGE_THE_CONTENT_OF_THIS_SITE.CONTENT_1" />}
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {<FormattedMessage id="PRIVACY_THE_RESPONSIBILITY_FOR_DAMAGES_AS_A_RESULT_OF_THE_USE_IF_THIS_SITE.TITLE" />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_THE_RESPONSIBILITY_FOR_DAMAGES_AS_A_RESULT_OF_THE_USE_IF_THIS_SITE.CONTENT_1" />}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_THE_RESPONSIBILITY_FOR_DAMAGES_AS_A_RESULT_OF_THE_USE_IF_THIS_SITE.CONTENT_2" />}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_THE_RESPONSIBILITY_FOR_DAMAGES_AS_A_RESULT_OF_THE_USE_IF_THIS_SITE.CONTENT_3" />}
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {<FormattedMessage id="PRIVACY_THE_INFORMATION_PROVIDED_FROM_OTHER_SITES.TITLE" />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {<FormattedMessage id="PRIVACY_THE_INFORMATION_PROVIDED_FROM_OTHER_SITES.CONTENT" />}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button onClick={handleClose} color="primary" style={{ "width": "15%"}} autoFocus variant='contained' size="large">
                        <FormattedMessage id="ALERTBOX.BTN.OK.TITLE"  />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

