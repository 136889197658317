import React from 'react';
import {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import Paper from '@material-ui/core/Paper';
import {Formik} from "formik";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';
import {alertBox} from "../../components/BoxProvider";
import * as Globals from "../../constants/globals";
import Dropdown from '../../components/Dropdown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/PostAddOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import {Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import {
    POST_HPKS_TAKE_OVER,
    GET_HPKS_ID_TYPES,
    GET_HPKS_COUNTRIES,
    GET_HPKS_CITIES,
    GET_HPKS_COUNTIES
} from '../../constants/uri';
import {post, get, checkHttpStatus} from '../../utils/RestUtilsWithAxios';
import TransferInfoTable from './TransferInfoTable';
import * as Regex from "../../constants/Regexp";
import * as ErrorCodes from "../../constants/ErrorCodes";
import DownloadButtons from '../../components/PdfDownloadButton';
import {printThisPage} from '../../utils/ExportUtils';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(3),
        margin: 'auto',
        padding: 30,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(9),
        },
    },
    fontLight: {
        fontWeight: 'lighter'
    },
    title: {
        flex: '0 0 auto',
        textAlign: 'left'
    },
    titleGutter: {
        marginBottom: theme.spacing(1)
    },
    tableWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    overflowX: {
        overflowX: 'auto',
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        marginLeft: 22,
        marginTop: -8,
        fontSize: 16
    },
    textInBox: {
        color: "white"
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30
    },
}))

const headStyles = makeStyles(theme => ({
    headFont: {
        fontSize: 12,
        fontWeight: 600
    },
}));

/**
 * HpksTransfer
 * @param props
 * @return {*}
 * @constructor
 */
function HpksTransfer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [fade, setFade] = useState(false);
    const [openForClear, setOpenForClear] = useState(false);
    const [openForDeleteTableData, setOpenForDeleteTableData] = useState(false);
    const [tableItems, setTableItems] = useState([]);
    const [openForTable, setOpenForTable] = useState(false);
    const [openTableForAdd, setOpenTableForAdd] = useState(true);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [isRealInvestor] = useState(localStorage.getItem("InvestorType") == 'R');
    const [idTypeList, setIdTypeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countyList, setCountyList] = useState([]);
    const [openResultPopup, setOpenResultPopup] = React.useState(false);
    const [saveControl, setSaveControl] = React.useState(false);
    const [transferData, setTransferData] = useState({});
    const [successTableItems, setSuccessTableItems] = useState([]);
    const [errorTableItems, setErrorTableItems] = useState([]);


    const [values, setValues] = useState({
        name: '',
        surname: '',
        title: '',
        idType: isRealInvestor ? 'TCKN' : 'VKN',
        id: '',
        mobile: '',
        mail: '',
        address: '',
        addressCounty: '',
        addressCity: '',
        addressCountry: 'TR',
        countryCode: 'TR',
        shareList: []
    });

    const [tableValues, setTableValues] = useState({
        mersisNo: '',
        listItemNo: '',
        shareSerialNo: '',
        shareOrderNo: '',
        shareGroupNo: '',
        shareAmount: '',
        shareNominalValue: '',
        shareTotalValue: '',
        mkkCertRef: '',
        explanation: ''
    });

    useEffect(() => {
        get(GET_HPKS_ID_TYPES)
            .then(function (response) {
                var typeList = [];
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    typeList.push(newItem);
                }
                setIdTypeList(typeList);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }, []);

    useEffect(() => {
        get(GET_HPKS_COUNTRIES)
            .then(function (response) {
                var countries = [];
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].countryCode;
                    newItem.label = response.data[i].countryDesc;
                    countries.push(newItem);
                }
                setCountryList(countries);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }, []);

    useEffect(() => {

        if (values.addressCountry === "TR") {
            get(GET_HPKS_CITIES)
                .then(function (response) {
                    var cities = [];
                    for (var i = 1; i < response.data.length; i++) {
                        var newItem = {};
                        newItem.value = response.data[i].code;
                        newItem.label = response.data[i].name;
                        cities.push(newItem);
                    }
                    setCityList(cities);
                    setFade(true);
                })
                .catch(function (error) {
                    checkHttpStatus(error, props.intl);
                })
        }
    }, []);

    useEffect(() => {
        if (values.addressCountry !== "TR") {
            values.addressCity = "";
            values.addressCounty = "";
        }
    }, [values.addressCountry]);

    useEffect(() => {
        if (values.addressCountry === "TR" && values.addressCity) {
            let countiesParam = {
                cityCode: values.addressCity
            }

            post(GET_HPKS_COUNTIES, countiesParam)
                .then(function (response) {
                    var counties = [];
                    for (var i = 1; i < response.data.length; i++) {
                        var newItem = {};
                        newItem.value = response.data[i].code;
                        newItem.label = response.data[i].name;
                        counties.push(newItem);
                    }
                    setCountyList(counties);
                    setFade(true);
                })
                .catch(function (error) {
                    checkHttpStatus(error, props.intl);
                })
        }
    }, [values.addressCity]);


    /**
     * formikRef
     * @param node
     */
    const formikRef = (node) => {
        if (node !== null) {
            setValues(node.values);
        }
    }

    /**
     * formikRef2
     * @param node
     */
    const formikRef2 = (node) => {
        if (node !== null) {
            setTableValues(node.values);
        }
    }

    const clearForm = () => {
        values.name = '';
        values.surname = '';
        values.title = '';
        values.idType = isRealInvestor ? 'TCKN' : 'VKN';
        values.id = '';
        values.mobile = '';
        values.mail = '';
        values.address = '';
        values.addressCity = '';
        values.addressCounty = '';
        values.addressCountry = 'TR';
        values.countryCode = 'TR';
        setTableItems([]);
        setSuccessTableItems([]);
        setErrorTableItems([]);
        setOpenForClear(false);
        alertBox("", props.intl.formatMessage({id: "KAP_DISCLOSURE.ALERTBOX.FORM_CLEARED"}),
            Globals.ALERT_SUCCESS, props.intl);
    }

    /**
     * EnhancedTableHead
     * @param props
     * @return {*}
     * @constructor
     */
    function EnhancedTableHead(props) {
        const headClasses = headStyles();

        const headRows = [
            {id: 'mersisNo', numeric: false, disablePadding: false, label: 'Şirket Mersis No'},
            {id: 'shareOrderNo', numeric: false, disablePadding: false, label: 'Tertip'},
            {id: 'shareGroupNo', numeric: false, disablePadding: false, label: 'Grup'},
            {id: 'shareNominalValue', numeric: true, disablePadding: false, label: 'İtibari değer'},
            {id: 'shareAmount', numeric: true, disablePadding: false, label: 'Pay Adedi'},
            {id: 'shareTotalValue', numeric: true, disablePadding: false, label: 'Pay Tutarı (TL)'},
            {id: 'shareSerialNo', numeric: true, disablePadding: false, label: 'Sıra No'},
            {id: 'mkkCertRef', numeric: true, disablePadding: false, label: 'MKK Referans No'},
            {id: 'explanation', numeric: true, disablePadding: false, label: 'Açıklama '},
        ];

        return (
            <TableHead>
                <TableRow>
                    {headRows.map(row => (
                        <TableCell
                            key={row.id}
                            style={row.style ? row.style : null}
                            align={row.numeric ? 'right' : 'left'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            className={headClasses.headFont}
                        >
                            {
                                <div>
                                    {row.label}
                                </div>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    /**
     * openModal1
     * @param forAdd
     * @param i
     */
    function openModal1(forAdd, i) {
        if (tableItems && tableItems.length >= 10) {
            alertBox("", "Devralınan pay bilgileri tablosuna 10 kayıttan fazlası girilemez.",
                Globals.ALERT_WARNING, props.intl);
        } else {
            if (forAdd) {
                tableValues.mersisNo = ''
                tableValues.shareOrderNo = ''
                tableValues.shareGroupNo = ''
                tableValues.shareNominalValue = ''
                tableValues.shareAmount = ''
                tableValues.shareTotalValue = ''
                tableValues.shareSerialNo = ''
                tableValues.mkkCertRef = ''
                tableValues.explanation = ''
            } else {
                tableValues.mersisNo = tableItems[i].mersisNo
                tableValues.shareOrderNo = tableItems[i].shareOrderNo
                tableValues.shareGroupNo = tableItems[i].shareGroupNo
                tableValues.shareNominalValue = tableItems[i].shareNominalValue
                tableValues.shareAmount = tableItems[i].shareAmount
                tableValues.shareTotalValue = tableItems[i].shareTotalValue
                tableValues.shareSerialNo = tableItems[i].shareSerialNo
                tableValues.mkkCertRef = tableItems[i].mkkCertRef
                tableValues.explanation = tableItems[i].explanation
            }
            setOpenTableForAdd(forAdd);
            setSelectedRow(i);
            setOpenForTable(true);
        }
    }

    /**
     * openRemoveTableDataModal
     * @param i
     */
    function openRemoveTableDataModal(i) {
        setSelectedRow(i);
        setOpenForDeleteTableData(true);
    }

    /**
     * removeItem
     * @param i
     */
    function removeItem(i) {
        //splice metodu kullaninca ekrana yansimiyor
        var newTable = [];
        for (var j = 0; j < tableItems.length; j++) {
            if (i !== j)
                newTable.push(tableItems[j]);
        }
        setTableItems(newTable);
        setOpenForDeleteTableData(false);
    }

    /**
     * getTableItemByListItemNo
     * @param listItemNo
     * @return {{}}
     */
    function getTableItemByListItemNo(listItemNo) {
        let result = {};
        tableItems.map(item => {
            if (item.listItemNo.toString() === listItemNo) {
                result = item;
            }
        });
        return result;
    }

    /**
     * getStatusText
     * @param responseCode
     */
    function getStatusText(responseCode) {
        if ("ESA40" === responseCode) {
            return ErrorCodes.ESA40;
        } else if ("ESA41" === responseCode) {
            return ErrorCodes.ESA41;
        } else if ("ESA42" === responseCode) {
            return ErrorCodes.ESA42;
        } else if ("ESA43" === responseCode) {
            return ErrorCodes.ESA43;
        } else if ("ESA44" === responseCode) {
            return ErrorCodes.ESA44;
        } else if ("ESA45" === responseCode) {
            return ErrorCodes.ESA45;
        } else if ("ESA46" === responseCode) {
            return ErrorCodes.ESA46;
        } else if ("ESA47" === responseCode) {
            return ErrorCodes.ESA47;
        } else if ("ESA48" === responseCode) {
            return ErrorCodes.ESA48;
        } else if ("ESA25" === responseCode) {
            return ErrorCodes.ESA25;
        } else if ("500" === responseCode) {
            return ErrorCodes.CODE_500;
        } else if ("1111" === responseCode) {
            return ErrorCodes.CODE_1111;
        }
    }

    /**
     * closeForTable
     */
    function closeForTable() {
        setOpenForTable(false);
    }

    /**
     * saveTransfer
     */
    function saveTransfer() {
        post(POST_HPKS_TAKE_OVER, transferData)
            .then(function (response) {
                if (response.data && response.data.orderList) {
                    let successTableList = [];
                    let errorTableList = [];
                    response.data.orderList.map(order => {
                        let tableItem = getTableItemByListItemNo(order.listItemNo);
                        tableItem.shareNominalValue = tableItem.shareNominalValue.replace(/\./g, ',')
                        tableItem.shareTotalValue = tableItem.shareTotalValue.replace(/\./g, ',')

                        if (order.responseCode === '0000') {
                            tableItem.recordStatus = "Başarılı";
                            successTableList.push(tableItem);
                        } else {
                            tableItem.recordStatus = getStatusText(order.responseCode);
                            errorTableList.push(tableItem);
                        }
                    });
                    setTableItems(errorTableList);
                    setSuccessTableItems(successTableList);
                    setErrorTableItems(errorTableList);
                    setOpenResultPopup(true);
                } else {
                    alertBox("", props.intl.formatMessage({id: "HPKS.TRANSFER_ERROR"}),
                        Globals.ALERT_ERROR, props.intl);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })

        setSaveControl(false);
    }

    /**
     * handleClose1
     * @param isOk
     * @param forAdd
     * @param i
     */
    function handleClose1(isOk, forAdd, i) {
        if (isOk) {
            var newItem = {
                listItemNo: tableItems.length + 1,
                mersisNo: tableValues.mersisNo,
                shareOrderNo: tableValues.shareOrderNo,
                shareGroupNo: tableValues.shareGroupNo,
                shareNominalValue: tableValues.shareNominalValue,
                shareAmount: tableValues.shareAmount,
                shareTotalValue: tableValues.shareTotalValue,
                shareSerialNo: tableValues.shareSerialNo,
                mkkCertRef: tableValues.mkkCertRef,
                explanation: tableValues.explanation
            };
            if (forAdd) {
                tableItems.push(newItem);
            } else {
                tableItems[i] = newItem;
            }
            //handleToplamlar(tableItems);
        }
        closeForTable();
    }

    const pdfHeadRows = [
        {id: 'mersisNo', numeric: false, disablePadding: false, label: 'Şirket Mersis No'},
        {id: 'shareOrderNo', numeric: false, disablePadding: false, label: 'Tertip'},
        {id: 'shareGroupNo', numeric: false, disablePadding: false, label: 'Grup'},
        {id: 'shareNominalValue', numeric: true, disablePadding: false, label: 'İtibari değer'},
        {id: 'shareAmount', numeric: true, disablePadding: false, label: 'Pay Adedi'},
        {id: 'shareTotalValue', numeric: true, disablePadding: false, label: 'Pay Tutarı (TL)'},
        {id: 'shareSerialNo', numeric: true, disablePadding: false, label: 'Sıra No'},
        {id: 'mkkCertRef', numeric: true, disablePadding: false, label: 'MKK Referans No'},
        {id: 'explanation', numeric: true, disablePadding: false, label: 'Açıklama '},
        {id: 'status', numeric: true, disablePadding: false, label: 'Durum '},
    ];

    /**
     * prepareItemArr
     * @return {Array}
     */
    function prepareItemArr() {
        var setArray = [];

        for (var i = 0; i < successTableItems.length; i++) {
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(successTableItems[i].mersisNo);
            valueItem.values.push(successTableItems[i].shareOrderNo);
            valueItem.values.push(successTableItems[i].shareGroupNo);
            valueItem.values.push(successTableItems[i].shareNominalValue);
            valueItem.values.push(successTableItems[i].shareAmount);
            valueItem.values.push(successTableItems[i].shareTotalValue);
            valueItem.values.push(successTableItems[i].shareSerialNo);
            valueItem.values.push(successTableItems[i].mkkCertRef);
            valueItem.values.push(successTableItems[i].explanation);
            valueItem.values.push(successTableItems[i].recordStatus);
            setArray.push(valueItem);
        }

        for (var i = 0; i < errorTableItems.length; i++) {
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(errorTableItems[i].mersisNo);
            valueItem.values.push(errorTableItems[i].shareOrderNo);
            valueItem.values.push(errorTableItems[i].shareGroupNo);
            valueItem.values.push(errorTableItems[i].shareNominalValue);
            valueItem.values.push(errorTableItems[i].shareAmount);
            valueItem.values.push(errorTableItems[i].shareTotalValue);
            valueItem.values.push(errorTableItems[i].shareSerialNo);
            valueItem.values.push(errorTableItems[i].mkkCertRef);
            valueItem.values.push(errorTableItems[i].explanation);
            valueItem.values.push(errorTableItems[i].recordStatus);
            setArray.push(valueItem);
        }
        return setArray;
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef}
                    initialValues={values}
                    validate={values => {

                        const errors = {};
                        if (isRealInvestor && !values.name) {
                            errors.name = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.NAME_SURNAME_TITLE.test(values.name)) {
                            errors.name = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }
                        if (isRealInvestor && !values.surname) {
                            errors.surname = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.NAME_SURNAME_TITLE.test(values.surname)) {
                            errors.surname = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }
                        if (!isRealInvestor && !values.title) {
                            errors.title = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.NAME_SURNAME_TITLE.test(values.title)) {
                            errors.title = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.idType) {
                            errors.idType = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.ID_TYPE.test(values.idType)) {
                            errors.idType = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        let idReg = Regex.MKN_SCL_PAN;

                        if (values.idType) {
                            if (values.idType === 'TCKN' || values.idType === 'MKN') {
                                idReg = Regex.TCKN;
                            } else if (values.idType === 'YKN') {
                                idReg = Regex.YKN;
                            } else if (values.idType === 'VKN') {
                                idReg = Regex.VKN;
                            }
                        }

                        if (!values.id) {
                            errors.id = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!idReg.test(values.id)) {
                            errors.id = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.mobile) {
                            errors.mobile = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.HPKS_MOBILE.test(values.mobile)) {
                            errors.mobile = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.mail) {
                            errors.mail = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.HPKS_MAIL.test(values.mail)) {
                            errors.mail = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.address) {
                            errors.address = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.ADDRESS.test(values.address)) {
                            errors.address = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.addressCity) {
                            errors.addressCity = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.ADDRESS_CITY.test(values.addressCity)) {
                            errors.addressCity = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.addressCounty) {
                            errors.addressCounty = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.ADDRESS_COUNTY.test(values.addressCounty)) {
                            errors.addressCounty = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.addressCountry) {
                            errors.addressCountry = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.ADDRESS_COUNTRY.test(values.addressCountry)) {
                            errors.addressCountry = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.countryCode) {
                            errors.countryCode = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.COUNTRY_CODE.test(values.countryCode)) {
                            errors.countryCode = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!(tableItems && tableItems.length !== 0)) {
                            errors.tableItems = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }

                        if (Object.keys(errors).length !== 0) {
                            alertBox("", "Hatalı / eksik bilgi girişi yaptınız. Lütfen girdiğiniz alanları " +
                                "ve tabloyu kontrol ediniz!", Globals.ALERT_WARNING, props.intl);
                        }

                        return errors;
                    }}
                    onSubmit={(values, {setStatus, setSubmitting}) => {

                        let updatedTableItemList = [];
                        tableItems.map(item => {
                            item.shareNominalValue = item.shareNominalValue.replace(/,/g, '.')
                            item.shareTotalValue = item.shareTotalValue.replace(/,/g, '.')
                            updatedTableItemList.push(item);
                        });

                        values.shareList = updatedTableItemList;
                        setTransferData(values);
                        setSaveControl(true);
                        setSubmitting(false);

                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          //handleReset,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">

                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}

                            <Grid item xs={12}>
                                <div className={classes.title}>
                                    <Typography style={{color: "black"}} variant="h6" id="tableTitle">
                                        Devralan Bilgileri
                                    </Typography>
                                </div>
                            </Grid>
                            <Divider className={classes.divider}/>
                            <Grid container spacing={3}>
                                {isRealInvestor && (<Grid item xs={6} lg={3}>
                                    <Typography classes={{h6: classes.titleGutter}} variant="h6">Ad</Typography>
                                    <Grid item style={{height: 70}}>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            name="name"
                                            label="Ad"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            helperText={touched.name && errors.name}
                                            error={Boolean(touched.name && errors.name)}
                                        />
                                    </Grid>
                                </Grid>)}
                                {isRealInvestor && (<Grid item xs={6} lg={3}>
                                    <Typography classes={{h6: classes.titleGutter}} variant="h6">Soyad</Typography>
                                    <Grid item style={{height: 70}}>
                                        <TextField
                                            fullWidth
                                            id="surname"
                                            name="surname"
                                            label="Soyad"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.surname}
                                            helperText={touched.surname && errors.surname}
                                            error={Boolean(touched.surname && errors.surname)}
                                        />
                                    </Grid>
                                </Grid>)}
                                {!isRealInvestor && (<Grid item xs={6} lg={4}>
                                    <Typography classes={{h6: classes.titleGutter}} variant="h6">Ünvan</Typography>
                                    <Grid item style={{height: 70}}>
                                        <TextField
                                            fullWidth
                                            id="title"
                                            name="title"
                                            label="Ünvan"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            helperText={touched.title && errors.title}
                                            error={Boolean(touched.title && errors.title)}
                                        />
                                    </Grid>
                                </Grid>)}
                                <Grid item xs={6} lg={3}>
                                    <Typography classes={{h6: classes.titleGutter}} variant="h6">Uyruk</Typography>
                                    <Grid item style={{height: 70}}>
                                        <Dropdown name="countryCode"
                                                  noMargin
                                                  id="countryCode"
                                                  title='Uyruk Seç'
                                                  items={countryList}
                                                  onChange={handleChange}
                                                  error={Boolean(errors.countryCode)}
                                                  helperText={errors.countryCode}
                                                  containerSelectedItem={values.countryCode}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3} style={{height: 70, marginBottom: 8}}>
                                        <Grid item xs={6}>
                                            <Typography classes={{h6: classes.titleGutter}} variant="h6">Kimlik
                                                Türü</Typography>
                                            <Grid item style={{height: 70}}>
                                                <Dropdown name="idType"
                                                          required={true}
                                                          noMargin
                                                          id="idType"
                                                          title='Kimlik ID Türü Seç'
                                                          items={idTypeList}
                                                          onChange={handleChange}
                                                          error={Boolean(errors.idType)}
                                                          helperText={errors.idType}
                                                          containerSelectedItem={values.idType}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography classes={{h6: classes.titleGutter}} variant="h6">Kimlik
                                                No</Typography>
                                            <Grid item style={{height: 70}}>
                                                <TextField
                                                    fullWidth
                                                    id="id"
                                                    name="id"
                                                    label="Kimlik No"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.id}
                                                    helperText={touched.id && errors.id}
                                                    error={Boolean(touched.id && errors.id)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3} style={{height: 70, marginBottom: 8, marginTop: 15}}>
                                        <Grid item xs={3}>
                                            <Typography classes={{h6: classes.titleGutter}} variant="h6">Telefon
                                                No</Typography>
                                            <Grid item style={{height: 70}}>
                                                <TextField
                                                    fullWidth
                                                    id="mobile"
                                                    name="mobile"
                                                    label="0XXXXXXXXXX"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mobile}
                                                    helperText={touched.mobile && errors.mobile}
                                                    error={Boolean(touched.mobile && errors.mobile)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography classes={{h6: classes.titleGutter}} variant="h6">E-Posta
                                                Adresi</Typography>
                                            <Grid item style={{height: 70}}>
                                                <TextField
                                                    fullWidth
                                                    id="mail"
                                                    name="mail"
                                                    label="E-Posta"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mail}
                                                    helperText={touched.mail && errors.mail}
                                                    error={Boolean(touched.mail && errors.mail)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3} style={{height: 70, marginBottom: 8, marginTop: 15}}>
                                        <Grid item xs={3}>
                                            <Typography classes={{h6: classes.titleGutter}}
                                                        variant="h6">Ülke</Typography>
                                            <Grid item style={{height: 70}}>
                                                <Dropdown name="addressCountry"
                                                          noMargin
                                                          id="addressCountry"
                                                          title='Ülke Seç'
                                                          items={countryList}
                                                          onChange={handleChange}
                                                          error={Boolean(errors.addressCountry)}
                                                          helperText={errors.addressCountry}
                                                          containerSelectedItem={values.addressCountry}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography classes={{h6: classes.titleGutter}} variant="h6">İl
                                            </Typography>
                                            {values.addressCountry === 'TR' ?
                                                <Grid item style={{height: 70}}>
                                                    <Dropdown name="addressCity"
                                                              noMargin
                                                              id="addressCity"
                                                              title='İl Seç'
                                                              items={cityList}
                                                              onChange={handleChange}
                                                              error={Boolean(errors.addressCity)}
                                                              helperText={errors.addressCity}
                                                              containerSelectedItem={values.addressCity}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item style={{height: 70}}>
                                                    <TextField
                                                        fullWidth
                                                        id="addressCity"
                                                        name="addressCity"
                                                        label="İl"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.addressCity}
                                                        helperText={touched.addressCity && errors.addressCity}
                                                        error={Boolean(touched.addressCity && errors.addressCity)}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography classes={{h6: classes.titleGutter}}
                                                        variant="h6">İlçe</Typography>
                                            {values.addressCountry === 'TR' ?
                                                <Grid item style={{height: 70}}>
                                                    <Dropdown name="addressCounty"
                                                              noMargin
                                                              id="addressCounty"
                                                              title='İlçe Seç'
                                                              items={countyList}
                                                              onChange={handleChange}
                                                              error={Boolean(errors.addressCounty)}
                                                              helperText={errors.addressCounty}
                                                              containerSelectedItem={values.addressCounty}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item style={{height: 70}}>
                                                    <TextField
                                                        fullWidth
                                                        id="addressCounty"
                                                        name="addressCounty"
                                                        label="İlçe"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.addressCounty}
                                                        helperText={touched.addressCounty && errors.addressCounty}
                                                        error={Boolean(touched.addressCounty && errors.addressCounty)}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3} style={{height: 70, marginBottom: 8, marginTop: 15}}>
                                        <Grid item xs={6}>
                                            <Typography classes={{h6: classes.titleGutter}}
                                                        variant="h6">Adres</Typography>
                                            <Grid item style={{height: 70}}>
                                                <TextField
                                                    fullWidth
                                                    id="address"
                                                    name="address"
                                                    label="Adres"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.address}
                                                    helperText={touched.address && errors.address}
                                                    error={Boolean(touched.address && errors.address)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>


                            <Grid item xs={12}>
                                <div className={classes.title}>
                                    <Typography style={{color: "black", paddingTop: 50}} variant="h6" id="tableTitle">
                                        Devralınan Pay Bilgileri
                                    </Typography>
                                </div>
                            </Grid>
                            <Divider className={classes.divider}/>

                            <div className={classes.tableWrapper}>
                                <div className={classes.overflowX}>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            rowCount={tableItems.length}
                                            intl={props.intl}
                                        />
                                        <TableBody>
                                            {tableItems.map((row, i) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={'row' + i}
                                                    >
                                                        <TableCell align="left" key={'mersisNo_' + i}
                                                                   id={'mersisNo_' + i}>{row.mersisNo}
                                                                   </TableCell>
                                                        <TableCell align="left" key={'shareOrderNo_' + i}
                                                                   id={'shareOrderNo_' + i}>{row.shareOrderNo}
                                                                   </TableCell>
                                                        <TableCell align="left" key={'shareGroupNo_' + i}
                                                                   id={'shareGroupNo_' + i}>{row.shareGroupNo}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'shareNominalValue_' + i}
                                                                   id={'shareNominalValue_' + i}>{row.shareNominalValue}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'shareAmount_' + i}
                                                                   id={'shareAmount_' + i}>{row.shareAmount}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'shareTotalValue_' + i}
                                                                   id={'shareTotalValue_' + i}>{row.shareTotalValue}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'shareSerialNo_' + i}
                                                                   id={'shareSerialNo_' + i}>{row.shareSerialNo}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'mkkCertRef_' + i}
                                                                   id={'mkkCertRef_' + i}>{row.mkkCertRef}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'explanation_' + i}
                                                                   id={'explanation_' + i}>{row.explanation}
                                                                   </TableCell>
                                                        <TableCell align="right" key={'open_' + i} id={'open_' + i}><a
                                                            onClick={() => openModal1(false, i)}><EditIcon
                                                            size="large" className={classes.glowOnHover}/></a>
                                                        </TableCell>
                                                        <TableCell align="right"
                                                                   key={'remove_' + i} id={'remove_' + i}><a
                                                            onClick={() => openRemoveTableDataModal(i)}><DeleteIcon
                                                            size="large"
                                                            className={classes.glowOnHover}/></a></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {
                                                tableItems.length === 0 ?
                                                    <TableRow>
                                                        <TableCell colSpan={12}>
                                                            Tabloda kayıt bulunmamaktadır.
                                                        </TableCell>
                                                    </TableRow>
                                                    : null
                                            }
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <Button variant="contained" onClick={() => openModal1(true, -1)}>
                                                        <AddIcon style={{marginRight: 8}}/>
                                                        Tabloya giriş yapmak için tıklayınız
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>

                            <Divider className={classes.divider}/>

                            <Grid container justify="flex-end" spacing={3}>
                                <Grid item className={classes.showButton}>
                                    <Button type='reset' variant="contained" color='primary' size='large' fullWidth
                                            onClick={() => setOpenForClear(true)}>
                                        FORMU TEMİZLE
                                    </Button>
                                </Grid>
                                <Grid item className={classes.showButton}>
                                    <Button variant="contained" color='primary' size='large' fullWidth type='submit'>
                                        GÖNDER
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Paper>

            <Paper className={classes.infoMsg}>
                <Grid container style={{backgroundColor: "white"}}>
                    <Typography display="inline" variant="subtitle1">
                        {props.intl.formatMessage({id: "HPKS.MESSAGE.DETAILED_INFO"})}
                    </Typography>
                </Grid>
            </Paper>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openResultPopup}
                aria-labelledby="max-width-dialog-title">
                <DialogContent>
                    <Typography variant="h6" id="tableSuccessMessageTitle">
                        Başarılı Kayıtlarınız
                    </Typography>
                    <TransferInfoTable accInfoData={successTableItems} intl={props.intl} selectAccount={null}/>
                    <Divider variant="middle"/>
                    <Typography variant="h6" id="tableSuccessMessageTitle">
                        Hatalı Kayıtlarınız
                    </Typography>
                    <TransferInfoTable accInfoData={errorTableItems} intl={props.intl} selectAccount={null}/>
                </DialogContent>
                <DialogActions>
                    <DownloadButtons
                        pdfOnClick={() => printThisPage(prepareItemArr(), pdfHeadRows,
                            'Hamiline Pay Devir Bildirim Sonuç Raporu')}/>
                    <Button type='submit' variant="contained" color="primary"
                            onClick={() => setOpenResultPopup(false)}>Tamam</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={saveControl}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                style={{textAlign: "left"}}
            >
                <DialogTitle
                    id="form-dialog-title">{props.intl.formatMessage({id: "HPKS.SAVE_TRANSFER_MODAL_TITLE"})}
                    </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">
                        {props.intl.formatMessage({id: "HPKS.SAVE_TRANSFER_MODAL_CONTENT"})}
                    </Typography>
                </DialogContent>
                <DialogActions style={{padding: 16}}>
                    <Button color="primary"
                            onClick={() => {
                                setSaveControl(false)
                            }}
                    >
                        {props.intl.formatMessage({id: "COMMON.BUTTON_NO"})}
                    </Button>
                    <Button
                        color="primary" variant="contained"
                        onClick={() => {
                            saveTransfer()
                        }}
                    >
                        {props.intl.formatMessage({id: "COMMON.BUTTON_YES"})}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openForClear}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    Formu Temizle
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Girdiğiniz tüm bilgiler temizlenecektir. Onaylıyor musunuz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding: 16}}>
                    <Button onClick={() => setOpenForClear(false)} color="primary">
                        VAZGEÇ
                    </Button>
                    <Button onClick={() => clearForm()} variant="contained" color="primary">
                        TEMİZLE
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openForDeleteTableData}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>
                        Tablodan silmek istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding: 16}}>
                    <Button onClick={() => setOpenForDeleteTableData(false)} color="primary">
                        HAYIR
                    </Button>
                    <Button onClick={() => removeItem(selectedRow)} variant="contained" color="primary">
                        EVET
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openForTable}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    Devralınan Pay Bilgileri
                </DialogTitle>
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef2}
                    initialValues={tableValues}
                    validate={values => {
                        const errors = {};

                        if (!values.mersisNo) {
                            errors.mersisNo = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.MKN_SCL_PAN.test(values.mersisNo)) {
                            errors.mersisNo = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.shareSerialNo) {
                            errors.shareSerialNo = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.SHARE_SERIAL_NO.test(values.shareSerialNo)) {
                            errors.shareSerialNo = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.shareOrderNo) {
                            errors.shareOrderNo = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.SHARE_ORDER_NO.test(values.shareOrderNo)) {
                            errors.shareOrderNo = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.shareGroupNo) {
                            errors.shareGroupNo = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.SHARE_GROUP_NO.test(values.shareGroupNo)) {
                            errors.shareGroupNo = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.shareAmount) {
                            errors.shareAmount = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.SHARE_VALUE.test(values.shareAmount)) {
                            errors.shareAmount = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.shareNominalValue) {
                            errors.shareNominalValue = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.SHARE_NOMINAL_VALUE.test(values.shareNominalValue)) {
                            errors.shareNominalValue = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.shareTotalValue) {
                            errors.shareTotalValue = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.SHARE_TOTAL_VALUE.test(values.shareTotalValue)) {
                            errors.shareTotalValue = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (!values.mkkCertRef) {
                            errors.mkkCertRef = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        } else if (!Regex.MKK_CERT_REF.test(values.mkkCertRef)) {
                            errors.mkkCertRef = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        if (values.explanation && !Regex.EXPLANATION.test(values.explanation)) {
                            errors.explanation = props.intl.formatMessage({id: "AUTH.VALIDATION.INVALID_FIELD"});
                        }

                        return errors;
                    }}
                    onSubmit={(values, {setStatus, setSubmitting}) => {
                        handleClose1(true, openTableForAdd, selectedRow)
                    }}
                    // onReset={(values) =>{ }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            <DialogContent>
                                <DialogContentText>

                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="mersisNo"
                                        name="mersisNo"
                                        label="Şirket Mersis No"
                                        variant="outlined"
                                        value={values.mersisNo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.mersisNo && errors.mersisNo}
                                        error={Boolean(touched.mersisNo && errors.mersisNo)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="shareOrderNo"
                                        name="shareOrderNo"
                                        label="Tertip"
                                        variant="outlined"
                                        value={values.shareOrderNo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.shareOrderNo && errors.shareOrderNo}
                                        error={Boolean(touched.shareOrderNo && errors.shareOrderNo)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="shareGroupNo"
                                        name="shareGroupNo"
                                        label="Grup"
                                        variant="outlined"
                                        value={values.shareGroupNo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.shareGroupNo && errors.shareGroupNo}
                                        error={Boolean(touched.shareGroupNo && errors.shareGroupNo)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        id="shareNominalValue"
                                        name="shareNominalValue"
                                        label="İtibari değer"
                                        variant="outlined"
                                        value={values.shareNominalValue}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.shareNominalValue && errors.shareNominalValue}
                                        error={Boolean(touched.shareNominalValue && errors.shareNominalValue)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        fullwidth
                                        margin="normal"
                                        id="shareAmount"
                                        name="shareAmount"
                                        label="Pay Adedi"
                                        variant="outlined"
                                        value={values.shareAmount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.shareAmount && errors.shareAmount}
                                        error={Boolean(touched.shareAmount && errors.shareAmount)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        id="shareTotalValue"
                                        name="shareTotalValue"
                                        label="Pay Tutarı (TL)"
                                        variant="outlined"
                                        value={values.shareTotalValue}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.shareTotalValue && errors.shareTotalValue}
                                        error={Boolean(touched.shareTotalValue && errors.shareTotalValue)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        id="shareSerialNo"
                                        name="shareSerialNo"
                                        label="Sıra No"
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shareSerialNo}
                                        helperText={touched.shareSerialNo && errors.shareSerialNo}
                                        error={Boolean(touched.shareSerialNo && errors.shareSerialNo)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        id="mkkCertRef"
                                        name="mkkCertRef"
                                        label="MKK Referans No"
                                        variant="outlined"
                                        value={values.mkkCertRef}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.mkkCertRef && errors.mkkCertRef}
                                        error={Boolean(touched.mkkCertRef && errors.mkkCertRef)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        id="explanation"
                                        name="explanation"
                                        label="Açıklama 	"
                                        variant="outlined"
                                        value={values.explanation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.explanation && errors.explanation}
                                        error={Boolean(touched.explanation && errors.explanation)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') e.preventDefault();
                                        }}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{padding: 16}}>
                                <Button onClick={() => handleClose1(false, openTableForAdd, -1)} color="primary">
                                    Vazgeç
                                </Button>
                                <Button type='submit' variant="contained" color="primary">
                                    Ekle
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>

        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(HpksTransfer)
);