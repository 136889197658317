import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import { alertBox } from '../../components/BoxProvider';
import {
    ELUS_GET_SEC_TYPES,
    GET_AVAILABLE_LIMIT,
    REQUEST_FORM_LINK,
    REQUEST_FORM_REPORT_LINK
} from '../../constants/uri';
import * as Globals from '../../constants/globals';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(9),
        },
    },
    fontLight: {
        fontWeight: 'lighter'
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: "#aa332340"
    },
    boxTitle: {
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        marginLeft: 22,
        marginTop: -8,
        fontSize: 16
    },
    textInBox: {
        color: "white"
    },
}))

/**
 * ReportRequest
 * @param props
 * @return {*}
 * @constructor
 */
function ReportRequest(props) {
    const classes = useStyles();
    const [fade, setFade] = useState(false);
    const [link, setLink] = useState("");
    useEffect(()=>{
        setFade(true)
    },[]);

    useEffect(() => {
        get(REQUEST_FORM_REPORT_LINK)
            .then(function (response) {

                setLink(response.data.link);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })

    }, []);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Fade in={fade}><div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Talep Takibi</Typography>
                        </Grid>
                        <Divider className={classes.divider} />

                        <Grid item xs={12}>

                            <iframe src={link} width="100%" height="400" frameBorder="0"></iframe>

                        </Grid>
                    </Grid>
                </div></Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(ReportRequest)
);