import React from 'react';
import {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { post, get, checkHttpStatus } from '../../utils/RestUtilsWithAxios';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import DownloadButtons from '../../components/PdfAndExcelDownloadButtons';
import BalanceInfoTable from './partials/BalanceInfoTable';
import PartnersInfo from './partials/PartnersInfo';
import { GET_ACCOUNT_INFO, 
    GET_PARTNERS_BY_ACCOUNT,
    ACCOUNT_INFO_DOWNLOAD_AS_EXCEL,
    ACCOUNT_INFO_DOWNLOAD_AS_PDF
     } from '../../constants/uri';
import * as DownloadUtils from '../../utils/downloadUtils';
import Icon from '@material-ui/core/Icon';
import * as Globals from '../../constants/globals';
import Grid from '@material-ui/core/Grid';
import {printThisPage,stableSort,getSorting} from '../../utils/ExportUtils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    }, 
}))

/**
 * BalanceInfo
 * @param props
 * @return stableSort(setArray,getSorting("asc",0))
 * @constructor
 */
function BalanceInfo (props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [account, setAccount] = useState(null);
    const [partners, setPartners] = useState([]);
    const [fade, setFade] = useState(false);


    useEffect(()=>{
        var postData = {};
        postData['mbrId'] = null;
        post(GET_ACCOUNT_INFO, postData)
        .then(function (response) {
            setData(response.data);
            setFade(true);
        })
        .catch(function (error) {
            checkHttpStatus(error,props.intl);
        })
    },[]);

    useEffect(() => {
        if(account !== null){
            var postData = {};
            postData['mbrId'] = account.member;
            postData['accNo'] = account.accountNo;
            post(GET_PARTNERS_BY_ACCOUNT, postData)
            .then(function (response) {
                setPartners(response.data);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
            setShow(true);
        }
    }, [account]);

   

    /**
     * downloadAsPdf
     */
    function downloadAsPdf() {
        get(ACCOUNT_INFO_DOWNLOAD_AS_PDF)
            .then(function (response) {
                DownloadUtils.downloadFileByContent(response.data, "Hesap Bilgileri.pdf",
                    Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <Fade in={fade} timeout={100}>
                    {
                        data.length > 0 ?
                        <div>
                            <BalanceInfoTable accInfoData={data} intl={props.intl} selectAccount={setAccount} />
                           
                        </div>
                        :
                        <Typography variant="subtitle1">{props.intl.
                        formatMessage({id:"ACCOUNT_INFO.MESSAGE.CANNOT_FIND"})}</Typography>
                    }
                </Fade>
                <PartnersInfo open={show} onClose={setShow} intl={props.intl}
                              partnerList={partners} account={account} selectAccount={setAccount} />
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(BalanceInfo)
);