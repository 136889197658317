import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import BalanceInfo from "../BalanceInfo/BalanceInfo";
import BalancePortfolio from '../BalancePortfolio/BalancePortfolio';
import AccountTransactions from '../Balance/AccountTransactions';
import InvestorBlockage from '../InvestorBlockage/InvestorBlockage';
import AutomaticBlockage from '../AutomaticBlockage/AutomaticBlockage';
import KFSLimit from '../KFS/KFSLimit';
import RightsInformation from '../RightsInformation/RightsInformation';
import GeneralHkPage from '../HkReport/GeneralHkPage';
import UserInfo from '../UserInfo/UserInfo';
import AgentPermission from '../AgentshipPermission/AgentshipPermission';
import SecuritySetings from '../SecuritySettings/SecuritySettings';
import ContactUs from '../ContactUs/ContactUs';
import AddRequest from '../AddRequest/AddRequest';
import ReportRequest from '../ReportRequest/ReportRequest';
import { LayoutSplashScreen } from "../../../_metronic";
import GeneralContainer from '../Disclosure/GeneralContainer';
import * as Pages from '../../constants/pages';
import {isUserInvestor} from "../../utils/authUtils";
import UserGuideline from '../../pages/UserGuideline/UserGuideline';
import ExpressConsent from '../../pages/Yrts/ExpressConsent';
import HpksTransfer from '../HpksTransfer/HpksTransfer';
import HpksBalance  from '../HpksBalance/HpksBalance';
import HpksGm  from '../HpksGm/HpksGm';
import Hpks  from '../Hpks/HpksLinkPage';
import PromotionApp from '../Promotion/PromotionApp';
import YrtsQuery from '../Yrts/YrtsQuery';
import KFSPortfolio from '../KFS/KFSPortfolio';
import ELUSPortfolio from '../Elus/ELUSPortfolio';
import ELUSBelgeleri from '../Elus/ELUSBelgeleri';
import KFSTransaction from '../KFS/KFSTransaction';
import AdminAuthorization from "../Admin/AdminAuthorization";
import ParameterDefinition from "../Admin/ParameterDefinition";
import PendingTrade from '../PendingTrade/PendingTrade';
import DividendTransactions from '../DividendTransactions/DividendTransactions';
import NotificationPreferences from '../NotificationPreferences/NotificationPreferences'

export default function HomePage() {

  
  React.useEffect(() => {

    const elem = document.getElementById("kt_aside_close_btn")
    if(elem)
        elem.click()
  }, [window.location.pathname]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          isUserInvestor() ?
          <Redirect exact from="/" to={Pages.PORTFOLIO} />
              :
          <Redirect exact from="/" to={Pages.HK_REPORTS} />
        }
        <Route path="/builder" component={Builder} />
        <Route path={Pages.BALANCE_INFO} component={BalanceInfo} />
        <Route path={Pages.PORTFOLIO} component={BalancePortfolio} />
        <Route path={Pages.TRANSACTIONS} component={AccountTransactions} />
        <Route path={Pages.INVESTOR_BLOCKAGE} component={InvestorBlockage}/>
        <Route path={Pages.AUTOMATIC_BLOCKAGE} component={AutomaticBlockage}/>
        <Route path={Pages.KFS_LIMIT_VIEW} component={KFSLimit} />
        <Route path={Pages.RIGHTS_INFORMATION} component={RightsInformation} />
        <Route path={Pages.HK_REPORTS} component={GeneralHkPage} />
        <Route path={Pages.AGENTSHIP_PERMISSION} component={AgentPermission} />
        <Route path={Pages.UPDATEFORM} component={UserInfo}/>
        <Route path={Pages.NOTIFICATION_PREFERENCES} component={NotificationPreferences} />
        <Route path={Pages.SECURITY_SETTINGS} component={SecuritySetings} />
        <Route path={Pages.CONTACT_US} component={ContactUs} />
        <Route path={Pages.ADD_REQUEST} component={AddRequest} />
        <Route path={Pages.REPORT_REQUEST} component={ReportRequest} />
        <Route path={Pages.KAP_DISCLOSURE} component={GeneralContainer} />
        <Route path={Pages.USER_GUIDELINE} component={UserGuideline} />
        <Route path={Pages.YRTS} component={ExpressConsent} />
        <Route path={Pages.HPKS_TRANSFER} component={HpksTransfer} />
        <Route path={Pages.HPKS_BALANCES} component={HpksBalance} />
        <Route path={Pages.HPKS_GM} component={HpksGm} />
        <Route path={Pages.PROMOTION_APP} component={PromotionApp} />
        <Route path={Pages.HPKS} component={Hpks} />
        <Route path={Pages.YRTS_QUERY} component={YrtsQuery} />
        <Route path={Pages.KFS_PORTFOLIO} component={KFSPortfolio} />
        <Route path={Pages.KFS_TRANSACTION} component={KFSTransaction} />
        <Route path={Pages.ELUS_PORTFOLIO} component={ELUSPortfolio} />
        <Route path={Pages.ELUS_DOCS} component={ELUSBelgeleri} />
        <Route path={Pages.ADMIN_AUTHORIZATION} component={AdminAuthorization} />
        <Route path={Pages.PARAMETER_DEFINITION} component={ParameterDefinition} />
        <Route path={Pages.PENDING_TRADE} component={PendingTrade} />
        <Route path={Pages.DIVIDEND_TRANSACTION} component={DividendTransactions} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
