import React, { Component } from 'react';

var timerElem;

/**
 * Created by tsahin on 7.1.2020
 */
class CountDownContainer extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.intervalFunc = this.intervalFunc.bind(this);
    }

    componentDidMount() {
        this.intervalFunc();
    }

    intervalFunc(){
        var countDownDate = (new Date().getTime()) + this.props.minutes * 60000;
        var _endFunc = this.props.endFunction;
        timerElem = document.getElementById("timerArea");
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (timerElem == null) {
                console.log("timer is up");
                clearInterval(x);
            } else {
                timerElem.innerHTML = "<b>" +  (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds + "</b>";
                if (distance < 0) {
                    clearInterval(x);
                    timerElem.innerHTML = " <br><b> 00:00 </b>";
                    console.log("timer end");
                    _endFunc();
                }
            }
        }, 1000);
    }

    componentWillUnmount(){
        timerElem = null;
    }

    /**
     *
     * @return {*}
     */
    render() {
        return (
            <span id="timerArea" ></span>
        )
    }
}

export default CountDownContainer;