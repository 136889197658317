import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import {PieChart} from 'react-minimal-pie-chart';
import Button from '@material-ui/core/Button';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import * as Globals from "../../../constants/globals";


const useStyles = makeStyles(theme => ({
    totalValueBox: {
        backgroundColor: Globals.COLOR_DARK_GRAY,
        padding: 10,
        borderRadius: 4,
        margin: 8,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: 4
        },
    },
    totalValueTitle: {
        color: "white"
    },
    totalValueContent: {
        color: "white",
        [theme.breakpoints.down('xs')]: {
            fontSize:14
        },
    },
    selectedRatioBox: {
        padding: 10,
        borderRadius: 4,
        margin: 8,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: 4
        },
    },
    selectedValueBox:{
        padding: 10,
        borderRadius: 4,
        margin: 8,
        display:"flex",
        flex:1,
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: 4
        },
    },
    selectedMarketValue: {
        textAlign: "center",
        fontSize: 16,
        position:"absolute"
    },
    chartWrapper: {
        position:"relavite",
        [theme.breakpoints.up('sm')]: {
            maxWidth: 240
        },
    },
    animatedItem: {
        animation: `$myEffect 800ms cubic-bezier(0.83, 0, 0.17, 1) `
    },
    "@keyframes myEffect": {
        "0%": {
          opacity: 0,
          transform: "scaleX(0)"
        },
        "100%": {
          opacity: 1,
          transform: "scaleX(1)"
        }
    },
}))

/**
 * PortfolioPieChart
 * @param props
 * @return {*}
 * @constructor
 */
function InfoDialog(props){
    const classes = useStyles();
    const [openInfo,setOpenInfo] = React.useState(false);

return(
    <Grid container item direction="row-reverse" xs={12} md={12}>
                <Grid container justify="center" alignItems="left" item xs={12} sm className={classes.chartWrapper}>
                    <div style={{width:"100%", textAlign:"right"}}>
                        <Button color="primary" onClick={() => setOpenInfo(true)} style={{marginLeft:'auto'}}>
                            <InfoIcon fontSize='medium' style={{marginRight:8}} />
                        </Button>
                    </div>
                    <div>
                        <Dialog
                            open={openInfo}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"PORTFÖY VE HESAP BİLGİLERİ"}</DialogTitle>
                            <DialogTitle id="alert-dialog-title">{"Bilgilendirme"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_1"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_2"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_3"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_4"})}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                                    {props.intl.formatMessage({id: "PORTFOLIO.MESSAGE.APPLY_PORTFOLIO_INFO_POPUP_5"})}
                                    <ul style={{ listStyleType: "disc" }}>
                                        <li>Pay Senetleri bakiye kaydı 1 adet = 1 TL/Nominal sermayedir. (Yabancı para cinsinden ihraç edilen pay senetleri için izahnamede belirtilen kapsamda izleme yapılabilmektedir. 1 adet = 2 Euro sermaye gibi.)</li>
                                        <li>Devlet ve Özel Sektör Borçlanma Araçları, Kira Sertifikaları, Varlığa/İpoteğe Dayalı ve Varlık/İpotek Teminatlı Menkul Kıymetler ve benzer özellikteki sermaye piyasası araçları için bakiye adedi = Nominal değerdir.</li>
                                        <li>Yatırım Fonları ve Borsa Yatırım Fonları bakiye adedi = Katılım payıdır.</li>
                                        <li>Varantlar, Gayrimenkul Sertifikaları ve diğer sertifikalar için bakiye adedi = İhraç edildikleri adettir. (Sertifika adedi)</li>
                                        <li>Elektronik Ürün Senetleri (ELÜS) için bakiye adedi = Kilogramdır.</li>
                                    </ul>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{padding:16}}>
                                <Button onClick={() => setOpenInfo(false)} color="primary" autoFocus>
                                    {props.intl.formatMessage({id: "ALERTBOX.BTN.OK.TITLE"})}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
    
)
}
export default injectIntl(
    connect(
      null,
    )(InfoDialog)
);