import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Formik } from "formik";
import {checkHttpStatus, get, post} from "../../../utils/RestUtilsWithAxios";
import {
    CANCEL_AUTOMATIC_BLOCKAGE,
    GET_AUTOMATIC_BLOCKAGE_DATA,
} from "../../../constants/uri";
import {  Form } from "react-bootstrap";

function desc(a, b, orderBy) {
    if(orderBy === 'startDate' || orderBy === 'endDate'){
        if(a[orderBy] == null && b[orderBy] != null)
            return 1;
        else if(a[orderBy] != null && b[orderBy] == null)
            return -1;
        else if (a[orderBy] == null && b[orderBy] == null)
            return 0;
        else{
            const dateIndices = [ 6, 7, 8, 9, 3, 4, 0, 1 ];
            var r = 0;
            for(var i=0; i<dateIndices.length; i++){
                r = a[orderBy].charCodeAt(dateIndices[i]) - b[orderBy].charCodeAt(dateIndices[i]);
                if(r !== 0)
                    return r;
            }
            return r;
        }
    }
    else{
        return b[orderBy].toString().localeCompare(a[orderBy].toString());
    }
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        fontWeight : 600
    }
}));

function EnhancedTableHead(props) {
    const classes = headStyles();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const headRows = [
        { id: 'blockageClass', align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.BLOCKAGE_TYPE"}) },
        { id: 'member',        align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.MEMBER"}) },
        { id: 'accountNo',     align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.ACCOUNT_NO"}) },
        { id: 'isin',          align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.ISIN"}) },
        { id: 'isinType',      align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.ISIN_TYPE"}) },
        { id: 'addDef',        align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.ADD_DEF"}) },
        { id: 'amount',        align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.AMOUNT"}) },
        { id: 'startDate',     align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.START_DATE"}) },
        { id: 'endDate',       align:"left",  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "AUTOMATIC_BLOCKAGE.TABLE.HEADROWS.END_DATE"}) },

    ];


    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all desserts' }}
                    />
                </TableCell>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={classes.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
}));




const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} satır seçildi
                    </Typography>
                ) : (
                    <Typography style={{color: "black"}} variant="h6" id="tableTitle">
                        Bekleyen Blokaj Talimatlarım İptal
                    </Typography>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13: {
        fontSize: 13
    },
    headFont: {
        fontSize: 13,
        fontWeight : 600
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    whiteText: {
        color:'white'
    }
}));

export default function WaitingBlockageTable(props) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('blockageClass');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showCancelBlockage, setShowCancelBlockage] = useState(true);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataReady, setDataReady] = useState(false);

    /**
     * enableLoading - setLoading(true)
     */
    const enableLoading = () => {
        setLoading(true);
    };

    /**
     * disableLoading - setLoading(false)
     */
    const disableLoading = () => {
        setLoading(false);
    };


    useEffect(() => {
        getAutomaticBlokageData();
    }, []);

    /**
     * getAutomaticBlokageData
     */
    function getAutomaticBlokageData() {
        get(GET_AUTOMATIC_BLOCKAGE_DATA, props.intl).then(function (response) {
            setRows(response.data);
            if(response.data.length === 0)
                setShowCancelBlockage(false)
            setDataReady(true);
        }).catch(function (error) {
            checkHttpStatus(error, props.intl)
        });
    }

    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleSelectAllClick
     * @param event
     */
    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(m => m.messageId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    /**
     * handleClick
     * @param event
     * @param messageId
     */
    function handleClick(event, messageId) {
        const selectedIndex = selected.indexOf(messageId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, messageId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);

    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const isSelected = member => selected.indexOf(member) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            { rows.length > 0 && dataReady ?
                <Paper className={classes.paper}>
                    <Formik
                        initialValues={{  }}
                        onSubmit={(values, { setSubmitting }) => {
                            enableLoading();
                            post(CANCEL_AUTOMATIC_BLOCKAGE, {messageList: selected}, props.intl ).
                            then(function (response) {
                                getAutomaticBlokageData();
                                setSelected([]);
                                disableLoading();
                                setSubmitting(false);
                            }).catch(function (error) {
                                checkHttpStatus(error, props.intl)
                                disableLoading();
                                setSubmitting(false);
                            })
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <Form onSubmit={handleSubmit} >
                                <EnhancedTableToolbar numSelected={selected.length} pageTitle={props.pageTitle}
                                                      intl={props.intl}/>
                                <div className={classes.tableWrapper}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={ 'medium' }
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                            intl={props.intl}
                                        />
                                        <TableBody>
                                            {stableSort(rows, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const   isItemSelected = isSelected(row.messageId);

                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            id= {row.messageId}
                                                            key={row.messageId}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    onChange={event => handleClick(event, row.messageId)}
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': row.messageId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'blockageClass_' + row.messageId}
                                                                       id={'blockageClass_' + row.messageId}
                                                                       style={{minWidth:190}}>{row.blockageClass}</TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'member_' + row.messageId}
                                                                       id={'member_' + row.messageId}>{row.member}</TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'accountNo_' + row.messageId}
                                                                       id={'accountNo_' + row.messageId}>{row.accountNo}
                                                            </TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'isin_' + row.messageId}
                                                                       id={'isin_' + row.messageId}>{row.isin} {row.isinIvrCode!=null ? (" / "+row.isinIvrCode) :""}</TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'isinType_' + row.messageId}
                                                                       id={'isinType_' + row.messageId}>{row.isinType}
                                                            </TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'addDef_' + row.messageId}
                                                                       id={'addDef_' + row.messageId}>{row.addDef}</TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'amount_' + row.messageId}
                                                                       id={'amount_' + row.messageId}>{row.amount}</TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'startDate_' + row.messageId}
                                                                       id={'startDate_' + row.messageId}>{row.startDate}
                                                            </TableCell>
                                                            <TableCell className={classes.fontSize13} align="left"
                                                                       key={'endDate_' + row.messageId}
                                                                       id={'endDate_' + row.messageId}>{row.endDate}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={8} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                                <div style={{textAlign:'end', paddingTop:16}}>
                                    <Button classes={{label:classes.whiteText}} type='submit' variant="contained"
                                            color='primary' size='large'
                                            className={[classes.showButton, `btn ${clsx(
                                                {
                                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                                }
                                            )}`]}
                                    >
                                        TALİMAT İPTAL
                                    </Button>
                                </div>
                            </Form>)}
                    </Formik>
                </Paper>
                :
                dataReady ?
                    <Paper className={classes.paper} >
                        <Typography style={{color: "black"}} variant="subtitle1"> {props.intl.
                        formatMessage({id:"INVESTOR_BLOCKAGE.MESSAGE.RECORD_NOT_FOUND"})} </Typography>
                        <div style={{textAlign:'end'}}>

                        </div>
                    </Paper>
                    :
                    null

            }
        </div>
    );
}