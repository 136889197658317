import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TextField, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { toAbsoluteUrl } from "../../../_metronic";
import * as Regex from "../../constants/Regexp";
import * as URI from "../../constants/uri";
import * as Globals from "../../constants/globals";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import * as builder from "../../../_metronic/ducks/builder";
import Grid from "@material-ui/core/Grid";
import { alertBox } from "../../components/BoxProvider";
import {
  checkHttpStatus,
  get,
  post,
  enableSpinner,
  disableSpinner,
} from "../../utils/RestUtilsWithAxios";
import ChangePassword from "./partials/ChangePassword";
import { makeStyles } from "@material-ui/core/styles/index";
import { clearTimer, intervalFunc } from "../../utils/countDown";

const useStyles = makeStyles((theme) => ({
  whiteText: {
    color: "white",
  },
  fontLight: {
    fontWeight: "lighter",
    fontSize: 15,
  },
  fontSize15: {
    fontSize: 15,
  },
}));

function Login(props) {
  const classes = useStyles();
  const { intl } = props;
  const theme = useTheme();
  const notxs = useMediaQuery(theme.breakpoints.up("sm"));
  const [loading, setLoading] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [edevletLogin, setEdevletLogin] = useState(false);
  const [loginLock, setLoginLock] = useState(false);
  const [checked, setCheckBox] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [approvedUserToken, setApprovedUserToken] = useState(null);
  const [valueType, setValueType] = useState("");
  const [valueForUnlock, setValueForUnlock] = useState("");
  const [countDown, setCountDown] = useState(false);
  const [sendCodeIsAbled,setSendCodeIsAbled] = useState(true);
  const [firstInitialize, setFirstInitialize] = useState(true);
  const [confirmCodeBlock, setConfirmCodeBlock] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [expiredPassword, setExpiredPassword] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const [showPassInfo, setShowPassInfo] = React.useState(false);
  const dispatch = useDispatch();
  const [waitingCaptchaService, setWaitingCaptchaService] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [captchaCount, setCaptchaCount] = useState(0);
  const [getPublicSiteKey, setGetPublicSiteKey] = useState(true);
  const [siteKey, setSiteKey] = useState(null);
  const [investorType, setInvestorType] = useState(null);
  const [country, setCountry] = useState(null);
  const [smsIsAbled,setSmsIsAbled] = useState(false);
  const [epostaIsAbled,setEpostaIsAbled] = useState(false);

  /**
   * Captcha isaretlendiginde çalısan method
   */
  function handleOnChangeCaptcha() {
    if (captcha.current.getValue()) {
      console.log("Token var");
      setWaitingCaptchaService(true);

      post(URI.CAPTCHA, {
        token: captcha.current.getValue(),
        getPublicSiteKey: false,
      }).then(function(response) {
        if (response && response.data.serviceError === false) {
          console.log("Robot degilsin.");
          setValidUser(response.data.success);
          console.log("success : " + response.data.success);
          setWaitingCaptchaService(false);
        } else {
          console.log("Captcha service hata oluştu.");
          alertBox(
            "Hata",
            "Captcha servisinde beklenmedik bir hata oluştu. Daha sonra tekrar deneyiniz.",
            Globals.ALERT_ERROR,
            intl
          );
        }
      });
    }
  }

  /**
   * Captcha expired oldugunda calisan method
   */
  function expiredCaptcha() {
    console.log("captcha exp oldu.");
    setValidUser(false);
  }

  /**
   * Captcha resetlemek icin kullanilan method
   */
  function resetCaptcha() {
    if (captcha.current) {
      captcha.current.reset();
      setValidUser(false);
    }
  }

  /**
   * Captcha referansı
   */
  const captcha = useRef(null);

  /**
   * enableLoading - setLoading(true)
   */
  const enableLoading = () => {
    setLoading(true);
  };
  /**
   * disableLoading - setLoading(false)
   */
  const disableLoading = () => {
    setLoading(false);
  };
  /**
   * Sayfa ilk yuklendiginde backend den sitekey cekiliyor
   */
  React.useEffect(() => {
    post(URI.CAPTCHA, { getPublicSiteKey: getPublicSiteKey }).then(function(
      response
    ) {
      if (response) {
        console.log("publicSiteKey : " + response.data.publicSiteKey);
        setSiteKey(response.data.publicSiteKey);
        setGetPublicSiteKey(false);
      } else {
        console.log("backend gelen keyResponce hatalı.");
      }
    });
  }, []);


  React.useEffect(() => {
    try {
      var searchStr = window.location.search;
      if(searchStr != null && searchStr !== ''){
        var searchArr = searchStr.split('=');
        if(searchArr.length === 2) {
            if (searchArr[0] === '?debugMode') {
                if (searchArr[1] === 'true') {
                    setDebugMode(true);
                }
            }
        } else if(searchArr.length === 3){
            if(searchArr[0] === '?code') {
              var eGovern = searchArr[1].split('&')[0];
              var url = URI.EDEVLET_CONFIRM_LOGIN;
              post(url, { edevletToken: eGovern }).then(function (response) {
                  // localStorage.clear();
                  localStorage.setItem(Globals.TOKEN, response.data.token);
                  localStorage.setItem("NameSurname", response.data.name + ' ' + response.data.surname);
                  localStorage.setItem("HelloMsgNameSurnameTitle", 'Hoşgeldiniz Sayın ' + response.data.name + ' ' + response.data.surname);
                  localStorage.setItem("HelloMsg", response.data.helloMsg);
                  localStorage.setItem("SicilNo", response.data.sicilNo);
                  localStorage.setItem("InvestorType", response.data.investorType);
                  localStorage.setItem("Country", response.data.country);
                  localStorage.setItem("QualificationStat", response.data.qualificationStat);
                  if (response.data.status === Globals.UNREGISTERED_USER)
                      setShowRegistration(true);
                  else {
                      setEdevletLogin(true);
                      setFirstLogin(true);
                      getApproveCode(true);
                  }
              }).catch(function (error) {
                  checkHttpStatus(error, props.intl);
              })
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("rememberMe") === "true") {
      setCheckBox(true);
      document.getElementById("mkkSicilNo").value = localStorage.getItem(
        "mkkSicilNo"
      );
    }
    /**
     * enterPressed
     * @param event
     */
    const enterPressed = (event) => {
      var confirm = document.getElementById("form-dialog-title");
      var welcome = document.getElementById("form-dialog-title-2");
      if (confirm && event.keyCode === 13) {
        document.getElementById("approveButton").click();
      } else if (welcome && event.keyCode === 13) {
        document.getElementById("welcomeButton1").click();
      }
    };

    window.addEventListener("keydown", enterPressed);
    return () => {
      window.removeEventListener("keydown", enterPressed);
    };
  }, []);

  /**
   * handleCheckBox - setCheckBox
   */
  function handleCheckBox() {
    setCheckBox(!checked);
  }

  /**
   * handleApproveCodeInput
   * @param event
   */
  const handleApproveCodeInput = (event) => {
    var inputTextVal = event.target.value;
    if (inputTextVal.length > 6) {
      document.getElementById("confirmCode").value = inputTextVal.substring(
        0,
        6
      );
    }
    var regEx = /[0-9]/g;
    if (!regEx.test(inputTextVal.charAt(inputTextVal.length - 1))) {
      document.getElementById("confirmCode").value = inputTextVal.substring(
        0,
        inputTextVal.length - 1
      );
    }
    var newVal = document.getElementById("confirmCode").value;
    var newReg = /^\d+$/;
    if (newVal.length > 0 && !newReg.test(newVal)) {
      document.getElementById("confirmCode").value = "";
    }
  };
  /**
   * timeIsUp - setCountDown(false)
   */
  const timeIsUp = () => {
    setCountDown(false);
    if (document.getElementById("infoArea3") === null) {
    } else {
      document.getElementById("infoArea3").innerHTML =
        "Süreniz dolmuştur.Yeni kod almak için " +
        "Onay Kodu Gönder butonunu tıklayınız:";
    }
    setSendCodeIsAbled(false);
  };
  /**
   * removeTimer - setCountDown(false)
   */
  const removeTimer = () => {
    setCountDown(false);
    clearTimer();
}

  /**
   * confirmApproveCode
   */
  function confirmApproveCode() {
    var smsOrMailVal = document.getElementById("confirmCode").value;
    var url;

    if (debugMode) {
      if (!loginLock) url = URI.CONFIRM_LOGIN_CODE_DEBUG;
      else url = URI.CONFIRM_UNLOCK_CODE_DEBUG;
    } else {
      if (!loginLock) url = URI.CONFIRM_LOGIN_CODE;
      else url = URI.CONFIRM_UNLOCK_CODE;
    }

    var sendData;
    if (!loginLock) sendData = { confirmVal: smsOrMailVal };
    else
      sendData = {
        password: smsOrMailVal,
        valueType: valueType,
        tcknOrVknOrSicil: valueForUnlock,
      };

    post(url, sendData)
      .then(function(response) {
        removeTimer();
        if (response.data.status === "OK") {
          if (!loginLock) {
            setFirstLogin(false);
            localStorage.removeItem(Globals.TOKEN);
            setApprovedUserToken(response.data.token);
            setExpiredPassword(response.data.expiredPassword);
            setShowMessage(true);
          } else {
            setFirstLogin(false);
            setLoginLock(false);
            alertBox(
              "",
              "Tekrar şifre girişi yapabilirsiniz",
              Globals.ALERT_SUCCESS,
              intl
            );
          }
        } else {
          alertBox("", "Hatalı giriş yaptınız", Globals.ALERT_ERROR, intl);
        }
      })
      .catch(function(error) {
        if ("ER170" === checkHttpStatus(error, props.intl)) {
          setCountDown(false);
          setConfirmCodeBlock(true);
        }
      });
  }

  /**
   *
   * charCodeControl
   * @param event
   */
  const charCodeControl = (event) => {
    var charCode = event.charCode
      ? event.charCode
      : event.keyCode
      ? event.keyCode
      : event.which
      ? event.which
      : 0;
    var valid = charCode >= 48 && charCode <= 57;
    if (!valid) {
      event.preventDefault();
    }
  };

  /**
   *
   * @return {string}
   */
  function getPureUrlTemplate() {
    return window.location.origin;
  }

  /**
   * goToEgovern
   */
  function goToEgovern() {
    window.location = URI.EDEVLET_LOGIN;
  }

  const getApproveCode = (fromEdevlet) => {
  

    var investorType = localStorage.getItem("InvestorType");
    var country = localStorage.getItem("Country");
    setInvestorType(investorType);
    setCountry(country);
    var url;
    if (!loginLock) url = URI.GET_LOGIN_CODE;
    else url = URI.GET_UNLOCK_CODE;

    if (country === "TR") {
      if (investorType === "R") {
        setSmsIsAbled(true);
        setEpostaIsAbled(false);
      } else {
        setEpostaIsAbled(true);
        setSmsIsAbled(false);

      }
    } else {
      setEpostaIsAbled(true);
      setSmsIsAbled(false);
    }

 
    enableSpinner();
    var apiCall;
    if (!loginLock) apiCall = get(url);
    else
      apiCall = post(url, {
        valueType: valueType,
        tcknOrVknOrSicil: valueForUnlock,
      });
    apiCall
      .then(function(response) {
        disableSpinner();
         if (response.data.status === "OK") {
          if (!loginLock) {
            localStorage.removeItem(Globals.TOKEN);
            localStorage.setItem(Globals.TOKEN, response.data.token);
          }
          setCountDown(true);
          setFirstInitialize(false);
          setConfirmCodeBlock(false);
          if (!loginLock) {
            if (country !== "TR") {
              document.getElementById("pleasecode").innerHTML =
                "e-Posta adresinize gönderilen onay " + "kodunuzu giriniz.";
            } else {
              if (investorType === "R") {
                document.getElementById("pleasecode").innerHTML =
                  response.data.gsmNo +
                  " numaralı telefonunuza " +
                  "gönderilen SMS onay kodunu giriniz.";
              } else {
                document.getElementById("pleasecode").innerHTML =
                  "e-Posta adresinize gönderilen onay " + "kodunuzu giriniz.";
              }
            }
          } else {
            document.getElementById("pleasecode").innerHTML =
              "e-Posta adresinize gönderilen onay kodunuzu giriniz.";
              setEpostaIsAbled(true)
              setSmsIsAbled(false)
          }

          document.getElementById("infoArea3").innerHTML =
            "* Gönderilen onay kodu mesajlarının geçerlilik süresi 3 " +
            "dakikadır. Tekrar onay kodu talep etmeniz için gereken süreniz:";
        } else {
          this.setState({ sendCode: false });
        }
      })
      .catch(function(error) {
        // handle error
        setSmsIsAbled(false)
        setEpostaIsAbled(false)
        disableSpinner();
        checkHttpStatus(error, props.intl);
      });
  };

  const shouldSendCodeToSms = () => {
    enableSpinner();
    var apiCall;
    if (!loginLock) apiCall = get(URI.GET_LOGIN_SMS_CODE);
    else
      apiCall = post(URI.GET_UNLOCK_CODE, {
        valueType: valueType,
        tcknOrVknOrSicil: valueForUnlock,
      });

    apiCall
      .then(function(response) {
        disableSpinner();
        if (response.data.status === "OK") {
          if (!loginLock) {
            localStorage.removeItem(Globals.TOKEN);
            localStorage.setItem(Globals.TOKEN, response.data.token);
          }
          setCountDown(true);
          setFirstInitialize(false);
          setConfirmCodeBlock(false);
           setEpostaIsAbled(true)
       
          if (!loginLock) {
            document.getElementById("pleasecode").innerHTML =
              response.data.gsmNo +
              " numaralı telefonunuza gönderilen SMS onay kodunu giriniz.";
          }
          document.getElementById("infoArea3").innerHTML =
            "* Gönderilen onay kodu mesajlarının geçerlilik süresi 3 dakikadır. " +
            "Tekrar onay kodu talep etmeniz için gereken süreniz:";
        } else {
          this.setState({ sendCode: false });
        }
      })
      .catch(function(error) {
        disableSpinner();
        checkHttpStatus(error, props.intl);
      });
  };

  const shouldSendCodeToEmail = () => {
    enableSpinner();
    var apiCall;
    if (!loginLock) apiCall = get(URI.GET_LOGIN_EMAIL_CODE);
    else
      apiCall = post(URI.GET_UNLOCK_CODE, {
        valueType: valueType,
        tcknOrVknOrSicil: valueForUnlock,
      });

    apiCall
      .then(function(response) {
        disableSpinner();
        if (response.data.status === "OK") {
          if (!loginLock) {
            localStorage.removeItem(Globals.TOKEN);
            localStorage.setItem(Globals.TOKEN, response.data.token);
          }
          setCountDown(true);
          setFirstInitialize(false);
          setConfirmCodeBlock(false);
          setSmsIsAbled(true)
        
          if (!loginLock) {
            document.getElementById("pleasecode").innerHTML =
              "e-Posta adresinize gönderilen onay kodunuzu giriniz.";
          }
          document.getElementById("infoArea3").innerHTML =
            "* Gönderilen onay kodu mesajlarının geçerlilik süresi 3 dakikadır. " +
            "Tekrar onay kodu talep etmeniz için gereken süreniz:";
        } else {
          this.setState({ sendCode: false });
        }
      })
      .catch(function(error) {
        disableSpinner();
        checkHttpStatus(error, props.intl);
      });
  };

  if (showRegistration) {
    if (debugMode) {
      return <Redirect to="/auth/registration?debugMode=true" />;
    } else {
      return <Redirect to="/auth/registration" />;
    }
  }

  /**
   * loadHomePage
   */
  function loadHomePage() {

    localStorage.setItem(Globals.TOKEN, approvedUserToken);
    if (expiredPassword)
      setUpdatePassword(true);
    else {
      if (checked) {
        localStorage.setItem("mkkSicilNo", document.getElementById("mkkSicilNo").value);
        localStorage.setItem("rememberMe", true);
      }
      else {
        localStorage.removeItem("mkkSicilNo");
        localStorage.setItem("rememberMe", false);
      }
      get(URI.GET_MENU_ITEMS)
        .then(function (response) {
          var menuConf = {
            header: {
              self: {},
              items: [

                        ]
            },
            aside:{
              self: {},
              items:[]
                    }
          };
          var menuList = response.data.menuList;
          for(var i=0;i<menuList.length;i++){
            if(menuList[i].subMenuList !== null && menuList[i].subMenuList.length > 0) {
              var parentMenu = {
                title: menuList[i].label,
                root: true,
                alignment: "left",
                page: "#",
                bullet: "dot",
                iconUrl: menuList[i].iconUrl,
                submenu: []
              };
              for(var j=0;j<menuList[i].subMenuList.length;j++){
                var subMenu = {
                  title: menuList[i].subMenuList[j].label,
                  root: true,
                  alignment: "left",
                  page: menuList[i].subMenuList[j].url,
                  bullet: "dot",
                  iconUrl: menuList[i].subMenuList[j].iconUrl
                };
                parentMenu.submenu.push(subMenu);
              }
              menuConf.aside.items.push(parentMenu);
            } else {
              var parentMenu = {
                title: menuList[i].label,
                root: true,
                alignment: "left",
                page: menuList[i].url,
                bullet: "dot",
                iconUrl: menuList[i].iconUrl
              };
              menuConf.aside.items.push(parentMenu);
            }
          }

          dispatch(builder.actions.setMenuConfig(menuConf));
          props.isLogedIn();
        })
        .catch(function (error) {
          checkHttpStatus(error,null);
        });

    }
  }

  /**
   *
   * @param menuList
   * @return result
   */
  const getHpksParentMenu = (menuList) => {
    var result = null;
    if(menuList) {
      for(var i=0;i<menuList.length;i++){
        if(menuList[i].isHpksParentMenu) {
          result = menuList[i];
        }
      }
    }
    return result;
  };



  /**
   *
   * @param menuList
   * @return result
   */
  const getYrtsParentMenu = (menuList) => {
    var result = null;
    if(menuList) {
      for(var i=0;i<menuList.length;i++){
        if(menuList[i].isYrtsParentMenu) {
          result = menuList[i];
        }
      }
    }
    return result;
  };

  /**
   *
   * @param menuList
   * @return result
   */
  const getKfsParentMenu = (menuList) => {
    var result = null;
    if(menuList) {
      for(var i=0;i<menuList.length;i++){
        if(menuList[i].isKfsParentMenu) {
          result = menuList[i];
        }
      }
    }
    return result;
  };

  /**
   *
   * @param menuList
   * @return result
   */
  const getElusParentMenu = (menuList) => {
    var result = null;
    if(menuList) {
      for(var i=0;i<menuList.length;i++){
        if(menuList[i].isElusParentMenu) {
          result = menuList[i];
        }
      }
    }
    return result;
  };


  /**
   *
   * @param showMessage
   */
  function showGreetingMessage(showMessage) {
    
    return <Dialog
        open={showMessage}
        aria-labelledby="form-dialog-title-2"
        fullWidth={true}
        style={{ textAlign: "left" }}
      >
        <DialogTitle id="form-dialog-title-2">{props.intl.
      formatMessage({ id: "AUTH.VALIDATION.GREETING_MESSAGE_TITLE" })}</DialogTitle>
      <DialogContent>
          <DialogContentText>
              {localStorage.getItem('HelloMsgNameSurnameTitle')}
          </DialogContentText>
        <DialogContentText>
          {localStorage.getItem('HelloMsg')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button color="primary" id='welcomeButton2'
          onClick={() => setShowMessage(false)}
        >
          {props.intl.formatMessage({ id: "COMMON.BUTTON_CANCEL" })}
        </Button>
        <Button
          variant="contained" color="primary" id='welcomeButton1'
          onClick={() => loadHomePage()}
        >
          {props.intl.formatMessage({ id: "COMMON.BUTTON_CONTINUE" })}
        </Button>
      </DialogActions>
    </Dialog>
  }

  return (
    <>
      <div className="kt-login__head">

      </div>

      <div className="kt-login__body" style={!notxs ? {margin:0} : null}>
        <div className="kt-login__form">
          <div className="kt-login__title" style={!notxs ? {margin:0} : null}>
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              mkkSicilNo: localStorage.getItem("mkkSicilNo") !== null ? localStorage.getItem("mkkSicilNo") : "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.mkkSicilNo) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.mkkSicilNo = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
              if(!loginLock) {
                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }
              }
              if (values.mkkSicilNo && (values.mkkSicilNo.toString().length > 11 || values.mkkSicilNo.
                  toString().length < 8 || values.mkkSicilNo.toString().length === 9 || isNaN(values.mkkSicilNo))) {
                errors.mkkSicilNo = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }
              if (values.mkkSicilNo && !Regex.TCKN_VKN_IDENTITY.test(values.mkkSicilNo)) {
                errors.mkkSicilNo = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if(captchaCount<2 || (captchaCount>=2 && validUser)){

                enableLoading();
                var postData = {};
                if (values.mkkSicilNo.toString().length === 8) {
                  postData['valueType'] = "IDENTITY";
                  setValueType('IDENTITY');
                }else if (values.mkkSicilNo.toString().length === 10) {
                  postData['valueType'] = "VKN";
                  setValueType('VKN');
                }else if (values.mkkSicilNo.toString().length === 11) {
                  postData['valueType'] = "TCKN";
                  setValueType('TCKN');
                }

                postData['tcknOrVknOrSicil'] = values.mkkSicilNo;
                setValueForUnlock(values.mkkSicilNo);
                postData['password'] = values.password;

                if(!loginLock) {
                  post(URI.LOGIN, postData).then(function (response) {
                      // localStorage.clear();

                            localStorage.setItem(Globals.TOKEN, response.data.token);
                            if (response.data.investorType === 'R') {
                                localStorage.setItem("NameSurname", response.data.name + ' ' + response.data.surname);
                                localStorage.setItem("HelloMsgNameSurnameTitle", 'Hoşgeldiniz Sayın ' + response.data.name + ' ' + response.data.surname);
                            } else {
                                localStorage.setItem("NameSurname", response.data.title);
                                localStorage.setItem("HelloMsgNameSurnameTitle", 'Hoşgeldiniz Sayın ' + response.data.title);
                            }
                            localStorage.setItem("HelloMsg", response.data.helloMsg);
                            localStorage.setItem("SicilNo", response.data.sicilNo);
                            localStorage.setItem("InvestorType", response.data.investorType);
                            localStorage.setItem("QualificationStat", response.data.qualificationStat);
                            localStorage.setItem("Country", response.data.country);

                      if (response.data.status === Globals.UNREGISTERED_USER) {
                        setShowRegistration(true);
                      }
                            else {
                        setFirstLogin(true);
                        getApproveCode(false);
                      }
                      disableLoading();
                      setSubmitting(false);

                      resetCaptcha();
                    
                        }).catch(function (error) {
                      setCaptchaCount(captchaCount+1);
                      disableLoading();
                      setSubmitting(false);
                      var err = checkHttpStatus(error, props.intl);
                      if('ER173' === err)
                                setLoginLock(true);

                      resetCaptcha();


                    });
                }else{
                  disableLoading();
                  setSubmitting(false);
                  setFirstLogin(true);
                  getApproveCode(false);
                }
              
                }else{
                if(!waitingCaptchaService){
                  alertBox("Hata", "Lütfen captcha alanını doldurunuz.", Globals.ALERT_ERROR, intl);
                }
                setSubmitting(false);
              }
            }

              }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                style={{ marginTop: 16, marginBottom: 16 }}
                onSubmit={handleSubmit}
              >
                <div className="kt-login__divider">
                  <div className="kt-divider">
                    <span />

                    <span />
                  </div>
                </div>

                <div className="form-group" style={{ height: 94 }}>
                  <TextField
                    defaultValue={
                      localStorage.getItem("mkkSicilNo") === null
                        ? ""
                        : localStorage.getItem("mkkSicilNo")
                    }
                    id="mkkSicilNo"
                    type="text"
                    label="TCKN/VKN/MKK Sicil Numarası"
                    margin="normal"
                    className="kt-width-full"
                    name="mkkSicilNo"
                    variant="outlined"
                    inputProps={{ maxLength: 11 }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyPress={(event) => {
                      charCodeControl(event);
                    }}
                    helperText={errors.mkkSicilNo}
                    error={Boolean(touched.mkkSicilNo && errors.mkkSicilNo)}
                  />
                </div>

                <div className="form-group" style={{ height: 94 }}>
                  <TextField
                    margin="normal"
                    label="Şifre"
                    className="kt-width-full"
                    name="password"
                    variant="outlined"
                    inputProps={{
                      maxLength: 6,
                      autoComplete: "new-password",
                      type: "password",
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                {captchaCount >= 2 ? (
                  <ReCAPTCHA
                    sitekey={siteKey}
                    onChange={handleOnChangeCaptcha}
                    ref={captcha}
                    onExpired={expiredCaptcha}
                  />
                ) : null}

                <div
                  className="kt-login__actions"
                  style={!notxs ? { margin: 0 } : null}
                >
                  <Link
                    onClick={() => setShowPassInfo(true)}
                    className="kt-link kt-login__link-forgot"
                    style={{ color: "#aa3323", fontWeight: "bold" }}
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>

                  <Button
                    id="kt_login_signin_submit"
                    type="submit"
                    color="primary"
                    size="large"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting}
                    classes={{ label: classes.whiteText }}
                    className={`btn btn-secondary btn-elevate ${clsx({
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                    })}`}
                    style={{ width: "45%" }}
                  >
                    {!loginLock ? (
                      <FormattedMessage id="COMMON.BUTTON_CONTINUE" />
                    ) : (
                      <FormattedMessage id="COMMON.BUTTON_SEND" />
                    )}
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckBox}
                        value={checked}
                        color="primary"
                      />
                    }
                    label={props.intl.formatMessage({
                      id: "AUTH.LOGIN.REMEMBER_ME",
                    })}
                  ></FormControlLabel>
                </div>

                <div>
                  <Dialog
                    open={firstLogin}
                    // onClose={handleClose4}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    style={{ textAlign: "left" }}
                    onExit={() => setFirstInitialize(true)}
                  >
                    <DialogTitle id="form-dialog-title">
                      {props.intl.formatMessage({
                        id: "AUTH.VALIDATION.ENTER_WITH_APPROVE_CODE",
                      })}
                    </DialogTitle>
                    <DialogContent>
                      <Typography gutterBottom variant="body1">
                        {!loginLock
                          ? "Sayın " + localStorage.getItem("NameSurname")
                          : ""}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body1"
                        id="pleasecode"
                      ></Typography>

                      <TextField
                        autoFocus
                        autoComplete="off"
                        margin="dense"
                        id="confirmCode"
                        label="Onay Kodu"
                        type="numeric"
                        variant="outlined"
                        onChange={(event) => handleApproveCodeInput(event)}
                        onKeyPress={(event) => {
                          charCodeControl(event);
                        }}
                      />

                      <div>
                        <br />
                        <Button
                          color="primary"
                          onClick={() => {
                            setFirstLogin(false);
                            removeTimer();
                          }}
                        >
                          {props.intl.formatMessage({
                            id: "COMMON.BUTTON_CANCEL",
                          })}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          id="approveButton"
                          disabled={!sendCodeIsAbled && !firstInitialize}
                          onClick={() => {
                            confirmApproveCode();
                          }}
                        >
                          {props.intl.formatMessage({
                            id: "COMMON.BUTTON_ENTER",
                          })}
                        </Button>
                      </div>

                      <div>
                        <br />
                        <DialogContentText style={{ marginBottom: 0 }}>
                          <Typography
                            variant="subtitle1"
                            id="infoArea3"
                          ></Typography>
                        </DialogContentText>
                             {countDown  ? (intervalFunc(3,() =>timeIsUp()),
                                                        setCountDown(false),setSendCodeIsAbled(true)) : null}
                                                    
                                                    <span id="timerArea" ></span>               

                      </div>
                      {/* <span className="form-text" id="infoArea3">
                          </span> */}
                      <div>
                        <br />
                        <Button
                          color="primary"
                          disabled={sendCodeIsAbled && !confirmCodeBlock}
                          onClick={() => {
                            removeTimer();
                            getApproveCode(edevletLogin);
                          }}
                        >
                          {
                       
                          smsIsAbled ? "Onay kodunu telefonuma gönder" :  "Onay kodunu e-posta adresime gönder" }
                        </Button>
                        <br />

                        {country === "TR" && investorType === "R" ? (
                          <Button
                            color="primary"
                            onClick={() => {
                              removeTimer();
                              shouldSendCodeToEmail();
                            }}
                          >
                            Onay kodunu e-posta adresime gönder
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => {
                              removeTimer();
                              shouldSendCodeToSms();
                            }}
                          >
                            Onay kodunu telefonuma gönder
                          </Button>
                        )}
                      </div>
                    </DialogContent>
                  </Dialog>
                  {showGreetingMessage(showMessage)}
                  <ChangePassword open={updatePassword} />
                </div>
              </form>
            )}
          </Formik>

          <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>VEYA</span>
              <span />
            </div>
          </div>

          <div className="kt-login__options">
            <button className="btn kt-btn"
              style={{ "width": "100%", backgroundColor: 'white', borderColor: 'rgba(188,10,23,1)', borderWidth: 1 }}
              onClick={goToEgovern}
            >
              <img
                style={{ height: 40 }}
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/edevletlogo-withText-red.png")}
              />
            </button>
          </div>
          <div>
            <Dialog
              open={showPassInfo}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              style={{ textAlign: "left" }}
            >
              <DialogTitle id="form-dialog-title">
                <Typography variant='h5' align='center' style={{color: '#aa3323',fontWeight:'bold'}}>
                  Şifremi Nasıl Oluştururum?
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>
                     e-YATIRIMCI uygulamasına giriş yapabilmek için ihtiyacınız olan MKK Sicil ve Şifresi kişiye özel olarak oluşturulmaktadır.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>
                      MKK Sicil numaranız varsa,
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'  style={{marginLeft: 3}} >
                    &nbsp; &nbsp;  <span>●</span>  &nbsp; e-YATIRIMCI uygulamasına e-Devlet ile giriş yaptıktan sonra Ayarlar/Güvenlik İşlemleri adımından şifrenizi oluşturabilir ya da değiştirebilirsiniz.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline' style={{marginLeft: 3}}>
                    &nbsp; &nbsp;  <span>●</span>  &nbsp; MKK Sicil Şifrenizi 444 0 655 numaralı MKK İletişim Merkezini arayarak da oluşturabilirsiniz.               
                     </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography classes={{h6:classes.fontLight}} variant='h6' display='inline'>
                      MKK Sicil numaranız bulunmuyorsa, yatırım kuruluşlarında yatırım hesabı açtırarak sicil numarasına sahip olabilirsiniz.                    
                     </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: 16 }}>
                <Button
                  variant="contained" color="primary"
                  onClick={() => {setShowPassInfo(false); setSmsIsAbled(false); setEpostaIsAbled(false) }}
                >
                  {props.intl.formatMessage({ id: "COMMON.MODAL_CLOSE" })}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);