/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {connect} from "react-redux";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

/**
 * Created by gaslan on 3.4.2020
 */
class UserProfile extends React.Component {
    /**
     *
     * @return {*}
     */
    render() {
        const {showHi, showBadge} = this.props;

        return (
            <div className="kt-header__topbar-item kt-header__topbar-item--user">
                <div className="kt-header__topbar-user">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                {showHi && (
                                    <span className="kt-header__topbar-welcome">
                                        Hoş Geldiniz Sayın
                                    </span>
                                )}
                                {showHi && (
                                    <span className="kt-header__topbar-username">
                                        {localStorage.getItem('NameSurname')}
                                    </span>
                                )}
                            </div>
                            {localStorage.getItem('SicilNo') !== "null" && (
                                <div className="row">
                                    {showHi && (
                                        <span className="kt-header__topbar-welcome">
                                          MKK Sicil Numaranız:
                                        </span>
                                    )}
                                    {showHi && (
                                        <span className="kt-header__topbar-username">
                                          {localStorage.getItem('SicilNo')}
                                        </span>
                                    )}
                                </div>
                            )
                            }
                            {showHi && (
                                <div className="row">
                                    <OverlayTrigger delay={{hide: 450, show: 100}}
                                                    overlay={(props) => (<Tooltip  {...props}>Nitelikli yatırımcı olmaya ilişkin kriterler Sermaye Piyasası Kurulu tarafından belirlenmektedir. Yatırım kuruluşunuzdan detaylı bilgi alabilirsiniz.</Tooltip>)}
                                                    placement="bottom">
                                        {localStorage.getItem('QualificationStat') !== "null" && localStorage.getItem('QualificationStat') === "Y" ?
                                            (<span className="kt-header__topbar-welcome">
                                                <b> Nitelikli Yatırımcı </b>
                                             </span>)
                                            :
                                            (<span className="kt-header__topbar-welcome">
                                                <b> Nitelikli Olmayan Yatırımcı </b>
                                             </span>)
                                        }
                                    </OverlayTrigger>
                                </div>
                            )
                            }


                        </div>
                    </div>

                    {showBadge && (
                        <span
                            className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
              {/* TODO: Should get from currentUser */}
                            John Doe
            </span>
                    )}
                </div>
                {/* <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'
          <div className="kt-notification">
            <Link to="/logout">
            <a className="kt-notification__item" >
                <div className="kt-notification__item-icon">
                  <i className="flaticon-logout kt-font-danger" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    Güvenli Çıkış
                  </div>
                </div>
            </a>
            </Link>
          </div>
        </Dropdown.Menu> */}
            </div>
        );
    }
}

/**
 *mapStateToProps
 * @param user
 * @return {{user: *}}
 */
const mapStateToProps = ({auth: {user}}) => ({
    user
});

export default connect(mapStateToProps)(UserProfile);