import history from '../../history';

export const HOME = '/';
export const LOGIN = '/login';
export const DASHBOARD = '/dashboard';
export const PORTFOLIO = '/portfolio-balance';
export const CONTACTFORM = '/contact-form';
export const APPROVELOGINFORM = '/approve-login';
export const BALANCE_INFO = '/balance-info';
export const EDEVLET_CONFIRM  = '/edevlet-confirm/:edevletToken';
export const UPDATEFORM = '/user-info';
export const TRANSACTIONS = '/account-transactions';
export const INVESTOR_BLOCKAGE = '/investor-blockage';
export const AUTOMATIC_BLOCKAGE = '/automatic-blockage';
export const KFS_LIMIT_VIEW = '/kfs-limit-view';
export const RIGHTS_INFORMATION = '/corporate-actions-information';
export const AGENTSHIP_PERMISSION = '/e-gem-agency-permission';
export const HK_REPORTS = '/corporate-actions-reports';
export const NOTIFICATION_PREFERENCES = '/notification-preferences';
export const SECURITY_SETTINGS = '/security-settings';
export const CONTACT_US = '/contact-us';

export const ADD_REQUEST = '/request-form';

export const REPORT_REQUEST = '/request-list';
export const KAP_DISCLOSURE = '/pdp-disclosures';
export const USER_GUIDELINE = '/user-guideline';
export const YRTS = "/yrts-acik-riza";
export const HPKS_TRANSFER = "/hpks-transfer";
export const HPKS_BALANCES = "/hpks-balances";
export const HPKS_GM = "/hpks-gm";
export const HPKS = "/hpks-transfer-notification";
export const PROMOTION_APP = "/promotion-request";
export const YRTS_QUERY = "/yrts-yk-sorgu";
export const KFS_PORTFOLIO = "/kfs-portfolio-balance";
export const ELUS_PORTFOLIO = "/elus-portfolio-balance";
export const ELUS_DOCS = "/elus-as-documents";
export const KFS_TRANSACTION = "/kfs-member-transaction";
export const ADMIN_AUTHORIZATION = "/admin-authorization";
export const PARAMETER_DEFINITION = "/parametre-tanimlama";
export const PENDING_TRADE = "/pending-trade";
export const DIVIDEND_TRANSACTION = "/dividend-transaction";

export const goto = (path) => { history.push(path) };
 