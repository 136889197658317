import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NumberFormat from 'react-number-format';
import { Link, withRouter } from "react-router-dom";

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;

}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        ['@media (max-width:1024px)']: {
            fontSize: 11
        },
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'executiveOffice',     numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.EXECUTIVE_OFFICE"}) },
        { id: 'fileNo',   numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.FILE_NO"}) },
        { id: 'noticeDate',  numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PORTFOLIO.TABLE.NOTICE_DATE"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13,
        ['@media (max-width:1024px)']: {
            fontSize: 11
        },
    }
}));
/**
 * GenericPortfolio
 * @param props
 * @return {*}
 * @constructor
 */
export default function DistraintInfo (props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('accNo');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const rows = props.distraintInfo;

    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    
    return (   
                <div>
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                intl={props.intl}
                            />
                            <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.name + index}
                                        >
                                            <TableCell component="th" key={"executiveOffice_"+index} id={"executiveOffice_"+index}
                                                       className={classes.fontSize13}> {row.executiveOffice} </TableCell>
                                            <TableCell component="th" key={"fileNo_"+index} id={"fileNo_"+index}
                                                       className={classes.fontSize13}> {row.fileNo} </TableCell>
                                            <TableCell component="th" key={"noticeDate_"+index} id={"snoticeDate_"+index}
                                                       className={classes.fontSize13}> {row.noticeDate} </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText = {props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage = {props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
    )
}