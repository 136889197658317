import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect, } from "react-redux";
import { get, post, checkHttpStatus } from '../../../utils/RestUtilsWithAxios';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button  from "@material-ui/core/Button";
import Dropdown from '../../../components/Dropdown';
import { alertBox } from '../../../components/BoxProvider';
import DownloadButtons from '../../../components/PdfAndExcelDownloadButtons';
import * as Globals from '../../../constants/globals';
import PledgeTable from './PledgeTable';
import * as DownloadUtils from '../../../utils/downloadUtils';
import {formatDecimal,printThisPage} from '../../../utils/ExportUtils';
import Icon from '@material-ui/core/Icon';

import {
    PLEDGE_GET_MEMBER_LIST,
    PLEDGE_GET_ACCOUNTS_FOR_MEMBER,
    GET_PARAMETER_DATA,
    GET_PLEDGE_DATA,
    PLEDGE_COLLATERAL_DOWNLOAD_AS_EXCEL,
    PLEDGE_COLLATERAL_DOWNLOAD_AS_PDF
} from '../../../constants/uri';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    formControl: {
        margin: theme.spacing(1),
    },
    group: {
        margin: theme.spacing(1, 0),
    },
    showButton: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: 200
        },
    },
    textContainer: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },

}))

/**
 * Pledge
 * @param props
 * @return {*}
 * @constructor
 */
function Pledge(props) {
    const classes = useStyles();
    const [members, setMembers] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [value, setValue] = useState("RHNA");
    const [explanations, setExplanations] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedExplanation, setSelectedExplanation] = useState(null);
    const [dataFieldMessage, setDataFieldMessage] = useState('')
    const [showExportButton, setShowExportButton] = useState(false);

    const [data, setData] = useState([]);

    const headRows = [
        { id: 'senderMemberAccount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.SENDER"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.SENDER_SUBTITLE"}) },
        { id: 'receiverMemberAccount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.RECEIVER"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.RECEIVER_SUBTITLE"}) },
        { id: 'safeKeepingMemberAccount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.KEEPER"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.KEEPER_SUBTITLE"}) },
        { id: 'isinInfo', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ISIN_INFO"}), secondLabel: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ISIN_INFO_SUBTITLE"}), style:{minWidth:180} },
        { id: 'enterExitDate', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ENTER_EXIT_DATE"}) },
        { id: 'amount', numeric: true, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.AMOUNT"}) },
        { id: 'type', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.TYPE"}) },
        { id: 'altArtFlag', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id: "PLEDGE.TABLE.ALT_ART_FLAG"}) },
    ];

    /**
     * prepareItemArr
     * @param trArray
     * @return {Array}
     */
    function prepareItemArr(trArray) {
        var setArray = [];
        for(var i=0;i<trArray.length;i++){
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(trArray[i].senderMemberAccount);
            valueItem.values.push(trArray[i].receiverMemberAccount);
            valueItem.values.push(trArray[i].safeKeepingMemberAccount);
            valueItem.values.push(trArray[i].isinInfo);
            valueItem.values.push(trArray[i].enterExitDate);
            if(trArray[i].amount == null){
                valueItem.values.push('');
            }else{
                valueItem.values.push(formatDecimal(trArray[i].amount.toFixed(3)));
            }
            valueItem.values.push(trArray[i].type);
            valueItem.values.push(trArray[i].altArtFlag);
            setArray.push(valueItem);
        }
        return setArray;
    }

    React.useEffect(() => {
        get(PLEDGE_GET_MEMBER_LIST)
            .then(function (response) {
                var setList = [];
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setMembers(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })

        post(GET_PARAMETER_DATA, { name: 'RT_NEDENI' })
            .then(function (response) {
                var setList = [];
                response.data.map((item) => {
                    var newItem = {}
                    newItem.value = item[0];
                    newItem.label = item[1];
                    setList.push(newItem);
                })
                setExplanations(setList);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }, []);

    /**
     * handleRadioChange
     * @param event
     */
    function handleRadioChange(event) {
        setValue(event.target.value);
    }

    /**
     * memberSelected
     * @param changedValue
     */
    function memberSelected(changedValue) {
        post(PLEDGE_GET_ACCOUNTS_FOR_MEMBER, { mbrId: changedValue })
            .then(function (response) {
                var setList = [];
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].key;
                    newItem.label = response.data[i].text;
                    setList.push(newItem);
                }
                setAccounts(setList);
                setSelectedAccount(null);
                setSelectedMember(changedValue);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     * retreivePledgeData
     */
    function retreivePledgeData() {
        var details = { memberId: selectedMember, accountNo: selectedAccount,
            rtReason: selectedExplanation, rtType: value }
        if (!selectedMember || selectedMember === '' || !selectedAccount || selectedAccount === ''
            || !selectedExplanation || selectedExplanation === '') {
            alertBox("", props.intl.formatMessage({ id: "ALERT.PLEDGE.ENTER_DETAILS" }),
                Globals.ALERT_WARNING, props.intl);
            return;
        }
        setShowExportButton(false);
        post(GET_PLEDGE_DATA, details)
            .then(function (response) {
                setData(response.data);
                if (response.data.length === 0) {
                    setDataFieldMessage("Rehin / Teminat işleminiz bulunmamaktadır.");
                } else {
                    setDataFieldMessage("");
                    setShowExportButton(true);
                }
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     * downloadAsExcel
     */
    function downloadAsExcel() {
        post(PLEDGE_COLLATERAL_DOWNLOAD_AS_EXCEL, { 'list': data })
            .then(function (response) {
                DownloadUtils.downloadFileByContentExcel(response.data, "Rehin İşlemleri.xls",
                    Globals.CONTENT_TYPE_EXCEL);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    /**
     * downloadAsPdf
     */
    function downloadAsPdf() {
        post(PLEDGE_COLLATERAL_DOWNLOAD_AS_PDF, { 'list': data })
            .then(function (response) {
                DownloadUtils.downloadFileByContent(response.data, "Rehin İşlemleri.pdf", Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <Grid container>
                    <Typography variant="h5">{props.intl.formatMessage({ id: "PLEDGE.SELECT_PLEDGE_TYPE" })}
                    </Typography>
                    <Grid container direction="row" alignItems="flex-end" >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup
                                row
                                name="pledgeSelect"
                                className={classes.group}
                                value={value}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel control={<Radio color="primary" />} label="R/T Alacaklısı"
                                                  value="RHNA" />
                                <FormControlLabel control={<Radio color="primary" />} label="R/T Borçlusu"
                                                  value="RHNV" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography variant="h5">{props.intl.formatMessage({ id: "PLEDGE.ENTER_PLEDGE_DETAILS" })}</Typography>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Dropdown required={true} name="members" id="members" title={props.intl.
                        formatMessage({ id: "PLEDGE.DROPDOWN.INSTITUTION_NAME" })} items={members}
                                  handleContainerChange={memberSelected} containerSelectedItem={selectedMember} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Dropdown required={true} name="accounts" id="accounts" title={props.intl.
                        formatMessage({ id: "PLEDGE.DROPDOWN.ACCOUNT_NO" })} items={accounts}
                                  handleContainerChange={setSelectedAccount} containerSelectedItem={selectedAccount} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Dropdown required={true} name="explanations" id="explanations" title={props.intl.
                        formatMessage({ id: "PLEDGE.DROPDOWN.DESCRIPTION" })} items={explanations}
                                  handleContainerChange={setSelectedExplanation}
                                  containerSelectedItem={selectedExplanation} />
                    </Grid>
                </Grid>
                <Grid container justify="flex-end">
                    <Button variant='contained' color='primary' size='large' onClick={retreivePledgeData}
                            className={classes.showButton} fullWidth>
                        {props.intl.formatMessage({ id: "COMMON.BUTTON_SHOW" })}
                    </Button>
                </Grid>
            </Paper>
            <Paper className={classes.paper}>
                {
                    data.length > 0 ?
                        <div>
                            <PledgeTable pledgeData={data} intl={props.intl} />
                            <DownloadButtons pdfOnClick={() => printThisPage(prepareItemArr(data),headRows,
                                'REHİN VE TEMİNAT İŞLEMLERİ RAPORU',false)} excelOnClick={() => downloadAsExcel()} />
                        </div>
                        :
                        <Typography align='center' classes={{h6: classes.textContainer}} variant='h6'>
                            {dataFieldMessage}
                        </Typography>
                }
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(Pledge)
);