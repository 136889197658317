import React from 'react';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {GET_DEBT_INSTRUMENTS_REPORTS,
        DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_EXCEL,
        DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_PDF} from "../../constants/uri";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import MyTable from '../../components/MyTable';
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/es/Typography/Typography";
import HkFilterPanel from './HkFilterPanel';
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import { alertBox } from '../../components/BoxProvider';
import format from "date-fns/format";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin:'auto',
        padding: 30,
        paddingTop:20
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

/**
 * DebtInstrumentsReportPage
 * @param props
 * @return {*}
 * @constructor
 */
function DebtInstrumentsReportPage(props) {

    /**
     * formatDate
     * @param dateStr
     */
    function formatDate(dateStr) {
        var date = new Date(dateStr);
        var formatedDate = format(date, "dd/MM/yyyy");
        return formatedDate;
    }

    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for (var i = 0; i < array.length; i++) {
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].assetDescCol);
            valueItem.values.push(array[i].isinCol);
            valueItem.values.push(array[i].assetClassCol);
            valueItem.values.push(array[i].assetTypeCol);
            valueItem.values.push(array[i].processTypeCol);
            valueItem.values.push(array[i].paymentDateCol);
            //valueItem.values.push(formatDate(array[i].paymentDateCol));
            valueItem.values.push(array[i].interestDividenDistRationCol);
            setArray.push(valueItem);
        }
        return setArray;
    }
    
    const headRows = [
        { id: 'assetDescCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ASSET_DESC" }) },
        { id: 'isinCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ISIN" }) },
        { id: 'assetClassCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ASSET_CLASS" }) },
        { id: 'assetTypeCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_ASSET_TYPE" }) },
        { id: 'processTypeCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_PROCESS_TYPE" }) },
        { id: 'paymentDateCol', numeric: false, date: true, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_PAYMENT_DATE" }) },
        { id: 'interestDividenDistRationCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DI_INTEREST_DIVIDEND_DISTRIBUTION_RATIO" }) }
    ];
    const alignment = ['left', 'left', 'left', 'left', 'left', 'right', 'right' ];

    /**
     * filterReports
     * @param selectedBeginDate
     * @param selectedEndDate
     * @param selectedIhrMember
     */
    function filterReports(selectedBeginDate,selectedEndDate,selectedIhrMember) {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.substring(4,6)+'-'+selectedEndDate.
        substring(6,8));
        var beginDate = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));
        
        if (beginDate.getTime() > endDate.getTime()) {
            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, props.intl);
            return;
        }
        post(GET_DEBT_INSTRUMENTS_REPORTS,{beginDate:selectedBeginDate,endDate:selectedEndDate,mbrId:selectedIhrMember})
            .then(function (response) {
                setReportList([]);
                setReportList(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error,props.intl);
            })
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        post(serviceName, {debtInstrumentList: reportList})
            .then(function (response) {
                if (serviceName === DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_EXCEL)
                    DownloadUtils.downloadFileByContentExcel(response.data, "Borçlanma Araçları.xls",
                        Globals.CONTENT_TYPE_EXCEL);
                else if (serviceName === DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_PDF)
                    DownloadUtils.downloadFileByContent(response.data, "Borçlanma Araçları.pdf",
                        Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    const [reportList,setReportList] = useState([]);
    const [fade,setFade] = useState(false);
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Paper className={classes.paper} >
                <HkFilterPanel initOnOpen={true} filterReports={filterReports} ihrMembers={props.ihrMembers}/>
                <Fade in={fade}>
                    {
                        reportList.length > 0 ?
                            <div> 
                                <MyTable expandable = {false}
                                            intl={props.intl}
                                            tableRows={prepareItemArray(reportList)}
                                            headRows={headRows}
                                            alignment = {alignment}
                                            defaultSort='desc'
                                            defaultSortIndex={5}
                                            dateTimeColumns={[5]}
                                            numericColumns={[6]}
                                            printOption={true}
                                            downloadAsExcel={() => download(DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_EXCEL)}
                                            printHeader='BORÇLANMA ARAÇLARI BİLGİ RAPORU'
                                />
                            </div>
                            :
                            <Typography variant="subtitle1">
                                {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DATA_NOT_FOUND"})}
                            </Typography> 
                    }
                </Fade>
            </Paper>
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(DebtInstrumentsReportPage)
);