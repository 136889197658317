import React, { useState } from "react";
import { connect} from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { alertBox } from '../../../components/BoxProvider';
import * as Regex from "../../../constants/Regexp";
import { post, checkHttpStatus } from '../../../utils/RestUtilsWithAxios';
import { UPDATE_GREETING_MESSAGE } from '../../../constants/uri';
import * as Globals from '../../../constants/globals';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    formWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    form: {
        width: '100%',
        maxWidth:450
    },
    showButton: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width:200
        },
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#aa332340"
    }
}))

/**
 * ChangePassword
 * @param props
 * @return {*}
 * @constructor
 */
function ChangePassword(props) {

    const classes = useStyles();
    const [message, setMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Grid container style={{maxWidth:450}} direction='column'>
            <h3>
                {props.intl.formatMessage({id:"SECURITY.FORM.WELCOME_MESSAGE_TITLE"})}
            </h3>
            <Divider className={classes.divider}/>
            <Formik
                enableReinitialize
                initialValues={{
                    newMessage: message,
                }}
                validate={values => {
                    const errors = {};

                    setMessage(values.newMessage);

                    if (!Regex.WELCOME.test(values.newMessage)) {
                        errors.newMessage = props.intl.formatMessage({
                            id: "AUTH.VALIDATION.INVALID_FIELD"
                        });
                    }
                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    var postData = {};
                    postData['greetingMessage'] = values.newMessage;

                    post(UPDATE_GREETING_MESSAGE, postData)
                    .then(function (response) {
                        if(response.data.status) {
                            alertBox(props.intl.formatMessage({id:"SECURITY.ALERT.SUCCESS_TITLE_2"}), props.intl.formatMessage({id:"SECURITY.ALERT.MESSAGE_CHANGED"}), Globals.ALERT_SUCCESS, props.intl)
                            localStorage.setItem("HelloMsg", values.newMessage);    
                        }
                        else
                            alertBox(props.intl.formatMessage({id:"SECURITY.ALERT.ERROR_TITLE_2"}),response.data.exception.message, Globals.ALERT_ERROR, props.intl)
                        setSubmitting(false);
                    })
                    .catch(function (error) {
                        setSubmitting(false);
                        checkHttpStatus(error,props.intl);
                    })
                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        {status && (
                            <div role="alert" className="alert alert-danger">
                                <div className="alert-text">{status}</div>
                            </div>
                        )}

                        <div className="form-group mb-0" style={{height:188}}>
                            <TextField
                                id="newMessage"
                                margin="normal"
                                label={props.intl.formatMessage({id:"SECURITY.FORM.NEW_WELCOME_MESSAGE"})}
                                className="kt-width-full"
                                name="newMessage"
                                disabled={false}
                                variant="outlined"
                                multiline
                                rows="8"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                defaultValue={localStorage.getItem('HelloMsg')}
                                helperText={touched.newMessage && errors.newMessage}
                                error={Boolean(touched.newMessage && errors.newMessage)}
                                inputProps={{
                                    maxLength: 50
                                }}
                                InputProps={{
                                    endAdornment: <a style={{alignSelf:"flex-start"}} onClick={()=> setDialogOpen(true)}><InfoIcon color="primary" size="large" /></a>
                                }}
                            />
                        </div>

                        <Grid container justify='flex-end'>
                            <Button variant="contained" color='primary' size='large' fullWidth type="submit" className={classes.showButton}>
                                {props.intl.formatMessage({id: "SECURITY.BUTTON.NEW_WELCOME_MESSAGE"})}
                            </Button>   
                        </Grid>

                        <Dialog open={dialogOpen}
                                aria-labelledby="max-width-dialog-title"
                                maxWidth={"sm"}
                                fullWidth={true}
                                style={{textAlign:"left"}}
                        >
                            <DialogTitle id="max-width-dialog-title">
                                {props.intl.formatMessage({id: "SECURITY.MODAL.WELCOME_TITLE"})}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant="subtitle1">
                                        {props.intl.formatMessage({id:"SECURITY.MODAL.WELCOME_DESC"})}
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{padding:16}}>
                                <Button onClick={()=>setDialogOpen(false)} color="primary">
                                    {props.intl.formatMessage({id:"COMMON.MODAL_CLOSE"})}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                )}
            </Formik>
        </Grid>
    )

}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
      null,
    )(ChangePassword)
);