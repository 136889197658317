import React from 'react';
import { useState } from 'react';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
    GET_EGK_INFOS, EGK_INFOS_DOWNLOAD_AS_EXCEL, EGK_INFOS_DOWNLOAD_AS_PDF,
    SERMAYE_INFOS_DOWNLOAD_AS_EXCEL
} from "../../constants/uri";
import { checkHttpStatus, get } from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/es/Paper/Paper";
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from "@material-ui/core/es/Typography/Typography";
import MyTable from '../../components/MyTable';
import { makeStyles } from "@material-ui/core/styles/index";
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
}));

/**
 * GkPage
 * @param props
 * @return {*}
 * @constructor
 */
function GkPage(props) {

    React.useEffect(() => {
        get(GET_EGK_INFOS)
            .then(function (response) {
                setGkInfos(response.data);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }, []);

    /**
     * prepareItemArray
     * @param array
     * @return {Array}
     */
    function prepareItemArray(array) {
        var setArray = [];
        for (var i = 0; i < array.length; i++) {
            var valueItem = {};
            valueItem.values = [];
            valueItem.values.push(array[i].titleInfo);
            valueItem.values.push(array[i].typeInfo);
            valueItem.values.push(array[i].timeInfo);
            setArray.push(valueItem);
        }
        return setArray;
    }

    /**
     * download
     * @param serviceName
     */
    function download(serviceName) {
        get(serviceName)
            .then(function (response) {
                if (serviceName === EGK_INFOS_DOWNLOAD_AS_EXCEL)
                    DownloadUtils.downloadFileByContentExcel(response.data, "Genel Kurul.xls",
                        Globals.CONTENT_TYPE_EXCEL);
                else if (serviceName === EGK_INFOS_DOWNLOAD_AS_PDF)
                    DownloadUtils.downloadFileByContent(response.data, "Genel Kurul.pdf",
                        Globals.CONTENT_TYPE_PDF);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })
    }

    const [gkInfos,setGkInfos] = useState([]);
    const [fade,setFade] = useState(false);
    const classes = useStyles();
    const headRows = [
        { id: 'titleCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.COMPANY" }) },
        { id: 'typeCol', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.GK_TYPE" }) },
        { id: 'timeCol', numeric: false, date: true, disablePadding: false, label: props.intl.
            formatMessage({ id: "RIGHTS_INFORMATION.TABLE.DATE_TIME" }) }
    ];
    const alignment = ['left', 'left', 'right'];
    return (
        <div>
            {
                gkInfos && gkInfos.length > 0 ?
                    <Paper className={classes.paper} >
                        <Fade in={fade}>
                            <div>
                                <MyTable expandable = {false}
                                        intl={props.intl}
                                        tableRows={prepareItemArray(gkInfos)}
                                        headRows={headRows}
                                        alignment = {alignment}
                                        defaultSort='desc'
                                        defaultSortIndex={2}
                                        dateTimeColumns={[2]}
                                        printOption={true}
                                        downloadAsExcel={() => download(EGK_INFOS_DOWNLOAD_AS_EXCEL)}
                                        printHeader='GENEL KURUL BİLGİLERİ'
                                />
                                <Grid container style={{marginTop:20}}>
                                    <InfoIcon size="small" style={{marginRight:8}} />
                                    <Typography display="inline" variant="subtitle1">
                                        {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.DETAILED_INFO"})} 
                                    </Typography>
                                </Grid>
                            </div>
                        </Fade>
                    </Paper>
                    :
                    <Paper className={classes.paper} >
                        <Fade in={fade}>
                            <Typography variant="subtitle1">
                                {props.intl.formatMessage({id:"RIGHTS_INFORMATION.MESSAGE.GK_NOT_FOUND"})}
                            </Typography>
                        </Fade>                        
                    </Paper>
            }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(GkPage)
);