import React, {useEffect} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {GET_CONFIG_PARAM} from "../../constants/uri";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import * as Globals from "../../constants/globals";

var intl = undefined;

function MaintenancePage() {

    const [trText, setTrText] = React.useState("");
    const [enText, setEnText] = React.useState("");

    useEffect(() => {
        getParameter(Globals.EYAT_MKS_DOWN_MESSAGE_DATE_INFO_TR);
        getParameter(Globals.EYAT_MKS_DOWN_MESSAGE_DATE_INFO_EN);
    });

    function getParameter(key) {
        post(GET_CONFIG_PARAM, {name: key})
            .then(function (response) {
                if (key == Globals.EYAT_MKS_DOWN_MESSAGE_DATE_INFO_EN)
                    setEnText(response.data);
                else
                    setTrText(response.data);
            })
            .catch(function (error) {
                checkHttpStatus(error, intl);
            });
    }

    return (
        <>
            <div style={{backgroundColor: 'white', height: '100%', paddingTop:'50px' }}>
                <img style={{marginLeft:'auto', marginRight:'auto', display:'block', paddingBottom:'50px' }}  src="/media/logos/merkezi-kayit-istanbul-logo.png" />
                <div style={{color: '#9f3122', textAlign: 'center', fontFamily: 'arial', fontWeight: 'bold', paddingBottom:'20px'}}>
                    <p style={{fontSize:'1.5rem'}}>{trText}</p>
                    <p style={{fontSize:'1.5rem'}}>Lütfen daha sonra tekrar deneyiniz.</p>
                    <p style={{fontSize:'1.5rem'}}>Anlayışınız için teşekkür ederiz.</p>
                </div>
                <hr style={{border: '1px solid #9f3122' }}/>
                <div style={{color: '#9f3122', textAlign: 'center', fontFamily: 'arial', fontWeight: 'bold', paddingTop:'20px'}}>
                    <p style={{fontSize:'1.5rem'}}>{enText}</p>
                    <p style={{fontSize:'1.5rem'}}>Please try again later.</p>
                    <p style={{fontSize:'1.5rem'}}>Thank you for your understanding.</p>
                </div>
            </div>
        </>
    );
}

export default injectIntl(
    connect(
        null,
    )(MaintenancePage)
);
