import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogActions ,DialogContent} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core"
import { Typography } from '@material-ui/core';
import * as Globals from "../../../constants/globals";

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: 5,
        backgroundColor: Globals.COLOR_DARK_GRAY,
        padding: 10,
        color: 'white',
        borderRadius: 4
    }
}));
/**
 * PartnersInfo
 * @param props
 * @return {null}
 * @constructor
 */
export default function PartnersInfo (props) {
    const classes = useStyles();

    return (
        props.account ?
        <Dialog
            open={props.open}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {props.account.member} | {props.account.accountNo}
            </DialogTitle>
            <DialogContent>
            <Typography gutterBottom color='primary' variant='subtitle1'>
                {props.intl.formatMessage({id:"ACCOUNT_INFO.MODAL.TITLE"}) + props.account.partnerType}
            </Typography>
            {
                props.partnerList.map(partner => (
                        <Grid container direction="row" justify="space-between" alignItems="flex-end" className={classes.grid}>
                                <Typography display='inline' variant='h6'>{partner.title}</Typography>
                                <Typography display='inline' variant='h4'>{partner.percentage}</Typography>
                        </Grid>
                ))
            }
            </DialogContent>
            <DialogActions style={{padding:16}}>
                <Button color='primary' onClick={()=>{props.onClose(false); props.selectAccount(null)}}>
                    {props.intl.formatMessage({id:"COMMON.MODAL_CLOSE"})}
                </Button>
            </DialogActions>
        </Dialog>
        : null
    )
}